import { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import type { Asset } from 'types/asset.types';
import { ThemePalette } from 'mui.theme';
import { Chart } from './Chart';
import { VehicleSelector } from './Components/VehicleSelector';

interface Props {
  enableDarkTheme: boolean;
  comparingAsset: string;
  assets: Asset[];
  tireData: any;
  colors: string[];
  unitSystem: string;
  metricType: string;
  setMetricType: any;
  dataType: string;
  tireCodes: any;
  limits: any;
}

const CompareContent = ({
  enableDarkTheme,
  comparingAsset,
  assets,
  tireData,
  colors,
  unitSystem,
  metricType,
  setMetricType,
  dataType,
  tireCodes,
  limits,
}: Props) => {
  const assetData = assets.find((item) => item.bumperNumber === comparingAsset);

  let assetTireData = { pressureData: {} };
  if (assetData) {
    assetTireData = tireData[assetData?.device.deviceId] ?? {
      pressureData: {},
    };
  }
  const [profiles, setProfiles] = useState<string[]>(
    Object.keys(assetTireData.pressureData)
  );

  if (!assetData) {
    return <></>;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item lg={5} sm={12} md={6} xs={12}>
          <Box
            sx={{
              border: '1px solid #2F445E',
              borderRadius: '4px',
              padding: '10px 10px 5px 10px',
            }}
          >
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              {`Tire ${metricType} Trend for  ${
                assetData ? assetData.bumperNumber : ''
              }`}
            </Typography>
            <Chart
              enableDarkTheme={enableDarkTheme}
              data={assetTireData}
              colors={colors}
              allowedProfiles={profiles}
              dataType={dataType}
              unitSystem={unitSystem}
              tireCodes={tireCodes}
              metricType={metricType}
              setMetricType={setMetricType}
            />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            {Object.keys(assetTireData?.pressureData).map((item, index) => {
              const values = Object.values(assetTireData?.pressureData);
              // @ts-expect-error ignoring for checks
              const hasNoData = values[index].length === 0;
              return (
                <FormControlLabel
                  key={index}
                  label={
                    <span style={{ fontSize: '12px' }}>
                      {tireCodes[item] ?? item}
                    </span>
                  }
                  control={
                    <Checkbox
                      size="small"
                      sx={{
                        color: hasNoData
                          ? ThemePalette.typography.lightGrey
                          : colors[index],
                        '&.Mui-checked': {
                          color: hasNoData
                            ? ThemePalette.typography.lightGrey
                            : colors[index],
                        },
                        '& svg': {
                          fontSize: '16px',
                        },
                      }}
                      checked={hasNoData ? false : profiles.includes(item)}
                      onChange={() => {
                        if (hasNoData) {
                          return;
                        }
                        if (profiles.includes(item)) {
                          setProfiles(profiles.filter((e) => e !== item));
                        } else {
                          setProfiles([...profiles, item]);
                        }
                      }}
                    />
                  }
                  sx={{
                    color: hasNoData
                      ? ThemePalette.typography.lightGrey
                      : colors[index],
                  }}
                />
              );
            })}
          </Box>
        </Grid>
        <Grid item lg={7} sm={12} md={6} xs={12}>
          <Box
            sx={{
              border: '1px solid #2E435E',
              padding: '10px',
              borderRadius: '4px',
              marginTop: '10px',
            }}
          >
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '14px',
                fontWeight: '700',
                margin: '5px',
              }}
            >
              {`Tire ${metricType} Status for  ${
                assetData ? assetData.bumperNumber : ''
              }`}
            </Typography>
            <Grid container spacing={2}>
              <VehicleSelector
                tireData={assetTireData}
                limits={limits}
                assetType={assetData?.assetType ?? ''}
                enableDarkTheme={enableDarkTheme}
                unitSystem={unitSystem}
                tireCodes={tireCodes}
                metricType={metricType}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompareContent;
