import dayjs from 'dayjs';
import { type Asset } from 'types/asset.types';
import { formatDateToString } from 'utils/helpers/dateTime';

export const chartSelectorMap: Record<string, number> = {
  '1D': 1,
  '7D': 7,
  '14D': 14,
  '1Y': 365,
  '1m': 1 / 1440, // 1 minute (1 day has 1440 minutes)
  '10m': 10 / 1440, // 10 minutes
  '30m': 30 / 1440, // 30 minutes
  '1h': 1 / 24, // 1 hour (1 day has 24 hours)
  '6h': 6 / 24, // 6 hours
};

/**
 * Retrieves and filters chart data based on the selected chart selector.
 * @param chartData An array of chart data objects.
 * @param selectedChartSelector The selected chart selector value.
 * @returns An array of filtered and formatted chart data objects.
 */
export function getChartData(
  chartData: any[],
  selectedChartSelector: string,
  isSymStart?: boolean,
  tagName?: string,
  asset?: Asset
) {
  const unixCurrentTime = dayjs().valueOf();
  const timeFrame = chartSelectorMap[selectedChartSelector];
  const unixEndDate = dayjs().subtract(timeFrame, 'days').valueOf();
  let filteredArray = [];
  let noData = [];
  if (!chartData?.length) {
    return [];
  }
  if (selectedChartSelector === 'ALL') {
    filteredArray = chartData;
  } else {
    filteredArray = chartData.filter(
      (obj) => obj.ts >= unixEndDate && obj.ts <= unixCurrentTime
    );
  }

  // NODATA now comes from api for all the missing timestamps
  // We need to filter NODATA points if all points received contains NODATA.
  noData = filteredArray.filter((obj) => obj.avg_val !== 'NODATA');
  if (noData.length === 0) {
    filteredArray = noData;
  }

  return filteredArray?.map((data: any) => {
    return {
      ...data,
      value: getTagValue(isSymStart, data, tagName, asset),
      createdAt: formatDateToString(data.ts, 'YYYY MMM DD HH:mm'),
    };
  });
}

const getTimeData = (arg: any) => {
  const value = dayjs.unix(arg.value).format('YYYY MMM DD HH:mm');
  return new Date(value).getTime();
};

export const getTagValue = (
  isSymStart: any,
  data: any,
  tagName: any,
  asset: any
) => {
  if (isSymStart) {
    return getTimeData(data);
  }

  if (tagName?.includes('edge_odometer')) {
    const defaultAssetOdometer = asset?.odometerOffset || 0;
    let tagOdometerValue = data.max_val || 0;
    tagOdometerValue = tagOdometerValue / 1000; // Converting to KM
    return (tagOdometerValue as number) + (defaultAssetOdometer as number);
  }

  return data.value;
};

export function formatChartData(
  chartData: any[],
  isSymStart?: boolean,
  tagName?: string,
  asset?: Asset
) {
  return chartData?.map((data: any) => {
    return {
      ...data,
      value: getTagValue(isSymStart, data, tagName, asset),
    };
  });
}
