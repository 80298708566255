import StyledSwitchButton from 'components/Buttons/SwitchButton';
import { ThemePalette } from 'mui.theme';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

interface Props {
  onLabel?: string;
  offLabel?: string;
  showShiftWise: boolean;
  setShowShiftWise: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const DailyShiftTimelineSwitch = ({
  onLabel,
  offLabel,
  showShiftWise,
  setShowShiftWise,
  disabled,
}: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowShiftWise(e.target.checked);
  };
  return (
    <div
      style={{
        color: enableDarkTheme ? ThemePalette.typography.mediumGray : '#3d3d3d',
        fontSize: '12px',
      }}
    >
      <span>{offLabel}</span>
      <StyledSwitchButton
        checked={showShiftWise}
        onChange={handleToggle}
        disabled={disabled}
      />
      <span>{onLabel}</span>
    </div>
  );
};

export default DailyShiftTimelineSwitch;
