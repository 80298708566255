import { useEffect, useState, type SyntheticEvent } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'store/hook';
import useDateRange from 'hooks/dateRange';
import DriverBehaviorHandler from 'handlers/driver.behavior.handler';
import { isDarkTheme } from 'utils/theme';
import Fleet from './Fleet';
import TimeLine from './TimeLine';
import Header from './Header';
import dayjs from 'dayjs';
// import TimeLine from 'views/EngineHours/Components/TimeLine';
import { SingleFadeLoader } from 'components/SingleFadeLoader';

const DriverBehavior = () => {
  const customerCode = useAppSelector((state) => state.authReducer).customer
    .code;

  const driverBehaviorHandler = new DriverBehaviorHandler();
  const [isSelected, setIsSelected] = useState('1D');
  const [timeLineAsset, setTimeLineAsset] = useState('');
  const [timelineDate, setTimelineDate] = useState('');
  const [value, setValue] = useState(0);
  const { startDate, endDate, setStartDate, setEndDate, yesterday } =
    useDateRange();
  const { data, isLoading } = useAppSelector(
    (state) => state.driverBehaviorReducer
  );
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChange = (
    newValue: number,
    assetId?: string,
    selectedDate?: string
  ) => {
    if (assetId && selectedDate) {
      setTimeLineAsset(assetId);
      setTimelineDate(selectedDate);
    } else {
      setTimeLineAsset('');
    }
    setValue(newValue);
  };

  const setHandleChange = (event: SyntheticEvent, newValue: number) => {
    handleChange(newValue);
  };

  const fetchData = async (startDate: Date, endDate: Date, range: string) => {
    if (range === 'Custom') {
      if (startDate && endDate) {
        const startDateFormatted = dayjs(startDate).format('YYYY-MM-DD');
        const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD');
        await driverBehaviorHandler.getAll({
          startDate: startDateFormatted,
          endDate: endDateFormatted,
        });
      }
    } else {
      await driverBehaviorHandler.getAll({
        range,
      });
    }
  };

  useEffect(() => {
    if (!customerCode) return;
    void fetchData(startDate, endDate, isSelected);
  }, [isSelected, endDate, customerCode]);

  useEffect(() => {
    setTimelineDate('');
  }, [isSelected]);

  return (
    <Box
      sx={{
        margin: '10px 0px 10px 0px',
      }}
    >
      <Header
        enableDarkTheme={enableDarkTheme}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        yesterday={yesterday}
        handleDateChange={handleDateChange}
        setHandleChange={setHandleChange}
        value={value}
        isLoading={isLoading}
      />

      {isLoading ? (
        <SingleFadeLoader />
      ) : (
        <>
          <Fleet
            enableDarkTheme={enableDarkTheme}
            value={value}
            handleChange={handleChange}
            data={data}
          />
          <TimeLine
            enableDarkTheme={enableDarkTheme}
            value={value}
            timeLineAsset={timeLineAsset}
            timelineDate={timelineDate}
            data={data}
          />
        </>
      )}
    </Box>
  );
};

export default DriverBehavior;
