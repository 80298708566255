import { useMemo, useState } from 'react';
import { ThemePalette } from 'mui.theme';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { Box, Grid, Typography } from '@mui/material';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import type { DeviceStats } from 'types/utilization.types';
import { FlexBox } from 'components/Containers/FlexBox';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import AssetItem from 'views/Utilization/AllFleet/Components/AssetItem';
import {
  calculateUtilization,
  getDataUnit,
  getColor,
  convertMinutesToHours,
  convertsecondsToHours,
  getShiftHours,
} from 'views/Utilization/utils';
import { truncateTo2dp } from 'utils/helpers/general';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  customerCode: string;
  isLoading: boolean;
  isSelected: string;
  data: DeviceStats[];
  isLoaderType?: boolean;
  isPrinting: boolean;
  utilizationType: string;
}

const AssetView = ({
  enableDarkTheme,
  unitSystem,
  customerCode,
  isLoading,
  data,
  isLoaderType = false,
  isPrinting,
  utilizationType,
}: Props) => {
  let options = [
    'Production',
    'Utilization',
    'Fuel',
    'Avg Fuel Rate',
    'Engine Hours',
    'Idling Hours',
    'Productive Hours',
  ];

  if (isLoaderType) {
    options = [
      'Utilization',
      'Fuel',
      'Avg Fuel Rate',
      'Engine Hours',
      'Idling Hours',
      'Productive Hours',
    ];
  }
  const sortOptions = ['Least Utilization', 'Most Utilization'];
  const [selectedSource, setSelectedSource] = useState<string>('Utilization');
  const [selectedSort, setSelectedSort] = useState<string>('Most Utilization');

  const formattedData = data.map((item) => {
    const prodValue = Object.values(item.date_stats).reduce(
      (acc, stats) => acc + (stats.max_load_ton || 0),
      0
    );
    const noOfValidEntries = Object.values(item.date_stats).filter(
      (entry) => entry.utilization_engine_active_minute > 0
    ).length;
    const shiftHours =
      noOfValidEntries > 0
        ? getShiftHours(customerCode) *
          (noOfValidEntries - 1 > 0 ? noOfValidEntries - 1 : 1)
        : 0;
    return {
      ...item,
      utilization: Number(item.avg_utilization_by_shift_hours) * 100,
      fuel: Number(item.total_fuel_consumption_l),
      avgfuelrate: +truncateTo2dp(item.avg_hourly_fuel_consumption_l),
      enginehours: Number(
        convertMinutesToHours(Number(item.total_engine_active_minutes))
      ),
      idlinghours: Number(
        convertsecondsToHours(Number(item.total_idling_duration))
      ),
      productivehours: Number(
        convertMinutesToHours(Number(item.total_engine_working_minutes))
      ),
      production: Number(truncateTo2dp(prodValue)),
      unit: getDataUnit(selectedSource, unitSystem),
    };
  });

  const sortedListData = [...formattedData].sort((a: any, b: any) => {
    if (selectedSort === 'Least Utilization') {
      return a.utilization - b.utilization;
    }
    return b.utilization - a.utilization;
  });

  // filtering out falsy values
  const sortedTreeMap = useMemo(() => {
    return sortedListData
      .filter(
        (item: any) => item[selectedSource.toLowerCase().replace(/ /g, '')]
      )
      .map((item, index, arr) => ({
        isMin:
          selectedSort === 'Least Utilization'
            ? index === 0
            : index === arr.length - 1,
        ...item,
      }));
  }, [sortedListData]);

  return (
    <Box
      sx={{
        marginTop: isPrinting ? '60%' : '20px',
        padding: 2,
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        borderRadius: '5px',
        border: '1px solid #2F445D',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '600',
              paddingTop: '5px',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            Detailed Analysis
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
              paddingRight: '15px',
              paddingTop: '5px',
            }}
          >
            Show on chart
          </Typography>
          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={options}
            selected={selectedSource}
            setSelected={setSelectedSource}
            minWidth="150px"
          />

          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
              paddingLeft: '10px',
              paddingRight: '15px',
              paddingTop: '5px',
            }}
          >
            Sort By
          </Typography>

          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={sortOptions}
            selected={selectedSort}
            setSelected={setSelectedSort}
            minWidth="150px"
          />
        </Box>
      </Box>

      {isLoading ? (
        <MultiColorCircularLoader height="200px" />
      ) : (
        <Grid
          container
          sx={{
            marginTop: '10px',
          }}
        >
          <Grid lg={12} sm={12} md={12}>
            <Box>
              <FlexBox
                sx={{
                  maxWidth: 'inherit',
                  height: '230px',
                  paddingTop: '20px',
                  marginRight: '10px',
                  paddingBottom: '10px',
                }}
              >
                {sortedTreeMap.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <Treemap
                      width={800}
                      height={600}
                      data={sortedTreeMap}
                      dataKey={selectedSource.toLowerCase().replace(/ /g, '')}
                      stroke="#fff"
                      fill={ThemePalette.typography.lightGrey}
                      content={<CustomContent />}
                    >
                      <Tooltip
                        cursor={{ fill: '#1B2B4A' }}
                        wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                        contentStyle={{
                          backgroundColor: 'transparent',
                        }}
                        itemStyle={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                        }}
                        content={
                          <CustomTooltip
                            enableDarkTheme={enableDarkTheme}
                            selectedSource={selectedSource}
                          />
                        }
                      />
                      ;
                    </Treemap>
                  </ResponsiveContainer>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    No Data available for the selected date range
                  </Typography>
                )}
              </FlexBox>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                * coloring is based on least productive asset
              </Typography>
            </Box>
          </Grid>
          <Grid lg={12} sm={12} md={12}>
            <Box
              sx={{
                marginTop: '15px',
              }}
            >
              {sortedListData.length > 0 ? (
                <>
                  {sortedListData.map((item, index) => {
                    const isFifthItem = (index + 1) % 4 === 0; // Check if it's every fifth item

                    return (
                      <div
                        key={index}
                        style={
                          isFifthItem && isPrinting
                            ? { marginBottom: '35%' }
                            : {}
                        }
                      >
                        <AssetItem
                          enableDarkTheme={enableDarkTheme}
                          key={index}
                          data={item}
                          unitSystem={unitSystem}
                          customerCode={customerCode}
                          selectedSource={selectedSource}
                          utilizationType={utilizationType}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AssetView;

const CustomContent = ({
  x,
  y,
  width,
  height,
  bumper_id: bumperId,
  value,
  unit,
  ...rest
}: any) => {
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={getColor(rest?.isMin)}
        stroke="#fff"
      />
      {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
      <text
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        x={x + width / 2}
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        y={y + height / 2}
        textAnchor="middle"
        fill="#fff"
        fontSize={14}
      >
        {bumperId}
      </text>
      {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
      <text
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        x={x + width / 2}
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        y={y + height / 2 + 20}
        textAnchor="middle"
        fill="#fff"
        fontSize={12}
      >
        {`${convertToTwoDecimalPlaces(value)} ${unit ?? ''}`}
      </text>
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
  enableDarkTheme,
  selectedSource,
}: any) => {
  if (active && payload && payload.length) {
    const bumperId = payload[0].payload.bumper_id;
    const unit = payload[0].payload.unit;

    return (
      <div
        style={{
          background: 'transparent',
          color: enableDarkTheme
            ? ThemePalette.typography.wheat
            : ThemePalette.typography.black,
          fontSize: '11px',
          outline: 'none',
        }}
      >
        {/* Replace with the custom attribute you want to show */}
        <p>{`Bumper Id: ${bumperId}`}</p>
        <p>{`${selectedSource}: ${truncateTo2dp(payload[0].value)}${unit}`}</p>
      </div>
    );
  }
  return null;
};
