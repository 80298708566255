import { Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { extractTagChartData } from '../../utils';
import { IndividualAreaChart } from './Area';

export function formatYAxis(value: any) {
  let updatedValue = value;
  if (value >= 1e12) {
    // Trillions
    updatedValue = `${(value / 1e12).toFixed(2)} T`;
  } else if (value >= 1e9) {
    // Billions
    updatedValue = `${(value / 1e9).toFixed(2)} B`;
  } else if (value >= 1e6) {
    // Millions
    updatedValue = `${(value / 1e6).toFixed(2)} M`;
  } else if (value >= 1e3) {
    // Thousands
    updatedValue = `${(value / 1e3).toFixed(2)} k`;
  }
  return updatedValue;
}

export const Chart = ({
  enableDarkTheme,
  data,
  keyToUse,
  activeButton,
  selectedAsset,
  selectedOperation,
  startDate,
  endDate,
  unitSystem,
  tagGrainData,
  dataType,
  grainStartDate,
  grainEndDate,
}: {
  enableDarkTheme: boolean;
  data: {
    tagName: string;
    tagDetails: any[];
    isValidRange: boolean;
  };
  keyToUse: string;
  activeButton: string;
  selectedAsset: any;
  selectedOperation: any;
  startDate: any;
  endDate: any;
  unitSystem: string;
  tagGrainData: any;
  dataType: string;
  grainStartDate: any;
  grainEndDate: any;
}) => {
  const { filteredArray, unit } = extractTagChartData(
    data,
    keyToUse,
    activeButton,
    startDate,
    endDate,
    selectedAsset,
    unitSystem,
    dataType,
    tagGrainData
  );

  return (
    <FlexBox
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        width: '100%',
        height: '300px',
      }}
    >
      {filteredArray.length > 0 ? (
        <IndividualAreaChart
          enableDarkTheme={enableDarkTheme}
          data={filteredArray}
          dataType={dataType}
          unit={unit}
          selectedOperation={selectedOperation}
          startDate={grainStartDate}
          endDate={grainEndDate}
        />
      ) : (
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {' '}
          No Data For the selected time range
        </Typography>
      )}
    </FlexBox>
  );
};
