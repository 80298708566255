import { Grid } from '@mui/material';
import { CustomTabPanel } from '../CustomPanel';
import { ThemePalette } from 'mui.theme';
import FleetProductionDetails from './components/FleetProductionDetails';
import FleetProductionChart from './components/FleetProductionChart';
import AverageCycleTime from './components/AverageCycleTime';
import Suggestions from '../components/Suggestions/Suggestions';
import AnalyticsWrapper from './components/AnalyticsWrapper';
import AssetWrapper from './components/AssetWrapper';
import DateRangeDiv from '../HeaderMenu/DateRangeDiv';
import type {
  fleetData,
  productionAsset,
  productionLoadingStates,
} from 'types/production.types';
import {
  calculateCurrentDailyAverage,
  getHaulerSummary,
  getLoaderSummary,
  getProductionAssetsByTypeMapping,
} from './utils';
import { useMemo, useState } from 'react';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  data: fleetData;
  loadingStates: productionLoadingStates;
  unitSystem: string;
  customerCode: string;
  assetOverview: any;
  aggregation: string;
  setAggregation: any;
  isPrinting: boolean;
}

const AllFleet = ({
  value,
  enableDarkTheme,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  data,
  loadingStates,
  unitSystem,
  customerCode,
  assetOverview,
  aggregation,
  setAggregation,
  isPrinting,
}: Props) => {
  const haulersSummary: any = getHaulerSummary(data.summary);
  const loaderSummary: any = getLoaderSummary(data.summary);

  const haulerAssets: productionAsset[] = getProductionAssetsByTypeMapping(
    data.assets,
    'Haul Truck'
  );
  const loaderAssets: productionAsset[] = getProductionAssetsByTypeMapping(
    data.assets,
    'Loader'
  );

  const [rollingDailyAverage, setRollingDailyAverage] = useState<number>(0);

  const currentDailyAverage = useMemo(() => {
    return calculateCurrentDailyAverage(data?.fleetPeriodSummary ?? []);
  }, [data?.fleetPeriodSummary]);

  return (
    <>
      <CustomTabPanel value={value} index={0}>
        <div>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <FleetProductionDetails
              enableDarkTheme={enableDarkTheme}
              data={data.totalFleetDetails}
              isLoading={loadingStates.isLoadingFleetDetails}
              unitSystem={unitSystem}
              currentDailyAverage={currentDailyAverage}
              rollingDailyAverage={rollingDailyAverage}
              isLoadingFleetPeriodSummary={
                loadingStates.isLoadingFleetPeriodSummary
              }
            />
            <FleetProductionChart
              enableDarkTheme={enableDarkTheme}
              data={data.fleetPeriodSummary}
              isLoading={loadingStates.isLoadingFleetPeriodSummary}
              unitSystem={unitSystem}
              title="Fleet Production"
              setRollingDailyAverage={setRollingDailyAverage}
            />
          </Grid>

          <DateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            yesterday={yesterday}
            ignoreYtd={value === 2}
            aggregation={aggregation}
            setAggregation={setAggregation}
          />

          <AnalyticsWrapper
            enableDarkTheme={enableDarkTheme}
            data={data.summary}
            isLoading={loadingStates.isLoadingFleetSummary}
            unitSystem={unitSystem}
            assetOverview={assetOverview}
            scoreData={data.score}
            chartData={data.summaryChart}
            aggregation={aggregation}
            isSelected={isSelected}
            customerCode={customerCode}
          />
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Grid item lg={9} sm={12} md={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <AverageCycleTime
                  enableDarkTheme={enableDarkTheme}
                  title="Average Hauler Cycle Time"
                  data={haulersSummary}
                  isLoading={loadingStates.isLoadingFleetSummary}
                />
                <AverageCycleTime
                  enableDarkTheme={enableDarkTheme}
                  title="Average Loader Cycle Time"
                  data={loaderSummary}
                  isLoading={loadingStates.isLoadingFleetSummary}
                />
              </Grid>
            </Grid>
            <Suggestions
              enableDarkTheme={enableDarkTheme}
              minHeight="300px"
              data={data.suggestions}
              isLoading={loadingStates.isLoadingSuggestions}
              startDate={startDate as string}
              isSelected={isSelected}
            />
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              marginTop: isPrinting ? '20%' : null,
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <AssetWrapper
              enableDarkTheme={enableDarkTheme}
              title="Haulers"
              data={haulerAssets}
              isLoading={loadingStates.isLoadingFleetAssets}
              unitSystem={unitSystem}
              customerCode={customerCode}
              isPrinting={isPrinting}
            />
            <AssetWrapper
              enableDarkTheme={enableDarkTheme}
              title="Loaders"
              data={loaderAssets}
              isLoading={loadingStates.isLoadingFleetAssets}
              unitSystem={unitSystem}
              customerCode={customerCode}
              isPrinting={isPrinting}
            />
          </Grid>
        </div>
      </CustomTabPanel>
    </>
  );
};

export default AllFleet;
