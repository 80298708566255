import BaseAPI from './base.api';

export default class FuelAnalyticsAPI extends BaseAPI {
  getFuelEfficiency = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/fuel-efficiency`,
      {
        params: {
          makeModels,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getFuelIdleEvents = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/idle-events`,
      {
        params: {
          makeModels,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getFuelNoneIdleEvents = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/non-idling-event`,
      {
        params: {
          makeModels,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getFuelConsumptionEngineHours = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/fuel-consumption-engine-hours`,
      {
        params: {
          makeModels,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getMonthOnMonthData = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    selectedConsumptionRange: string,
    startDate?: string,
    endDate?: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/fuel-consumption-month-on-month`,
      {
        params: {
          makeModels,
          range: selectedConsumptionRange,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getFleetFuelConsumptionPerDevice = async (
    startDate?: string,
    endDate?: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/customer-device-fuel-consumption`,
      {
        params: {
          startDate: startDate ?? null,
          endDate: endDate ?? null,
        },
      }
    );
    return response.data;
  };

  getNonHaulTrucksFuelEfficiency = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/get-non-haul-truck-fuel-efficiency`,
      {
        params: {
          makeModels,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getNonHaulTrucksFuelIdleEvents = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/get-non-haul-truck-fuel-event-handling-time`,
      {
        params: {
          makeModels,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getEngineHours = async () => {
    const response = await this.API.get(
      'analytics/fuel-analytics/engine-hours-summary'
    );
    return response.data;
  };

  getEngineHoursWithinRange = async (startDate: string, endDate: string) => {
    const response = await this.API.get(
      'analytics/fuel-analytics/engine-hours-summary-within-range',
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getAssetEngineHour = async (
    deviceId: string,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/device-engine-hours`,
      {
        params: {
          deviceId,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getAssetEngineHourPerDay = async (
    deviceId: string,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/device-engine-hours-per-day`,
      {
        params: {
          deviceId,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getHistoricalAnalysisCardData = async (
    type: string,
    startDate: string,
    endDate: string
  ) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/historical-analysis-card`,
      {
        params: {
          type,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getFuelUsageReport = async (startDate: string, endDate: string) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/fuel-usage-report`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };

  getAirFilters = async (devices: string[]) => {
    const response = await this.API.get(`analytics/fuel-analytics/air-filter`, {
      params: {
        devices,
      },
    });
    return response.data;
  };
}
