import React from 'react';

interface CustomContentProps {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  name: string;
  bumperNumber: string;
  value: number;
  unit: string;
  selectedDeviceId: string | null;
  isHovered: boolean;
}

const CustomContent: React.FC<CustomContentProps> = ({
  x,
  y,
  width,
  height,
  color,
  name,
  bumperNumber,
  value,
  unit,
  selectedDeviceId,
  isHovered,
}) => {
  const fontSize = 12;
  const lineHeight = fontSize * 1.2;

  const dynamicColor = selectedDeviceId === name ? '#8884d8' : color;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={dynamicColor}
        stroke="#fff"
        style={{ cursor: 'pointer' }}
      />
      <text
        x={x + width / 2}
        y={y + height / 2 - lineHeight}
        textAnchor="middle"
        fill="#fff"
        fontSize={fontSize * 0.9}
      >
        {`Bumper No. ${bumperNumber}`}
      </text>
      <text
        x={x + width / 2}
        y={y + height / 2 + lineHeight}
        textAnchor="middle"
        fill="#fff"
        fontSize={fontSize * 0.9}
      >
        {`${value?.toFixed(2)} ${unit}`}
      </text>
    </g>
  );
};

export default CustomContent;
