/**
 * Checks if a value is a valid number or numeric string.
 * Returns true for finite numbers and strings that can be converted to valid numbers.
 * Returns false for NaN, Infinity, -Infinity, null, undefined, and non-numeric strings/values.
 *
 * @param value - The value to check
 * @returns True if value is a valid number or numeric string, false otherwise
 *
 * @example
 * isNumber(123)      // true
 * isNumber('123')    // true
 * isNumber('123.45') // true
 * isNumber('abc')    // false
 */
export const isNumber = (value: unknown): boolean => {
  // Handle number type
  if (typeof value === 'number') {
    return !isNaN(value) && Number.isFinite(value);
  }

  // Handle string type
  if (typeof value === 'string') {
    const trimmed = value.trim();
    return (
      trimmed !== '' &&
      !isNaN(Number(trimmed)) &&
      Number.isFinite(Number(trimmed))
    );
  }

  return false;
};

export const tireCodeNames = {
  light_truck: {
    '0x17': 'Front-Left',
    '0x19': 'Front-Right',
    '0x27': 'Rear-Left',
    '0x29': 'Rear-Right',
  },
  heavy_truck: {
    '0x17': 'Front-Left',
    '0x19': 'Front-Right',
    '0x27': 'Rear-Left',
    '0x29': 'Rear-Right',
  },
  dozer: {
    '0x17': 'Front-Left',
    '0x19': 'Front-Right',
    '0x27': 'Rear-Left',
    '0x29': 'Rear-Right',
  },
  loader: {
    '0x17': 'Front-Left',
    '0x19': 'Front-Right',
    '0x27': 'Rear-Left',
    '0x29': 'Rear-Right',
  },
  drill: {
    '0x17': 'Front-Left',
    '0x19': 'Front-Right',
    '0x27': 'Rear-Left',
    '0x29': 'Rear-Right',
  },
  grader: {
    '0x17': 'Front-Left',
    '0x19': 'Front-Right',
    '0x27': 'Middle-Left',
    '0x29': 'Middle-Right',
    '0x37': 'Rear-Left',
    '0x39': 'Rear-Right',
  },
  haul_truck: {
    '0x17': 'Front-Left',
    '0x19': 'Front-Right',
    '0x26': 'Rear-Outside-Left',
    '0x27': 'Rear-Inside-Left',
    '0x29': 'Rear-Inside-Right',
    '0x28': 'Rear-Outside-Right',
  },
};

export const fixedLimts = {
  imperial: {
    pressure: {
      minValue: 350,
      maxValue: 650,
      startValue: 0,
      endValue: 850,
    },
    temperature: {
      minValue: 50,
      maxValue: 176,
      startValue: 32,
      endValue: 212,
    },
  },
  metric: {
    temperature: {
      minValue: 10,
      maxValue: 80,
      startValue: 0,
      endValue: 100,
    },
    pressure: {
      minValue: 20,
      maxValue: 60,
      startValue: 0,
      endValue: 100,
    },
  },
};
