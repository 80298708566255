import { type Role } from 'components/Permission/permission.types';
import { appConfiguration } from 'utils/config/app.config';
interface NavigationParams {
  path: string;
  displayName: string;
  roles: Role[];
  iconName?: string;
}
interface HiddenNavigationParams {
  roles: Role[];
  path: string;
}

interface INavigationMap {
  fleetOverview: {
    fleetSummary: NavigationParams;
    engineHoursSummary: NavigationParams;
    dtcSummary: NavigationParams;
    healthIndex: NavigationParams;
    newLandingPage: NavigationParams;
  };
  vehicleStatus: NavigationParams;

  notifications: NavigationParams;
  driverBehavior: NavigationParams;
  roadConditionSummary: NavigationParams;

  utilization: {
    overview: NavigationParams;
    idlingAnalysis: NavigationParams;
  };
  fuelAnalytics: {
    fuelUsage: NavigationParams;
    historicalAnalysis: NavigationParams;
  };
  production: {
    overview: NavigationParams;
    loadCycleAnalysis: NavigationParams;
  };
  tirePressure: {
    fleet: NavigationParams;
    vahicle: NavigationParams;
  };
  customerSelectionView: NavigationParams;
  settings: {
    internalUsers: NavigationParams;
    externalUsers: NavigationParams;
    customers: NavigationParams;
    contactInformation: NavigationParams;
    rules: NavigationParams;
    // localization: NavigationParams;
    tags: NavigationParams;
  };
  reports: HiddenNavigationParams;
  squares: {
    dashboard: NavigationParams;
    costAnalysis: NavigationParams;
    // maintenance: NavigationParams;
    workOrders: NavigationParams;
    uploadWorkOrders: NavigationParams;
  };
  survivalAnalysis: {
    overview: NavigationParams;
    prognosis: NavigationParams;
  };
  development?: NavigationParams;
}

/*
  Source of truth for all routes, including nested routes
*/
export const navigationMap: INavigationMap = {
  fleetOverview: {
    fleetSummary: {
      path: '/fleet-summary',
      displayName: 'Fleet Summary',
      iconName: 'sidebarFleetOverview2',
      roles: [], // page is visible to any role
    },
    newLandingPage: {
      path: '/fleet-summary-new',
      displayName: 'New Landing page',
      iconName: 'sidebarFleetOverview2',
      roles: [], // page is visible to any role
    },
    engineHoursSummary: {
      path: '/engine-hours',
      displayName: 'Engine Hours Summary',
      iconName: 'sidebarFleetOverview2',
      roles: [],
    },
    dtcSummary: {
      path: '/diagnostic-tool-summary',
      displayName: 'Diagnostic Tool Summary',
      iconName: 'sidebarFleetOverview2',
      roles: [],
    },
    healthIndex: {
      path: '/health-index',
      displayName: 'Health Index',
      iconName: 'sidebarFleetOverview2',
      roles: [],
    },
  },

  vehicleStatus: {
    path: '/vehicle-status',
    displayName: 'Vehicle Status',
    iconName: 'sidebarVehicleStatus',
    roles: [],
  },

  notifications: {
    path: '/notifications',
    displayName: 'Notification Center',
    iconName: 'sidebarNotificationCenter',
    roles: [],
  },
  driverBehavior: {
    path: '/driver-behavior',
    displayName: 'Driver Behavior',
    iconName: 'driverBehavior',
    roles: [],
  },
  roadConditionSummary: {
    path: '/road-condition-summary',
    displayName: 'Road Condition Summary',
    iconName: 'sidebarRoadCondition',
    roles: [],
  },
  utilization: {
    overview: {
      path: '/utilization',
      displayName: 'Overview',
      iconName: 'sidebarUtilization',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
    idlingAnalysis: {
      path: '/utilization/analysis',
      displayName: 'Idle Analysis',
      iconName: 'sidebarUtilization',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
  },
  fuelAnalytics: {
    fuelUsage: {
      path: '/fuel-usage',
      displayName: 'Usage Dashboard',
      iconName: 'sidebarFuelUsage2',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
    historicalAnalysis: {
      path: '/fuel-usage/historical-analysis',
      displayName: 'Historical Analysis',
      iconName: 'sidebarFuelUsage2',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
  },
  production: {
    overview: {
      path: '/production/overview',
      displayName: 'Overview',
      iconName: 'sidebarProduction',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
    loadCycleAnalysis: {
      path: '/production/:assetVin',
      displayName: 'Load Cycle Analysis',
      iconName: 'sidebarProduction',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
  },
  tirePressure: {
    fleet: {
      path: '/tire-analytics/fleet-insight',
      displayName: 'Fleet Insight',
      iconName: 'sidebarProduction',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
    vahicle: {
      path: '/tire-analytics/vehicle-insight',
      displayName: 'Vehicle Insight',
      iconName: 'sidebarProduction',
      roles: ['internal-user', 'director', 'external-admin', 'external-user'],
    },
  },
  customerSelectionView: {
    path: '/customer-selection',
    displayName: 'Customer Selection',
    roles: ['director', 'internal-user'],
  },

  settings: {
    internalUsers: {
      path: '/settings/symboticware-user',
      displayName: 'Symboticware Users',
      roles: ['director', 'internal-user'],
    },
    externalUsers: {
      path: '/settings/user',
      displayName: 'Users & Assets',
      roles: ['internal-user', 'external-admin', 'external-user', 'director'],
    },
    customers: {
      path: '/settings/customer-accounts',
      displayName: 'Customer Accounts',
      roles: ['director'],
    },
    contactInformation: {
      path: '/settings/contact-information',
      displayName: 'Contact Information',
      roles: ['director', 'internal-user', 'external-admin'],
    },
    rules: {
      path: '/settings/rules',
      displayName: 'Rules',
      roles: ['internal-user', 'external-admin', 'external-user', 'director'],
    },
    // localization: {
    //   path: '/settings/localization',
    //   displayName: 'Localization Settings',
    //   roles: [],
    // },
    tags: {
      path: '/settings/tags',
      displayName: 'Tags',
      roles: ['director', 'internal-user'],
    },
  },
  reports: {
    path: '/reports/productivity',
    roles: ['internal-user', 'external-admin', 'director'],
  },
  squares: {
    // maintenance: {
    //   path: '/squares/maintenance',
    //   displayName: 'Maintenance',
    //   roles: ['director', 'external-admin'],
    // },
    dashboard: {
      path: '/squares/dashboard',
      displayName: 'Dashboard',
      roles: ['director', 'external-admin'],
    },
    costAnalysis: {
      path: '/squares/costAnalysis',
      displayName: 'Cost Analysis',
      roles: ['director', 'external-admin'],
    },
    workOrders: {
      path: '/squares/workOrder',
      displayName: 'Work Orders',
      roles: ['director', 'external-admin'],
    },
    uploadWorkOrders: {
      path: '/squares/uploadWorkOrders',
      displayName: 'Upload Work Orders',
      roles: ['director', 'external-admin'],
    },
    // ReportsView: {
    //   path: '/squares/Reports',
    //   displayName: 'Reports',
    //   roles: ['director', 'external-admin'],
    // },
  },
  survivalAnalysis: {
    overview: {
      path: '/survivalAnalysis/overview',
      displayName: 'Overview',
      roles: ['director', 'external-admin'],
    },
    prognosis: {
      path: '/survivalAnalysis/prognosis',
      displayName: 'Prognosis',
      roles: ['director', 'external-admin'],
    },
  },
  ...(appConfiguration.isDevelopment && {
    development: {
      path: '/playground',
      displayName: 'Playground',
      iconName: 'sidebarFleetOverview',
      roles: ['developer'],
    },
  }),
};
