import { Box, Typography } from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import { ThemePalette } from 'mui.theme';
import { type Asset } from 'types/asset.types';

export const AssetSelector = ({
  enableDarkTheme,
  makeModels,
  selectedMakeModel,
  setSelectedMakeModel,
  assetBumperIds,
  selectedAsset,
  setSelectedAsset,
  comparingAsset,
  setComparingAsset,
}: {
  enableDarkTheme: boolean;
  makeModels: string[];
  selectedMakeModel: string;
  setSelectedMakeModel: any;
  assetBumperIds: string[];
  selectedAsset: Asset;
  setSelectedAsset: any;
  comparingAsset: string;
  setComparingAsset: any;
}) => {
  const makeModelList = makeModels.map((item) => {
    return {
      id: item,
      display: item,
    };
  });

  const assetList = assetBumperIds.map((item) => {
    return { id: item, display: item };
  });

  return (
    <>
      {/* Make Model */}
      <Box
        sx={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            paddingBottom: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Make Model
        </Typography>
        <Selector
          minWidth="200px"
          value={selectedMakeModel}
          onChange={(event) => {
            setSelectedMakeModel(String(event.target.value));
          }}
          selectorOptions={makeModelList}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>
      {/* BUmper ID */}
      <Box
        sx={{
          paddingTop: '10px',
          paddingRight: '10px',
        }}
      >
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            paddingBottom: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Bumper Id
        </Typography>
        <Selector
          minWidth="200px"
          value={selectedAsset.bumperNumber}
          onChange={(event) => {
            setSelectedAsset(String(event.target.value));
          }}
          selectorOptions={assetList}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      {/* Compare to */}
      <Box
        sx={{
          paddingTop: '10px',
          paddingRight: '10px',
          marginLeft: '20px',
        }}
      >
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            paddingBottom: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Compare to
        </Typography>
        <Selector
          minWidth="200px"
          value={comparingAsset}
          onChange={(event) => {
            setComparingAsset(String(event.target.value));
          }}
          selectorOptions={assetList.filter(
            (item) => item.id !== selectedAsset.bumperNumber
          )}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>
    </>
  );
};
