import type { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import ReactApexChart from 'react-apexcharts';
import { Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import type { DriverBehaviorTimeLineData } from 'types/driver.behavior.types';
import {
  isAllTimeLineDataEmpty,
  transformDriverBehaviorData,
} from 'utils/driverBehavior';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { WarningAmberRounded } from '@mui/icons-material';
import { tooltip } from './tooltip';

interface Props {
  startDateWithTime?: string;
  endDateWithTime?: string;
  shiftsWithTime?: Array<{ startDate: string; endDate: string }>;
}

const colorPalette = [
  '#ff4560', // Red
  '#008ffb', // Blue
  '#00e396', // Green
  '#feb019', // Yellow
  '#775dd0', // Purple
  '#f46036', // Orange
];

const TimeLine = ({
  startDateWithTime,
  endDateWithTime,
  shiftsWithTime,
}: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const customerCode = useAppSelector((state) => state.authReducer).customer
    .code;
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const {
    data,
    isLoading,
    error: { timelineError },
  } = useAppSelector((state) => state?.driverBehaviorReducer);

  const [
    chartHeight,
    // setChartHeight
  ] = useState(550);

  // const

  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      height: 950,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      custom: (props) =>
        tooltip({
          ...props,
          unitSystem,
        }),
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        borderRadius: 2,
      },
    },
    colors: ['#FEB019', '#FF4560', '#00E396', '#325C9F', '#D2448F', '#777777'],
    xaxis: {
      type: 'datetime',
      position: 'top',
      labels: {
        datetimeUTC: false,
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
    },
    grid: {
      column: {
        colors: ['#152A4B', '#162C4E'],
      },
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: 'solid',
      opacity: 0.6,
    },
    legend: {
      position: 'top',
      labels: {
        colors: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      },
    },
  });

  useEffect(() => {
    if (!startDateWithTime || !endDateWithTime || !customerCode) {
      console.log('no selected start date or customer code');
      return;
    }
    let annotations: ApexAnnotations | undefined;
    if (shiftsWithTime && shiftsWithTime?.length > 0) {
      annotations = {
        xaxis: shiftsWithTime.map((shift, index) => ({
          x: new Date(shift.startDate).getTime(),
          x2: new Date(shift.endDate).getTime(),
          borderColor: colorPalette[index % colorPalette.length], // Rotate through colors
          fillColor: colorPalette[index % colorPalette.length], // Rotate through colors
          opacity: 0.2,
          label: {
            text: `Shift ${index + 1} ( ${dayjs(shift.startDate).format(
              `HH:mm`
            )} - ${dayjs(shift.endDate).format(`HH:mm`)} )`, // Label shifts uniquely
            style: {
              color: '#000',
            },
          },
        })),
      };
    }
    setChartOptions((prev) => ({
      ...prev,
      annotations,
      xaxis: {
        ...prev.xaxis,
        min: new Date(
          `${dayjs(startDateWithTime)?.format(`YYYY-MM-DD HH:mm:ss`)} UTC`
        ).getTime(),
        max: new Date(
          `${dayjs(endDateWithTime).format(`YYYY-MM-DD HH:mm:ss`)} UTC`
        ).getTime(),
      },
    }));
  }, [customerCode, startDateWithTime, endDateWithTime, shiftsWithTime]);

  const engineHoursTimeLineData = {
    productive: data.productive,
    idling: data.idling,
    engine_hours: data.engine_hours,
    braking: [],
    overspeed: data?.overspeed,
    incorrect_gear: data?.incorrect_gear,
  };

  const timeLineData = useMemo(() => {
    const x = transformDriverBehaviorData(engineHoursTimeLineData, true);
    const clientTimezoneOffset = new Date().getTimezoneOffset(); // Offset in minutes from UTC

    const utcToClientTZData: DriverBehaviorTimeLineData[] = x.map((item) => {
      return {
        ...item,
        data: item.data.map((data) => {
          const startDateUTC = new Date(data.y[0]);
          const startDateClient = new Date(
            startDateUTC.getTime() - clientTimezoneOffset * 60 * 1000
          );

          const endDateUTC = new Date(data.y[1]);
          const endDateClient = new Date(
            endDateUTC.getTime() - clientTimezoneOffset * 60 * 1000
          );

          return {
            ...data,
            y: [startDateClient.getTime(), endDateClient.getTime()],
          };
        }),
      };
    });

    return utcToClientTZData;
  }, [engineHoursTimeLineData]);

  // useEffect(() => {
  //   if (timeLineData && timeLineData?.length > 0) {
  //     let maxHeight = 0;
  //     timeLineData.forEach((item) => {
  //       const assets = new Set();
  //       item.data.forEach((data) => {
  //         assets.add(data.x);
  //       });
  //       maxHeight = Math.max(maxHeight, assets.size);
  //     });
  //     if (maxHeight !== 0) {
  //       setChartHeight(Math.min(maxHeight * 50, 550));
  //     }
  //   }
  // }, [timeLineData]);

  return (
    <div
      style={{
        height: `${chartHeight}px`,
        position: 'relative',
        borderRadius: '10px',
      }}
    >
      {timelineError && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            zIndex: 1,
          }}
        >
          <WarningAmberRounded />
          <p>{timelineError}</p>
        </div>
      )}
      {isLoading ? (
        <MultiColorCircularLoader height="300px" />
      ) : (
        <div
          style={{
            padding: '20px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {isAllTimeLineDataEmpty(timeLineData) ? (
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
              }}
            >
              No timeline data available for the selected time range.
            </Typography>
          ) : (
            <ReactApexChart
              options={chartOptions}
              series={timeLineData}
              type="rangeBar"
              height={chartHeight}
              width={'100%'}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TimeLine;
