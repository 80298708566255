import dayjs from 'dayjs';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Tooltip,
  Legend,
  Line,
  Dot,
  Area,
} from 'recharts';
import {
  type DeviceEngineHour,
  type EngineHourPerDayInstance,
} from 'store/engine.hours.slice';

interface Props {
  data: DeviceEngineHour[] | EngineHourPerDayInstance[];
  chartType?: 'area' | 'bar' | 'line';
}
export const formatTimestamp = (
  timestamp: number,
  format: string = 'YYYY-MMM-DD HH:mm (Z)'
): string => {
  const date = dayjs(timestamp * 1000);
  const formattedDate = date.format(format);
  return formattedDate;
};

export const EngineHoursAreaChart = ({
  data,
  chartType: type = 'area',
}: Props) => {
  return (
    <>
      {data.length > 0 ? (
        <>
          <ResponsiveContainer
            width="95%"
            height="90%"
            style={{ margin: 'auto' }}
          >
            <ComposedChart
              width={500}
              height={600}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 140,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="hour"
                // tickFormatter={formatTimestamp}
                angle={type === 'area' || type === 'line' ? -65 : 0}
                textAnchor={
                  type === 'area' || type === 'line' ? 'end' : 'middle'
                }
                tick={{ fontSize: 12 }}
                allowDuplicatedCategory={false}
              />
              <YAxis
                label={{
                  value: 'Hours',
                  angle: -90,
                  position: 'inside',
                  fill: 'grey',
                  dy: 10,
                  dx: -10,
                }}
                // domain={[0, 'dataMax + 1']}
              />
              <Tooltip />
              {type === 'bar' && (
                <>
                  <Bar
                    name={'Total Engine Hours'}
                    dataKey="engine_hour_total"
                    stackId="1"
                    fill="#8884d8"
                    barSize={45}
                    label={{
                      position: 'top',
                      enableBackground: 'true',
                      fill: '#8884d8',
                      fontSize: 16,
                      formatter: (value: any) => value?.toFixed(2),
                    }}
                  />
                  <Bar
                    name={'Total Idle Hours'}
                    dataKey="idle_hour_total"
                    stackId="2"
                    fill="#82ca9d"
                    barSize={45}
                    label={{
                      position: 'top',
                      enableBackground: 'true',
                      fill: '#82ca9d',
                      fontSize: 16,
                      formatter: (value: any) => value?.toFixed(2),
                    }}
                  />
                  <Bar
                    name={'Total Non Idle Hours'}
                    dataKey="non_idle_hour_total"
                    stackId="3"
                    fill="#ffc658"
                    barSize={45}
                    label={{
                      position: 'top',
                      enableBackground: 'true',
                      fill: '#ffc658',
                      fontSize: 16,
                      formatter: (value: any) => value?.toFixed(2),
                    }}
                  />
                </>
              )}
              {type === 'bar' && <Legend />}
              {type === 'area' && (
                <Area
                  dot={<Dot r={2} />}
                  type="monotone"
                  dataKey="engine_hour_total"
                  stackId="1"
                  stroke="#8884d8"
                  strokeWidth={2}
                  fill="#8884d8"
                />
              )}
              {type === 'line' && (
                <Line
                  dot={<Dot r={2} />}
                  type="monotone"
                  dataKey="engine_hour_total"
                  stroke="#8884d8"
                  strokeWidth={2}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
