import { Cartesian3 } from 'cesium';
import ReactDOMServer from 'react-dom/server';
import { type Asset } from 'types/asset.types';

export const svgToDataUrl = (svgElement: React.ReactElement) => {
  const svgString = new XMLSerializer().serializeToString(
    new DOMParser().parseFromString(
      ReactDOMServer.renderToStaticMarkup(svgElement),
      'image/svg+xml'
    )
  );
  return `data:image/svg+xml;base64,${btoa(svgString)}`;
};

export type CustomCSSProperties = React.CSSProperties & {
  '--circle-color'?: string;
  '--background-color'?: string;
  '--mountain-color'?: string;
};

export const getMakeModel = (asset: Asset) =>
  `${asset?.make?.toLowerCase()}${asset?.model?.toLowerCase()}`;

export const getIsAssetOffline = (asset: Asset) =>
  (asset?.device?.deviceStatus as string).toLowerCase() !== 'online';

export const svgStyleImport = (asset: Asset) => {
  const isAssetOffline = getIsAssetOffline(asset);
  return {
    '--background-color': isAssetOffline ? '#C3C3C3' : '#0000FF',
    '--mountain-color': isAssetOffline ? '#787878' : '#FFFFFF',
  };
};

// util func to convert GPS coordinates to Cartesian3
export const toCartesian3 = (
  longitude: number,
  latitude: number,
  altitude = 0
) => Cartesian3.fromDegrees(longitude, latitude, altitude);
