import DriverBehaviorAPI from 'api/driver.behaviour.api';
import { BaseHandler } from './base.handler';
import {
  setIsLoading,
  getAll,
  resetTimelineError,
  setTimelineError,
} from 'store/driver.behavior.slice';
import { allEvents, allEngineHoursEvents } from 'utils/driverBehavior';

export default class DriverBehavorHandler extends BaseHandler {
  private readonly api: DriverBehaviorAPI;

  constructor() {
    super();
    this.api = new DriverBehaviorAPI();
  }

  private generateCacheKey(
    payload: { startDate?: string; endDate?: string; range?: string },
    engineHours: boolean
  ): string {
    return `driver_behavior_${JSON.stringify(payload)}_${engineHours}`;
  }

  private getCachedData(key: string): any | null {
    try {
      const cachedData = sessionStorage.getItem(key);
      if (cachedData) {
        return JSON.parse(cachedData);
      }
    } catch (error) {
      console.warn('Error reading from cache:', error);
    }
    return null;
  }

  private setCachedData(key: string, data: any): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.warn('Error writing to cache:', error);
    }
  }

  async getAll(
    payload: {
      startDate?: string;
      endDate?: string;
      range?: string;
    },
    engineHours?: boolean
  ) {
    this.dispatch(resetTimelineError());
    this.dispatch(setIsLoading(true));

    try {
      // Check cache first
      const cacheKey = this.generateCacheKey(payload, engineHours ?? false);
      const cachedData = this.getCachedData(cacheKey);

      if (cachedData) {
        this.dispatch(getAll(cachedData));
        return [];
      }

      const eventsToUse = engineHours ? allEngineHoursEvents : allEvents;
      const driverData = await Promise.all(
        eventsToUse.map(async (event) => {
          const response = await this.api.getAll({ ...payload, event });
          return { [event]: response ?? [] };
        })
      );

      const formattedData = driverData.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});

      this.setCachedData(cacheKey, formattedData);

      this.dispatch(getAll(formattedData));
      return [];
    } catch (_) {
      this.handleError(
        'An error occurred while fetching driver behavior data.'
      );
      this.dispatch(setTimelineError('An error occurred while fetching data'));
    } finally {
      this.dispatch(setIsLoading(false));
    }
    return [];
  }

  clearCache(): void {
    const cacheKeys = Object.keys(sessionStorage);
    cacheKeys.forEach((key) => {
      if (key.startsWith('driver_behavior_')) {
        sessionStorage.removeItem(key);
      }
    });
  }
}
