import dayjs from 'dayjs';
import { ThemePalette } from 'mui.theme';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';
import { formatYAxis } from './Chart';
import { truncateTo2dp } from 'utils/helpers/general';
import { interpolateDataPoints } from '../../utils';

export const IndividualAreaChart = ({
  enableDarkTheme,
  data,
  dataType,
  unit,
  selectedOperation,
  startDate,
  endDate,
}: {
  enableDarkTheme: boolean;
  data: any;
  dataType: string;
  unit: string;
  selectedOperation: string;
  startDate: any;
  endDate: any;
}) => {
  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    let formattedDate = date.format('YY MMM DD');
    if (dataType === 'highGrain') {
      formattedDate = date.format('HH:mm:ss');
    }
    return formattedDate;
  };

  let chartData = data;

  if (data.length > 0) {
    const deviceSensor = data[0].device_sensor;
    if (['SbIgnitionStatus'].includes(deviceSensor)) {
      chartData = interpolateDataPoints(data, 120000, startDate, endDate);
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          stroke={'#25364c'}
          vertical={false}
        />
        <XAxis
          dataKey="ts"
          allowDuplicatedCategory={false}
          tickFormatter={formatTimestamp}
          textAnchor="end"
          tick={{ fontSize: 11 }}
        />
        <YAxis
          tickFormatter={formatYAxis}
          label={{
            value: `${unit}`,
            angle: 270,
            position: 'insideLeft',
            fontSize: 10,
            textAnchor: 'middle',
            dy: 0,
            dx: -5,
          }}
          tick={{ fontSize: 11 }}
        />
        <Tooltip
          cursor={{ fill: '#1B2B4A' }}
          wrapperStyle={{ outline: 'none', fontSize: '11px' }}
          contentStyle={{
            backgroundColor: 'transparent',
          }}
          itemStyle={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
          content={
            <CustomTooltip
              enableDarkTheme={enableDarkTheme}
              unit={unit}
              selectedOperation={selectedOperation}
            />
          }
        />
        <Area
          dataKey={selectedOperation}
          stroke="#F08733"
          activeDot={{ r: 3 }}
          dot={{
            fill: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            strokeWidth: 1,
            r: 2,
          }}
        />
        {dataType === 'highGrain' && (
          <Brush
            dataKey="ts"
            height={30}
            data={chartData}
            tickFormatter={formatTimestamp}
            fill={
              enableDarkTheme
                ? ThemePalette.dark.boxBackground
                : ThemePalette.light.boxBackground
            }
          >
            <AreaChart>
              <Area
                dataKey={selectedOperation}
                type="linear"
                stroke="#F08733"
                fill="#162A46"
                isAnimationActive={false}
              />
            </AreaChart>
          </Brush>
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltip = ({
  active,
  payload,
  enableDarkTheme,
  selectedOperation,
}: any) => {
  if (active && payload && payload.length) {
    const { ts } = payload[0].payload;
    const value = payload[0].payload[selectedOperation];

    return (
      <div
        style={{
          background: 'transparent',
          color: enableDarkTheme
            ? ThemePalette.typography.wheat
            : ThemePalette.typography.black,
          fontSize: '11px',
          outline: 'none',
        }}
      >
        <p>{`Date: ${dayjs(ts).format('YYYY MMM DD HH:mm:ss')}`}</p>
        <p>{`Value: ${formatYAxis(truncateTo2dp(value))}`}</p>
      </div>
    );
  }
  return null;
};
