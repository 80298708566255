import { Box, Typography, LinearProgress, TextField } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import type { DriverBehavior } from 'types/driver.behavior.types';
import {
  capitalizeFirstLetter,
  composeUniqueKey,
} from 'utils/helpers/string.manipulation';
import type { Asset } from 'types/asset.types';

const ItemFilter = ({
  enableDarkTheme,
  title,
  selectedAsset,
  setSelectedAsset,
  searchValue,
  handleChange,
  fleetData,
  assetData,
  assets,
}: {
  enableDarkTheme: boolean;
  title: string;
  selectedAsset: string;
  setSelectedAsset: any;
  searchValue: string;
  handleChange: any;
  fleetData: DriverBehavior;
  assetData: DriverBehavior[];
  assets: Asset[];
}) => {
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const filteredAssetData = assetData.filter((item) =>
    item.bumper_id.includes(searchValue)
  );

  return (
    <Box
      sx={{
        borderRight: '1px solid #2F445E',
        paddingRight: '20px',
      }}
    >
      <Box
        sx={{
          border: '1px solid #2F445E',
          borderRadius: '8px',
          padding: '20px 20px 20px 15px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          Total number of {title.replace(/_/g, ' ')} events
        </Typography>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '600',
            marginTop: '10px',
          }}
        >
          {fleetData.noOfEvents}
        </Typography>
        <Typography
          sx={{
            color:
              fleetData.eventPercentage < 0
                ? ThemePalette.typography.success
                : ThemePalette.typography.danger,
            marginTop: '10px',
          }}
        >
          {fleetData.eventPercentage > 0 ? (
            <CallMadeIcon
              sx={{
                fontSize: '14px',
                paddingRight: '3px',
              }}
            />
          ) : (
            <CallReceivedIcon
              sx={{
                fontSize: '14px',
                paddingRight: '3px',
              }}
            />
          )}
          {Math.abs(fleetData.eventPercentage)}%
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '20px',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {capitalizeFirstLetter(title.replace(/_/g, ' '))} by Vehicle
        </Typography>
        <Typography
          sx={{
            fontSize: '10px',
            marginTop: '5px',
            color: ThemePalette.typography.grey,
          }}
        >
          Click on a vehicle to see its events on the graph
        </Typography>

        <TextField
          label="Search for an asset"
          variant="outlined"
          InputProps={{
            sx: {
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#38475E',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#38475E',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#38475E',
              },
            },
          }}
          InputLabelProps={{
            sx: {
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
              '&.Mui-focused': {
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              },
            },
          }}
          sx={{
            marginTop: '8px',
            borderRadius: '4px',
            width: '100%',
            '& .MuiInputBase-input::placeholder': {
              color: 'gray',
            },
          }}
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown} // Prevent Enter key from submitting or doing any action
        />
      </Box>

      {/** Progress Bar with list */}
      <Box
        sx={{
          marginTop: '20px',
          marginBottom: '10px',
        }}
      >
        {filteredAssetData.map((item, index) => {
          const assetDetails = assets.find(
            (asset) => asset.bumperNumber === item.bumper_id
          );
          const makeModel = assetDetails
            ? composeUniqueKey([assetDetails.make, assetDetails.model]).replace(
                '!',
                '-'
              )
            : '';
          return (
            <Box
              key={index}
              sx={{
                mb: 2,
                '&:last-child': { mb: 0 },
                padding: '10px',
                paddingBottom: '10px',
                backgroundColor:
                  item.bumper_id === selectedAsset ? '#061A2E' : 'none',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              onClick={() => {
                setSelectedAsset(item.bumper_id);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 0.5,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    {item.bumper_id}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#64748B',
                      fontSize: '10px',
                    }}
                  >
                    {makeModel}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.noOfEvents}
                  <Box component="span" sx={{ ml: 1, color: '#64748B' }}>
                    <ChevronRightIcon
                      fontSize="small"
                      sx={{ paddingTop: '5px' }}
                    />
                  </Box>
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={(item.noOfEvents / fleetData.noOfEvents) * 100}
                sx={{
                  height: 6,
                  borderRadius: 4,
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                  '& .MuiLinearProgress-bar': {
                    bgcolor: '#3666EC',
                    borderRadius: 4,
                  },
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ItemFilter;
