import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { ThemePalette } from 'mui.theme';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hook';
import { shiftWiseEngineStartEndDataToExcel } from 'utils/exports/excel';
import { isDarkTheme } from 'utils/theme';
import { ShiftDetailsUTC } from 'views/Utilization/utils';

const ExportShiftsToExcel = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const customerCode = useAppSelector((state) => state.authReducer).customer
    .code;

  const { data } = useAppSelector((state) => state.driverBehaviorReducer);

  const shiftWiseData = useMemo(() => {
    const { engine_hours: engineHours } = data;
    const customerShifts = ShiftDetailsUTC?.[customerCode?.toLowerCase()] || [];

    // Helper function to determine shift based on timestamp
    const getShift = (timestamp: string): string | null => {
      const hour = dayjs(timestamp).hour(); // Use dayjs for getting the hour
      for (let i = 0; i < customerShifts.length; i++) {
        const { startHour, endHour } = customerShifts[i];
        if (
          (startHour < endHour && hour >= startHour && hour < endHour) || // Same day shift
          (startHour > endHour && (hour >= startHour || hour < endHour)) // Overnight shift
        ) {
          return `shift${i + 1}`;
        }
      }
      return null; // No matching shift
    };

    // Initialize shift data object
    const shiftData = customerShifts.reduce(
      (acc: Record<string, any[]>, _, index: number) => {
        acc[`shift${index + 1}`] = [];
        return acc;
      },
      {}
    );

    // Process each truck's productive data
    engineHours.forEach((truck) => {
      const { bumper_id: bumperId, data: events } = truck;

      if (bumperId && events?.length) {
        const shiftRanges: Record<string, { first: string; last: string }> = {};

        // Categorize events into shifts
        events.forEach(({ start_ts: startTs, end_ts: endTs }) => {
          const shift = getShift(startTs);
          if (shift) {
            if (!shiftRanges[shift]) {
              shiftRanges[shift] = { first: startTs, last: endTs };
            } else {
              shiftRanges[shift].first = dayjs(
                shiftRanges[shift].first
              ).isBefore(dayjs(startTs))
                ? shiftRanges[shift].first
                : startTs;

              shiftRanges[shift].last = dayjs(shiftRanges[shift].last).isAfter(
                dayjs(endTs)
              )
                ? shiftRanges[shift].last
                : endTs;
            }
          }
        });

        // Add the shift ranges for the truck
        Object.entries(shiftRanges).forEach(([shift, range]) => {
          shiftData[shift].push({
            bumper_id: bumperId,
            first_engine_start_ts: dayjs(`${range.first} UTC`).format(
              'YYYY-MM-DD HH:mm'
            ),
            last_ending_end_ts: dayjs(`${range.last} UTC`).format(
              'YYYY-MM-DD HH:mm'
            ),
          });
        });
      }
    });
    return shiftData;
  }, [data, customerCode]);

  return (
    <Button
      disabled={data?.productive?.length === 0}
      size="small"
      sx={{
        marginLeft: '15px',
        fontSize: '12px',
        textTransform: 'none',
        border: '1px solid #2B53BC',
        color:
          data?.productive?.length === 0
            ? `${ThemePalette.typography.grey} !important`
            : enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        borderRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
      onClick={() => {
        shiftWiseEngineStartEndDataToExcel(
          shiftWiseData,
          'Shift wise engine hours data'
        );
      }}
    >
      Download Shift Wise Engine Hours Data
    </Button>
  );
};

export default ExportShiftsToExcel;
