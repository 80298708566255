import { Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SVG } from 'components/Asset/SVG';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { type Asset } from 'types/asset.types';
import { type ITag } from 'types/tag.type';
import { InteractiveColor } from 'types/theme.types';
import { resolveAssetTypeDisplay } from 'utils/helpers/assets';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import {
  AssetCardsDiv,
  CardDetails,
  CardHeaderDiv,
  CardSummary,
} from './AssetCard.styled';
import { FlexBox } from 'components/Containers/FlexBox';
import { HeavyBodyText } from 'components/Typography/HeavyBodyText';
import { truncateTo2dp } from 'utils/helpers/general';
import { notificationLevel } from 'views/FleetOverview/helpers/asset.helpers';
import { selectExportFormat } from 'store/exportData.slice';
import { useAppSelector } from 'store/hook';
import { usePDF } from 'react-to-pdf';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import { ThemePalette } from 'mui.theme';

interface Props {
  filteredAssets: Asset[];
  searchValue: string;
  // tags: TagDisplay[];
  exportTo?: boolean;
  handleExport: (val: boolean) => void;
  enableDarkTheme: boolean;
}

const AssetCard = ({
  filteredAssets,
  searchValue,
  exportTo,
  handleExport,
  enableDarkTheme,
}: Props) => {
  const [filteredArray, setFilteredArray] = React.useState(filteredAssets);
  React.useEffect(() => {
    const filteredData = filteredAssets.filter(
      (asset) =>
        asset.assetType.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.model.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.bumperNumber.toLowerCase().includes(searchValue.toLowerCase())
    );
    filteredData.sort((a, b) => {
      const level1 = notificationLevel(a.notifications).level;
      const level2 = notificationLevel(b.notifications).level;
      return level2 - level1;
    });
    setFilteredArray(filteredData);
  }, [searchValue, filteredAssets]);

  const [statusColor, setStatusColor] = React.useState<InteractiveColor>(
    InteractiveColor.Normal
  );

  const navigateTo = useNavigate();

  const exportFormat = useAppSelector(selectExportFormat);

  const { toPDF, targetRef } = usePDF({
    filename: `Fleet-Summary-Cards-${formatDate(new Date())}.pdf`,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      if (exportTo && exportFormat === 'pdf') {
        toPDF();
        handleExport(false);
      }
    };

    void fetchData();
  }, [exportTo, handleExport, exportFormat]);

  return (
    <AssetCardsDiv>
      <Grid container spacing={2} ref={targetRef} id="assetCards">
        {/* Header content for the PDF */}
        {exportTo && exportFormat === 'pdf' && (
          <div
            style={{
              flexDirection: 'column',
              margin: '0.5% 30%',
            }}
          >
            <h1>Fleet Summary Cards</h1>
          </div>
        )}
        {filteredArray.map((asset) => {
          const {
            assetId,
            assetType,
            model,
            assetVin,
            make,
            bumperNumber,
            notifications,
          } = asset;
          const assetTypeResolved = resolveAssetTypeDisplay(assetType);

          return (
            <Grid item xs={8} md={4} lg={3} key={assetId}>
              <Card
                className="card"
                sx={{
                  height: '100%',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.modalBackground
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                <CardHeaderDiv
                  backgroundcolor={statusColor}
                  subheader={assetTypeResolved}
                  onClick={() => {
                    navigateTo(`/vehicle-status?assetVin=${assetVin}`, {
                      state: {
                        makemodel: {
                          make,
                          model,
                          bumperNumber,
                        },
                      },
                    });
                  }}
                />
                <CardContent>
                  <CardSummary
                    onClick={() => {
                      navigateTo(`/vehicle-status?assetVin=${assetVin}`, {
                        state: {
                          makemodel: {
                            make,
                            model,
                            bumperNumber,
                          },
                        },
                      });
                    }}
                    style={{
                      backgroundColor: enableDarkTheme
                        ? ThemePalette.dark.modalBackground
                        : ThemePalette.light.menuOptions,
                    }}
                  >
                    <div className="image">
                      <SVG
                        name={mapVehiclesAssets(assetType)}
                        style={{ fill: notificationLevel(notifications).color }}
                      />
                    </div>
                    <div className="summary">
                      <p>
                        Type: <span>{assetTypeResolved}</span>
                      </p>
                      <p>
                        OEM: <span>{make} </span>
                      </p>
                      <p>
                        Model: <span>{model}</span>
                      </p>
                      <p>
                        Bumper ID: <span>{bumperNumber}</span>
                      </p>
                    </div>
                  </CardSummary>
                  <CardDetails>
                    <div
                      className="summary"
                      style={{
                        height: '100%',
                      }}
                    >
                      {asset.device.tags.map((tag: ITag) => {
                        if (!tag.showOnFleetOverviewCard) return null;

                        const tagValue =
                          asset.liveTagData?.[tag.tagName] ?? '--';
                        return (
                          <FlexBox
                            key={`${tag.tagName}${tag.deviceId}${asset.assetId}`}
                          >
                            <HeavyBodyText
                              sx={{
                                wordBreak: 'break-word',
                                maxWidth: '80%',
                              }}
                              text={tag.tagAlias || tag.tagName}
                            />
                            <HeavyBodyText
                              sx={{
                                float: 'right',
                                marginLeft: 'auto',
                              }}
                              text={truncateTo2dp(tagValue.toString())}
                            />
                          </FlexBox>
                        );
                      })}
                    </div>
                  </CardDetails>
                </CardContent>
                {/* 
                  TODO, future implementation
                <CardFooter $error={statusColor === '#A03434'}>
                  <p>CO2 Alert(s): None</p>
                </CardFooter> */}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </AssetCardsDiv>
  );
};

export default AssetCard;
