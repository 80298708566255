import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import MainRouter from 'components/Route/MainRouter';
import { updateLoadingState } from 'store/app.slice';
import { useAppDispatch, useAppSelector } from 'store/hook';

import { externalFetchAll, internalFetchAll } from 'handlers/util/fetch.all';
import { useNavigate } from 'react-router-dom';
import {
  isInternalUserOrDirector,
  isExternalUserOrAdmin,
} from 'utils/validators/role.validation';

// handlers
import ExternalUserHandler from 'handlers/external.user.handler';
import InternalUserHandler from 'handlers/internal.user.handler';
import CustomerHandler from 'handlers/customer.handler';
import AuthHandler from 'handlers/auth.handler';
import AssetHandler from 'handlers/asset.handler';
import OperatorHandler from 'handlers/operator.handler';
import HierarchyHandler from 'handlers/hierarchy.handler';
import RuleHandler from 'handlers/rule.handler';
import DeviceHandler from 'handlers/device.handler';
import TagHandler from 'handlers/tag.handler';
import SquaresMaintenanceHandler from 'handlers/squaresHandlers/squaresMaintenance.handler';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import NotificationHandler from 'handlers/notification.handler';
import EngineHoursHandler from 'handlers/engine.hours.handler';
import WeatherHandler from 'handlers/weather.handler';
import FuelPriceHandler from 'handlers/fuelPrice.handler';
import FleetOverviewHandler from 'handlers/fleetOverView.handler';
import { useRemoveAuth } from 'hooks/useRemoveAuth';
import SurvivalAnalysisOverviewHandler from 'handlers/survivalAnalysisHandlers/survivalAnalysisOverview.handler';

import { ThemePalette } from 'mui.theme';
import UtilizationHandler from 'handlers/utilization.handler';
import ProductionHandler from 'handlers/production.handler';
import TirePressureHandler from 'handlers/tire-pressure';
import TagThresholdHandler from 'handlers/tag.threshold.handler';
import GeoHandler from 'handlers/geo.handler';
import DtcHandler from 'handlers/dtc.handler';

function App(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();
  const { execRemoveAuth } = useRemoveAuth();
  const {
    accessToken,
    role,
    customer: { theme },
  } = useAppSelector((state) => state.authReducer);
  const { customer } = useAppSelector((state) => state.persistedReducer);

  // handlers - add new handlers here
  const authHandler = new AuthHandler();
  const internalUserHandler = new InternalUserHandler();
  const customerHandler = new CustomerHandler();
  const externalUserHandler = new ExternalUserHandler();
  const assetHandler = new AssetHandler();
  const engineHoursHandler = new EngineHoursHandler();
  const operatorHandler = new OperatorHandler();
  const hierarchyHandler = new HierarchyHandler();
  const ruleHandler = new RuleHandler();
  const deviceHandler = new DeviceHandler();
  const tagHandler = new TagHandler();
  const squaresMaintenanceHandler = new SquaresMaintenanceHandler();
  const squaresDashboardHandler = new SquaresDashboardHandler();
  const notificationHandler = new NotificationHandler();
  const weatherHandler = new WeatherHandler();
  const fuelPriceHandler = new FuelPriceHandler();
  const fleetOverviewHandler = new FleetOverviewHandler();
  const survivalAnalysisOverviewHandler = new SurvivalAnalysisOverviewHandler();
  const utilizationHandler = new UtilizationHandler();
  const productionHandler = new ProductionHandler();
  const tirePressureHandler = new TirePressureHandler();
  const tagThresholdHandler = new TagThresholdHandler();
  const geoHandler = new GeoHandler();
  const dtcHandler = new DtcHandler();

  const externalFetchHandlers = {
    handlers: {
      externalUserHandler,
      assetHandler,
      operatorHandler,
      hierarchyHandler,
      ruleHandler,
      deviceHandler,
      tagHandler,
      squaresMaintenanceHandler,
      squaresDashboardHandler,
      notificationHandler,
      engineHoursHandler,
      weatherHandler,
      fuelPriceHandler,
      fleetOverviewHandler,
      survivalAnalysisOverviewHandler,
      utilizationHandler,
      productionHandler,
      tirePressureHandler,
      tagThresholdHandler,
      geoHandler,
      dtcHandler,
    },
  };

  const internalFetchHandlers = {
    handlers: {
      internalUserHandler,
      customerHandler,
      ...externalFetchHandlers.handlers,
    },
  };

  useEffect(() => {
    // if authenticated or loading, return
    if ((isAuthenticated && !isLoading) || isLoading) return;

    void authHandler.login();

    dispatch(updateLoadingState(true));
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    // Return early if token has been set
    if (isLoading || accessToken?.length > 0) return;

    void authHandler.hydrateToken();
    void authHandler.hydrateClaims();

    dispatch(updateLoadingState(false));
  }, [isLoading]);

  useEffect(() => {
    let backgroundColor = ThemePalette.light.pageBackground;
    if (theme === 'dark') {
      backgroundColor = ThemePalette.dark.pageBackground;
    }
    document.body.style.backgroundColor = backgroundColor;
  }, [theme]);

  /*
    Execute fetch all if authenticated and access token is set
    handlers must be instantiated before being passed to fetchAll
    to establish context for redux dispatch and other hooks
    this is because hooks cannot be called outside a functional component
  */
  useEffect(() => {
    if (!isAuthenticated || !accessToken || !role) return;

    // Navigate internal user to the customer selection screen
    // if a customer has not been assigned to app context
    if (!customer.id && isInternalUserOrDirector(role)) {
      navigate('/customer-selection');

      return;
    }

    if (isExternalUserOrAdmin(role)) {
      void Promise.allSettled([externalFetchAll(externalFetchHandlers)]);

      return;
    }

    // Fetch information for internal users
    void Promise.allSettled([internalFetchAll(internalFetchHandlers)]);
  }, [isAuthenticated, accessToken, role, customer.id]);

  useEffect(() => {
    let timeout: any;
    // Iterate over all keys in sessionStorage
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      // Clear the key if it's not 'persist:root'
      if (key !== 'persist:root' && key != null) {
        sessionStorage.removeItem(key);
      }
    }

    const resetTimeout = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        execRemoveAuth();
      }, 3600000); // 1 hour in milliseconds
    };

    const handleActivity = () => {
      resetTimeout();
    };

    // Reset the timeout on user activity
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);

    // Initial setup
    resetTimeout();

    // Clean up event listeners
    return () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  /* if loading, show loading screen */
  if (isLoading) {
    dispatch(updateLoadingState(true));

    return null;
  }

  return <MainRouter />;
}

export default App;
