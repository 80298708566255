import { Box, Typography } from '@mui/material';

const RightCards = () => {
  return (
    <Box sx={{ padding: '0 10px', width: '20%' }}>
      <Box
        sx={{
          border: '1px solid rgba(98, 98, 98, 0.2)',
          borderRadius: '4px',
          padding: '10px 20px',
        }}
      >
        <Typography
          variant="body2"
          style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '10px' }}
        >
          Fuel Saved in July
        </Typography>
        <Typography
          variant="h2"
          style={{ fontSize: '45px', fontWeight: 'bold' }}
        >
          -23
          <span
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
          >
            LTR
          </span>
        </Typography>
        <Typography style={{ fontSize: '12px', color: 'rgba(235, 87, 87, 1)' }}>
          18% more fuel consumed then Jun-24
        </Typography>
      </Box>

      <Box
        sx={{
          border: '1px solid rgba(98, 98, 98, 0.2)',
          borderRadius: '4px',
          padding: '10px 20px',
          height: '400px',
          margin: '10px 0 0 0',
        }}
      ></Box>
    </Box>
  );
};

export default RightCards;
