import { Fragment } from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ThemePalette } from 'mui.theme';
import { type Asset } from 'types/asset.types';
import { SVG } from 'components/Asset/SVG';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';

export const AssetDetail = ({
  enableDarkTheme,
  selectedAsset,
  latestTagData,
  hierarchyInfo,
  injestedTime,
}: {
  enableDarkTheme: boolean;
  selectedAsset: Asset;
  latestTagData: any;
  hierarchyInfo: any;
  injestedTime?: Record<string, string>;
}) => {
  const assetDetails = [
    {
      label: 'Asset ID',
      value: selectedAsset.bumperNumber,
    },
    {
      label: 'Type',
      value: assetTypeMappings[selectedAsset.assetType],
    },
    {
      label: 'Hierarchy Node',
      value: hierarchyInfo.nodeDisplay,
    },
    {
      label: 'OEM',
      value: selectedAsset.make,
    },
    {
      label: 'Model',
      value: selectedAsset.model,
    },
    {
      label: 'Status',
      value: selectedAsset.isActive ? 'Operational' : 'Out Of Service',
    },
  ];

  return (
    <Box
      sx={{
        marginLeft: '10px',
        borderRadius: '5px',
        marginTop: '17px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        height: '100px',
        paddingTop: '5px',
        paddingLeft: '10px',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Asset Details
        {injestedTime ? (
          <>
            <span
              style={{
                fontSize: '11px',
                paddingLeft: '10px',
                fontWeight: 'normal',
              }}
            >
              {Object.keys(injestedTime)[0]}
            </span>
            <span
              style={{
                fontSize: '11px',
                fontWeight: '600',
                paddingLeft: '5px',
              }}
            >
              {Object.values(injestedTime)[0]}
            </span>
          </>
        ) : (
          <>
            <span
              style={{
                fontSize: '11px',
                paddingLeft: '10px',
                fontWeight: 'normal',
              }}
            >
              Last injested time:
            </span>
            <span
              style={{
                fontSize: '11px',
                fontWeight: '600',
                paddingLeft: '5px',
              }}
            >
              {Array.isArray(latestTagData)
                ? undefined
                : latestTagData[selectedAsset.device?.deviceId]?.ingestDate}
            </span>
          </>
        )}
      </Typography>
      <Box
        sx={{
          paddingTop: '10px',
        }}
      >
        <Grid2
          container
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          {/* Render asset details */}
          {assetDetails.map((data, index, arr) => (
            <Fragment key={`${data.label}-group`}>
              <Grid2 key={`${data.label}-1`} display="flex">
                {index === 0 && (
                  <SVG
                    name={mapVehiclesAssets(selectedAsset.assetType)}
                    style={{
                      fill: '#E2C171',
                      height: '45px',
                      width: '50px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                )}
                <div>
                  <Typography
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      fontSize: '11px',
                      textAlign: 'center',
                      paddingBottom: '5px',
                    }}
                  >
                    {data.label}
                  </Typography>
                  <Typography
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      fontSize: '11px',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    {data.value}
                  </Typography>
                </div>
              </Grid2>
              <Grid2 padding={0}>
                {index < arr.length - 1 && (
                  <Divider
                    key={`${data.label}-divider`}
                    orientation="vertical"
                    sx={{
                      background: ThemePalette.border,
                    }}
                  />
                )}
              </Grid2>
            </Fragment>
          ))}
        </Grid2>
      </Box>
    </Box>
  );
};
