import { Box, Grid, Typography } from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import { ThemePalette } from 'mui.theme';
import { type Asset } from 'types/asset.types';

export const AssetSelector = ({
  enableDarkTheme,
  makeModels,
  selectedMakeModel,
  setSelectedMakeModel,
  assetBumperIds,
  selectedAsset,
  setSelectedAsset,
  inputWidth = '200px',
}: {
  enableDarkTheme: boolean;
  makeModels: string[];
  selectedMakeModel: string;
  setSelectedMakeModel: any;
  assetBumperIds: string[];
  selectedAsset: Asset;
  setSelectedAsset: any;
  inputWidth?: string;
}) => {
  const makeModelList = makeModels.map((item) => {
    return {
      id: item,
      display: item,
    };
  });

  const assetList = assetBumperIds.map((item) => {
    return { id: item, display: item };
  });

  return (
    <Grid item sm={12} md={6} lg={4}>
      <Box
        sx={{
          borderRadius: '5px',
          marginTop: '7px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          height: '80px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {/* Make Model */}
        <Box
          sx={{
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <Typography
            variant="bodyBold"
            display="block"
            mb={'4px'}
            sx={{
              paddingBottom: '25px',
            }}
          >
            Make Model
          </Typography>
          <Selector
            minWidth={inputWidth}
            value={selectedMakeModel}
            onChange={(event) => {
              setSelectedMakeModel(String(event.target.value));
            }}
            selectorOptions={makeModelList}
            enableDarkTheme={enableDarkTheme}
          />
        </Box>
        {/* BUmper ID */}
        <Box
          sx={{
            paddingRight: '10px',
          }}
        >
          <Typography
            variant="bodyBold"
            display="block"
            mb={'4px'}
            sx={{
              paddingBottom: '25px',
            }}
          >
            Bumper Id
          </Typography>
          <Selector
            minWidth={inputWidth}
            value={selectedAsset.bumperNumber}
            onChange={(event) => {
              setSelectedAsset(String(event.target.value));
            }}
            selectorOptions={assetList}
            enableDarkTheme={enableDarkTheme}
          />
        </Box>
      </Box>
    </Grid>
  );
};
