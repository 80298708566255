import { Box, Grid } from '@mui/material';
import TirePressure from '../TirePressure';
import FourWheels from '../Schematics/FourWheels';
import { fixedLimts } from 'views/TirePressure/util';

export const FourTires = ({
  tireData,
  limits,
  enableDarkTheme,
  unitSystem,
  tireCodes,
  metricType,
}: {
  tireData: any;
  limits: any;
  enableDarkTheme: boolean;
  unitSystem: string;
  tireCodes: any;
  metricType: string;
}) => {
  const tireCurrentStatus =
    metricType === 'Pressure'
      ? tireData.tireCurrentStatus
      : tireData.tireCurrentTemperature;

  const tireNumberSystem = ['0x17', '0x19', '0x27', '0x29'];

  // @ts-expect-error ignore this we have it defined
  const limitsData = fixedLimts[unitSystem][metricType.toLowerCase()];

  const isOverMax = (code: string, value: number, limitData: any) => {
    const { maxValue } = limitData;
    const { minValue } = limitData;
    return !(value >= minValue && value <= maxValue);
  };

  const p1 = Number(tireCurrentStatus?.['0x17'] ?? 0);

  const p2 = Number(tireCurrentStatus?.['0x19'] ?? 0);

  const p3 = Number(tireCurrentStatus?.['0x27'] ?? 0);

  const p4 = Number(tireCurrentStatus?.['0x29'] ?? 0);

  const p1Limits = limits.find((item: any) => item.tireCode === '0x17');
  const p2Limits = limits.find((item: any) => item.tireCode === '0x19');
  const p3Limits = limits.find((item: any) => item.tireCode === '0x27');
  const p4Limits = limits.find((item: any) => item.tireCode === '0x29');

  const isP1WarninigMax = isOverMax('0x17', p1, limitsData);

  const isP2WarninigMax = isOverMax('0x19', p2, limitsData);

  const isP3WarninigMax = isOverMax('0x27', p3, limitsData);

  const isP4WarninigMax = isOverMax('0x29', p4, limitsData);

  const data: any = {
    p1,
    p2,
    p3,
    p4,
    p1Limits,
    p2Limits,
    p3Limits,
    p4Limits,
    isP1WarninigMax,
    isP2WarninigMax,
    isP3WarninigMax,
    isP4WarninigMax,
  };

  const slowLeaks = tireData.tireCurrentStatus?.slowLeaks ?? [];

  const fastLeaks = tireData.tireCurrentStatus?.fastLeaks ?? [];

  return (
    <>
      <Grid item lg={3}>
        <Box
          sx={{
            marginTop: '80px',
          }}
        >
          {[1, 3].map((item, index) => {
            return (
              <TirePressure
                key={index}
                enableDarkTheme={enableDarkTheme}
                value={data[`p${item}`]}
                limit={data[`p${item}Limits`]}
                tireCode={tireNumberSystem[item - 1]}
                unitSystem={unitSystem}
                tireCodes={tireCodes}
                metricType={metricType}
                slowLeaks={slowLeaks}
                fastLeaks={fastLeaks}
              />
            );
          })}
        </Box>
      </Grid>
      <Grid item lg={6}>
        <Box
          sx={{
            textAlign: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <FourWheels name="tireVehicleSchematicIcon" values={data} />
        </Box>
      </Grid>
      <Grid item lg={3}>
        <Box
          sx={{
            marginTop: '80px',
          }}
        >
          {[2, 4].map((item, index) => {
            return (
              <TirePressure
                key={index}
                enableDarkTheme={enableDarkTheme}
                value={data[`p${item}`]}
                limit={data[`p${item}Limits`]}
                tireCode={tireNumberSystem[item - 1]}
                unitSystem={unitSystem}
                tireCodes={tireCodes}
                metricType={metricType}
                slowLeaks={slowLeaks}
                fastLeaks={fastLeaks}
              />
            );
          })}
        </Box>
      </Grid>
    </>
  );
};
