import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ShiftHours } from 'utils/enums';

dayjs.extend(duration);

export function calculateUtilization(
  totalEngineActiveMinutes: number,
  totalEngineWorkingMinutes: number
) {
  if (totalEngineActiveMinutes === 0 && totalEngineWorkingMinutes === 0) {
    return '0.00';
  }

  if (totalEngineActiveMinutes === 0) {
    return 'N/A';
  }

  const utilization =
    (totalEngineWorkingMinutes / totalEngineActiveMinutes) * 100;
  return utilization.toFixed(2);
}

export function calculatePercentageDifference(
  totalEngineActiveMinutes: number,
  totalEngineWorkingMinutes: number,
  totalEngineActiveMinutesDiff: number,
  totalEngineWorkingMinutesDiff: number
) {
  const currentUtilization =
    (totalEngineWorkingMinutes / totalEngineActiveMinutes) * 100;

  const previousActiveMinutes =
    totalEngineActiveMinutes / (1 + totalEngineActiveMinutesDiff / 100);
  const previousWorkingMinutes =
    totalEngineWorkingMinutes / (1 + totalEngineWorkingMinutesDiff / 100);

  const previousUtilization =
    (previousWorkingMinutes / previousActiveMinutes) * 100;

  const utilizationDiff =
    ((currentUtilization - previousUtilization) / previousUtilization) * 100;

  return utilizationDiff;
}

/**
 * Convert nanoseconds to minutes using Day.js
 * @param nanoseconds - The time in nanoseconds to be converted
 * @returns The time converted to minutes (formatted to 2 decimal places)
 */
export function convertNanosecondsToMinutes(nanoseconds: number): number {
  const milliseconds = nanoseconds / 1e6;

  const minutes = dayjs.duration(milliseconds, 'milliseconds').asMinutes();

  return parseFloat(minutes.toFixed(2));
}

/**
 * Convert minutes to minutes using Day.js
 * @param minutes - The time in nanoseconds to be converted
 * @returns The time converted to minutes (formatted to 2 decimal places)
 */
export function convertMinutesToHours(minutes: number): number {
  const hours = dayjs.duration(minutes, 'minutes').asHours();

  return parseFloat(hours.toFixed(2));
}

/**
 * Convert nanoseconds to hours using Day.js
 * @param nanoseconds - The time in nanoseconds to be converted
 * @returns The time converted to hous (formatted to 2 decimal places)
 */
export function convertNanosecondsToHours(nanoseconds: number): number {
  const milliseconds = nanoseconds / 1e6;

  const hours = dayjs.duration(milliseconds, 'milliseconds').asHours();
  return parseFloat(hours.toFixed(2));
}

/**
 * Convert seconds to hours using Day.js
 * @param seconds  - The time in seconds to be converted
 * @returns The time converted to hous (formatted to 2 decimal places)
 */
export function convertsecondsToHours(seconds: number): number {
  const hours = dayjs.duration(seconds, 'seconds').asHours();
  return parseFloat(hours.toFixed(2));
}

export function isSumZeroOrEmpty(array: any[], field: string) {
  if (array.length === 0) return true;

  const sum = array.reduce(
    (acc: number, obj) => acc + (Number(obj[field]) || 0),
    0
  );

  return sum === 0;
}

export function getDataUnit(type: string, unitSystem: string) {
  type = type.toLowerCase().replace(/ /g, '');
  if (type === 'production') {
    return unitSystem === 'imperial' ? 'iTons' : 'Tons';
  }

  if (type === 'fuel') {
    return unitSystem === 'imperial' ? 'gal' : 'l';
  }

  if (type === 'utilization') {
    return '%';
  }

  if (type === 'idlinghours') {
    return 'hrs';
  }

  if (type === 'productivehours') {
    return 'hrs';
  }
  if (type === 'avgfuelrate') {
    return unitSystem === 'imperial' ? 'gal/h' : 'l/h';
  }
  if (type === 'enginehours') {
    return 'hrs';
  }
}

export const getColor = (isMin?: boolean) => {
  const danger = `#FF7C54`;
  const success = `#1B9D59`;
  if (isMin) {
    return danger;
  } else {
    return success;
  }
};

export function daysLeftInYear() {
  const today: any = new Date();
  const endOfYear: any = new Date(today.getFullYear(), 11, 31);
  const timeDifference = endOfYear - today;
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysLeft;
}

export function filterDataByPeriod(
  dataArray: Array<{
    period: string;
    date: string;
    total_engine_active_minutes: string;
    total_engine_working_minutes: string;
  }>,
  period: string
) {
  const today = new Date();

  let startDate: Date;
  switch (period) {
    case '7days':
      startDate = new Date(today.setDate(today.getDate() - 8));
      break;
    case '14days':
      startDate = new Date(today.setDate(today.getDate() - 15));
      break;
    case '1month':
      startDate = new Date(today.setDate(today.getDate() - 30));
      break;
    case '6months':
      startDate = new Date(today.setMonth(today.getMonth() - 6));
      break;
    default:
      startDate = new Date(today.setDate(today.getDate() - 8));
  }

  return dataArray
    .filter((item) => new Date(item.date) >= startDate)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
}

export const getShiftHours = (customerCode: string) => {
  return customerCode === 'buz' || customerCode === 'mop'
    ? ShiftHours[customerCode]
    : ShiftHours.others;
};

export const ShiftDetails: Record<
  string,
  Array<{ startHour: number; endHour: number }>
> = {
  mop: [
    { startHour: 6, endHour: 18 },
    { startHour: 18, endHour: 6 },
  ],
  buz: [{ startHour: 5, endHour: 16 }],
  others: [{ startHour: 0, endHour: 20 }],
};

export const ShiftDetailsUTC: Record<
  string,
  Array<{ startHour: number; endHour: number }>
> = {
  mop: [
    { startHour: 4, endHour: 16 },
    { startHour: 16, endHour: 28 },
  ],
  buz: [{ startHour: 0, endHour: 11 }],
  others: [{ startHour: 0, endHour: 20 }],
};

interface ShiftDetail {
  startHour: number;
  endHour: number;
  daysToAdd: number;
  endTimeHour: number;
}
/**
 * Retrieves shift details for a given client ID, calculating days to add
 * and the ending hour of the shift for accurate shift timing. If the client ID
 * does not have any shifts or is not found, the default shift from "others" is returned.
 *
 * @param {string} clientId - The identifier for the client whose shift details need to be retrieved.
 * @returns {Array<Object>} Returns:
 *   - If shifts exist for the client ID: An array of shift details, where each object contains:
 *      - {number} startHour - The hour in UTC when the shift starts.
 *      - {number} endHour - The hour in UTC when the shift ends.
 *      - {number} daysToAdd - The number of days to add for the shift to end.
 *      - {number} endTimeHour - The hour of the day (0–23) when the shift ends.
 *   - If no shifts exist: An array with an object with the default shift details from "others" including:
 *      - {number} startHour - The default start hour in UTC.
 *      - {number} endHour - The default end hour in UTC.
 *      - {number} daysToAdd - Always 0 for the default case.
 *      - {number} endTimeHour - Same as the default end hour.
 *
 * @example
 * // Example with existing client ID
 * const clientId = "mop";
 * const result = getShiftDetails(clientId);
 * console.log(result);
 * // Output:
 * // [
 * //   { startHour: 4, endHour: 16, daysToAdd: 0, endTimeHour: 16 },
 * //   { startHour: 16, endHour: 28, daysToAdd: 1, endTimeHour: 4 }
 * // ]
 *
 * @example
 * // Example with missing client ID
 * const clientId = "unknown";
 * const result = getShiftDetails(clientId);
 * console.log(result);
 * // Output:
 * // { startHour: 0, endHour: 20, daysToAdd: 0, endTimeHour: 20 }
 */
export const getShiftDetails = (clientId: string): ShiftDetail[] => {
  const shifts = ShiftDetailsUTC[clientId];
  if (!shifts || shifts.length === 0) {
    return [
      {
        ...ShiftDetailsUTC.others[0],
        daysToAdd: 0,
        endTimeHour: ShiftDetailsUTC.others[0].endHour,
      },
    ];
  }

  return shifts.map(({ startHour, endHour }) => {
    const daysToAdd = Math.floor(endHour / 24);
    const endTimeHour = endHour % 24;
    return {
      startHour,
      endHour,
      daysToAdd,
      endTimeHour,
    };
  });
};

export const generateShiftData = (
  startDateStr: string,
  shiftDetails: ShiftDetail[]
): Array<{ startDate: string; endDate: string }> => {
  const startDate = new Date(startDateStr);
  const shifts = [];

  for (const shift of shiftDetails) {
    // Calculate start date and time
    const shiftStart = new Date(startDate);
    shiftStart.setHours(shift.startHour, 0, 0, 0);

    // Calculate end date and time
    const shiftEnd = new Date(startDate);
    shiftEnd.setDate(shiftEnd.getDate() + shift.daysToAdd);
    shiftEnd.setHours(shift.endTimeHour, 0, 0, 0);

    // Format the dates to ISO strings or any desired format
    shifts.push({
      startDate: dayjs(new Date(shiftStart).getTime()).format(
        'YYYY-MM-DD HH:mm:ss UTC'
      ),
      endDate: dayjs(new Date(shiftEnd).getTime()).format(
        'YYYY-MM-DD HH:mm:ss UTC'
      ),
    });
  }

  return shifts;
};

export const calculateUtilCurrentDailyAverage = (chartData: any[]) => {
  // if the chartData is empty, return 0, else extract seven day working minutes and return the average
  if (chartData?.length > 0) {
    const sevenDayData = chartData
      ?.filter((data) => data?.period === '7days')
      ?.map((data) => +data?.total_engine_working_minutes);

    const sevenDayTotalWorkingMinutes = sevenDayData.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );

    return sevenDayTotalWorkingMinutes / 7;
  } else {
    return 0;
  }
};
