import { createSlice } from '@reduxjs/toolkit';

export interface dtcState {
  data: any[];
  isLoading: boolean;
}

const initialState: dtcState = {
  data: [],
  isLoading: true,
};

export const dtcSlice = createSlice({
  name: 'dtc',
  initialState,
  reducers: {
    getAll(state, { payload }) {
      return {
        data: payload,
        isLoading: false,
      };
    },
    setLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { getAll, setLoading } = dtcSlice.actions;
export default dtcSlice.reducer;
