import { Box } from '@mui/material';
import EventListItem from './EventListItem';
import type { DriverBehaviorData } from 'types/driver.behavior.types';
import { allEvents } from 'utils/driverBehavior';
import type { Asset } from 'types/asset.types';

const EventList = ({
  enableDarkTheme,
  handleChange,
  data,
  assets,
}: {
  enableDarkTheme: boolean;
  handleChange: any;
  data: DriverBehaviorData;
  assets: Asset[];
}) => {
  return (
    <Box>
      {allEvents.map((item, index) => {
        // @ts-expect-error passing empty arr as default
        const eventData = data[item] ?? [];
        return (
          <EventListItem
            title={item}
            key={index}
            enableDarkTheme={enableDarkTheme}
            index={index}
            handleChange={handleChange}
            data={eventData}
            assets={assets}
          />
        );
      })}
    </Box>
  );
};

export default EventList;
