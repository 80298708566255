import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import { ThemePalette } from 'mui.theme';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
// import '../../FuelHistoricalAnalysis/styles.css';

interface Props {
  enableDarkTheme: boolean;
  startDate: any;
  endDate: any;
  handleDateChange: any;
  yesterday: any;
  currency: 'usd' | 'cad';
  setCurrency: (arg: 'usd' | 'cad') => void;
  fuelUnit: 'ltr' | 'gal';
  setFuelUnit: (arg: 'ltr' | 'gal') => void;
  fuelPrice: any;
  fuelPriceMod: any;
  setFuelPriceMod: any;
  setSelectedAssetType: any;
  selectedAssetType: string;
  allFleetData: any;
  consumptionMethod: 'averageConsumption' | 'totalConsumption';
  setConsumptionMethod: any;
}

const Header = ({
  enableDarkTheme,
  startDate,
  endDate,
  handleDateChange,
  yesterday,
  fuelPrice,
  currency,
  setCurrency,
  fuelUnit,
  setFuelUnit,
  fuelPriceMod,
  setFuelPriceMod,
  setSelectedAssetType,
  selectedAssetType,
  allFleetData,
  consumptionMethod,
  setConsumptionMethod,
}: Props) => {
  const currencyItems = ['usd', 'cad'];
  const unitItems = ['ltr', 'gal'];

  const checkboxes = [
    { id: 'haul_truck', label: 'HL - Haul Truck' },
    { id: 'dozer', label: 'DO - Dozer' },
    { id: 'drill', label: 'DR - Drill' },
    { id: 'excavator', label: 'EX - Excavator' },
    { id: 'loader', label: 'LO - Loader' },
  ];

  useEffect(() => {
    if (!fuelPrice || Object.keys(fuelPrice).length === 0) return;
    const latestPrice = Object.values(fuelPrice)[0] as any;
    const conversionRate = fuelPrice.genericCurrencyConversions?.rate || 1;

    const convertPrice = (price: number, fromCurrency: string) => {
      if (!price) return 0;

      if (fromCurrency === 'usd' && currency === 'cad') {
        return price * conversionRate;
      }
      if (fromCurrency === 'cad' && currency === 'usd') {
        return price / conversionRate;
      }
      return price;
    };

    setFuelPriceMod({
      diesel: Number(
        convertPrice(
          +latestPrice.diesel?.price || 0,
          latestPrice.diesel.currency
        ).toFixed(2)
      ),
      gas: Number(
        convertPrice(
          +latestPrice.gas?.price || 0,
          latestPrice.gas.currency
        ).toFixed(2)
      ),
    });
  }, [currency, fuelPrice, fuelPrice?.genericCurrencyConversions?.cad]);

  const handleCurrency = (
    event: React.MouseEvent<HTMLElement>,
    currency: 'usd' | 'cad'
  ) => {
    if (currency !== null) {
      setCurrency(currency);
    }
  };
  const handleFuelUnit = (
    event: React.MouseEvent<HTMLElement>,
    fuelUnit: 'ltr' | 'gal'
  ) => {
    if (fuelUnit !== null) {
      setFuelUnit(fuelUnit);
    }
  };
  const handleConsumptionMethod = (
    value: 'averageConsumption' | 'totalConsumption'
  ) => {
    setConsumptionMethod(value);
  };

  const handleSelectedAssetType = (val: string) => {
    setSelectedAssetType(val);
  };
  return (
    <>
      <Box
        sx={{
          borderColor: 'divider',
          margin: '10px 0 0px 0',
          backgroundColor: enableDarkTheme ? '#172A47' : '#ffffff',
          borderTopRightRadius: '5px',
          borderTopLeftRadius: '5px',
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            color: enableDarkTheme ? ThemePalette.typography.white : '#3d3d3d',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box>
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Date Range:
            </Typography>
            <DatePicker
              showIcon
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              maxDate={yesterday}
              selectsRange
              className={
                enableDarkTheme
                  ? 'custom-datetimepicker-fuel'
                  : 'custom-datetimepicker-fuel-light-theme'
              }
            />
          </Box>
        </Box>
        <Divider />
        <Box
          style={{
            color: enableDarkTheme ? ThemePalette.typography.white : '#3d3d3d',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'space-between',
          }}
        >
          <Box>
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Gas Price:
            </Typography>
            <FormControl
              sx={{
                width: '80px',
                color: enableDarkTheme ? '#fff' : '#000',
              }}
            >
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: enableDarkTheme ? '#D0D5DD' : '#909090',
                      }}
                    >
                      $
                    </Typography>
                  </InputAdornment>
                }
                value={fuelPriceMod.gas}
                disabled
                sx={{
                  height: '30px',
                  padding: '0px 5px',
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: enableDarkTheme ? '#fff' : '#101828',
                    fontSize: '14px',
                  },
                  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: enableDarkTheme
                      ? 'rgba(124, 126, 130, 0.1)'
                      : 'rgba(144, 144, 144, 0.1)',
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ marginLeft: '20px' }}>
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Diesel Price:
            </Typography>
            <FormControl sx={{ width: '80px' }}>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: enableDarkTheme ? '#D0D5DD' : '#909090',
                      }}
                    >
                      $
                    </Typography>
                  </InputAdornment>
                }
                value={fuelPriceMod.diesel}
                disabled
                sx={{
                  height: '30px',
                  padding: '0px 5px',
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: enableDarkTheme ? '#fff' : '#101828',
                    fontSize: '14px',
                  },
                  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: enableDarkTheme
                      ? 'rgba(124, 126, 130, 0.1)'
                      : 'rgba(144, 144, 144, 0.1)',
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <ToggleButtonGroup
              value={currency}
              exclusive
              onChange={handleCurrency}
              aria-label="Currency"
              sx={{ height: '30px', margin: '27px 0 0 10px' }}
              color="info"
            >
              {currencyItems.map((item: any, index: any) => {
                return (
                  <ToggleButton
                    value={item}
                    aria-label={item}
                    key={index}
                    sx={{
                      color: enableDarkTheme ? '#D0D5DD' : '#000',
                      borderColor: '#7c7e82',
                      '&.Mui-selected': {
                        backgroundColor: enableDarkTheme
                          ? '#0F49E5'
                          : '#022859',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#022859',
                        },
                      },
                    }}
                  >
                    <Typography style={{ fontSize: '12px' }}>
                      {item.toUpperCase()}
                    </Typography>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Divider />

        <Box
          style={{
            color: enableDarkTheme ? ThemePalette.typography.white : '#3d3d3d',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box sx={{ marginLeft: '20px' }}>
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Method:
            </Typography>
            <Selector
              minWidth="1px"
              selectSx={{
                fontSize: '12px',
                minWidth: '220px',
              }}
              value={consumptionMethod}
              onChange={(event) => {
                handleConsumptionMethod(
                  String(event.target.value) as
                    | 'averageConsumption'
                    | 'totalConsumption'
                );
              }}
              selectorOptions={[
                {
                  id: 'averageConsumption',
                  display: 'Average Consumption',
                },
                {
                  id: 'totalConsumption',
                  display: 'Total Consumption',
                },
              ]}
              enableDarkTheme={enableDarkTheme}
            />
          </Box>

          <Box>
            <ToggleButtonGroup
              value={fuelUnit}
              exclusive
              onChange={handleFuelUnit}
              aria-label="fuelUnit"
              sx={{ height: '30px', margin: '27px 0 0 10px' }}
              color="info"
            >
              {unitItems.map((item: any, index: any) => {
                return (
                  <ToggleButton
                    value={item}
                    aria-label={item}
                    key={index}
                    sx={{
                      color: enableDarkTheme ? '#D0D5DD' : '#000',
                      borderColor: '#7c7e82',
                      '&.Mui-selected': {
                        backgroundColor: enableDarkTheme
                          ? '#0F49E5'
                          : '#022859',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#022859', // Keeps the color consistent on hover
                        },
                      },
                    }}
                  >
                    <Typography style={{ fontSize: '12px' }}>
                      {item.toUpperCase()}
                    </Typography>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: enableDarkTheme ? '#172A47' : '#ffffff',
          padding: '10px',
          display: 'flex',
          justifyContent: 'left',
          color: enableDarkTheme ? '#fff' : '#000',
        }}
      >
        {/* <Box>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="total-fleet"
              name="radio-buttons-group"
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="total-fleet"
                control={
                  <Radio
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                      '&.Mui-checked': {
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                      },
                    }}
                  />
                }
                label="Total Fleet"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '13px',
                  },
                }}
              />
              <FormControlLabel
                value="custom"
                control={
                  <Radio
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                      '&.Mui-checked': {
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                      },
                    }}
                  />
                }
                label="Custom"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '13px',
                  },
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        </Box> */}

        <Box>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
            {checkboxes.map((checkboxItem, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedAssetType === `${checkboxItem.id}`}
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                        '&.Mui-checked': {
                          color: enableDarkTheme
                            ? ThemePalette.typography.cornflowerBlue
                            : ThemePalette.typography.primary,
                        },
                      }}
                    />
                  }
                  label={`${checkboxItem.label}`}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '13px',
                    },
                  }}
                  onChange={(e) => {
                    handleSelectedAssetType(`${checkboxItem.id}`);
                  }}
                  disabled={
                    !allFleetData.some(
                      (item: any) => item.asset_type === `${checkboxItem.id}`
                    )
                  }
                />
              );
            })}
          </FormGroup>
        </Box>
      </Box>
    </>
  );
};

export const Divider = () => {
  return (
    <div
      style={{
        height: '40px',
        width: '1px',
        backgroundColor: '#cccccc',
        margin: '10px 20px',
      }}
    ></div>
  );
};

export default Header;
