/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect, useMemo, useState } from 'react';
import { Treemap, Tooltip } from 'recharts';
import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import CustomContent from './CustomContent'; // Ensure this is correctly imported
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

interface EngineHoursGraphsProps {
  setSelectedRow: (value: string) => void;
  setSelectedAsset: (value: string) => void;
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, value, unit, bumperNumber } = payload[0].payload;

    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          fontSize: '12px',
          lineHeight: '1.5',
        }}
      >
        <p>
          <strong>Name:</strong> {name}
        </p>
        <p>
          <strong>Bumper No:</strong> {bumperNumber}
        </p>
        <p>
          <strong>Value:</strong> {value.toFixed(2)} {unit}
        </p>
      </div>
    );
  }

  return null;
};

const EngineHoursGraphs: React.FC<EngineHoursGraphsProps> = ({
  setSelectedRow,
  setSelectedAsset,
}) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const { assets } = useAppSelector((state) => state.assetReducer);

  const { data } = useAppSelector((state) => state.engineHoursReducer);

  const assetMap = useMemo(() => {
    return new Map(assets.map((asset: any) => [asset.assetId, asset.name]));
  }, [assets]);

  const processedData = useMemo(() => {
    return data
      .filter((item) => item?.engine_hour_total > 0)
      .map((item) => ({
        name: assetMap.get(item.device) || item.device,
        value: item.engine_hour_total,
        color: enableDarkTheme ? '#3666EC' : '#3666EC',
        unit: 'hrs',
        deviceId: item.device,
        bumperNumber: assetMap.get(item.bumperNumber) || item.bumperNumber,
      }))
      .sort((a, b) => b.value - a.value);
  }, [data, assetMap]);

  // Select the device with the highest value by default
  useEffect(() => {
    if (processedData.length > 0) {
      const highestValueDevice = processedData[0];
      setSelectedDeviceId(highestValueDevice.deviceId);
      setSelectedRow(highestValueDevice.deviceId);
      setSelectedAsset(highestValueDevice.bumperNumber);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleTreemapClick = (node: any) => {
    const selectedDevice = data.find((item) => item.device === node.deviceId);

    if (selectedDevice) {
      setSelectedDeviceId(selectedDevice.device);
      setSelectedRow(selectedDevice.device);
      setSelectedAsset(
        assetMap.get(selectedDevice.bumperNumber) || selectedDevice.bumperNumber
      );
    }
  };
  const ref = React.useRef<HTMLDivElement>(null);

  const [parentWidthHeight, setParentWidthHeight] = React.useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (ref.current) {
      setParentWidthHeight({
        width: ref.current.offsetWidth * 0.9,
        height: ref.current.offsetHeight,
      });
    }
  }, [ref]);

  if (!data || data?.length === 0) {
    return (
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '20px',
          fontWeight: '600',
          color: enableDarkTheme
            ? ThemePalette.typography.lightGrey
            : ThemePalette.typography.black,
        }}
      >
        No data available to display.
      </Typography>
    );
  }

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        overflowX: 'auto',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
      }}
    >
      <Treemap
        // width={Math.max(1200, processedData.length * 150)}
        width={parentWidthHeight.width}
        height={500}
        data={processedData}
        dataKey="value"
        stroke={
          enableDarkTheme
            ? ThemePalette.typography.lightGrey
            : ThemePalette.typography.black
        }
        fill={enableDarkTheme ? '#3666EC' : '#3666EC'}
        content={
          <CustomContent
            x={0}
            y={0}
            width={0}
            height={0}
            color={''}
            name={''}
            bumperNumber={''}
            value={0}
            unit={''}
            selectedDeviceId={selectedDeviceId}
            isHovered={false}
          />
        } // Use CustomContent as the renderer
        onClick={handleTreemapClick}
      >
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </Box>
  );
};

export default EngineHoursGraphs;
