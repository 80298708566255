import GeoAPI from 'api/geo.api';
import { updateLoadingState } from 'store/app.slice';
import {
  type AssetTypeGeoTripData,
  type GeoTripAnalysis,
} from 'types/geo.types';

import { BaseHandler } from './base.handler';
import {
  setGeoLoading,
  setLatestTripsForEachFleetAsset,
} from 'store/geo.slice';

export default class GeoHandler extends BaseHandler {
  private readonly api: GeoAPI;

  constructor() {
    super();

    this.api = new GeoAPI();
  }

  async get(): Promise<any> {
    try {
      const cacheKey = `geo_cache_fleet`;
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData) {
        const data = JSON.parse(cachedData);

        // Check if the cache is stale (older than 24 hours)
        const cacheTimestamp = new Date(data.timestamp);
        const currentTime = new Date();
        const hoursDifference =
          (currentTime.getTime() - cacheTimestamp.getTime()) / (1000 * 60 * 60);

        if (hoursDifference < 24) {
          this.dispatch(setLatestTripsForEachFleetAsset(data));

          return data;
        }
      }
      this.dispatch(setGeoLoading(true));
      // Fetch fresh data if cache is empty or stale
      const freshData = await this.api.getLatestTripsByFleetAssets(); // Replace with your API call
      sessionStorage.setItem(
        cacheKey,
        JSON.stringify({ ...freshData, timestamp: new Date() })
      );

      // Dispatch the fresh data
      this.dispatch(setLatestTripsForEachFleetAsset(freshData));
      return freshData;
    } catch (_) {
      this.dispatch(setGeoLoading(false));
      this.handleError('An error occurred while fetching geo data.');
    }
  }

  async getTrips(
    deviceId: string,
    tripStartTime: string
  ): Promise<GeoTripAnalysis> {
    try {
      const geoData = await this.api.getTrips(deviceId, tripStartTime);

      return geoData;
    } catch (_) {
      this.handleError('An error occurred while fetching geo trips data.');
    }

    return {
      data: [],
      analysis: {},
    };
  }

  async getAssetTypeTrips(
    tripStartTime: string
  ): Promise<AssetTypeGeoTripData | undefined> {
    try {
      const geoData = await this.api.getAssetTypeTrips(tripStartTime);

      return geoData.data;
    } catch (_) {
      this.handleError(
        'An error occurred while fetching geo trips data by asset type.'
      );
      this.dispatch(updateLoadingState(false));
      return undefined;
    }
  }
}
