import dayjs from 'dayjs';
import { ThemePalette } from 'mui.theme';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { formatYAxis } from '../Individual/Chart';
import {
  darkThemeLineChartDefaultColorMap,
  lineChartDefaultColorMap,
} from 'utils/helpers/general';

export const GroupedLineChart = ({
  enableDarkTheme,
  dataType,
  data,
  uniqueUnits,
  selectedOperation,
}: {
  enableDarkTheme: boolean;
  dataType: string;
  data: any;
  uniqueUnits: string[];
  exportTo: any;
  unitSystem: string;
  handleExport: any;
  selectedAsset: any;
  startDate: any;
  endDate: any;
  activeButton: any;
  selectedOperation: string;
}) => {
  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    let formattedDate = date.format('YY MMM DD');
    if (dataType === 'highGrain') {
      formattedDate = date.format('HH:mm:ss');
    }
    return formattedDate;
  };

  const formatTimestampWithHour = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('YYYY MMM DD HH:mm (Z)');
    return formattedDate;
  };

  const formatValue = (value: any) => {
    if (typeof value === 'number' && value % 1 !== 0) {
      return value.toFixed(2);
    }
    return value;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          stroke={'#25364c'}
          vertical={false}
        />
        <XAxis
          dataKey="ts"
          allowDuplicatedCategory={false}
          tickFormatter={formatTimestamp}
          textAnchor="end"
          tick={{ fontSize: 11 }}
        />

        {uniqueUnits.map((unit, index) => {
          return (
            <YAxis
              key={index}
              yAxisId={unit}
              stroke={
                enableDarkTheme
                  ? darkThemeLineChartDefaultColorMap[index]
                  : lineChartDefaultColorMap[index]
              }
              label={{
                value: `${unit}`,
                angle: -90,
                position: 'inside',
                fill: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                dy: 10,
                dx: 15,
                fontSize: 10,
              }}
              tickCount={8}
              tickFormatter={formatYAxis}
            />
          );
        })}
        <Tooltip
          cursor={{ fill: '#1B2B4A' }}
          wrapperStyle={{ outline: 'none', fontSize: '11px' }}
          contentStyle={{
            backgroundColor: 'transparent',
          }}
          itemStyle={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
          labelFormatter={(value) => formatTimestampWithHour(value)}
          formatter={(value) => formatYAxis(formatValue(value))}
        />
        <Legend
          wrapperStyle={{ fontSize: '20px' }}
          layout="horizontal"
          verticalAlign="top"
          align="center"
        />
        {data.map((item: any, index: number) => {
          return (
            <Line
              key={index}
              yAxisId={item.unit}
              type="monotone"
              dataKey={selectedOperation}
              data={item.data}
              stroke={
                enableDarkTheme
                  ? darkThemeLineChartDefaultColorMap[index]
                  : lineChartDefaultColorMap[index]
              }
              name={item.name}
              connectNulls={false}
              activeDot={{ r: 3 }}
              dot={{
                fill: enableDarkTheme
                  ? ThemePalette.dark.boxBackground
                  : ThemePalette.light.boxBackground,
                strokeWidth: 1,
              }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
