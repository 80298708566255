import { useState, type SyntheticEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { type SelectChangeEvent } from '@mui/material';
import { useCustomDropdownEffect } from 'utils/exports/hooks';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  value: number;
  handleChange: (newValue: number) => void;
  handleExport: (val: boolean) => void;
  disableLoader?: boolean;
}

const HeaderMenu = ({
  value,
  handleChange,
  handleExport,
  disableLoader = true,
}: Props) => {
  const { theme } = useAppSelector((state) => state.authReducer).customer;
  const enableDarkTheme = isDarkTheme(theme);

  const setHandleChange = (event: SyntheticEvent, newValue: number) => {
    handleChange(newValue);
  };

  const [exportTo, setExportTo] = useState('');
  const [exportToLabel, setExportToLabel] = useState('Export To');

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportTo(event.target.value);
  };

  useCustomDropdownEffect(
    exportTo,
    handleExport,
    setExportToLabel,
    setExportTo,
    [exportTo]
  );

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={setHandleChange}
        aria-label="basic tabs"
        TabIndicatorProps={{
          sx: {
            backgroundColor: '#5E85F0',
          },
        }}
      >
        <Tab
          label="Total Fleet"
          {...a11yProps(0)}
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label="Haulers"
          {...a11yProps(1)}
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label="Loaders"
          {...a11yProps(2)}
          style={{
            color: disableLoader
              ? ThemePalette.typography.lightGrey
              : enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}
          disabled={disableLoader}
        />
        <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
          <ExportSelector
            view={'card'}
            exportToLabel={exportToLabel}
            exportTo={exportTo}
            onExportToChange={handleExportDropDownChange}
            enableDarkTheme={enableDarkTheme}
          />
        </Box>
      </Tabs>
    </Box>
  );
};

export default HeaderMenu;
