import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ComposedChartComponent from 'components/Charts/ComposedChart';
import { type Asset } from 'types/asset.types';
import FuelEfficiencySelectorHeader from './FuelEfficiencySelectorHeader';
import type {
  FuelLoadedUnloadedAnalytics,
  FuelLoadedUnloadedItem,
} from 'types/fuelAnalytics';
import NoChartData from 'views/FuelAnalytics/NoChart';
import Colors from 'views/Settings/ExternalUsers/Squares/Helpers/Graphs/styles';
import {
  getFuelLabelWrtUnitSystem,
  unLoadedFuelEfficiencyLabels,
} from 'utils/helpers/labels';
import { useAppSelector } from 'store/hook';

interface Props {
  assets: Asset[];
  fuelEfficiency: FuelLoadedUnloadedAnalytics;
  isLoading: boolean;
  toolTipData: any;
  enableDarkTheme: boolean;
  selectedDevice: string | undefined;
}

const UnLoadedFuelEfficiency = ({
  assets,
  fuelEfficiency,
  isLoading,
  toolTipData,
  enableDarkTheme,
  selectedDevice,
}: Props) => {
  const [chartData, setChartData] = useState<FuelLoadedUnloadedItem[]>([]);
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  useEffect(() => {
    setChartData(
      formatData(
        fuelEfficiency.sortedBottom5UnLoadedFuelData,
        toolTipData,
        unitSystem
      )
    );
  }, [fuelEfficiency]);

  const getChartData = async (efficiency: string, assets: string[]) => {
    let data = chartData;

    if (efficiency === 'worstAssets') {
      data = fuelEfficiency.sortedBottom5UnLoadedFuelData;
    } else if (efficiency === 'bestAssets') {
      data = fuelEfficiency.sortedTop5UnLoadedFuelData;
    } else if (assets.length) {
      const filteredData = fuelEfficiency.unloadedFuelData.reduce(
        (accumulator: any, dataEntry: any) => {
          if (assets.includes(dataEntry.device)) {
            accumulator.push(dataEntry);
          }
          return accumulator;
        },
        []
      );

      data = filteredData;
    }

    setChartData(formatData(data, toolTipData, unitSystem));
  };

  return (
    <Box
      sx={{
        marginRight: '4px',
        marginBottom: '10px',
        paddingTop: '20px',
      }}
    >
      <FuelEfficiencySelectorHeader
        title="UnLoaded Fuel Consumption Index:"
        assets={assets}
        getAssetData={getChartData}
        enableDarkTheme={enableDarkTheme}
        data={fuelEfficiency}
        selectedDevice={selectedDevice}
      />
      <Box
        sx={{
          height: '400px',
        }}
      >
        {isLoading ? (
          <></>
        ) : chartData?.length ? (
          <ComposedChartComponent
            data={chartData}
            barKeys={[
              {
                name: 'Unloaded Fuel Efficiency',
                color: Colors.orange,
                label: `Unloaded Fuel Consumption Index (${getFuelLabelWrtUnitSystem(
                  unitSystem,
                  unLoadedFuelEfficiencyLabels.unloaded_fuel_efficiency
                )}) - Lower Is Better`,
              },
              {
                name: 'CO2',
                color: Colors.dimGray,
                label: `CO2 Emission ${getFuelLabelWrtUnitSystem(
                  unitSystem,
                  unLoadedFuelEfficiencyLabels.CO2
                )}`,
              },
            ]}
            xAxis="bumperNumber"
            showLegend={true}
            enableDarkTheme={enableDarkTheme}
          />
        ) : (
          <NoChartData enableDarkTheme={enableDarkTheme} />
        )}
      </Box>
    </Box>
  );
};

export default UnLoadedFuelEfficiency;

function formatData(
  data: FuelLoadedUnloadedItem[],
  toolTipData: any,
  unitSystem: string
) {
  return data.map((item: FuelLoadedUnloadedItem) => {
    let toolTipObject = {};
    if (toolTipData.engineAverages.length > 0) {
      toolTipObject = {
        ...toolTipData.engineAverages[0],
      };
    }
    const { fuelSource, device, ...newItem } = item;
    newItem.CO2 = item.CO2;
    const initialResponse = {
      ...newItem,
      Device: device,
      'Fuel Source': fuelSource,
      'Unloaded Fuel Efficiency': newItem.unloaded_fuel_avg,
      ...toolTipObject,
    };
    const toolTipItemData = toolTipData.data.find(
      (i: any) => i.device === item.device
    );

    if (toolTipItemData) {
      return {
        ...initialResponse,
        ...toolTipItemData,
      };
    } else {
      return {
        ...initialResponse,
      };
    }
  });
}
