import { ButtonGroup, Button, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hook';

interface Props {
  enableDarkTheme: boolean;
  ignoreYtd?: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  isLoading: boolean;
}

const DateRange = ({
  enableDarkTheme,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  isLoading,
}: Props) => {
  const { loadingStates } = useAppSelector((state) => state.productionReducer);
  const handleSelect = (id: string) => {
    setIsSelected(id);

    const today = dayjs();
    let newStartDate = startDate;
    let newEndDate = yesterday;

    switch (id) {
      case '1D':
        newStartDate = dayjs(yesterday).subtract(1, 'day').toDate();
        newEndDate = yesterday;
        break;
      case '7D':
        newStartDate = dayjs(yesterday).subtract(7, 'days').toDate();
        newEndDate = yesterday;
        break;
      case '14D':
        newStartDate = dayjs(yesterday).subtract(14, 'days').toDate();
        newEndDate = yesterday;
        break;
      case '1Y':
        newStartDate = dayjs(yesterday).subtract(1, 'year').toDate();
        newEndDate = yesterday;
        break;
      case 'All':
        newStartDate = dayjs(today).startOf('year').toDate();
        newEndDate = yesterday;
        break;
      case 'Custom':
        // Do nothing, user will select manually
        break;
      default:
        break;
    }

    // Update the startDate and endDate for non-custom ranges
    if (id !== 'Custom') {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  const dateRangeList = ['1D', '7D', '14D', '1Y', 'All', 'Custom'];

  return (
    <>
      <Typography
        style={{ fontFamily: "'Inter Variable', sans-serif", margin: '20px 0' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontSize: '12px', marginRight: '10px' }}>
              Quick Range:
            </Typography>
            <ButtonGroup
              size="small"
              variant="outlined"
              aria-label="Basic button group"
              sx={{
                border: '1px solid #2F445D',
              }}
              disabled={isLoading}
            >
              {dateRangeList.map((item) => {
                return (
                  <Button
                    key={item}
                    size="small"
                    disabled={loadingStates?.isLoadingFleetSummary}
                    style={{
                      backgroundColor:
                        isSelected === item
                          ? '#3666EC'
                          : enableDarkTheme
                          ? ThemePalette.dark.boxBackground
                          : ThemePalette.light.toolBarBackground,
                      color: loadingStates?.isLoadingFleetSummary
                        ? ThemePalette.typography.grey
                        : enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                    onClick={() => {
                      handleSelect(item);
                    }}
                  >
                    {item}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
          {isSelected === 'Custom' && (
            <div
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.mediumGray
                  : '#3d3d3d',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontSize: '12px', margin: '0 10px 0 50px' }}>
                Date Range:
              </Typography>
              <DatePicker
                showIcon
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={yesterday}
                selectsRange
                className={
                  enableDarkTheme
                    ? 'custom-datepicker-prod'
                    : 'custom-datepicker-prod-light-theme'
                }
                disabled={isSelected !== 'Custom'}
              />
            </div>
          )}
        </div>
      </Typography>
    </>
  );
};

export default DateRange;
