import BaseAPI from './base.api';

export default class DriverBehaviourAPI extends BaseAPI {
  getAll = async (payload: {
    event: string;
    range?: string;
    startDate?: string;
    endDate?: string;
  }) => {
    const response = await this.API.get('driver-event', {
      params: {
        ...payload,
      },
    });
    return response.data;
  };
}
