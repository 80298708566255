import { Box, Button, ButtonGroup, styled, Typography } from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import { useState } from 'react';
import { useAppSelector } from 'store/hook';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { airFilterColors } from 'views/FuelHistoricalAnalysis/utils';

interface Props {
  enableDarkTheme: boolean;
  device: any;
}

// Styled components for the indicators
const IndicatorDot = styled(Button)(({ theme }) => ({
  position: 'absolute',
  width: '20px',
  height: '20px',
  padding: 0,
  minWidth: 'unset',
  transform: 'translate(-50%, -50%)',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const PingAnimation = styled(Box)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  borderRadius: '50%',
  backgroundColor: '#1E5EFF',
  opacity: 0.75,
  animation: 'ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite',
  '@keyframes ping': {
    '75%, 100%': {
      transform: 'scale(2)',
      opacity: 0.3,
    },
  },
}));

const InnerDot = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '16px',
  height: '16px',
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
}));

const Tooltip = styled(Box)(({ theme }) => ({
  position: 'absolute',
  visibility: 'hidden',
  opacity: 0,
  top: '-4px',
  left: '50%',
  transform: 'translate(-50%, -100%)',
  padding: '4px 8px',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  fontSize: '12px',
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'nowrap',
  transition: 'opacity 0.2s ease-in-out',
  zIndex: 1000,
  [`${IndicatorDot}:hover &`]: {
    visibility: 'visible',
    opacity: 1,
  },
}));

const AssetScene = ({ enableDarkTheme, device }: Props) => {
  const { airFilterData } = useAppSelector(
    (state) => state.fuelAnalyticsReducer
  );

  const filteredData = airFilterData.filter(
    (item) => item.device === device.device
  );

  const indicators = [
    // { id: 1, left: '35%', top: '25%', label: 'Top Light' },
    // { id: 2, left: '80%', top: '20%', label: 'Cabin Light' },
    { id: 3, left: '84%', top: '45%', label: 'Side Light' },
    // { id: 4, left: '80%', top: '65%', label: 'Under Light' },
    // { id: 5, left: '25%', top: '55%', label: 'Wheel Light' },
    // { id: 6, left: '50%', top: '55%', label: 'Center Light' },
  ];

  // Custom linear progress styles
  const linearProgressStyles = {
    height: 10,
    borderRadius: 5,
    backgroundColor: '#F9A405',
  };

  // Function to calculate the position of the arrow
  const getArrowPosition = (value: number) => {
    let valueToUse = value;
    if (value === 100) {
      valueToUse = 99.8;
    }
    const position = (valueToUse / 100) * 100; // Calculate percentage
    return `calc(${position}% - 8px)`; // Adjust the position for the arrow
  };

  const buttonVals = [
    { key: 0, label: 'Filter 1' },
    { key: 1, label: 'Filter 2' },
    { key: 2, label: 'Filter 3' },
    { key: 3, label: 'Filter 4' },
  ];

  const [selectedFilter, setSelectedFilter] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(
    airFilterColors?.find(
      (item) =>
        item.label.toLowerCase() === filteredData[selectedFilter]?.filter_health
    )?.key
  );
  const buttons = buttonVals.map((val: any) => (
    <Button
      key={val.key}
      sx={{
        margin: '4px 0',
        backgroundColor: enableDarkTheme
          ? selectedFilter === val.key
            ? '#131416'
            : '#0F49E5'
          : selectedFilter === val.key
          ? '#131416'
          : '#022859',
        color: 'white',
        '&:hover': {
          backgroundColor: '#536887',
        },
      }}
      onClick={() => {
        setSelectedFilter(val.key);
        setSelectedStatus(
          airFilterColors.find(
            (item) =>
              item.label.toLowerCase() === filteredData[val.key].filter_health
          )?.key
        );
      }}
    >
      {val.label}
    </Button>
  ));

  return (
    <Box
      sx={{
        width: '40%',
        position: 'relative',
      }}
    >
      <Box position="relative">
        <SVG name="haulTruckAssetImage" fill="none" />

        {indicators.map((indicator) => (
          <IndicatorDot
            key={indicator.id}
            sx={{
              left: indicator.left,
              top: indicator.top,
            }}
            onClick={() => {
              console.log(`Clicked ${indicator.label}`);
            }}
          >
            <PingAnimation />
            <InnerDot>
              <Tooltip>{indicator.label}</Tooltip>
            </InnerDot>
          </IndicatorDot>
        ))}
      </Box>
      {filteredData.length <= 0 ? (
        <Typography>No Air Filter Data</Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '30px',
            // width: '100%',
            border: '1px solid rgba(98, 98, 98, 0.2)',
            borderRadius: '4px',
            padding: '10px 20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '40%',
            }}
          >
            <Typography
              variant="body2"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                marginBottom: '40px',
                padding: '10px',
                color: enableDarkTheme ? '#fff' : '#022859',
              }}
            >
              Air Filter -{' '}
              {buttonVals.find((item) => item.key === selectedFilter)?.label}
            </Typography>

            {/* <SVG name="redFuelFilter" fill="none" /> */}
            <ButtonGroup
              orientation="vertical"
              aria-label="Vertical button group"
              sx={{
                width: '100px',
              }}
            >
              {buttons}
            </ButtonGroup>
          </Box>

          <Box
            sx={{
              // display: 'flex',
              flexWrap: 'wrap',
              marginLeft: '20px',
              justifyContent: 'space-between',
              width: '60%',
            }}
          >
            {/* <Box
            sx={{
              textAlign: 'left',
              flex: '1 0 48%',
              marginBottom: '5px',
            }}
          >
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Age
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                padding: '5px 10px 5px 0',
                fontWeight: 'bold',
              }}
              variant="h4"
            >
              4 Months
            </Typography>
          </Box> */}

            {/* <Box
            sx={{
              textAlign: 'left',
              flex: '1 0 48%',
              marginBottom: '10px',
            }}
          >
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Condition
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                padding: '5px 10px 5px 0',
                fontWeight: 'bold',
              }}
              variant="h4"
            >
              No sensor data between 2024-11-15 and 2024-12-05
            </Typography>
          </Box> */}

            <Box
              sx={{
                textAlign: 'left',
                flex: '1 0 48%',
                marginBottom: '5px',
              }}
            >
              <Typography
                style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
              >
                Date
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                  padding: '5px 10px 5px 0',
                  fontWeight: 'bold',
                }}
                variant="h4"
              >
                {filteredData[selectedFilter]?.date}
              </Typography>
            </Box>
            <Box></Box>

            {/* <Box
            sx={{
              textAlign: 'left',
              flex: '1 0 48%',
              marginBottom: '5px',
            }}
          >
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Fuel Consumed
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                padding: '5px 10px 5px 0',
                fontWeight: 'bold',
              }}
              variant="h4"
            >
              3.4 LTR
            </Typography>
          </Box> */}

            {/* <Box
            sx={{
              textAlign: 'left',
              flex: '1 0 48%',
              marginBottom: '5px',
            }}
          >
            <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
              Cost
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                padding: '5px 10px 5px 0',
                fontWeight: 'bold',
              }}
              variant="h4"
            >
              $800
            </Typography>
          </Box> */}

            <Box
              sx={{
                textAlign: 'left',
                flex: '1 0 48%',
                marginBottom: '5px',
              }}
            >
              <Typography
                style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
              >
                Description
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                  padding: '5px 10px 5px 0',
                  fontWeight: 'bold',
                }}
                variant="h4"
              >
                {filteredData[selectedFilter]?.description}
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: 'left',
                flex: '1 0 48%',
                marginBottom: '5px',
                marginTop: '40px',
              }}
            >
              <Typography
                style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
              >
                Air Filter Status
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                // sx={{ marginTop: '50px' }}
              >
                {airFilterColors.map((color, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: '23%',
                      height: 15,
                      backgroundColor: color.key,
                      borderRadius: 1,
                      boxShadow: 1,
                      opacity: selectedStatus === color.key ? 1 : 0.1,
                    }}
                  />
                ))}
              </Box>
              <Typography
                style={{
                  fontSize: '12px',
                  padding: '5px 10px 5px 0',
                  fontWeight: 'bold',
                }}
                variant="h4"
              >
                {capitalizeFirstLetter(
                  filteredData[selectedFilter]?.filter_health
                )}
              </Typography>
            </Box>

            {/* <Box position="relative" width="100%">
           
            <Typography
              variant="body2"
              style={{
                position: 'absolute',
                top: -10,
                zIndex: 1,
                left: getArrowPosition(90),
                transform: 'rotate(180deg)', // Rotate the arrow to point downwards
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                opacity: 1,
              }}
            >
              ▲
            </Typography>

            
            <LinearProgress
              variant="determinate"
              value={100}
              sx={{
                ...linearProgressStyles,
                opacity: 1,
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  background:
                    'linear-gradient(to right, #eb5757, #228b22, #eb5757)',
                },
              }}
            />

           
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 1,
              }}
            >
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                  fontSize: '12px',
                }}
              >
                Excellent
              </Typography>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                  fontSize: '12px',
                }}
              >
                Worst
              </Typography>
            </Box>
          </Box> */}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AssetScene;
