import { createSlice } from '@reduxjs/toolkit';
import { type targetData } from 'types/target.types';
import type { targetLoadingStates } from 'types/targets.types';
import { daysLeftInYear } from 'views/Utilization/utils';

export interface targetState {
  data: {
    target: targetData;
  };
  loadingStates: targetLoadingStates;
}

const initialState: targetState = {
  data: {
    target: {
      metricName: '',
      targetValue: 0,
      ytdTarget: 0,
    },
  },
  loadingStates: {
    isLoadingTarget: true,
  },
};

export const targetSlice = createSlice({
  name: 'utilization',
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state.loadingStates = {
        ...state.loadingStates,
        ...payload,
      };
    },
    setTarget(state, { payload }) {
      if (typeof payload?.targetValue !== 'number') {
        payload.targetValue = Number(payload?.targetValue);
      }
      const ytdTarget =
        Number(
          (
            ((payload?.targetValue || 0) / 365) *
            (365 - daysLeftInYear())
          ).toFixed(1)
        ) || 0;
      state.data.target = { ...payload, ytdTarget };
    },
  },
});

export const { setIsLoading: setTargetLoading, setTarget } =
  targetSlice.actions;

export default targetSlice.reducer;
