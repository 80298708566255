import { Box, Grid, Typography } from '@mui/material';
import type { DriverBehaviorData } from 'types/driver.behavior.types';
import { allEvents } from 'utils/driverBehavior';

interface Props {
  enableDarkTheme: boolean;
  data: DriverBehaviorData;
}

const Stats = ({ enableDarkTheme, data }: Props) => {
  const stats = allEvents.map((item) => {
    // @ts-expect-error we have type defined
    const fleetData = data[item].find(
      (item: any) => item.device_id === 'FLEET-WIDE'
    ) ?? {
      device_id: 'FLEET-WIDE',
      eventPercentage: 0,
      noOfEvents: 0,
    };

    return {
      title: `Total number of ${item} events`,
      value: fleetData.noOfEvents,
    };
  });

  return (
    <Box
      sx={{
        marginTop: '10px',
        marginRight: '20px',
        marginBottom: '10px',
        border: '1px solid #2F445E',
        borderRadius: '8px',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          padding: '16px',
          borderRadius: '8px',
        }}
      >
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box>
              <Typography sx={{ fontSize: '12px', color: '#878A99' }}>
                {stat.title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: '600',
                  marginTop: '5px',
                  fontSize: '16px',
                }}
              >
                {stat.value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Stats;
