import { Button, ButtonGroup, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import DatePicker from 'react-datepicker';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

export const dateRangeList = ['1D', '7D', '14D', '1M', 'Custom'];

interface QuickRangeProps {
  isDisabled?: boolean;
  isSelected?: string;
  handleSelect?: (item: string) => void;
  startDate: Date | null;
  endDate: Date | null;
  handleDateChange: (range: [Date | null, Date | null]) => void;
  onDateRangeChangeAction?: (
    startDate: Date | null,
    endDate: Date | null
  ) => void;
  maxDate?: Date;
  showHotLinks?: boolean;
}
const QuickRange = ({
  isDisabled = false,
  isSelected,
  handleSelect,
  startDate,
  endDate,
  handleDateChange,
  onDateRangeChangeAction,
  maxDate,
  showHotLinks = true,
}: QuickRangeProps) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const handleCustomDateChange = (range: [Date | null, Date | null]) => {
    handleDateChange(range);
    const [start, end] = range;

    if (start && end && onDateRangeChangeAction) {
      onDateRangeChangeAction(start, end); // Trigger only when both dates are selected
    }
  };

  return (
    <div
      style={{
        color: enableDarkTheme ? ThemePalette.typography.mediumGray : '#3d3d3d',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '10px',
        width: showHotLinks ? '645px' : '301px',
        height: '40px',
      }}
    >
      {showHotLinks && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography style={{ fontSize: '12px', marginRight: '10px' }}>
            Quick Range:
          </Typography>
          <ButtonGroup
            size="small"
            variant="outlined"
            aria-label="Basic button group"
            sx={{
              border: '1px solid #2F445D',
            }}
          >
            {dateRangeList.map((item) => (
              <Button
                disabled={isDisabled}
                key={item}
                size="small"
                style={{
                  backgroundColor:
                    isSelected === item
                      ? '#3666EC'
                      : enableDarkTheme
                      ? ThemePalette.dark.boxBackground
                      : ThemePalette.light.toolBarBackground,
                  color: isDisabled
                    ? `gray !important`
                    : enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
                onClick={() => {
                  if (item === isSelected) return; // avoid duplicate selection
                  if (handleSelect) handleSelect(item);
                }}
              >
                {item}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      )}
      {isSelected === 'Custom' && (
        <div
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.mediumGray
              : '#3d3d3d',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Typography style={{ fontSize: '12px' }}>Date Range:</Typography>
          <DatePicker
            showIcon
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleCustomDateChange}
            maxDate={maxDate}
            selectsRange
            className={
              enableDarkTheme
                ? 'custom-datepicker-prod'
                : 'custom-datepicker-prod-light-theme'
            }
          />
        </div>
      )}
    </div>
  );
};

export default QuickRange;
