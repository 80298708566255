import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  ResponsiveContainer,
} from 'recharts';
import {
  formatDateShortener,
  formatDateToMonthYear,
} from 'utils/helpers/dateTime';
import { fuelValueConversion } from '../utils';
import { Selector } from 'components/Form/Selectors/Selector';

interface Props {
  enableDarkTheme: boolean;
  timeFrame: 'month' | 'quarter' | 'custom';
  currency: 'usd' | 'cad';
  fuelPrice: any;
  fuelMeasure: 'consumption' | 'co2 emission' | 'cost';
  setFuelMeasure: any;
  fuelConsumptionRangeAnalytics: any;
  selectedAssetType: string;
  isLoading: boolean;
  consumptionMethod: 'averageConsumption' | 'totalConsumption';
  unitSystem: string;
  fuelUnit: 'ltr' | 'gal';
  baseCost: {
    diesel: number;
    gas: number;
  };
  setTimeFrame: (arg: 'month' | 'quarter' | 'custom') => void;
}

const LineChartDiv = ({
  enableDarkTheme,
  timeFrame,
  currency,
  fuelPrice,
  fuelMeasure,
  setFuelMeasure,
  fuelConsumptionRangeAnalytics,
  selectedAssetType,
  isLoading,
  consumptionMethod,
  unitSystem,
  fuelUnit,
  baseCost,
  setTimeFrame,
}: Props) => {
  const [data, setData] = useState<Array<{ date: string; value: number }>>([]);

  const spreadRangeData = useMemo(
    () =>
      [...fuelConsumptionRangeAnalytics].sort(
        (a: any, b: any) =>
          new Date(
            a.customer_month || a.customer_quarter || a.customer_day
          ).getTime() -
          new Date(
            b.customer_month || b.customer_quarter || a.customer_day
          ).getTime()
      ),
    [fuelConsumptionRangeAnalytics]
  );
  const calculateValue = useCallback(
    (item: any, measure: typeof fuelMeasure) => {
      switch (measure) {
        case 'consumption':
          return Number(
            consumptionMethod === 'averageConsumption'
              ? fuelValueConversion(
                  +item.fleet_average_fuel,
                  unitSystem,
                  fuelUnit
                ).toFixed(2)
              : fuelValueConversion(
                  +item.fleet_sum_fuel,
                  unitSystem,
                  fuelUnit
                ).toFixed(2)
          );
        case 'co2 emission':
          return Number(
            consumptionMethod === 'averageConsumption'
              ? item.CO2Average?.toFixed(2)
              : item.CO2?.toFixed(2) ?? 0
          );
        case 'cost':
          return Number(
            (
              (consumptionMethod === 'averageConsumption'
                ? +fuelValueConversion(
                    +item.fleet_average_fuel,
                    unitSystem,
                    fuelUnit,
                    'cost'
                  ).toFixed(2)
                : +fuelValueConversion(
                    +item.fleet_sum_fuel,
                    unitSystem,
                    fuelUnit,
                    'cost'
                  ).toFixed(2)) *
              baseCost[item.fuelSource.toLowerCase() as 'gas' | 'diesel']
            )?.toFixed(2)
          );
        default:
          return 0;
      }
    },
    [fuelPrice, consumptionMethod, fuelUnit, currency, baseCost]
  );

  useEffect(() => {
    if (spreadRangeData.length < 1) return;

    const itemVal = spreadRangeData
      .map((item: any) => {
        if (
          selectedAssetType === 'haul_truck' &&
          item.asset_type === 'haul_truck'
        ) {
          if (item.customer_day) {
            return {
              date: formatDateShortener(
                item.customer_month ||
                  item.customer_quarter ||
                  item.customer_day
              ),
              value: calculateValue(item, fuelMeasure),
            };
          } else {
            return {
              date: formatDateToMonthYear(
                item.customer_month ||
                  item.customer_quarter ||
                  item.customer_day
              ),
              value: calculateValue(item, fuelMeasure),
            };
          }
        } else if (selectedAssetType === item.asset_type) {
          if (item.customer_day) {
            return {
              date: formatDateShortener(
                item.customer_month ||
                  item.customer_quarter ||
                  item.customer_day
              ),
              value: calculateValue(item, fuelMeasure),
            };
          } else {
            return {
              date: formatDateToMonthYear(
                item.customer_month ||
                  item.customer_quarter ||
                  item.customer_day
              ),
              value: calculateValue(item, fuelMeasure),
            };
          }
        }
        return null;
      })
      .filter((item): item is { date: string; value: number } => item !== null);

    setData(itemVal);
  }, [
    spreadRangeData,
    fuelMeasure,
    timeFrame,
    calculateValue,
    selectedAssetType,
  ]);

  const handleFuelMeasure = (
    event: React.MouseEvent<HTMLElement>,
    newMeasure: typeof fuelMeasure
  ) => {
    if (newMeasure !== null) {
      setFuelMeasure(newMeasure);
    }
  };

  const initialBrushConfig = useMemo(() => {
    // Show initial 3 months/quarters of data
    const endIndex = Math.min(2, data.length - 1);
    return {
      startIndex: 0,
      endIndex,
    };
  }, [data.length]);
  const handleTimeFrame = (value: 'month' | 'quarter' | 'custom') => {
    setTimeFrame(value);
  };

  if (!data.length && !isLoading) {
    return (
      <Box
        sx={{
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: enableDarkTheme ? '#172A47' : '#ffffff',
        }}
      >
        <Typography>No data available yet</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        borderColor: 'divider',
        backgroundColor: enableDarkTheme ? '#172A47' : '#ffffff',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          color: enableDarkTheme ? ThemePalette.typography.white : '#3d3d3d',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            color: enableDarkTheme ? ThemePalette.typography.white : '#3d3d3d',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              padding: '5px 10px 5px 0',
              fontWeight: 'bold',
            }}
          >
            Fleet Fuel Consumption
          </Typography>

          <ToggleButtonGroup
            value={fuelMeasure}
            exclusive
            onChange={handleFuelMeasure}
            aria-label="fuelMeasure"
            size="small"
          >
            {['consumption', 'co2 emission', 'cost'].map((value) => (
              <ToggleButton
                key={value}
                value={value}
                sx={{
                  color: enableDarkTheme ? '#D0D5DD' : '#000',
                  borderColor: '#7c7e82',
                  '&.Mui-selected': {
                    backgroundColor: enableDarkTheme ? '#0F49E5' : '#022859',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#022859',
                    },
                  },
                }}
              >
                <Typography sx={{ fontSize: '12px' }}>{value}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ marginLeft: '20px', display: 'flex', flexDirection: 'row' }}>
          <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
            Time Frame:
          </Typography>
          <Selector
            minWidth="1px"
            selectSx={{
              fontSize: '12px',
              '& .MuiSelect-outlined': {
                borderColor: enableDarkTheme ? '#FFFFFF' : '#000000',
              },
            }}
            value={timeFrame}
            onChange={(event) => {
              handleTimeFrame(
                String(event.target.value) as 'month' | 'quarter' | 'custom'
              );
            }}
            selectorOptions={[
              {
                id: 'month',
                display: 'Monthly',
              },
              {
                id: 'quarter',
                display: 'Quarterly',
              },
              {
                id: 'custom',
                display: 'Custom Date Range',
              },
            ]}
            enableDarkTheme={enableDarkTheme}
          />
        </Box>
      </Box>
      <div style={{ position: 'relative', width: '100%', height: 200 }}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <CartesianGrid stroke={enableDarkTheme ? '#213656' : '#f5f5f5'} />
            <XAxis dataKey="date" axisLine={false} />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#497bfa"
              strokeWidth={3}
            />
            <Brush
              dataKey="date"
              height={80}
              stroke="#497bfa"
              fill="none"
              travellerWidth={10}
              y={0}
              {...initialBrushConfig}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
};

export default LineChartDiv;
