import {
  convertGallonsToLiters,
  convertLitersToGallons,
} from 'utils/helpers/unitSystem';

export const fuelValueConversion = (
  fuelValue: number,
  unitSystem: string,
  fuelUnit: string,
  type?: string
) => {
  if (type === 'cost') {
    // only use imperial i.e ltrs
    if (unitSystem === 'imperial') {
      return convertGallonsToLiters(fuelValue);
    } else {
      return fuelValue;
    }
  }
  if (
    (unitSystem === 'metric' && fuelUnit === 'ltr') ||
    (unitSystem === 'imperial' && fuelUnit === 'gal')
  ) {
    return fuelValue;
  } else if (unitSystem === 'imperial' && fuelUnit === 'ltr') {
    return convertGallonsToLiters(fuelValue);
  } else if (unitSystem === 'metric' && fuelUnit === 'gal') {
    return convertLitersToGallons(fuelValue);
  } else {
    return fuelValue;
  }
};

export const airFilterColors = [
  { key: '#4caf50', label: 'Healthy' },
  { key: '#acd157', label: 'Unknown' },
  { key: '#ff9800', label: 'Approaching EOL' },
  { key: '#f44336', label: 'EOL' },
];
