import { createSlice } from '@reduxjs/toolkit';
import type {
  fleetData,
  productionLoadingStates,
} from 'types/production.types';
import { daysLeftInYear } from 'views/Utilization/utils';

export interface productionState {
  data: {
    allFleet: fleetData;
  };
  loadingStates: productionLoadingStates;
}

const initialState: productionState = {
  data: {
    allFleet: {
      assets: [],
      summary: {
        period: 'current',
        cycle_time: 0,
        cycle_time_percentage_diff: 0,
        total_load: 0,
        total_load_percentage_diff: 0,
        avgTonDay: 0,
        avgTonDay_percentage_diff: 0,
        avgTon: 0,
        avgTon_percentage_diff: 0,
        avg_cycle_time: 0,
        avg_cycle_time_percentage_diff: 0,
        haul_truck_cycle_time: 0,
        haul_truck_cycle_time_percentage_diff: 0,
        haul_truck_travel_empty: 0,
        haul_truck_travel_empty_percentage_diff: 0,
        haul_truck_dump: 0,
        haul_truck_dump_percentage_diff: 0,
        haul_truck_loading_duration: 0,
        haul_truck_loading_duration_percentage_diff: 0,
        haul_truck_loaded_stop: 0,
        haul_truck_loaded_stop_percentage_diff: 0,
        haul_truck_travel_loaded: 0,
        haul_truck_travel_loaded_percentage_diff: 0,
        loader_cycle_time: 0,
        loader_travel_empty: 0,
        loader_dump: 0,
        loader_loading_duration: 0,
        loader_loaded_stop: 0,
        loader_travel_loaded: 0,
        cycle_count: 0,
        cycle_count_percentage_diff: 0,
        haul_truck_total_load: 0,
        haul_truck_avgTonDay: 0,
        haul_truck_avgTon: 0,
        haul_truck_total_load_percentage_diff: 0,
        haul_truck_avgTonDay_percentage_diff: 0,
        haul_truck_avgTon_percentage_diff: 0,
        haul_cycle_count_percentage_diff: 0,
        haul_cycle_count: 0,
        pass_count_cycle: 0,
        haul_truck_avg_cycle_time: 0,
        haul_truck_avg_cycle_time_percentage_diff: 0,
        loader_loading_fuel_consumption_l: 0,
        loader_unloading_fuel_consumption_l: 0,
        loader_loaded_trip_fuel_consumption_l: 0,
        loader_unloaded_trip_fuel_consumption_l: 0,
        haul_truck_loading_fuel_consumption_l: 0,
        haul_truck_unloading_fuel_consumption_l: 0,
        haul_truck_loaded_trip_fuel_consumption_l: 0,
        haul_truck_unloaded_trip_fuel_consumption_l: 0,
        avgLoadPerHour: 0,
        haul_truck_avgLoadPerHour: 0,
        loader_avgLoadPerHour: 0,
      },
      score: {
        date: '',
        prod_score: 0,
      },
      summaryChart: {
        total_load: [],
        cycle_time: [],
        avg_cycle_time: [],
        avgTonDay: [],
        avgLoadPerHour: [],
        avgTon: [],
        pass_count: [],
      },
      totalFleetDetails: {
        tons: 0,
        dailyAverageTon: 0,
        cycleTime: 0,
        ytdTarget: 0,
        annualTarget: 0,
        rollingDailyAverage: 0,
        remainingDays: 0,
        haulerTon: 0,
        haulerCount: 0,
      },
      suggestions: [],
      fleetPeriodSummary: [],
    },
  },
  loadingStates: {
    isLoadingFleetAssets: true,
    isLoadingFleetSummary: true,
    isLoadingFleetDetails: true,
    isLoadingFleetPeriodSummary: true,
    isLoadingSuggestions: true,
    isLoadingTarget: true,
  },
};

export const productionSlice = createSlice({
  name: 'production',
  initialState,
  reducers: {
    setTotalFleetAssets(state, { payload }) {
      state.data.allFleet.assets = payload;
    },
    setTotalFleetSummary(state, { payload }) {
      state.data.allFleet.summary = {
        ...state.data.allFleet.summary,
        ...payload.summary,
      };
      state.data.allFleet.score = {
        ...state.data.allFleet.score,
        ...(payload.score ? payload.score : { date: '', prod_score: 0 }),
      };
      state.data.allFleet.summaryChart = {
        ...state.data.allFleet.summaryChart,
        ...(payload.charts
          ? payload.charts
          : {
              total_load: [],
              cycle_time: [],
              avgTonDay: [],
              avgTon: [],
              pass_count: [],
            }),
      };
    },
    setTotalFleetDetails(state, { payload }) {
      state.data.allFleet.totalFleetDetails = {
        ...state.data.allFleet.totalFleetDetails,
        ...payload,
      };
    },
    setSuggestions(state, { payload }) {
      state.data.allFleet.suggestions = payload.data;
    },
    setFleetPeriodSummary(state, { payload }) {
      state.data.allFleet.fleetPeriodSummary = payload;
    },
    setIsLoading(state, { payload }) {
      state.loadingStates = {
        ...state.loadingStates,
        ...payload,
      };
    },
    updateTargets(state, { payload }) {
      state.data.allFleet.totalFleetDetails.annualTarget = Number(
        payload?.targetValue
      );
      state.data.allFleet.totalFleetDetails.ytdTarget =
        Number(
          (
            ((payload?.targetValue || 0) / 365) *
            (365 - daysLeftInYear())
          ).toFixed(1)
        ) || 0;
    },
  },
});

export const {
  setTotalFleetAssets,
  setTotalFleetSummary,
  setTotalFleetDetails,
  setIsLoading,
  setFleetPeriodSummary,
  setSuggestions,
  updateTargets,
} = productionSlice.actions;
export default productionSlice.reducer;
