import {
  type IFilterComp,
  type IFilterParams,
  type IDoesFilterPassParams,
} from 'ag-grid-community';

export class IsActiveFilter implements IFilterComp {
  eGui: HTMLDivElement | null = null;
  rbAll: HTMLInputElement | null = null;
  rbActive: HTMLInputElement | null = null;
  rbInactive: HTMLInputElement | null = null;
  filterActive: boolean = false;
  filterChangedCallback: (() => void) | null = null;

  init(params: IFilterParams): void {
    this.filterChangedCallback = params.filterChangedCallback;

    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
      <div class="custom-filter">
        <div>Select Status</div>
        <label>
          <input type="radio" name="statusFilter" id="rbAll" checked /> Show All
        </label>
        <label>
          <input type="radio" name="statusFilter" id="rbActive" /> Active
        </label>
        <label>
          <input type="radio" name="statusFilter" id="rbInactive" /> Inactive
        </label>
      </div>
    `;

    this.rbAll = this.eGui.querySelector('#rbAll');
    this.rbActive = this.eGui.querySelector('#rbActive');
    this.rbInactive = this.eGui.querySelector('#rbInactive');

    this.rbAll?.addEventListener('change', this.onRbChanged.bind(this));
    this.rbActive?.addEventListener('change', this.onRbChanged.bind(this));
    this.rbInactive?.addEventListener('change', this.onRbChanged.bind(this));
  }

  onRbChanged(): void {
    this.filterActive = !!this.rbActive?.checked || !!this.rbInactive?.checked;
    this.filterChangedCallback?.();
  }

  getGui(): HTMLElement {
    if (!this.eGui) {
      this.eGui = document.createElement('div'); // Create a default fallback
    }
    return this.eGui;
  }

  isFilterActive(): boolean {
    return this.filterActive;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const isActive = params.data.is_active;

    if (this.rbActive?.checked) {
      return isActive === true;
    } else if (this.rbInactive?.checked) {
      return isActive === false;
    }

    return true; // Show all
  }

  getModel(): any {
    if (!this.filterActive) return null;

    if (this.rbActive?.checked) {
      return { value: 'active' };
    } else if (this.rbInactive?.checked) {
      return { value: 'inactive' };
    }
    return null;
  }

  setModel(model: any): void {
    if (!model) {
      if (this.rbAll) this.rbAll.checked = true;
      this.filterActive = false;
    } else {
      this.filterActive = true;
      if (model.value === 'active') {
        if (this.rbActive) this.rbActive.checked = true;
      } else if (model.value === 'inactive') {
        if (this.rbInactive) this.rbInactive.checked = true;
      }
    }
  }
}
