import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hook';
import Header from './Components/Header';
import { isDarkTheme } from 'utils/theme';
import useDateRange from 'hooks/dateRange';
import './styles.css';
import LineChartDiv from './Components/LineChart';
import BodyDiv from './Components/BodyDiv';
import FuelAnalyticsHandler from 'handlers/fuelAnalytics.handler';
import NonHaulTruckHandler from 'handlers/nonHaulTruck.handler';

const FuelHistoryAnalytics = () => {
  const { theme, code: customerCode } = useAppSelector(
    (state) => state.authReducer
  ).customer;

  const { data: fuelPriceData } = useAppSelector(
    (state) => state.fuelPriceReducer
  );

  const { fleetDeviceDetails, fuelConsumptionRangeAnalytics } = useAppSelector(
    (state) => state.fuelAnalyticsReducer
  );

  const { isLoading } = useAppSelector((state) => state.appReducer);

  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const enableDarkTheme = isDarkTheme(theme);
  const fuelAnalyticsHandler = new FuelAnalyticsHandler();
  const nonHaulTruckHandler = new NonHaulTruckHandler();

  const { startDate, endDate, setStartDate, setEndDate, yesterday } =
    useDateRange();

  const [fuelPriceMod, setFuelPriceMod] = useState<{
    diesel: number;
    gas: number;
  }>({
    diesel: 0,
    gas: 0,
  });
  const [timeFrame, setTimeFrame] = useState<'month' | 'quarter' | 'custom'>(
    'month'
  );

  const [currency, setCurrency] = useState<'usd' | 'cad'>('usd');
  const [fuelUnit, setFuelUnit] = useState<'ltr' | 'gal'>(
    unitSystem === 'metric' ? 'ltr' : 'gal'
  );
  const [fuelMeasure, setFuelMeasure] = useState<
    'consumption' | 'co2 emission' | 'cost'
  >('consumption');
  const [selectedAssetType, setSelectedAssetType] =
    useState<string>('haul_truck');

  const [consumptionMethod, setConsumptionMethod] = useState<
    'averageConsumption' | 'totalConsumption'
  >('averageConsumption');

  // const handleSetCurrency = (val: any) => {
  //   setCurrency(val);
  // };

  // const handleSetFuelUnit = (val: any) => {
  //   setFuelUnit(val);
  // };

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    setTimeFrame('custom');
  };

  const getAssetAvgFuelConsumption = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    initialStartDate: Date,
    initialEndDate: Date,
    isHaulTruck: boolean,
    selectedConsumptionRange: string,
    selectedAssetType: string
  ) => {
    const startDate = dayjs(initialStartDate).format('YYYY-MM-DD');
    const endDate = dayjs(initialEndDate).format('YYYY-MM-DD');
    let rangeToReturn = 'month';
    if (selectedConsumptionRange.includes('quarter')) {
      rangeToReturn = 'quarter';
    } else if (selectedConsumptionRange === 'custom') {
      rangeToReturn = 'custom';
    }
    await fuelAnalyticsHandler.getFuelUsageHistoricalAnalytics(
      makeModels,
      startDate,
      endDate,
      rangeToReturn,
      selectedAssetType
    );
    if (!isHaulTruck) {
      await nonHaulTruckHandler.getAnalytics(makeModels, startDate, endDate);
    }
  };

  useEffect(() => {
    if (
      // selectedAsset.length > 0 &&
      customerCode !== 'cma' &&
      startDate &&
      endDate
    ) {
      // const isHaulTruck =
      //   assetTypeMappings[
      //     assetMakeModelList[selectedAsset[0]][0]?.assetType
      //   ] === 'Haul Truck';

      // const makeModels = getAssetsMakeModel(selectedAsset);
      void getAssetAvgFuelConsumption(
        [{ make: '', model: '' }],
        startDate,
        endDate,
        true,
        timeFrame,
        'Haul Truck'
      );
    }
  }, [
    // selectedAsset,
    startDate,
    endDate,
    customerCode,
    timeFrame,
  ]);

  return (
    <>
      <Header
        enableDarkTheme={enableDarkTheme}
        startDate={startDate}
        endDate={endDate}
        handleDateChange={handleDateChange}
        yesterday={yesterday}
        fuelPrice={fuelPriceData}
        currency={currency}
        setCurrency={setCurrency}
        fuelUnit={fuelUnit}
        setFuelUnit={setFuelUnit}
        fuelPriceMod={fuelPriceMod}
        setFuelPriceMod={setFuelPriceMod}
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setSelectedAssetType}
        allFleetData={fuelConsumptionRangeAnalytics}
        consumptionMethod={consumptionMethod}
        setConsumptionMethod={setConsumptionMethod}
      />
      <LineChartDiv
        enableDarkTheme={enableDarkTheme}
        timeFrame={timeFrame}
        fuelPrice={fuelPriceData}
        currency={currency}
        fuelMeasure={fuelMeasure}
        setFuelMeasure={setFuelMeasure}
        fuelConsumptionRangeAnalytics={fuelConsumptionRangeAnalytics}
        selectedAssetType={selectedAssetType}
        isLoading={isLoading}
        consumptionMethod={consumptionMethod}
        unitSystem={unitSystem}
        fuelUnit={fuelUnit}
        baseCost={fuelPriceMod}
        setTimeFrame={setTimeFrame}
      />
      <BodyDiv
        enableDarkTheme={enableDarkTheme}
        deviceData={fleetDeviceDetails}
        allFleetData={fuelConsumptionRangeAnalytics}
        fuelMeasure={fuelMeasure}
        baseCost={fuelPriceMod}
        selectedAssetType={selectedAssetType}
        isLoading={isLoading}
        unitSystem={unitSystem}
        fuelUnit={fuelUnit}
      />
    </>
  );
};

export default FuelHistoryAnalytics;
