import { Box, Grid } from '@mui/material';
import TirePressure from '../TirePressure';
import GraderSixWheels from '../Schematics/GraderSixWheels';
import { fixedLimts } from 'views/TirePressure/util';

export const GraderSixTires = ({
  tireData,
  limits,
  enableDarkTheme,
  unitSystem,
  tireCodes,
  metricType,
}: {
  tireData: any;
  limits: any;
  enableDarkTheme: boolean;
  unitSystem: string;
  tireCodes: any;
  metricType: string;
}) => {
  const tireCurrentStatus =
    metricType === 'Pressure'
      ? tireData.tireCurrentStatus
      : tireData.tireCurrentTemperature;
  const tireNumberSystem = ['0x17', '0x19', '0x27', '0x29', '0x37', '0x39'];

  // @ts-expect-error ignore this we have it defined
  const limitsData = fixedLimts[unitSystem][metricType.toLowerCase()];

  const isOverMax = (code: string, value: number, limitData: any) => {
    const { maxValue } = limitData;
    const { minValue } = limitData;
    return !(value >= minValue && value <= maxValue);
  };

  const p1 = Number(tireCurrentStatus?.['0x17'] ?? 0);

  const p2 = Number(tireCurrentStatus?.['0x19'] ?? 0);

  const p3 = Number(tireCurrentStatus?.['0x27'] ?? 0);

  const p4 = Number(tireCurrentStatus?.['0x29'] ?? 0);

  const p5 = Number(tireCurrentStatus?.['0x37'] ?? 0);

  const p6 = Number(tireCurrentStatus?.['0x39'] ?? 0);

  const p1Limits = limits.find((item: any) => item.tireCode === '0x17');
  const p2Limits = limits.find((item: any) => item.tireCode === '0x19');
  const p3Limits = limits.find((item: any) => item.tireCode === '0x27');
  const p4Limits = limits.find((item: any) => item.tireCode === '0x29');
  const p5Limits = limits.find((item: any) => item.tireCode === '0x37');
  const p6Limits = limits.find((item: any) => item.tireCode === '0x39');

  const isP1WarninigMax = isOverMax('0x17', p1, limitsData);

  const isP2WarninigMax = isOverMax('0x19', p2, limitsData);

  const isP3WarninigMax = isOverMax('0x27', p3, limitsData);

  const isP4WarninigMax = isOverMax('0x29', p4, limitsData);

  const isP5WarninigMax = isOverMax('0x37', p5, limitsData);

  const isP6WarninigMax = isOverMax('0x39', p6, limitsData);

  const data: any = {
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    p1Limits,
    p2Limits,
    p3Limits,
    p4Limits,
    p5Limits,
    p6Limits,
    isP1WarninigMax,
    isP2WarninigMax,
    isP3WarninigMax,
    isP4WarninigMax,
    isP5WarninigMax,
    isP6WarninigMax,
  };

  const slowLeaks = tireData.tireCurrentStatus?.slowLeaks ?? [];

  const fastLeaks = tireData.tireCurrentStatus?.fastLeaks ?? [];

  return (
    <>
      <Grid item lg={3}>
        {[1, 4, 3].map((item, index) => {
          return (
            <TirePressure
              key={index}
              enableDarkTheme={enableDarkTheme}
              value={data[`p${item}`]}
              limit={data[`p${item}Limits`]}
              tireCode={tireNumberSystem[item - 1]}
              unitSystem={unitSystem}
              tireCodes={tireCodes}
              metricType={metricType}
              slowLeaks={slowLeaks}
              fastLeaks={fastLeaks}
            />
          );
        })}
      </Grid>
      <Grid item lg={6}>
        <Box
          sx={{
            textAlign: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <GraderSixWheels name="tireVehicleSchematicIcon" values={data} />
        </Box>
      </Grid>
      <Grid item lg={3}>
        {[2, 5, 6].map((item, index) => {
          return (
            <TirePressure
              key={index}
              enableDarkTheme={enableDarkTheme}
              value={data[`p${item}`]}
              limit={data[`p${item}Limits`]}
              tireCode={tireNumberSystem[item - 1]}
              unitSystem={unitSystem}
              tireCodes={tireCodes}
              metricType={metricType}
              slowLeaks={slowLeaks}
              fastLeaks={fastLeaks}
            />
          );
        })}
      </Grid>
    </>
  );
};
