import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import useDateRange from 'hooks/dateRange';
import HeaderMenu from 'views/Production/HeaderMenu/index';
import Loader from './Loader';
import AllFleet from './AllFleet';
import Hauler from './Hauler';
import UtilizationHandler from 'handlers/utilization.handler';
import { exportOverviewPagesToPdf } from 'utils/exports/pdf-reports';
import { setIsPrinting } from 'store/exportData.slice';

const Utilization = () => {
  const location = useLocation();
  const typeReceived = location.state?.type;
  const utilizationHandler = new UtilizationHandler();
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const customerCode = useAppSelector(
    (state) => state.persistedReducer.customer.code
  );
  const { assetOverView: assetOverview } = useAppSelector(
    (state) => state.assetReducer
  );
  const { data, loadingStates } = useAppSelector(
    (state) => state.utilizationReducer
  );
  const { isPrinting } = useAppSelector((state) => state.exportDataReducer);
  const enableDarkTheme = isDarkTheme(theme);
  const dispatch = useAppDispatch();

  const defaultValue = typeReceived
    ? typeReceived === 'Haul Truck'
      ? 1
      : typeReceived === 'Loader'
      ? 2
      : 0
    : 0;
  const [value, setValue] = useState(defaultValue);
  const [isSelected, setIsSelected] = useState('1D');
  const [initRun, setInitRun] = useState<boolean>(true);
  const [isCustomDateChanged, setIsCustomDateChanged] = useState(false);
  const [aggregation, setAggregation] = useState<string>('Daily');
  const [shiftWiseAnalysis, setShiftWiseAnalysis] = useState<boolean>(false);
  const [shiftNumber, setShiftNumber] = useState<number>(1);
  const [utilizationType, setUtilizationType] = useState('shiftHours');

  const { startDate, endDate, setStartDate, setEndDate, yesterday } =
    useDateRange();

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    const dateDifference = dayjs(endDate).diff(dayjs(startDate), 'day');
    // setAggregation(dateDifference < 31 ? 'Daily' : 'Monthly');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };
  const handleExportClick = async (val: boolean) => {
    dispatch(setIsPrinting(true));
    await exportOverviewPagesToPdf(
      ['utilization-overview'],
      'utilization-overview',
      'Utilization Overview',
      false,
      'portrait'
    );
    dispatch(setIsPrinting(false));
  };

  const fetchData = async (range: string, startDate: Date, endDate: Date) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    const shouldSendDate = isSelected === 'Custom';
    const isNotLoadingData = Object.entries(loadingStates)
      .filter(
        ([key]) => key !== 'isLoadingTarget' && key !== 'isLoadingSuggestions'
      )
      .every(([, value]) => !value);
    if (isNotLoadingData) {
      await utilizationHandler.get(
        range,
        formattedStartDate,
        formattedEndDate,
        shouldSendDate,
        false,
        value === 2 ? 'loader' : value === 1 ? 'loader' : 'all',
        ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? aggregation.toLowerCase()
          : 'daily',
        shiftWiseAnalysis,
        shiftNumber,
        utilizationType
      );
      setInitRun(false);
    }
  };

  useEffect(() => {
    if (isSelected === 'Custom') {
      if (!isCustomDateChanged) {
        setIsCustomDateChanged(true);
      }
      if (isCustomDateChanged && startDate && endDate) {
        void fetchData(isSelected, startDate, endDate);
      }
    } else if (startDate && endDate) {
      setIsCustomDateChanged(false);
      void fetchData(isSelected, startDate, endDate);
    }
  }, [
    isSelected,
    startDate,
    endDate,
    aggregation,
    shiftWiseAnalysis,
    shiftNumber,
    utilizationType,
  ]);

  return (
    <>
      <HeaderMenu
        value={value}
        handleChange={handleChange}
        disableLoader={false}
        handleExport={handleExportClick}
      />
      <div id="utilization-overview">
        <Loader
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={yesterday}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          loadingStates={loadingStates}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
        />
        <Hauler
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={yesterday}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          loadingStates={loadingStates}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
        />
        <AllFleet
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={yesterday}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          loadingStates={loadingStates}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
        />
      </div>
    </>
  );
};

export default Utilization;
