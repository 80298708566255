import React from 'react';

const SixWheels = ({ values }: { values: any; name: string }) => {
  const {
    isP1WarninigMax,
    isP2WarninigMax,
    isP3WarninigMax,
    isP4WarninigMax,
    isP5WarninigMax,
    isP6WarninigMax,
  } = values;
  return (
    <svg
      width="90%"
      height="655px"
      viewBox="0 0 482 678"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="101.2" y="532" width="279.6" height="12" fill="#DFDFDF" />
      <rect
        x="52"
        y="458"
        width="64"
        height="160"
        rx="14.4"
        fill={isP3WarninigMax ? '#FF3004' : '#039161'}
      />
      <rect
        x="381"
        y="458.14"
        width="64"
        height="160"
        rx="14.4"
        fill={isP6WarninigMax ? '#FF3004' : '#039161'}
      />
      <rect x="101.2" y="86" width="279.6" height="12" fill="#DFDFDF" />
      <rect
        x="246"
        y="89"
        width="443"
        height="12"
        transform="rotate(90 246 89)"
        fill="#DFDFDF"
      />
      <rect
        x="127"
        y="458"
        width="64"
        height="160"
        rx="14.4"
        fill={isP4WarninigMax ? '#FF3004' : '#039161'}
      />
      <rect
        x="306"
        y="458.14"
        width="64"
        height="160"
        rx="14.4"
        fill={isP5WarninigMax ? '#FF3004' : '#039161'}
      />
      <rect
        x="52"
        y="12"
        width="64"
        height="160"
        rx="14.4"
        fill={isP1WarninigMax ? '#FF3004' : '#039161'}
      />
      <rect
        x="380.8"
        y="12"
        width="64"
        height="160"
        rx="14.4"
        fill={isP2WarninigMax ? '#FF3004' : '#039161'}
      />
      <g opacity="0.25" clipPath="url(#clip0_7566_5414)">
        <path
          d="M89.286 20.1257L106.391 3.77591L119.682 10.4593L92.7976 26.0161L89.286 20.1257Z"
          fill="url(#paint0_linear_7566_5414)"
        />
        <path
          d="M397.514 20.1257L380.371 3.77593L367.118 10.4593L393.587 25.3742L397.514 20.1257Z"
          fill="url(#paint1_linear_7566_5414)"
        />
        <path
          d="M132.445 1.81515e-05L354.355 3.75515e-05C362.058 3.82249e-05 404.802 22.5045 404.802 36.2866L404.802 61.2455L82.0361 61.2455L82.0361 36.2866C82.0361 22.5045 124.742 1.7478e-05 132.482 1.81548e-05L132.445 1.81515e-05Z"
          fill="url(#paint2_linear_7566_5414)"
        />
        <path
          d="M132.445 3.77596L354.355 3.77598C362.058 3.77598 404.802 26.2805 404.802 40.0626L404.802 65.0214L82.0361 65.0214L82.0361 40.0626C82.0361 26.2804 124.742 3.77596 132.482 3.77596L132.445 3.77596Z"
          fill="url(#paint3_linear_7566_5414)"
        />
        <path
          d="M161.859 20.7298L324.903 20.7298L341.479 31.3024L145.32 31.3024L161.859 20.7298Z"
          fill="url(#paint4_linear_7566_5414)"
        />
        <path
          d="M81.9985 61.5854L404.764 61.5854L404.764 49.1248L81.9985 49.1248L81.9985 61.5854Z"
          fill="url(#paint5_linear_7566_5414)"
        />
        <path
          d="M323.431 17.8224C334.154 17.8224 340.158 21.976 345.784 29.2257L347.332 31.227L354.205 69.175L132.52 69.175L139.392 32.322L140.978 30.3207C146.642 23.1087 152.608 17.8602 163.332 17.8602L323.431 17.8602L323.431 17.8224Z"
          fill="#B06F00"
        />
        <path
          d="M163.369 25.3742L323.431 25.3742C331.776 25.3742 341.971 29.7544 346.2 35.1539L346.577 61.6231L140.223 61.6231L140.601 35.1539C144.83 29.7543 155.025 25.3742 163.369 25.3742Z"
          fill="url(#paint6_linear_7566_5414)"
        />
        <path
          d="M81.6962 62.0006L404.575 62.0007L404.575 41.0821L81.6962 41.0821L81.6962 62.0006Z"
          fill="url(#paint7_linear_7566_5414)"
        />
        <path
          d="M81.3183 677.74L405.443 677.74L405.443 202.125L81.3184 202.125L81.3183 677.74Z"
          fill="url(#paint8_linear_7566_5414)"
        />
        <path
          d="M65.5732 170.52L421.189 170.52L405.444 202.125L81.3566 202.125L65.5732 170.52Z"
          fill="url(#paint9_linear_7566_5414)"
        />
        <path
          d="M65.5727 170.521L421.189 170.521L421.189 85.6379L65.5727 85.6379L65.5727 170.521Z"
          fill="url(#paint10_linear_7566_5414)"
        />
        <path
          d="M92.6464 212.094L92.6464 664.298L394.116 664.298L394.116 212.094L386.564 212.094L386.564 186.606L394.191 161.912L93.7037 161.912L101.331 186.606L101.331 212.094L92.6464 212.094Z"
          fill="#D48600"
        />
        <path
          d="M84.9054 58.3381L401.894 58.3381L421.189 85.6757L65.5727 85.6757L84.9054 58.3381Z"
          fill="url(#paint11_linear_7566_5414)"
        />
        <path
          d="M60.3997 96.1727L65.5728 85.6757L65.5728 170.521L60.3997 159.193L60.3997 96.1727Z"
          fill="url(#paint12_linear_7566_5414)"
        />
        <path
          d="M426.4 96.1727L421.189 85.6757L421.189 170.521L426.4 159.193L426.4 96.1727Z"
          fill="url(#paint13_linear_7566_5414)"
        />
        <g>
          <path
            d="M121.306 161.912L92.9485 161.912L100.576 186.606L100.576 212.094L93.024 212.094L93.024 664.298C93.024 664.298 100.916 631.183 105.938 617.552C140.714 523.569 125.875 223.27 125.875 223.27L361.265 223.27C361.265 223.27 346.426 523.569 381.202 617.552C386.224 631.183 394.116 664.298 394.116 664.298L394.116 212.094L386.564 212.094L386.564 186.606L394.191 161.912L365.834 161.912L365.608 163.082L121.494 163.082L121.268 161.912L121.306 161.912Z"
            fill="#D6D6D6"
          />
        </g>
        <path
          d="M386.564 212.094L394.192 198.387L408.2 170.521L408.2 161.912L394.192 161.912L386.564 186.606L386.564 212.094Z"
          fill="url(#paint14_linear_7566_5414)"
        />
        <path
          d="M101.331 212.094L93.7037 198.387L79.7328 170.521L79.7328 161.912L93.7037 161.912L101.331 186.606L101.331 212.094Z"
          fill="url(#paint15_linear_7566_5414)"
        />
        <path
          d="M386.564 192.043L386.564 186.606L394.191 161.912L365.834 161.912L365.608 163.082L121.532 163.082L121.306 161.912L92.9485 161.912L100.576 186.606L100.576 192.043L386.564 192.043Z"
          fill="url(#paint16_linear_7566_5414)"
        />
        <path
          d="M79.7329 161.647L75.9192 150.395L68.9338 127.55L68.9338 120.452L75.9192 120.452L79.7329 140.728L79.7329 161.647Z"
          fill="url(#paint17_linear_7566_5414)"
        />
        <path
          d="M75.9194 120.452L67.6124 85.2224L68.934 120.452L75.9194 120.452Z"
          fill="url(#paint18_linear_7566_5414)"
        />
        <path
          d="M408.2 161.647L412.014 150.395L419 127.551L419 120.452L412.014 120.452L408.2 140.729L408.2 161.647Z"
          fill="url(#paint19_linear_7566_5414)"
        />
        <path
          d="M412.014 120.452L420.321 85.2226L418.999 120.452L412.014 120.452Z"
          fill="url(#paint20_linear_7566_5414)"
        />
        <g>
          <path
            d="M150.531 38.4389L336.307 38.439L336.307 35.7958L150.531 35.7958L150.531 38.4389Z"
            fill="#CFCFCF"
          />
        </g>
      </g>
      <line x1="158.5" y1="313" x2="158.5" y2="466" stroke="#CCCCCC" />
      <line x1="159" y1="313.5" x2="-4.37114e-08" y2="313.5" stroke="#CCCCCC" />
      <circle
        cx="159"
        cy="469"
        r="20"
        transform="rotate(-180 159 469)"
        fill="#3666EC"
        fillOpacity="0.6"
      />
      <g filter="url(#filter0_d_7566_5414)">
        <circle
          cx="158.6"
          cy="468.6"
          r="5.2"
          transform="rotate(90 158.6 468.6)"
          fill="#3666EC"
        />
      </g>
      <circle
        cx="158.6"
        cy="468.6"
        r="7.2"
        transform="rotate(90 158.6 468.6)"
        stroke="white"
        strokeWidth="0.8"
      />
      <line
        y1="-0.5"
        x2="153"
        y2="-0.5"
        transform="matrix(-4.37114e-08 1 1 4.37114e-08 339 313)"
        stroke="#CCCCCC"
      />
      <line x1="338" y1="312.5" x2="482" y2="312.5" stroke="#CCCCCC" />
      <circle
        cx="339"
        cy="466"
        r="20"
        transform="rotate(-180 339 466)"
        fill="#3666EC"
        fillOpacity="0.6"
      />
      <g filter="url(#filter1_d_7566_5414)">
        <circle
          cx="338.6"
          cy="465.6"
          r="5.2"
          transform="rotate(90 338.6 465.6)"
          fill="#3666EC"
        />
      </g>
      <circle
        cx="338.6"
        cy="465.6"
        r="7.2"
        transform="rotate(90 338.6 465.6)"
        stroke="white"
        strokeWidth="0.8"
      />
      {/** Bottom Left outside Tire */}
      <line x1="-100" y1="593" x2="72" y2="592.5" stroke="#CCCCCC" />
      <circle
        cx="464.6"
        cy="725.6"
        r="7.2"
        transform="rotate(90 338.6 465.6)"
        stroke="white"
        strokeWidth="0.8"
        fill="#3666EC"
      />

      {/** Bottom Right outside Tire */}
      <line x1="420" y1="593" x2="492" y2="592.5" stroke="#CCCCCC" />
      <circle
        cx="464.6"
        cy="385.6"
        r="7.2"
        transform="rotate(90 338.6 465.6)"
        stroke="white"
        strokeWidth="0.8"
        fill="#3666EC"
      />

      {/** Top Right outside Tire */}
      <line x1="420" y1="43" x2="492" y2="43.5" stroke="#CCCCCC" />
      <circle
        cx="-84.6"
        cy="385.6"
        r="7.2"
        transform="rotate(90 338.6 465.6)"
        stroke="white"
        strokeWidth="0.8"
        fill="#3666EC"
      />

      {/** Top Left outside Tire */}
      <line x1="0" y1="43" x2="72" y2="43.5" stroke="#CCCCCC" />
      <circle
        cx="-84.6"
        cy="730.6"
        r="7.2"
        transform="rotate(90 338.6 465.6)"
        stroke="white"
        strokeWidth="0.8"
        fill="#3666EC"
      />
      <defs>
        <filter
          id="filter0_d_7566_5414"
          x="146.6"
          y="456.6"
          width="23.9999"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7566_5414"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7566_5414"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_7566_5414"
          x="326.6"
          y="453.6"
          width="23.9999"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7566_5414"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7566_5414"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7566_5414"
          x1="111.645"
          y1="-2.45227"
          x2="85.4857"
          y2="14.2519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7566_5414"
          x1="366.416"
          y1="11.6208"
          x2="392.385"
          y2="28.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC920A" />
          <stop offset="1" stopColor="#FCC400" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7566_5414"
          x1="404.764"
          y1="30.6228"
          x2="81.9983"
          y2="30.6227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB9200" />
          <stop offset="1" stopColor="#B04F00" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7566_5414"
          x1="404.764"
          y1="34.3987"
          x2="81.9983"
          y2="34.3987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC920A" />
          <stop offset="1" stopColor="#FCC400" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7566_5414"
          x1="341.479"
          y1="26.0161"
          x2="145.32"
          y2="26.0161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#757575" />
          <stop offset="0.21" stopColor="#6B6B6B" />
          <stop offset="0.31" stopColor="#5A5A5A" />
          <stop offset="0.45" stopColor="#484848" />
          <stop offset="0.56" stopColor="#424242" />
          <stop offset="0.9" stopColor="#3A3A3A" />
          <stop offset="1" stopColor="#4D4D4D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7566_5414"
          x1="426.4"
          y1="55.3551"
          x2="422.624"
          y2="55.3551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB9200" />
          <stop offset="1" stopColor="#B04F00" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7566_5414"
          x1="243.381"
          y1="61.4721"
          x2="243.381"
          y2="25.4875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B04F00" />
          <stop offset="1" stopColor="#DB9200" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7566_5414"
          x1="243.155"
          y1="39.7227"
          x2="243.155"
          y2="62.567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#B8B8B8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_7566_5414"
          x1="279.705"
          y1="439.933"
          x2="74.4084"
          y2="439.933"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_7566_5414"
          x1="283.255"
          y1="186.304"
          x2="57.9836"
          y2="186.304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_7566_5414"
          x1="283.255"
          y1="128.117"
          x2="57.9831"
          y2="128.117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_7566_5414"
          x1="283.255"
          y1="72.0069"
          x2="57.9831"
          y2="72.0069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_7566_5414"
          x1="84.83"
          y1="144.693"
          x2="37.1023"
          y2="103.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_7566_5414"
          x1="401.97"
          y1="144.693"
          x2="449.697"
          y2="103.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_7566_5414"
          x1="408.2"
          y1="186.984"
          x2="386.564"
          y2="186.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D48600" />
          <stop offset="0.32" stopColor="#D28200" />
          <stop offset="0.56" stopColor="#CB7900" />
          <stop offset="0.78" stopColor="#C16900" />
          <stop offset="0.97" stopColor="#B25200" />
          <stop offset="1" stopColor="#B04F00" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_7566_5414"
          x1="79.7327"
          y1="677.74"
          x2="101.369"
          y2="677.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D48600" />
          <stop offset="0.32" stopColor="#D28200" />
          <stop offset="0.56" stopColor="#CB7900" />
          <stop offset="0.78" stopColor="#C16900" />
          <stop offset="0.97" stopColor="#B25200" />
          <stop offset="1" stopColor="#B04F00" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_7566_5414"
          x1="243.57"
          y1="192.421"
          x2="243.57"
          y2="161.949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D48600" />
          <stop offset="1" stopColor="#D79936" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_7566_5414"
          x1="68.9338"
          y1="141.068"
          x2="79.7329"
          y2="141.068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D48600" />
          <stop offset="0.32" stopColor="#D28200" />
          <stop offset="0.56" stopColor="#CB7900" />
          <stop offset="0.78" stopColor="#C16900" />
          <stop offset="0.97" stopColor="#B25200" />
          <stop offset="1" stopColor="#B04F00" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_7566_5414"
          x1="75.9194"
          y1="102.856"
          x2="67.6124"
          y2="102.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_7566_5414"
          x1="419"
          y1="141.069"
          x2="408.2"
          y2="141.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D48600" />
          <stop offset="0.32" stopColor="#D28200" />
          <stop offset="0.56" stopColor="#CB7900" />
          <stop offset="0.78" stopColor="#C16900" />
          <stop offset="0.97" stopColor="#B25200" />
          <stop offset="1" stopColor="#B04F00" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_7566_5414"
          x1="411.976"
          y1="102.856"
          x2="420.321"
          y2="102.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC400" />
          <stop offset="1" stopColor="#EC920A" />
        </linearGradient>
        <clipPath id="clip0_7566_5414">
          <rect
            width="366"
            height="677.74"
            fill="white"
            transform="translate(426.4 677.74) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SixWheels;
