import { createSlice } from '@reduxjs/toolkit';

export interface tireProfileState {
  limits: any[];
  tireData: Record<string, unknown>;
  isLoadingFLeet: boolean;
  isLoadingVehicle: boolean;
  fleetData: Record<string, unknown>;
}

const initialState: tireProfileState = {
  limits: [],
  tireData: {},
  isLoadingFLeet: false,
  isLoadingVehicle: false,
  fleetData: {},
};

export const tireProfileSlice = createSlice({
  name: 'tireProfile',
  initialState,
  reducers: {
    setLimits(state, { payload }) {
      state.limits = payload;
    },
    setDeviceTireData(state, { payload }) {
      state.tireData[payload.device] = payload.data;
    },
    setIsLoadingFleet(state, { payload }) {
      state.isLoadingFLeet = payload;
    },
    setIsLoadingVehicle(state, { payload }) {
      state.isLoadingVehicle = payload;
    },
    setFleetData(state, { payload }) {
      state.fleetData = payload;
    },
  },
});

export const {
  setLimits,
  setDeviceTireData,
  setIsLoadingFleet,
  setIsLoadingVehicle,
  setFleetData,
} = tireProfileSlice.actions;

export default tireProfileSlice.reducer;
