import { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ThemePalette } from 'mui.theme';
import { useAppSelector } from 'store/hook';
import { ContentSelector } from './ContentSelector';
import ScrollableChipBar from 'components/ScrollableChipBar/ScrollableChipBar';
import { Chart } from './Chart/Individual/Chart';
import { GroupedChart } from './Chart/Grouped/Chart';
import {
  getKey,
  getTagsWithoutData,
  getExcelExportData,
  getRangeDates,
} from './utils';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { selectExportFormat } from 'store/exportData.slice';
import { exportToPdf } from 'utils/exports/pdf';
import { exportIndividualToPdf } from 'utils/exports/vehicleStatusIndividualPdf';
import { chartToExcelForVehicleStatus } from 'utils/exports/excel';

export const Content = ({
  enableDarkTheme,
  chartType,
  setChartType,
  tags,
  selectedTags,
  removeTag,
  data,
  activeButton,
  selectedAsset,
  selectedOperation,
  startDate,
  endDate,
  unitSystem,
  setIsSelectTagModalOpen,
  tagGrainData,
  dataType,
  isLoadingData,
  exportTo,
  handleExport,
  grainStartDate,
  grainEndDate,
}: {
  enableDarkTheme: boolean;
  chartType: string;
  setChartType: any;
  tags: any[];
  selectedTags: string[];
  removeTag: any;
  setSelectedTags: any;
  data: any[];
  activeButton: string;
  selectedAsset: any;
  selectedOperation: any;
  startDate: any;
  endDate: any;
  unitSystem: string;
  setIsSelectTagModalOpen: any;
  tagGrainData: any;
  dataType: string;
  isLoadingData: boolean;
  exportTo: any;
  handleExport: any;
  grainStartDate: any;
  grainEndDate: any;
}) => {
  const exportFormat = useAppSelector(selectExportFormat);
  const tagAliases: string[] = selectedTags
    .map((st) => {
      const mappedTag = tags.find((item) => item.tagName === st);
      if (mappedTag) {
        return mappedTag.tagAlias;
      }
      return false;
    })
    .filter((item) => item);

  function handleClickTag(tagAlias: string) {
    const clickedTag = tags.find((item) => item.tagAlias === tagAlias);
    if (clickedTag) {
      removeTag(clickedTag.tagName);
    }
  }

  const keyToUse = getKey(activeButton);

  let tagsWithoutData: string[] = [];

  if (!isLoadingData) {
    tagsWithoutData = getTagsWithoutData(
      data,
      selectedTags,
      keyToUse,
      activeButton,
      startDate,
      endDate,
      selectedAsset,
      unitSystem,
      dataType,
      tagGrainData,
      isLoadingData
    );
  }

  const noDataTagAliases: string[] = tagsWithoutData
    .map((st) => {
      const mappedTag = tags.find((item) => item.tagName === st);
      if (mappedTag) {
        return mappedTag.tagAlias;
      }
      return false;
    })
    .filter((item) => item);

  const sortedTagAliases = tagAliases.sort((a, b) => {
    const aInArr2 = noDataTagAliases.includes(a);
    const bInArr2 = noDataTagAliases.includes(b);
    if (aInArr2 && !bInArr2) return 1;
    if (bInArr2 && !aInArr2) return -1;
    return 0;
  });

  const sortedSelectedTags = selectedTags
    .map((tag) => {
      const tagDetail = tags.find((item) => item.tagName === tag);
      return tagDetail
        ? { id: tagDetail.tagName, alias: tagDetail.tagAlias }
        : null;
    })
    .filter((tg): tg is { id: string; alias: string } => tg !== null)
    .sort((a, b) => {
      const aInArr2 = noDataTagAliases.includes(a.alias);
      const bInArr2 = noDataTagAliases.includes(b.alias);
      if (aInArr2 && !bInArr2) return 1;
      if (bInArr2 && !aInArr2) return -1;
      return 0;
    })
    .map((item) => item.id);

  const fileNameWithDateTime = `Vehicle Status for ${
    selectedAsset.bumperNumber
  }-${formatDate(new Date())}`;

  const { startDate: sDate, endDate: eDate } = getRangeDates(
    activeButton,
    startDate,
    endDate
  );

  const title = `Vehicle Status for ${selectedAsset.bumperNumber} ${
    activeButton === 'All'
      ? '(All records)'
      : `between ${new Date(sDate).toDateString()} and ${new Date(eDate)}.`
  }`;

  useEffect(() => {
    const fetchData = async () => {
      if (exportTo && exportFormat === 'pdf') {
        if (chartType === 'Individual') {
          await exportIndividualToPdf(
            sortedSelectedTags,
            fileNameWithDateTime,
            title,
            enableDarkTheme,
            'landscape'
          );
        } else {
          await exportToPdf(
            'vehicle-status-page',
            fileNameWithDateTime,
            title,
            enableDarkTheme,
            'landscape'
          );
        }

        handleExport(false);
      }
      if (exportTo && exportFormat === 'excel') {
        if (selectedTags.length > 0) {
          const excelData = getExcelExportData(
            selectedTags,
            data,
            keyToUse,
            activeButton,
            startDate,
            endDate,
            selectedAsset,
            unitSystem,
            dataType,
            tagGrainData
          );
          chartToExcelForVehicleStatus(
            excelData,
            fileNameWithDateTime,
            title,
            unitSystem
          );
        }
        handleExport(false);
      }
    };

    void fetchData();
  }, [exportTo, handleExport, exportFormat]);

  return (
    <Box
      sx={{
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        borderRadius: '5px',
        marginTop: '10px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        padding: '10px',
      }}
    >
      <ContentSelector
        enableDarkTheme={enableDarkTheme}
        chartType={chartType}
        setChartType={setChartType}
        tags={tags}
        selectedTags={selectedTags}
        setIsSelectTagModalOpen={setIsSelectTagModalOpen}
        dataType={dataType}
      />

      <ScrollableChipBar
        items={sortedTagAliases}
        handleClick={handleClickTag}
        enableDarkTheme={enableDarkTheme}
        noDataItems={noDataTagAliases}
      />
      {isLoadingData ? (
        <MultiColorCircularLoader height="300px" />
      ) : (
        <Box
          sx={{
            marginTop: '10px',
            padding: '5px',
          }}
          id="vehicle-status-page"
        >
          {chartType === 'Individual' ? (
            <Grid container>
              {' '}
              {sortedSelectedTags.map((tag, index) => {
                const tagDetail = tags.find((item) => item.tagName === tag);
                const tagData = data.find((item) => item.tagName === tag);
                if (tagDetail && tagData) {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      id={tag}
                    >
                      <Box
                        sx={{
                          border: '1px solid #263C57',
                          borderRadius: '4px',
                          padding: '10px',
                          margin: '5px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              paddingTop: '5px',
                              paddingBottom: '20px',
                              paddingLeft: '10px',
                            }}
                          >
                            {tagDetail?.tagAlias}{' '}
                          </Typography>
                          <Typography
                            sx={{
                              paddingTop: '5px',
                              paddingBottom: '20px',
                              paddingRight: '5px',
                            }}
                          >
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickTag(tagDetail.tagAlias);
                              }}
                              style={{
                                cursor: 'pointer',
                                fontSize: '15px',
                              }}
                            />
                          </Typography>
                        </Box>

                        <Chart
                          enableDarkTheme={enableDarkTheme}
                          data={tagData}
                          keyToUse={keyToUse}
                          activeButton={activeButton}
                          selectedAsset={selectedAsset}
                          selectedOperation={selectedOperation}
                          startDate={startDate}
                          endDate={endDate}
                          unitSystem={unitSystem}
                          tagGrainData={tagGrainData}
                          dataType={dataType}
                          grainStartDate={grainStartDate}
                          grainEndDate={grainEndDate}
                        />
                      </Box>
                    </Grid>
                  );
                }
                return true;
              })}
            </Grid>
          ) : (
            <Box>
              <GroupedChart
                enableDarkTheme={enableDarkTheme}
                data={data}
                keyToUse={keyToUse}
                activeButton={activeButton}
                selectedAsset={selectedAsset}
                selectedOperation={selectedOperation}
                startDate={startDate}
                endDate={endDate}
                unitSystem={unitSystem}
                tagGrainData={tagGrainData}
                dataType={dataType}
                selectedTags={selectedTags}
                exportTo={exportTo}
                handleExport={handleExport}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
