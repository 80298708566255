import { Box, TextField, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { FlexBox } from 'components/Containers/FlexBox';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import ExternalUserHandler from 'handlers/external.user.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { type ICreateExternalUserPayload } from 'types/payloads/user.payload.types';
import { USER_PERMISSION_LEVEL_OPTIONS } from 'utils/enums';
import { createOrUpdateExternalUserRequestBodySchema } from 'utils/schemas/user.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { ThemePalette } from 'mui.theme';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
}

export const CreateExternalUserModal = ({
  title,
  open,
  handleAbort,
  enableDarkTheme = false,
}: Props) => {
  const userHandler = new ExternalUserHandler();

  const [isValidForm, setFormValidity] = useState(false);
  const [externalUser, setExternalUser] = useState<ICreateExternalUserPayload>({
    firstName: '',
    lastName: '',
    email: '',
    customerAccessLevel: 'external-user',
    customerId: '',
    phoneNumber: '',
  });

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        externalUser,
        createOrUpdateExternalUserRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [externalUser]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setExternalUser({
      ...externalUser,
      [name]: value,
    });
  };

  const handlePhoneChange = (value: string) => {
    setExternalUser({
      ...externalUser,
      phoneNumber: value,
    });
  };

  const handleSuccess = async () => {
    const newUserDetails = {
      ...externalUser,
      role: externalUser.customerAccessLevel,
    };
    await userHandler.createExternalUser(newUserDetails);

    handleAbort();
  };

  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'ADD NEW USER',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      {/* First Name TextField */}
      <SWTextField
        key="first-name"
        name="firstName"
        label="First Name*"
        TextFieldProps={{
          name: 'firstName',
          value: externalUser.firstName,
          onChange: handleChange,
          InputProps: {
            sx: { ...modalInputFieldStyle, height: '37px' },
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Last Name TextField */}
      <SWTextField
        key="last-name"
        name="lastName"
        label="Last Name*"
        TextFieldProps={{
          name: 'lastName',
          value: externalUser.lastName,
          onChange: handleChange,
          InputProps: {
            sx: { ...modalInputFieldStyle, height: '37px' },
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Email  TextField */}
      <Box key="email">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Email Address*
        </Typography>
        <FlexBox>
          <TextField
            name="email"
            onChange={handleChange}
            value={externalUser.email}
            InputProps={{
              sx: { ...modalInputFieldStyle, height: '37px' },
            }}
          />
        </FlexBox>
      </Box>

      <Box key="permission-level">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Permission Level
        </Typography>
        <Selector
          value={externalUser.customerAccessLevel}
          onChange={(event) => {
            setExternalUser({
              ...externalUser,
              customerAccessLevel:
                event.target.value.toString() as ICreateExternalUserPayload['customerAccessLevel'],
            });
          }}
          selectorOptions={USER_PERMISSION_LEVEL_OPTIONS}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      <Box key="phone-number">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Phone Number
        </Typography>
        <PhoneInput
          country={'ca'}
          value={externalUser.phoneNumber}
          onChange={handlePhoneChange}
          inputStyle={{
            width: '270px',
            height: '20px',
            maxHeight: '10px',
            minWidth: '270px',
            maxWidth: '270px',
          }}
          enableSearch={true}
          enableAreaCodes={true}
          buttonStyle={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
          placeholder=""
          specialLabel=""
        />
      </Box>
    </InteractiveModal>
  );
};
