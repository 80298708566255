import dayjs from 'dayjs';
import BaseAPI from './base.api';
import { getPastDate } from './utils';

export default class ProductionAPI extends BaseAPI {
  getTotalFleet = async (
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    aggregation: string
  ) => {
    let params: any = {
      range,
    };
    if (shouldSendDate) {
      params = {
        startDate,
        endDate,
      };
    }
    const response = await this.API.get('production/total-fleet', {
      params: {
        ...params,
        aggregation,
      },
    });
    return response.data;
  };

  getTotalFleetSummary = async (
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    aggregation: string
  ) => {
    let params: any = {
      range,
    };
    if (shouldSendDate) {
      params = {
        startDate,
        endDate,
      };
    }
    const response = await this.API.get('production/date-range-summary', {
      params: {
        ...params,
        aggregation,
      },
    });
    return response.data;
  };

  getTotalFleetProduction = async (
    assetType: string | 'all' | 'hauler' | 'loader'
  ) => {
    const response = await this.API.get('production/ytd-summary', {
      params: {
        assetType,
      },
    });
    return response.data;
  };

  getFleetPeriodSummary = async () => {
    const response = await this.API.get('production/fleet-period-summary');
    return response.data;
  };

  getSuggestions = async (
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    assetType: string,
    aggregation: string
  ) => {
    let dateToUse, dateToEnd;
    if (shouldSendDate) {
      dateToUse = startDate;
      dateToEnd = endDate;
    } else {
      const rangeDate = getPastDate(range);
      dateToUse = rangeDate;
      const today = dayjs();
      dateToEnd = today.format('YYYY-MM-DD');
    }

    const response = await this.API.get('production/suggestions', {
      params: {
        startDate: dateToUse,
        assetType,
        endDate: dateToEnd,
        range,
        aggregation,
      },
    });
    return response.data;
  };
}
