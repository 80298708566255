import { useState, useEffect } from 'react';
import type { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Grid, Box, Typography } from '@mui/material';
import { AssetDetail } from './AssetDetail';
import { AssetSelector } from './AssetSelector';
import { type Asset } from 'types/asset.types';
import { getAssetMakeModel } from 'views/FleetOverview/components/AssetCardsV2/utils';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
import { ThemePalette } from 'mui.theme';
import type { DriverBehaviorData } from 'types/driver.behavior.types';
import {
  transformDriverBehaviorData,
  filterDriverBehaviorByDeviceId,
  isAllTimeLineDataEmpty,
} from 'utils/driverBehavior';
import { tooltip } from 'views/EngineHours/Components/tooltip';
import { useAppSelector } from 'store/hook';

interface Props {
  enableDarkTheme: boolean;
  assets: Asset[];
  selectedAsset: string;
  timelineDate: string;
  setSelectedAsset: any;
  latestTagData: any;
  hierarchyInfo: any;
  data: DriverBehaviorData;
}

const AssetDisplay = ({
  enableDarkTheme,
  hierarchyInfo,
  latestTagData,
  assets,
  selectedAsset,
  setSelectedAsset,
  timelineDate,
  data,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const chartOptions: ApexOptions = {
    chart: {
      height: 450,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      custom: (props) =>
        tooltip({
          ...props,
          unitSystem,
        }),
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        borderRadius: 4,
      },
    },

    colors: ['#FEB019', '#FF4560', '#D2448F', '#325C9F', '#777777', '#00E396'],
    xaxis: {
      type: 'datetime',
      position: 'top',
      labels: {
        datetimeUTC: false,
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: 'solid',
      opacity: 0.6,
    },
    legend: {
      position: 'top',
      labels: {
        colors: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      },
    },
  };

  const assetToDisplay =
    assets.find((item) => item.bumperNumber === selectedAsset) ?? assets[0];

  const [assetBumperIds, setAssetBumperIds] = useState<string[]>([]);

  const makeModels = getAssetMakeModel(assets);

  const [selectedMakeModel, setSelectedMakeModel] = useState(
    assetToDisplay
      ? composeUniqueKey([assetToDisplay.make, assetToDisplay.model]).replace(
          '!',
          '-'
        )
      : makeModels[0]
  );

  useEffect(() => {
    const filteredAssets = assets
      .filter(
        (asset) =>
          composeUniqueKey([asset.make, asset.model]) ===
          selectedMakeModel.replace('-', '!')
      )
      .map((item) => {
        return item.bumperNumber;
      });
    setAssetBumperIds(filteredAssets);

    if (!filteredAssets.includes(selectedAsset)) {
      setSelectedAsset(filteredAssets[0]);
    }
  }, [selectedMakeModel]);

  const selectedAssetDeviceId = assets.find(
    (item) => item.bumperNumber === selectedAsset
  )?.bumperNumber;

  let timeLineData: any = [];

  if (selectedAssetDeviceId) {
    const assetData = filterDriverBehaviorByDeviceId(
      data,
      selectedAssetDeviceId
    );
    const startTimelineDateMs = new Date(`${timelineDate} 00:00`).getTime();
    const endTimelineDateMs = new Date(`${timelineDate} 23:59`).getTime();
    timeLineData = transformDriverBehaviorData(assetData)?.map((metricData) => {
      return {
        ...metricData,
        data: metricData?.data?.filter((individualData) => {
          if (individualData?.y?.[0] && individualData?.y?.[1]) {
            // if the start and end time are within the selected time range
            return (
              (individualData?.y?.[0] >= startTimelineDateMs &&
                individualData?.y?.[0] <= endTimelineDateMs) ||
              (individualData?.y?.[1] >= startTimelineDateMs &&
                individualData?.y?.[1] <= endTimelineDateMs) ||
              // if start time is efore the start time and end time is after the end time but inside the selected time range
              (individualData?.y?.[0] < startTimelineDateMs &&
                individualData?.y?.[1] > endTimelineDateMs)
            );
          }
          return false;
        }),
      };
    });
  }

  const sideBarList = [
    {
      title: 'Total number of speeding events',
      value:
        timeLineData.find((item: any) => item.name === 'overspeed')?.data
          .length ?? 0,
      unit: null,
    },
  ];

  return (
    <Box>
      <Grid container>
        <AssetSelector
          enableDarkTheme={enableDarkTheme}
          makeModels={makeModels}
          selectedMakeModel={selectedMakeModel}
          setSelectedMakeModel={setSelectedMakeModel}
          assetBumperIds={assetBumperIds}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
        <AssetDetail
          enableDarkTheme={enableDarkTheme}
          hierarchyInfo={hierarchyInfo}
          selectedAsset={assetToDisplay}
          latestTagData={latestTagData}
        />
      </Grid>

      <Grid container>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Box>
            {sideBarList.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid #2F445E',
                    marginTop: '20px',
                    borderRadius: '4px',
                    padding: '10px 10px 10px 10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    sx={{
                      marginTop: '10px',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <div
            style={{
              padding: '20px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {isAllTimeLineDataEmpty(timeLineData) ? (
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                No data available for the selected time range.
              </Typography>
            ) : (
              <ReactApexChart
                options={chartOptions}
                series={timeLineData}
                type="rangeBar"
                height={450}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetDisplay;
