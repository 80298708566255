import BaseAPI from './base.api';

export default class TireProfileAPI extends BaseAPI {
  getTireProfileLimits = async (): Promise<any> => {
    const response = await this.API.get('tire-profile/limits');
    return response.data;
  };

  setTireProfileLimits = async (payload: any): Promise<any> => {
    const response = await this.API.post('tire-profile/limits', payload);
    return response.data;
  };

  getAssetTireData = async (
    deviceId: string,
    dataType: string,
    assetType: string
  ): Promise<any> => {
    const response = await this.API.get('tire-profile/data', {
      params: {
        deviceId,
        dataType,
        assetType,
      },
    });
    return response.data;
  };

  getAssetTireGrainData = async (
    deviceId: string,
    dataType: string,
    assetType: string,
    startDate: Date,
    endDate: Date
  ): Promise<any> => {
    const response = await this.API.post('tire-profile/data/grain', {
      deviceId,
      dataType,
      assetType,
      startDate,
      endDate,
    });
    return response.data;
  };

  getFleetData = async (): Promise<any> => {
    const response = await this.API.get('tire-profile/fleet');
    return response.data;
  };
}
