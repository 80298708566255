import dayjs from 'dayjs';
import { FormControlLabel } from '@mui/material';
import { type RuleNotifications } from 'types/notification.types';
import StyledSwitchButton from 'components/Buttons/SwitchButton';
import { Notification } from './CellRenderer.styled';
import { StatusWrapper } from 'components/AgGrid/CellRenderer/CellRenderer';
import { formatConditions } from 'views/Settings/Rules/helpers/common';
import { COLORS, NOTIFICATION_LEVEL } from 'utils/enums';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

interface Props {
  value: string;
  data: RuleNotifications;
  handleNameClick: (
    ruleId: string,
    deviceId: string,
    assetVin: string,
    alertStartTs: string,
    isRead: boolean,
    conditions: any
  ) => void;
  selectedNotification: any;
  handleToggleClick: (
    notificationId: string,
    isActive: boolean,
    rule: { ruleName: string },
    asset: { bumperNumber: string }
  ) => void;
}

export const NotificationDate = ({ data: { alertStartTs } }: Props) => {
  return <span>{dayjs(alertStartTs).format('MM/DD/YYYY HH:mm (Z)')}</span>;
};

export const NotificationTagName = ({
  data: {
    notificationId,
    deviceId,
    isRead,
    alertStartTs,
    conditions,
    asset: { assetVin },
    rule: { ruleName },
  },
  handleNameClick,
}: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const { selectedNotification } = useAppSelector(
    (state) => state.ruleNotificationsReducer
  );
  return (
    <Notification
      isRead={isRead}
      onClick={() => {
        if (selectedNotification?.notificationId === notificationId) {
          return null;
        } else {
          handleNameClick(
            notificationId,
            deviceId,
            assetVin,
            alertStartTs,
            isRead,
            conditions
          );
        }
      }}
    >
      <span
        style={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          textDecoration: 'none',
        }}
      >
        {' '}
        {ruleName}
      </span>
    </Notification>
  );
};

export const NotificationSeverity = ({
  data: {
    rule: { notificationLevel },
  },
}: Props) => {
  let notificationLevelString = NOTIFICATION_LEVEL.find(
    (item) => item.id === notificationLevel
  )?.display;
  let textColor = 'black';
  if (notificationLevelString) {
    textColor = getColorBasedOnString(notificationLevelString);
    const words = notificationLevelString.split(' ');
    notificationLevelString = words[0];
  }
  return (
    <span
      style={{
        color: textColor,
      }}
    >
      {notificationLevelString}
    </span>
  );
};

export const Status = ({ data: { isActive } }: Props) => {
  return <StatusWrapper isActive={isActive} />;
};

export const NotificationAsset = ({
  data: {
    asset: { bumperNumber },
  },
}: Props) => {
  return <span>{bumperNumber}</span>;
};

export const LastConnected = ({ data: { alertEndTs } }: Props) => {
  return (
    <span>
      {alertEndTs ? dayjs(alertEndTs).format('MM/DD/YYYY h:mma') : ''}{' '}
      {alertEndTs ? 'EST' : ''}
    </span>
  );
};

export const RulesLogic = ({ data: { conditions } }: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  return (
    <span>{conditions ? formatConditions(conditions, unitSystem) : ''}</span>
  );
};

export const Delete = ({
  data: {
    notificationId,
    isActive,
    rule: { ruleName },
    asset: { bumperNumber },
  },
  handleToggleClick,
}: Props) => {
  return (
    <FormControlLabel
      control={<StyledSwitchButton />}
      label=""
      labelPlacement="start"
      checked={isActive}
      sx={{
        textAlign: 'center',
        marginRight: '10px',
      }}
      onChange={() => {
        handleToggleClick(
          notificationId,
          !isActive,
          { ruleName },
          { bumperNumber }
        );
      }}
    />
  );
};

export const TooltipComponent = ({ value }: { value: string }) => {
  return (
    <div
      className="tooltip"
      style={{
        backgroundColor: '#D3D3D3',
        padding: '10px',
      }}
    >
      {value}
    </div>
  );
};

export const RulesLogicToolTip = ({ value }: { value: any }) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  return (
    <div
      className="tooltip"
      style={{
        backgroundColor: '#D3D3D3',
        padding: '10px',
      }}
    >
      {value ? formatConditions(value, unitSystem) : ''}
    </div>
  );
};

function getColorBasedOnString(str: string) {
  const lowerStr = str.toLowerCase();
  if (lowerStr.includes('low')) {
    return COLORS.grey;
  } else if (lowerStr.includes('medium')) {
    return COLORS.yellow;
  } else if (lowerStr.includes('high')) {
    return COLORS.red;
  } else {
    // You can choose a default color if the input string doesn't include any of the expected values
    return 'black';
  }
}
