import React, { useState, useEffect } from 'react';
import { Box, List, Typography, TextField } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { Selector } from 'components/Form/Selectors/Selector';
import { ASSET_TYPES } from 'utils/enums';
import { ThemePalette } from 'mui.theme';
import type { Asset } from 'types/asset.types';
import type { SelectOptions } from 'types/utils.types';
import { isNumber } from '../../util';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
  assets: Asset[];
  addPressureLimit: any;
}

export const PressureLimitModal = ({
  title,
  open,
  handleAbort,
  enableDarkTheme,
  assets,
  addPressureLimit,
}: Props) => {
  const [selectedType, setSelectedType] = useState<string>('');
  const [possbleMake, setPossibleMake] = useState<SelectOptions[]>([]);
  const [selectedMake, setSelectedMake] = useState<string>('');
  const [possbleModel, setPossibleModel] = useState<SelectOptions[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [minPressure, setMinPressure] = useState<string>('');
  const [maxPressure, setMaxPressure] = useState<string>('');

  useEffect(() => {
    if (selectedType !== '') {
      const possibleAssets = assets
        .filter((item) => item.assetType === selectedType)
        .map((item) => {
          return {
            id: item.make,
            display: item.make,
          };
        });
      setPossibleMake(possibleAssets);
      setSelectedMake('');
      setPossibleModel([]);
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedMake !== '') {
      const possibleAssets = assets
        .filter(
          (item) =>
            item.assetType === selectedType && item.make === selectedMake
        )
        .map((item) => {
          return {
            id: item.model,
            display: item.model,
          };
        });
      setPossibleModel(possibleAssets);
    }
    setSelectedModel('');
  }, [selectedMake]);

  const isValidMinPressure =
    isNumber(minPressure) &&
    Number(minPressure) > 0 &&
    Number(minPressure) < Number(maxPressure);
  const isValidMaxPressure =
    isNumber(maxPressure) && Number(maxPressure) <= 850;

  const isFormValid =
    selectedType !== '' &&
    selectedMake !== '' &&
    selectedModel !== '' &&
    isValidMinPressure &&
    isValidMaxPressure;

  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isFormValid,
        onClick: () => {
          addPressureLimit({
            selectedType,
            selectedMake,
            selectedModel,
            minPressure,
            maxPressure,
          });
          handleAbort();
        },
        label: 'Set Pressure Limits',
      }}
      handleAbort={handleAbort}
      title={title}
      enableDarkTheme={enableDarkTheme}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          minWidth: '800px',
        }}
      ></List>
      {/** Asset Type Box */}
      <Box>
        <Typography
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          Asset Type
        </Typography>
        <Selector
          inputProps={{
            sx: {
              minWidth: '355px',
            },
          }}
          fallbackValue="Select asset type"
          value={selectedType}
          onChange={(event) => {
            setSelectedType(event.target.value as string);
          }}
          selectorOptions={ASSET_TYPES}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      {/** Asset Make Box */}
      <Box>
        <Typography
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          Asset Make
        </Typography>
        <Selector
          inputProps={{
            sx: {
              minWidth: '355px',
            },
          }}
          fallbackValue="Select asset make"
          value={selectedMake}
          onChange={(event) => {
            setSelectedMake(event.target.value as string);
          }}
          selectorOptions={possbleMake}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      {/** Asset Model Box */}
      <Box>
        <Typography
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          Asset Model
        </Typography>
        <Selector
          inputProps={{
            sx: {
              minWidth: '355px',
            },
          }}
          fallbackValue="Select asset model"
          value={selectedModel}
          onChange={(event) => {
            setSelectedModel(event.target.value as string);
          }}
          selectorOptions={possbleModel}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      {/** Min Pressure Limit */}
      <Box>
        <Typography
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          Min Pressure{' '}
          <span
            style={{
              fontSize: '8px',
            }}
          >
            {' '}
            (KPA)
          </span>
        </Typography>
        <TextField
          id="outlined-basic"
          size="small"
          sx={{
            minWidth: '400px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#38465F', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#38465F', // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#38465F', // Focused border color
              },
            },
            ...(enableDarkTheme && {
              input: {
                color: ThemePalette.typography.wheat,
              },
            }),
          }}
          placeholder="0"
          onChange={(event) => {
            const eventTargetValue = event.target.value;
            setMinPressure(eventTargetValue);
          }}
        />
      </Box>

      {/** Max Pressure Limit */}
      <Box>
        <Typography
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          Max Pressure
          <span
            style={{
              fontSize: '8px',
            }}
          >
            {' '}
            (KPA) (up to 850)
          </span>
        </Typography>
        <TextField
          id="outlined-basic"
          size="small"
          sx={{
            minWidth: '400px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#38465F', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#38465F', // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#38465F', // Focused border color
              },
            },
            ...(enableDarkTheme && {
              input: {
                color: ThemePalette.typography.wheat,
              },
            }),
          }}
          placeholder="0"
          onChange={(event) => {
            const eventTargetValue = event.target.value;
            setMaxPressure(eventTargetValue);
          }}
        />
      </Box>
    </InteractiveModal>
  );
};
