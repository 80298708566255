import { useMemo } from 'react';
import { useAppSelector } from 'store/hook';
import {
  generateShiftData,
  getShiftDetails,
  ShiftDetailsUTC,
} from 'views/Utilization/utils';
import { formatStartEndDate } from '../utils/utils';
import dayjs from 'dayjs';

interface useStartEndDatesProps {
  showShiftWise: boolean;
  startDate: Date;
  endDate: Date;
}

const useStartEndDates = ({
  showShiftWise,
  startDate,
  endDate,
}: useStartEndDatesProps) => {
  const customerCode = useAppSelector((state) => state.authReducer).customer
    .code;
  const shifts = useMemo(() => {
    return ShiftDetailsUTC?.[customerCode?.toLowerCase()];
  }, [customerCode]);

  const startHour = useMemo(() => {
    if (showShiftWise) {
      if (shifts?.length > 0) {
        return ShiftDetailsUTC?.[customerCode?.toLowerCase()]?.[0]?.startHour;
      } else {
        return ShiftDetailsUTC?.others?.[0]?.startHour;
      }
    }
    return 0;
  }, [shifts, showShiftWise, customerCode]);

  const endHour = useMemo(() => {
    if (showShiftWise) {
      if (shifts?.length > 0) {
        return ShiftDetailsUTC?.[customerCode?.toLowerCase()]?.[
          ShiftDetailsUTC?.[customerCode?.toLowerCase()].length - 1
        ]?.endHour;
      } else {
        return ShiftDetailsUTC?.others?.[0]?.endHour;
      }
    }
    return 0;
  }, [shifts, showShiftWise, customerCode]);

  const shiftsWithTime = useMemo(() => {
    const shiftDetails = getShiftDetails(customerCode);
    const formattedDates = formatStartEndDate('Custom', startDate, endDate);
    if (formattedDates?.startApiDate) {
      return generateShiftData(formattedDates?.startApiDate, shiftDetails);
    }
    return [];
  }, [customerCode, startDate, endDate]);

  const startEndDateWithTime = useMemo(() => {
    if (!customerCode) return null;
    const formattedDates = formatStartEndDate('Custom', startDate, endDate);
    if (formattedDates) {
      // if the daily option is selected
      if (!showShiftWise) {
        // set the start and end dates with the selected dates and time as 00:00:00 in client timezone
        const startInMs = new Date(formattedDates.startApiDate).setHours(
          0,
          0,
          0
        );
        // create dayjs object
        const startDateWithTimeInitial = dayjs(new Date(startInMs));

        // convert to UTC and format
        const startDateWithTime = startDateWithTimeInitial
          .utc()
          .format(`YYYY-MM-DD HH:mm:ss`);

        // do the same for end date and also add 1 day
        const endInMs = new Date(formattedDates.endApiDate).setHours(0, 0, 0);
        const endDateWithTimeInitial = dayjs(new Date(endInMs))
          .utc()
          .add(1, 'day');
        const endDateWithTime =
          endDateWithTimeInitial.format(`YYYY-MM-DD HH:mm:ss`);

        const selectedEndDate = dayjs(new Date(formattedDates?.endApiDate));
        // calculate the difference between the selected dates
        const difference = selectedEndDate.diff(startDate, 'day', true);
        return {
          startDateWithTime,
          endDateWithTime,
          difference,
        };
      }
      const startDateWithTime = dayjs(
        new Date(formattedDates.startApiDate)
      ).format(`YYYY-MM-DD ${showShiftWise ? startHour : 0}:00:00`);
      const shiftDetails = getShiftDetails(customerCode);
      const endShiftDetails = shiftDetails[shiftDetails?.length - 1];
      const endDate = dayjs(
        new Date(
          showShiftWise
            ? formattedDates.startApiDate
            : formattedDates.endApiDate
        )
      ).add(showShiftWise ? endShiftDetails?.daysToAdd : 1, 'day');
      const endDateWithTime = endDate.format(
        `YYYY-MM-DD ${showShiftWise ? endShiftDetails?.endTimeHour : 0}:00:00`
      );
      const selectedEndDate = dayjs(new Date(formattedDates?.endApiDate)).add(
        showShiftWise ? endShiftDetails?.daysToAdd : 1,
        'day'
      );
      const difference = selectedEndDate.diff(startDate, 'day', true);
      return {
        startDateWithTime,
        endDateWithTime,
        difference,
      };
    }
    return null;
  }, [startDate, endDate, showShiftWise, customerCode]);

  return {
    startHour,
    endHour,
    ...startEndDateWithTime,
    shiftsWithTime,
  };
};

export default useStartEndDates;
