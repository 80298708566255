import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Collapse, Grid, Typography, Stack } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ItemFilter from './ItemFilter';
import ItemChart from './ItemChart';
import type { DriverBehavior } from 'types/driver.behavior.types';
import { capitalizeFirstWord } from 'utils/helpers/string.manipulation';
import type { Asset } from 'types/asset.types';

const EventListItem = ({
  enableDarkTheme,
  title,
  index,
  handleChange,
  data,
  assets,
}: {
  enableDarkTheme: boolean;
  title: string;
  index: number;
  handleChange: any;
  data: DriverBehavior[];
  assets: Asset[];
}) => {
  const location = useLocation();
  const assetId: string | null = new URLSearchParams(location.search).get(
    'asset'
  );
  const [itemOpen, setOpen] = useState(title === 'overspeed');
  const [selectedAsset, setSelectedAsset] = useState(assetId ?? '');
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const fleetData = data.find((item) => item.device_id === 'FLEET-WIDE') ?? {
    device_id: 'FLEET-WIDE',
    eventPercentage: 0,
    noOfEvents: 0,
    bumper_id: '',
  };

  const assetsData = data
    .filter((item) => item.device_id !== 'FLEET-WIDE')
    .sort((a, b) => b.noOfEvents - a.noOfEvents);

  const assetWithMost = assetsData[0];

  return (
    <Box
      sx={{
        // paddingTop: '10px',
        // paddingBottom: '10px',
        // paddingLeft: '20px',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        minHeight: '40px',
        marginTop: index === 0 ? '5px' : '20px',
        overflow: 'hidden',
        borderRadius: '6px',
        background: enableDarkTheme
          ? ThemePalette.dark.topBar
          : ThemePalette.light.topBar,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '5px',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          padding: '10px 20px',
          '&:hover': {
            // color: ThemePalette.typography.danger,
            background: enableDarkTheme ? '#323143' : '#F3F4F6',
          },
        }}
        onClick={() => {
          handleClick();
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            minHeight={'68px'}
          >
            <Typography
              sx={{
                fontSize: '18px',
                minWidth: '200px',
              }}
            >
              {capitalizeFirstWord(title.replace(/_/g, ' '))} Events
            </Typography>

            {!itemOpen && (
              <>
                {' '}
                <Box>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      minWidth: '260px',
                      color: '#9CA3AF',
                    }}
                  >
                    Total number over {title.replace(/_/g, ' ')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      color: ThemePalette.typography.danger,
                    }}
                  >
                    {fleetData.noOfEvents}
                  </Typography>
                </Box>
                <Box>
                  {assetWithMost && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          minWidth: '260px',
                          color: '#9CA3AF',
                        }}
                      >
                        Truck with most over {title.replace(/_/g, ' ')}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                        }}
                      >
                        {assetWithMost.bumper_id}
                      </Typography>
                    </>
                  )}
                </Box>
              </>
            )}
          </Stack>
        </Box>
        <span
          style={{
            transform: itemOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'all 0.3s ease',
          }}
        >
          <ExpandMoreIcon fontSize="small" />
        </span>
      </Box>
      <Collapse in={itemOpen} timeout="auto" unmountOnExit>
        <Box p={2}>
          {fleetData.noOfEvents === 0 ? (
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
              }}
            >
              No data available for the selected time range.
            </Typography>
          ) : (
            <Grid container spacing={2}>
              <Grid item lg={4}>
                <ItemFilter
                  enableDarkTheme={enableDarkTheme}
                  title={title}
                  selectedAsset={selectedAsset}
                  setSelectedAsset={setSelectedAsset}
                  searchValue={searchValue}
                  handleChange={handleSearchChange}
                  fleetData={fleetData}
                  assetData={assetsData}
                  assets={assets}
                />
              </Grid>
              <Grid item lg={8}>
                <ItemChart
                  enableDarkTheme={enableDarkTheme}
                  title={title}
                  selectedAsset={selectedAsset}
                  handleChange={handleChange}
                  fleetData={fleetData}
                  assetData={assetsData}
                  setSelectedAsset={setSelectedAsset}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default EventListItem;
