import {
  TableCell,
  TableRow,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface Props {
  enableDarkTheme: boolean;
  item: any;
  subItems?: any[];
}

const listItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '3px',
};

const dotStyle = (color: string) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: 8,
});

const arrowStyle = {
  fontSize: '1rem',
  color: 'red',
  marginLeft: 4,
};

const TableItem = ({ enableDarkTheme, item, subItems = [] }: Props) => {
  return (
    <>
      <TableRow
        sx={{
          border: 'none',
        }}
      >
        <TableCell
          sx={{
            border: 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                backgroundColor: item.color,
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
            <Typography
              color="#d1d5db"
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              }}
            >
              {item.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          align="right"
          sx={{
            border: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              color="#d1d5db"
              sx={{
                marginRight: '4px',
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              }}
            >
              {item.time}
            </Typography>
            {item.direction === 'up' ? (
              <NorthEastIcon
                sx={{
                  color: item.name === 'Productive' ? '#029161' : '#EB5757',
                  fontSize: 10,
                }}
              />
            ) : (
              <SouthEastIcon
                sx={{
                  color: item.name === 'Productive' ? '#EB5757' : '#029161',
                  fontSize: 10,
                }}
              />
            )}
          </Box>
        </TableCell>
      </TableRow>
      {/* Child Items */}
      {subItems.length > 0 && (
        <>
          {subItems.map((item, index) => {
            if (item.name === 'Unknown' && item.value === 0) {
              return <></>;
            }
            return (
              <ListItem sx={{ ...listItemStyle, pl: 4 }} key={index}>
                <Box display="flex" alignItems="center">
                  <Box sx={dotStyle(item.color)} />
                  <Typography
                    color="#fff"
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    color="#fff"
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {item.value}
                  </Typography>
                  {item.direction === 'up' ? (
                    <NorthEastIcon
                      sx={{
                        color:
                          item.name === 'Productive' ? '#029161' : '#EB5757',
                        fontSize: 10,
                      }}
                    />
                  ) : (
                    <SouthEastIcon
                      sx={{
                        color:
                          item.name === 'Productive' ? '#EB5757' : '#029161',
                        fontSize: 10,
                      }}
                    />
                  )}
                </Box>
              </ListItem>
            );
          })}
        </>
      )}
    </>
  );
};

export default TableItem;
