import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Grid, Box, Typography } from '@mui/material';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Label,
  ReferenceLine,
  Line,
  ComposedChart,
} from 'recharts';

import { ThemePalette } from 'mui.theme';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { truncateTo2dp } from 'utils/helpers/general';
import { isSumZeroOrEmpty, filterDataByPeriod } from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  isLoading: boolean;
  data: any[];
  setRollingDailyAverage: (value: number) => void;
}

type ChartSource =
  | 'Last 7 Days'
  | 'Last 14 Days'
  | 'Last 4 Weeks'
  | 'Last 6 Months';

const periodMap: Record<ChartSource, string> = {
  'Last 7 Days': '7days',
  'Last 14 Days': '14days',
  'Last 4 Weeks': '1month',
  'Last 6 Months': '6months',
};

const options = [
  'Last 7 Days',
  'Last 14 Days',
  'Last 4 Weeks',
  'Last 6 Months',
];

interface ChartEntry {
  date: string;
  value: number;
  period: string;
}

const mergeChartDataAndMovingAverages = (
  chartData: any[],
  movingAverages: Array<{
    date: string;
    movingAverage: number | null;
    period: string;
  }>,
  selectedChartSource: ChartSource
): Array<{
  date: string;
  value: number;
  movingAverage: number | null;
  period: string;
}> => {
  // logic to set the initial values for moving averages based on the selected window (selectedChartSource)

  if (
    selectedChartSource === 'Last 7 Days' ||
    selectedChartSource === 'Last 14 Days'
  ) {
    if (movingAverages?.length > 0) {
      movingAverages[0].movingAverage =
        (chartData[0]?.total_engine_working_minutes || 0) / 60;
    }
  } else if (selectedChartSource === 'Last 4 Weeks') {
    if (movingAverages?.length >= 2) {
      movingAverages[0].movingAverage =
        (chartData[0]?.total_engine_working_minutes || 0) / 60;
      movingAverages[1].movingAverage =
        chartData[1].total_engine_working_minutes / 60;
    }
  } else if (selectedChartSource === 'Last 6 Months') {
    if (movingAverages?.length >= 5) {
      for (let i = 0; i < 6; i++) {
        movingAverages[i].movingAverage =
          chartData[i].total_engine_working_minutes / 60;
      }
    }
  }

  return chartData.map((entry) => {
    const movingAverageEntry = movingAverages.find(
      (ma) => ma.date === entry.date
    );

    return {
      ...entry,
      movingAverage: movingAverageEntry
        ? Number(Number(movingAverageEntry?.movingAverage).toFixed(2))
        : null,
    };
  });
};

const calculateDailyMovingAverage = (
  data: ChartEntry[],
  windowSize: number
): Array<{ date: string; movingAverage: number | null; period: string }> => {
  // Sort the data by date in ascending order
  const sortedData = [...data].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const movingAverages: Array<{
    date: string;
    movingAverage: number | null;
    period: string;
  }> = [];

  // Iterate over the sorted data and ensure all dates are covered
  for (let i = 0; i < sortedData.length; i++) {
    const windowData = sortedData.slice(Math.max(0, i - windowSize + 1), i + 1);

    const windowSum = windowData.reduce(
      (sum, entry) => sum + Number(entry.value),
      0
    );

    const windowAverage =
      windowData.length === windowSize ? windowSum / windowSize : null;

    movingAverages.push({
      date: sortedData[i].date,
      period: sortedData[i].period,
      movingAverage: windowAverage,
    });
  }

  return movingAverages;
};

const Chart = ({
  enableDarkTheme,
  isLoading,
  data,
  setRollingDailyAverage,
}: Props) => {
  const [selectedChartSource, setSelectedChartSource] =
    useState<ChartSource>('Last 7 Days');

  const [chartData, setChartData] = useState<any[]>([]);

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };

  const getHighestValue = (arr: any) => {
    const highestValue: number = arr.reduce((max: any, obj: any) => {
      const currentValue = Number(obj.value);
      return !isNaN(currentValue) && currentValue > max ? currentValue : max;
    }, -Infinity);

    return highestValue;
  };

  useEffect(() => {
    if (data) {
      const filteredData = filterDataByPeriod(
        data,
        periodMap[selectedChartSource]
      ).map((item) => ({
        ...item,
        value: truncateTo2dp(Number(item.total_engine_working_minutes) / 60),
      }));

      setChartData(filteredData);
    }
  }, [data, selectedChartSource]);

  const average =
    chartData.reduce(
      (sum: number, entry: { value: string }) => sum + Number(entry.value),
      0
    ) / chartData.length;

  // Calculate the moving averages - If selectedChartSource is last 7 or last 14 days then keep window size as 2, if selectedChartSource is last 4 weeks then keep window size as 3, else keep window size as 7
  const movingAverages = calculateDailyMovingAverage(
    chartData,
    selectedChartSource === 'Last 7 Days' ||
      selectedChartSource === 'Last 14 Days'
      ? 2
      : selectedChartSource === 'Last 4 Weeks'
      ? 3
      : 7
  );
  const mergedChartData = mergeChartDataAndMovingAverages(
    chartData,
    movingAverages,
    selectedChartSource
  );

  useEffect(() => {
    const result = calculateDailyMovingAverage(chartData, 2);
    if (result?.length > 0 && Array.isArray(result)) {
      setRollingDailyAverage(result[result.length - 1].movingAverage ?? 0);
    }
  }, [chartData]);

  return (
    <Grid item lg={6} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          Productive Hours
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            Show
          </Typography>

          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={options}
            selected={selectedChartSource}
            setSelected={setSelectedChartSource}
            minWidth="150px"
          />
        </Box>

        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            {isSumZeroOrEmpty(chartData, 'value') ? (
              <Typography
                sx={{
                  fontSize: '11px',
                }}
              >
                No Chart data available
              </Typography>
            ) : (
              <FlexBox
                sx={{
                  maxWidth: 'inherit',
                  height: '230px',
                  paddingTop: '20px',
                  marginRight: '10px',
                  paddingBottom: '10px',
                }}
              >
                {mergedChartData.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                      width={700}
                      height={300}
                      data={mergedChartData}
                      margin={{
                        top: 20,
                        right: 110,
                        left: 5,
                        bottom: 5,
                      }}
                      barSize={30}
                    >
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={'#808080'}
                        vertical={false}
                      />
                      <XAxis
                        dataKey="date"
                        tickFormatter={formatTimestamp}
                        tick={{ fontSize: 10 }}
                      />
                      <YAxis
                        tick={{ fontSize: 10 }}
                        label={{
                          value: `Productive Hours`,
                          angle: 270,
                          position: 'insideLeft',
                          fontSize: 10,
                          textAnchor: 'middle',
                          dy: 50,
                        }}
                        domain={[0, getHighestValue(data)]}
                      />
                      <Label
                        style={{
                          textAnchor: 'middle',
                          fontSize: '11px',
                        }}
                        angle={270}
                        value={'Productive Hours'}
                      />
                      <Tooltip
                        cursor={{
                          fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC',
                        }}
                        wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                        contentStyle={{
                          backgroundColor: enableDarkTheme
                            ? ThemePalette.typography.cornflowerBlue
                            : ThemePalette.typography.wheat,
                        }}
                        itemStyle={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                        }}
                      />
                      <Bar
                        dataKey="value"
                        fill="#4A7BFA"
                        activeBar={<Rectangle fill="pink" stroke="blue" />}
                      />
                      <Line
                        type="monotone"
                        dataKey="movingAverage"
                        stroke={enableDarkTheme ? '#fafafa' : '#172A46'}
                        dot={{ r: 0 }}
                        strokeDasharray={'3 3'}
                      />

                      <ReferenceLine
                        y={average}
                        label={{
                          value: `Avg ${average.toFixed(2)} Hours/Day`,
                          position: 'right',
                          fontSize: '10px',
                        }}
                        stroke="#FF6600"
                        strokeDasharray="3 3"
                        isFront
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.lightGrey
                        : ThemePalette.typography.black,
                    }}
                  >
                    No data available currently
                  </Typography>
                )}
              </FlexBox>
            )}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default Chart;
