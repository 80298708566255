import dayjs from 'dayjs';
import { removeLastItemFromData } from 'utils/helpers/general';
import { chartSelectorMap, getTagValue } from 'utils/helpers/chartData';
import { formatDateToString } from 'utils/helpers/dateTime';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

export function getKey(period: string) {
  let keyToUse = 'hourly';
  switch (period) {
    case '1D':
      keyToUse = 'hourly';
      break;
    case '7D':
      keyToUse = 'hourly';
      break;
    case '14D':
      keyToUse = 'hourly';
      break;
    case '1Y':
      keyToUse = 'daily';
      break;
    default:
      keyToUse = 'daily';
  }
  return keyToUse;
}

export function extractTagChartData(
  data: {
    tagName: string;
    tagDetails: any[];
    isValidRange: boolean;
  },
  keyToUse: string,
  activeButton: string,
  startDate: any,
  endDate: any,
  selectedAsset: any,
  unitSystem: string,
  dataType: string,
  tagGrainData: any
) {
  const tagName = data.tagName;
  let chartData = [];
  let filteredArray = [];
  let noData = [];

  if (data) {
    if (data?.tagDetails[0]) {
      chartData = data?.tagDetails[0][keyToUse];
      if (!chartData.length) {
        chartData = [];
      }
      chartData = removeLastItemFromData(chartData, activeButton);
    }
  }

  const unixCurrentTime = dayjs().valueOf();
  const timeFrame = chartSelectorMap[activeButton];

  let unixEndDate: any;

  if (activeButton.includes('m')) {
    // If the activeButton is in minutes (like '1m', '10m', etc.)
    const minutes = timeFrame * 1440; // Convert the fractional days back to minutes
    unixEndDate = dayjs().subtract(minutes, 'minutes').valueOf();
  } else if (activeButton.includes('h')) {
    // If the activeButton is in hours (like '1h', '6h', etc.)
    const hours = timeFrame * 24; // Convert the fractional days back to hours
    unixEndDate = dayjs().subtract(hours, 'hours').valueOf();
  } else {
    // If the activeButton is in days (like '1D', '7D', etc.)
    unixEndDate = dayjs().subtract(timeFrame, 'days').valueOf();
  }

  if (activeButton !== 'All') {
    filteredArray = chartData?.filter(
      (item: any) => item.ts >= unixEndDate && item.ts <= unixCurrentTime
    );
  } else {
    filteredArray = chartData;
  }

  noData = filteredArray.filter((obj: any) => obj.avg_val !== 'NODATA');
  if (noData.length === 0) {
    filteredArray = noData;
  }

  if (activeButton === 'Custom' && startDate && endDate) {
    const customStartDate = dayjs(startDate).startOf('day').valueOf();
    const customEndDate = dayjs(endDate).endOf('day').valueOf();
    const today = dayjs().startOf('day').valueOf();
    const fourteenDaysAgo = dayjs()
      .subtract(14, 'days')
      .startOf('day')
      .valueOf();

    // Check if the date range is within the last 14 days
    if (customStartDate >= fourteenDaysAgo && customEndDate <= today) {
      // Use hourly data
      const hourlyData = data?.tagDetails[0]?.hourly ?? [];
      filteredArray = hourlyData?.filter(
        (item: any) => item.ts >= customStartDate && item.ts <= customEndDate
      );
    } else {
      // Use daily data
      const dailyData = data?.tagDetails[0]?.daily ?? [];
      filteredArray = dailyData?.filter(
        (item: any) => item.ts >= customStartDate && item.ts <= customEndDate
      );
    }
  }
  const isSymStart = tagName === 'sym_start';

  filteredArray = filteredArray?.map((data: any) => {
    return {
      ...data,
      value: getTagValue(isSymStart, data, tagName, selectedAsset),
      createdAt: formatDateToString(data.ts, 'YYYY MMM DD HH:mm'),
    };
  });
  let unit = selectedAsset.device.tags.find(
    (tag: any) => tag.tagName === tagName
  )?.unit;

  unit = getLabelWrtUnitSystem(unit, unitSystem);

  /* 
  When its the high grain selection we use the high grain response 
  without any filter since the api returns for the specified date range
  */
  if (dataType === 'highGrain') {
    let grainData = tagGrainData[tagName] || [];

    grainData = grainData.map((item: any) => {
      const value =
        item.value === true || item.value === 'true' || item.value === 'True'
          ? 1
          : item.value === false ||
            item.value === 'false' ||
            item.value === 'False'
          ? 0
          : item.value;

      return {
        ...item,
        createdAt: formatDateToString(item.ts, 'YYYY MMM DD HH:mm:ss'),
        value: Number(value),
        avg_val: Number(value),
        max_val: Number(value),
        min_val: Number(value),
      };
    });

    filteredArray = grainData;
  }

  return {
    filteredArray,
    unit,
  };
}

export function getTagsWithoutData(
  data: any,
  selectedTags: string[],
  keyToUse: string,
  activeButton: string,
  startDate: any,
  endDate: any,
  selectedAsset: any,
  unitSystem: string,
  dataType: string,
  tagGrainData: any,
  isLoadingData: boolean
) {
  let resp: string[] = [];
  if (!isLoadingData && data.length > 0) {
    const tagsData = selectedTags.map((tag) => {
      const tagData = data.find((item: any) => item.tagName === tag);
      if (tagData) {
        const { filteredArray, unit } = extractTagChartData(
          tagData,
          keyToUse,
          activeButton,
          startDate,
          endDate,
          selectedAsset,
          unitSystem,
          dataType,
          tagGrainData
        );
        return {
          name: tag,
          data: filteredArray,
          unit,
        };
      } else {
        return {
          name: tag,
          data: [],
          unit: '',
        };
      }
    });
    const tagsWithoutData = tagsData.filter((item) => item.data.length < 1);

    const tagsWithoutDataNames = tagsWithoutData.map((item) => item.name);
    resp = tagsWithoutDataNames;
  }

  return resp;
}

export function getExcelExportData(
  selectedTags: any[],
  data: any[],
  keyToUse: string,
  activeButton: string,
  startDate: string,
  endDate: string,
  selectedAsset: any,
  unitSystem: string,
  dataType: string,
  tagGrainData: any[]
) {
  const tagsData = selectedTags
    .map((tag) => {
      const tagData = data.find((item: any) => item.tagName === tag);
      if (tagData) {
        const { filteredArray, unit } = extractTagChartData(
          tagData,
          keyToUse,
          activeButton,
          startDate,
          endDate,
          selectedAsset,
          unitSystem,
          dataType,
          tagGrainData
        );
        return {
          name: tag,
          data: filteredArray,
          unit,
        };
      }
      return null;
    })
    .filter(
      (tg): tg is { name: string; data: any; unit: string } => tg !== null
    );

  const tagsWithData = tagsData.filter((item) => item.data.length > 0);
  return tagsWithData;
}

export function getRangeDates(range: string, startDate: Date, endDate: Date) {
  const today = new Date();

  switch (range) {
    case '1D':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 1);
      endDate = today;
      break;
    case '7D':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 7);
      endDate = today;
      break;
    case '14D':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 14);
      endDate = today;
      break;
    case '1YR':
      startDate = new Date(today);
      startDate.setFullYear(today.getFullYear() - 1);
      endDate = today;
      break;
    case 'ALL':
    case 'Custom':
      break;
    default:
      break;
  }

  return { startDate, endDate };
}
function interpolatePoints<T extends { ts: number }>(
  startPoint: T,
  increment: number,
  endTs: number,
  includeEnd: boolean = false
): T[] {
  const interpolated: T[] = [];
  let currentTs = startPoint.ts + increment;

  // Adjust the loop boundary based on `includeEnd`
  const boundary = includeEnd ? endTs + increment : endTs;

  while (currentTs + increment < boundary) {
    interpolated.push({
      ...startPoint,
      ts: currentTs,
    });
    currentTs += increment;
  }

  return interpolated;
}
export function interpolateDataPoints<T extends { ts: number }>(
  data: T[],
  increment: number = 1000,
  startDate: any,
  endDate: any
): T[] {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  const endDateUnix = dayjs.utc(endDate).valueOf();

  const interpolated: T[] = [];

  // Interpolate between data points
  for (let i = 0; i < data.length - 1; i++) {
    const currentPoint = data[i];
    const nextPoint = data[i + 1];

    interpolated.push(currentPoint);
    interpolated.push(
      ...interpolatePoints(currentPoint, increment, nextPoint.ts)
    );
  }

  // Add the last point from data
  const lastPoint = data[data.length - 1];
  interpolated.push(lastPoint);

  // Interpolate after the last data point
  if (lastPoint.ts < endDateUnix) {
    interpolated.push(
      ...interpolatePoints(lastPoint, increment, endDateUnix, true)
    );
  }

  return interpolated;
}
