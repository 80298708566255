import { Grid } from '@mui/material';
import CardAnalytics from 'views/Production/components/CardAnalytics/CardAnalytics';
import type {
  fleetDataSummary,
  fleetScoreDetails,
  summaryChart,
} from 'types/production.types';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { convertSeconds } from '../../AllFleet/utils';
import {
  evaluateNumber,
  numberEvaluationKeyMappings,
  getFleetKpiThreshold,
} from 'views/FleetOverview/components/AssetCardsV2/utils';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

interface Props {
  enableDarkTheme: boolean;
  data: fleetDataSummary;
  isLoading: boolean;
  unitSystem: string;
  assetOverview: any;
  scoreData: fleetScoreDetails;
  chartData: summaryChart;
  aggregation: string;
  isSelected: string;
  customerCode: string;
}

const isNegative = (value: number) => value < 0;

const returnAnalysis = (value: number | string) => {
  return `${value}% ${isNegative(Number(value)) ? 'less' : 'more'}`;
};

const HaulerAnalyticsWrapper = ({
  enableDarkTheme,
  data,
  isLoading,
  unitSystem,
  assetOverview,
  scoreData,
  chartData,
  aggregation,
  isSelected,
  customerCode,
}: Props) => {
  const kpiThresholds = getFleetKpiThreshold(assetOverview.kpiThresholds);
  const thresholdMappings = numberEvaluationKeyMappings('total');

  const totalLoad = data.haul_truck_total_load;
  const avgTonDay = data.haul_truck_avgTonDay;

  const avgLoadPerHour = data.avgLoadPerHour;

  const avgLoad = data.haul_truck_avgTon;
  const productionValue = evaluateNumber(
    scoreData.prod_score,
    thresholdMappings.productivity,
    kpiThresholds
  );

  const isDayRange =
    ['1D', '7D', '14D', '1M'].includes(isSelected) || aggregation === 'Daily';

  const tr = [
    {
      title: 'Production',
      pointingUp: !isNegative(
        Number(data.haul_truck_total_load_percentage_diff)
      ),
      value: formatNumberToShortScale(
        convertToTwoDecimalPlaces(avgLoadPerHour.toString())
      ),
      unit: unitSystem === 'imperial' ? 'iTons' : 'Tons',
      isGreen: !isNegative(data.haul_truck_total_load_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(
          data.haul_truck_total_load_percentage_diff.toString()
        )
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: true,
      progressBarValue: productionValue,
      chartData: chartData.avgLoadPerHour,
    },
    {
      title: `Avg Loads / ${isDayRange ? 'Day' : 'Month'}`,
      pointingUp: !isNegative(
        Number(data.haul_truck_avgTonDay_percentage_diff)
      ),
      value: formatNumberToShortScale(
        convertToTwoDecimalPlaces((avgTonDay ?? 0).toString())
      ),
      unit:
        unitSystem === 'imperial'
          ? `iTons/ ${isDayRange ? 'Day' : 'Month'}`
          : `Tons/ ${isDayRange ? 'Day' : 'Month'}`,
      isGreen: !isNegative(data.haul_truck_avgTonDay_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(
          data.haul_truck_avgTonDay_percentage_diff.toString()
        )
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 1,
      showProgressBar: false,
      chartData: chartData.avgTonDay,
    },
    {
      title: 'Avg Cycle Time',
      pointingUp: !isNegative(
        Number(data.haul_truck_avg_cycle_time_percentage_diff)
      ),
      value: convertToTwoDecimalPlaces(
        convertSeconds(data.haul_truck_avg_cycle_time ?? 0).value.toString()
      ),
      unit:
        convertSeconds(data.haul_truck_avg_cycle_time ?? 0).unit ?? 'Minutes',
      isGreen: !isNegative(data.haul_truck_avg_cycle_time_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(
          data.haul_truck_avg_cycle_time_percentage_diff.toString()
        )
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      chartData: chartData.cycle_time,
    },
    {
      title: 'Avg Load / Cycle',
      pointingUp: !isNegative(Number(data.haul_truck_avgTon_percentage_diff)),
      value: formatNumberToShortScale(
        convertToTwoDecimalPlaces((avgLoad ?? 0).toString())
      ),
      unit: unitSystem === 'imperial' ? 'iTons' : 'Tons',
      isGreen: !isNegative(data.haul_truck_avgTon_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(
          data.haul_truck_avgTon_percentage_diff.toString()
        )
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 1,
      showProgressBar: false,
      chartData: chartData.avgTon,
    },
    {
      title: 'Pass Count / Cycle',
      pointingUp: !isNegative(Number(data.haul_cycle_count_percentage_diff)),
      value: convertToTwoDecimalPlaces(data.pass_count_cycle.toString()),
      unit: null,
      isGreen: !isNegative(data.haul_cycle_count_percentage_diff),
      analysis: returnAnalysis(0),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      chartData: chartData.pass_count,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{
        paddingTop: '15px',
      }}
    >
      {tr.map((item, index) => {
        return (
          <Grid item key={index} lg={2.4} md={6} sm={12} xs={12}>
            <CardAnalytics
              enableDarkTheme={enableDarkTheme}
              analytics={item}
              isLoading={isLoading}
              customerCode={customerCode}
              isSelected={isSelected}
              aggregation={aggregation}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default HaulerAnalyticsWrapper;
