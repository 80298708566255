import { SixTires } from './VehicleTypes/SixTires';
import { FourTires } from './VehicleTypes/FourTires';
import { GraderSixTires } from './VehicleTypes/GraderSixWheel';

export const VehicleSelector = ({
  tireData,
  limits,
  assetType,
  enableDarkTheme,
  unitSystem,
  tireCodes,
  metricType,
}: {
  tireData: any;
  limits: any;
  assetType: string;
  enableDarkTheme: boolean;
  unitSystem: string;
  tireCodes: any;
  metricType: string;
}) => {
  const currentLimit =
    limits.customerLimits[assetType] ?? limits.globalLimits[assetType];
  if (assetType === 'haul_truck') {
    return (
      <SixTires
        tireData={tireData}
        limits={currentLimit}
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        tireCodes={tireCodes}
        metricType={metricType}
      />
    );
  }
  if (
    ['light_truck', 'loader', 'heavy_truck', 'dozer', 'drill'].includes(
      assetType
    )
  ) {
    return (
      <FourTires
        tireData={tireData}
        limits={currentLimit}
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        tireCodes={tireCodes}
        metricType={metricType}
      />
    );
  }
  if (assetType === 'grader') {
    return (
      <GraderSixTires
        tireData={tireData}
        limits={currentLimit}
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        tireCodes={tireCodes}
        metricType={metricType}
      />
    );
  }
  return <></>;
};
