import { type AgGridReact } from 'ag-grid-react';
import { clearExcel, setExcel } from 'store/exportData.slice';
import { ASSET_OPERATIONAL_STATUS, ASSET_TYPES } from 'utils/enums';
import { moveKeyToBeginningInObject } from 'utils/helpers/general';
import {
  fuelIdleConsumptionLabels,
  fuelNonIdleConsumptionLabels,
  getFuelLabelWrtUnitSystem,
  loadedFuelEfficiencyLabels,
  unLoadedFuelEfficiencyLabels,
} from 'utils/helpers/labels';
import { notificationLevel } from 'views/FleetOverview/helpers/asset.helpers';
import { handleAssetStatus } from 'views/Settings/ExternalUsers/AssetView/helpers/common';
import {
  AlertDelivery,
  AppliesTo,
  NotificationConditions,
  SeverityDisplay,
} from 'views/Settings/Rules/components/NotificationListCellRenderer';
import { utils, writeFile } from 'xlsx';

// this function maps backend data with columnHeaders and AGgrid output
export function processArrays(array1: any, array2: any) {
  const newArray: any = [];

  array1.forEach((obj1: any) => {
    const newObj: any = {};

    array2.forEach((column: any) => {
      const fieldParts = column.field.split('.');
      let value = obj1;

      fieldParts.forEach((part: any) => {
        if (value && Object.prototype.propertyIsEnumerable.call(value, part)) {
          value = value[part];
          if (part === 'isActive') {
            if (obj1.conditions) {
              value = value ? 'Active' : 'Inactive';
            } else {
              const operationalStatus = handleAssetStatus(value);
              value = (ASSET_OPERATIONAL_STATUS as any)[operationalStatus];
              if (!value) return null;
            }
          }
          if (part === 'assetType') {
            value = ASSET_TYPES.find((e) => e.id === value)?.display ?? '';
          }
          if (part === 'notifications') {
            const { warning } = notificationLevel(value);
            value = warning;
          }
          if (part === 'ingestDate') {
            value = dateFormat(value);
          }
          if (part === 'conditions') {
            value = NotificationConditions({
              data: {
                conditions: value,
                notificationLevel: value?.notificationLevel,
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: [],
                assets: [],
                alertByEmail: false,
                alertBySms: false,
                alertByUI: false,
                alertByNotificationCenter: false,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
          if (part === 'bumperNumbers') {
            value = AppliesTo({
              data: {
                conditions: [],
                notificationLevel: '',
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: value,
                assets: [],
                alertByEmail: false,
                alertBySms: false,
                alertByUI: false,
                alertByNotificationCenter: false,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
          if (part === 'notificationLevel') {
            value = SeverityDisplay({
              data: {
                conditions: [],
                notificationLevel: value,
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: [],
                assets: [],
                alertByEmail: false,
                alertBySms: false,
                alertByUI: false,
                alertByNotificationCenter: false,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
          if (part === 'alertByEmail') {
            value = AlertDelivery({
              data: {
                conditions: [],
                notificationLevel: '',
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: [],
                assets: [],
                alertByEmail: obj1.alertByEmail,
                alertBySms: obj1.alertBySms,
                alertByUI: obj1.alertByUI,
                alertByNotificationCenter: obj1.alertByNotificationCenter,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
        } else if (
          value.liveTagData &&
          Object.prototype.propertyIsEnumerable.call(value.liveTagData, part)
        ) {
          value = value.liveTagData[part];
        } else {
          value = undefined;
        }
      });
      // Convert the value to a string if it's an object
      if (typeof value === 'object') {
        if (
          column.headerName === 'Applies To' ||
          column.headerName === 'Condition(s)' ||
          column.headerName === 'Severity' ||
          column.headerName === 'Alert Delivery Method(s)'
        ) {
          newObj[column.headerName] = value;
        } else {
          newObj[column.headerName] = JSON.stringify(value);
        }
      } else {
        newObj[column.headerName] = value;
      }
    });

    newArray.push(newObj);
  });

  return newArray;
}

interface ExtractTableProps {
  handleToPrint: (val: boolean) => void;
  gridRef: React.RefObject<AgGridReact>;
  dispatch: any;
}
//  this function gets data output, processed(sorted, filtered) by AGgrid
export const extractTableData = ({
  handleToPrint,
  gridRef,
  dispatch,
}: ExtractTableProps) => {
  handleToPrint(true);

  if (gridRef.current) {
    dispatch(clearExcel());

    const allData: any = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      allData.push(node.data);
    });

    const columnDefs = gridRef.current.api.getColumnDefs();
    const keysToInclude = [
      'colId',
      'field',
      'filter',
      'flex',
      'headerClass',
      'headerName',
    ];

    const filteredColumnDef = columnDefs?.map((obj: any) => {
      const includedObject = Object.keys(obj)
        .filter((key) => keysToInclude.includes(key))
        .reduce((acc: any, key) => {
          acc[key] = obj[key];
          return acc;
        }, {});

      return includedObject;
    });

    const data = allData;

    dispatch(setExcel({ data, columnHeaders: filteredColumnDef }));
  }
};

export const dateFormat = (dateString: string) => {
  return new Date(dateString).toLocaleString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
};

export function trimSheetName(
  sheetName: string,
  maxLength: number = 31
): string {
  const sheetName2 = sheetName
    .replace('engine', 'e')
    .replace('intake', 'int')
    .replace('temperature', 'tmp')
    .replace('manifold', 'md')
    .replace('absolute', 'abs')
    .replace('pressure', 'press')
    .replace('resolution', 'res');
  if (sheetName2.length <= maxLength) {
    return sheetName2;
  }

  const firstPart = sheetName2.substring(0, 14);
  const lastPart = sheetName2.substring(sheetName2.length - 14);
  return `${firstPart}...${lastPart}`;
}

export const chartToExcelForVehicleStatus = (
  data: any,
  filename: string,
  title: string,
  unitSystem: string
) => {
  const wb = utils.book_new();
  const clonedData = JSON.parse(JSON.stringify(data));

  clonedData.map((tagGroup: any) => {
    const newData = tagGroup.data.map((item: any) => {
      item.ts = dateFormat(item.ts);
      item.sensorName = tagGroup.name;
      return {
        ts: item.ts,
        'Sensor Name': item.sensorName,
        'Min Value': item.min_val,
        'Avg Value': item.avg_val,
        'Max Value': item.max_val,
      };
    });

    const newObj: Record<string, unknown> = setHeaders(newData[0], unitSystem);
    newData.unshift(newObj);

    // Convert chart data to worksheet
    const ws = utils.json_to_sheet(newData);

    // Add the worksheet to the workbook
    utils.book_append_sheet(wb, ws, trimSheetName(tagGroup.name));

    // Add the title to the sheet
    ws.A1 = { t: 's', v: `${title}` };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }];
    return null;
  });
  // Save the workbook as a file
  writeFile(wb, `${filename}.xlsx`);
};

export const chartToExcelForUsageDashboard = (
  data: any,
  filename: string,
  title: string,
  isHistorical = false,
  unitSystem: string
) => {
  const wb = utils.book_new();

  const filterData = structuredClone(data);

  filterData.loadedFuelData = data.fuelLoadedUnloadedAnalytics.loadedFuelData;
  filterData.unloadedFuelData =
    data.fuelLoadedUnloadedAnalytics.unloadedFuelData;

  delete filterData.fuelLoadedUnloadedAnalytics;
  delete filterData.fuelConsumptionEngineHoursAnaly;
  delete filterData.fuelConsumptionEngineHoursAnalytics;
  if (!isHistorical) {
    delete filterData.fuelConsumptionRangeAnalytics;
  } else {
    delete Object.assign(filterData, {
      monthlyFuelConsumption: filterData.fuelConsumptionRangeAnalytics,
    }).fuelConsumptionRangeAnalytics;
  }
  const newFilteredData: any = [];
  filterData?.loadedFuelData?.map((item: any) => {
    const cloneObj = structuredClone(item);

    delete cloneObj.unloaded_duration_seconds;
    delete cloneObj.unloaded_fuel_avg;
    delete cloneObj.fuelSource;
    delete cloneObj.loaded_total_hours_operated;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      loaded_fuel_efficiency: cloneObj.loaded_fuel_avg,
    }).loaded_fuel_avg;

    newFilteredData.push(cloneObj);
    return cloneObj;
  });
  filterData.loadedFuelData = newFilteredData;

  const newunLoadedFilteredData: any = [];
  filterData?.unloadedFuelData?.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.fuelSource;
    delete cloneObj.loaded_fuel_avg;
    delete cloneObj.loaded_total_hours_operated;
    delete cloneObj.unloaded_duration_seconds;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      unloaded_fuel_efficiency: cloneObj.unloaded_fuel_avg,
    }).unloaded_fuel_avg;

    newunLoadedFilteredData.push(cloneObj);
    return cloneObj;
  });
  filterData.unloadedFuelData = newunLoadedFilteredData;

  const newFuelIdleEventsData: any = [];
  filterData?.fuelIdleEvents.data?.map((item: any) => {
    const cloneObj = structuredClone(item);
    cloneObj.idling_event_average =
      filterData.fuelIdleEvents.idlingEventAverage;
    delete cloneObj.avg_fuel_consumption_per_hour;
    delete cloneObj.fuelSource;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      total_idle_time_events: cloneObj.excess_idle,
    }).excess_idle;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );
    newFuelIdleEventsData.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  filterData.fuelIdleEvents = newFuelIdleEventsData;

  const newFuelNonIdleEventsData: any = [];
  filterData?.fuelNoneIdleEvents.data?.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.avg_idle_fuel_consumption;
    delete cloneObj.fuelSource;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      nonidling_event_average: cloneObj.avg_idle_time_minutes,
    }).avg_idle_time_minutes;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );
    newFuelNonIdleEventsData.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  filterData.fuelNonIdleEvents = newFuelNonIdleEventsData;

  delete filterData.fuelNoneIdleEvents;

  for (const key in filterData) {
    const dataArr = [];
    if (filterData[key].data) {
      dataArr.push(...filterData[key].data);
    } else if (Array.isArray(filterData[key])) {
      dataArr.push(...filterData[key]);
    }
    const dataWithKey: Array<Record<string, unknown>> = [];
    dataArr.map((tagData) => {
      const newObj = { ...tagData };
      return dataWithKey.push(newObj);
    });

    const newObj: Record<string, unknown> = setHeaders(
      dataWithKey[0],
      unitSystem,
      {
        CO2: dataWithKey[0]?.loaded_fuel_efficiency
          ? getFuelLabelWrtUnitSystem(
              unitSystem,
              loadedFuelEfficiencyLabels.CO2
            )
          : dataWithKey[0]?.unloaded_fuel_efficiency
          ? getFuelLabelWrtUnitSystem(
              unitSystem,
              unLoadedFuelEfficiencyLabels.CO2
            )
          : getFuelLabelWrtUnitSystem(
              unitSystem,
              fuelIdleConsumptionLabels.CO2
            ),
        loaded_fuel_efficiency: getFuelLabelWrtUnitSystem(
          unitSystem,
          loadedFuelEfficiencyLabels.loaded_fuel_efficiency
        ),
        unloaded_fuel_efficiency: getFuelLabelWrtUnitSystem(
          unitSystem,
          unLoadedFuelEfficiencyLabels.unloaded_fuel_efficiency
        ),
        total_fuel_consumed: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelIdleConsumptionLabels.total_fuel_consumed
        ),
        total_idle_time_events: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelIdleConsumptionLabels.total_idle_time_events
        ),
        total_idle_time_minutes: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelNonIdleConsumptionLabels.total_idle_time_minutes
        ),
        idling_event_average: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelIdleConsumptionLabels.total_idle_time_events
        ),
        nonidling_event_average: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelNonIdleConsumptionLabels.total_idle_time_minutes
        ),
      }
    );

    dataWithKey.unshift(newObj);

    const ws = utils.json_to_sheet(dataWithKey);

    utils.book_append_sheet(wb, ws, trimSheetName(key));

    ws.A1 = { t: 's', v: `${title}` };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }];
  }
  writeFile(wb, `${filename}.xlsx`);
};

export const exportEngineHoursToExcel = async ({
  dataWithKey,
  title,
  key,
  filename,
}: {
  dataWithKey: Array<Record<string, any>>;
  title?: string;
  key: string;
  filename: string;
}) => {
  const wb = utils.book_new(); // to create a new workbook

  // .... data manipulation as required for your output .......

  const ws = utils.json_to_sheet(dataWithKey); //  Convert your JSON object (dataWithKey) into a worksheet object (ws) that is compatible with Excel.

  utils.book_append_sheet(wb, ws, trimSheetName(key)); // Appends the worksheet (ws) to an existing workbook (wb) under a specific sheet name.

  // ws.A1 = { t: 's', v: `${title}` }; //  Modifies the value of cell A1 in the worksheet to display a title (title).

  // ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]; // Merges cells in the first row (A1 to K1), to create an header for you
  writeFile(wb, `${filename}.xlsx`); // saves, generates and downloads your workbook
};

export const chartToExcelForNonHaulTruckUsageDashboard = (
  data: any,
  filename: string,
  title: string,
  isHistorical = false,
  unitSystem: string
) => {
  const wb = utils.book_new();

  const filterData = structuredClone(data);

  const newData: any = [];
  filterData[0].data.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      fuel_use_rate: cloneObj.fuel_rate,
    }).fuel_rate;

    delete Object.assign(cloneObj, {
      avg_fuel_use_rate: cloneObj.avg_fuel_rate,
    }).avg_fuel_rate;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );

    newData.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  filterData[0].data = newData;

  const newData2: any = [];
  filterData[1].data.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.device;
    delete cloneObj.customer_day;
    delete cloneObj.avg_idle_fuel_consumption;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );

    newData2.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  filterData[1].data = newData2;

  if (!isHistorical) {
    filterData.splice(2, 1);
  }

  const sheetNames = [
    'Engine Hours',
    'Idling Time',
    'Monthly Fuel Consumption',
  ];

  filterData.map((sheets: any, index: number) => {
    let newData = sheets.data;
    if (Array.isArray(newData)) {
      newData.map((data: Record<string, unknown>) => {
        if (data.customer_day) {
          data.customer_day = dateFormat(data.customer_day as string);
        }
        delete data.fuelSource;
        delete data['Fuel Source'];
        return data;
      });
    } else {
      newData = sheets;
    }

    const newObj: Record<string, unknown> = setHeaders(newData[0], unitSystem);

    newData.unshift(newObj);
    const ws = utils.json_to_sheet(newData);

    utils.book_append_sheet(wb, ws, trimSheetName(sheetNames[index]));

    ws.A1 = { t: 's', v: `${title}` };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }];

    return null;
  });

  writeFile(wb, `${filename}.xlsx`);
};

const setHeaders = (
  dataWithKey: Record<string, unknown>,
  unitSystem: string,
  options?: Record<string, string>
): Record<string, unknown> => {
  const newObj: any = {};

  for (const key in dataWithKey) {
    if (options && key in options) {
      newObj[key] = `${key} (${options[`${key}`]})`;
    } else if (key.includes('fuel')) {
      newObj[key] = `${key} ${unitSystem === 'metric' ? '(litres)' : '(gals)'}`;
    } else if (key.includes('minutes')) {
      newObj[key] = `${key} (mins)`;
    } else if (key.includes('time')) {
      newObj[key] = `${key} (secs)`;
    } else if (key.includes('CO2')) {
      newObj[key] = `${key} ${unitSystem === 'metric' ? '(kg)' : '(lbs)'}`;
    } else if (key.includes('customer_month') || key === 'ts') {
      newObj[key] = `Date/Time`;
    } else if (
      key === 'sensorName' ||
      key === 'sensorName' ||
      key === 'tagName'
    ) {
      newObj[key] = `Tag Name`;
    } else if (key === 'bumperNumber') {
      newObj[key] = `bumper number`;
    } else {
      newObj[key] = key;
    }
    newObj[key] = newObj[key].split('_').join(' ');
  }
  return newObj;
};

export const shiftWiseEngineStartEndDataToExcel = (
  shiftWiseData: Record<string, any[]>,
  filename: string
) => {
  const wb = utils.book_new(); // to create a new workbook

  // .... data manipulation as required for your output .......

  const shifts = Object.keys(shiftWiseData);

  for (const shift of shifts) {
    const data = shiftWiseData[shift];
    const ws = utils.json_to_sheet(data); //  Convert your JSON object (dataWithKey) into a worksheet object (ws) that is compatible with Excel.
    utils.book_append_sheet(wb, ws, trimSheetName(shift)); // Appends the worksheet (ws) to an existing workbook (wb) under a specific sheet name.
  }
  // ws.A1 = { t: 's', v: `${title}` }; //  Modifies the value of cell A1 in the worksheet to display a title (title).

  // ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]; // Merges cells in the first row (A1 to K1), to create an header for you
  writeFile(wb, `${filename}.xlsx`); // saves, generates and downloads your workbook
};
