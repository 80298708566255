import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { ThemePalette } from 'mui.theme';
import { Guage } from './Guage';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';
import { fixedLimts } from 'views/TirePressure/util';
import { SWToolTip } from 'components/SWToolTip';

interface Props {
  enableDarkTheme: boolean;
  value: number;
  limit: any;
  tireCode: string;
  unitSystem: string;
  tireCodes: any;
  metricType: string;
  slowLeaks: string[];
  fastLeaks: string[];
}

const TirePressure = ({
  enableDarkTheme,
  value,
  tireCode,
  unitSystem,
  tireCodes,
  metricType,
  slowLeaks,
  fastLeaks,
}: Props) => {
  // @ts-expect-error ignore this we have it defined
  const limitsData = fixedLimts[unitSystem][metricType.toLowerCase()];
  const maxValue = limitsData.maxValue;
  const minValue = limitsData.minValue;
  const startRange = limitsData.startValue;
  const endRange = limitsData.endValue;
  const isWithinRange = value >= minValue && value <= maxValue;

  const showLeaksNotification = metricType === 'Pressure';
  const hasSlowLeaks = slowLeaks.includes(tireCode);
  const hasFastLeaks = fastLeaks.includes(tireCode);

  const noOfNotifications = (hasSlowLeaks ? 1 : 0) + (hasFastLeaks ? 1 : 0);

  const toolTipTitle = (() => {
    if (hasSlowLeaks && hasFastLeaks) {
      return 'Fast Leak detected\nSlow Leak detected';
    } else if (hasSlowLeaks) {
      return 'Slow Leak detected';
    } else if (hasFastLeaks) {
      return 'Fast Leak detected';
    } else {
      return 'No Leaks detected';
    }
  })();

  return (
    <Box
      sx={{
        padding: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        border: '1px solid #313F54',
        borderRadius: '5px',
        marginBottom: '30px',
        backgroundColor: enableDarkTheme ? '#172946' : '#FFFFFF',
      }}
    >
      <Typography
        sx={{
          fontSize: '12px',
          color: enableDarkTheme
            ? ThemePalette.typography.wheat
            : ThemePalette.typography.black,
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        {tireCodes[tireCode]}
      </Typography>
      <Box
        sx={{
          paddingTop: '20px',
        }}
      >
        <Guage
          enableDarkTheme={enableDarkTheme}
          value={value}
          maxValue={maxValue}
          minValue={minValue}
          unitSystem={unitSystem}
          metricType={metricType}
          startRange={startRange}
          endRange={endRange}
        />
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            paddingBottom: '10px',
            textAlign: 'center',
            color: isWithinRange ? '#228B21' : '#EB5758',
            marginTop: '20px',
          }}
        >
          {value}{' '}
          <span
            style={{
              color: ThemePalette.typography.lightGrey,
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {metricType === 'Pressure'
              ? unitSystem === 'metric'
                ? 'PSI'
                : 'KPA'
              : getLabelWrtUnitSystem('C', unitSystem).toLowerCase()}
          </span>
        </Typography>

        {showLeaksNotification && (
          <SWToolTip title={toolTipTitle} placement="top-start">
            <Typography
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                color: noOfNotifications === 0 ? '#228B21' : '#EB5758',
                display: 'flex',
              }}
            >
              {noOfNotifications > 0 && (
                <Box
                  sx={{
                    animation: 'blink 1.5s infinite',
                    '@keyframes blink': {
                      '0%': { opacity: 1 },
                      '50%': { opacity: 0.3 },
                      '100%': { opacity: 1 },
                    },
                  }}
                >
                  <ErrorIcon
                    sx={{
                      color: '#EB5758',
                      fontSize: '12px',
                      marginRight: '2px',
                    }}
                  />
                </Box>
              )}
              <span>{noOfNotifications} Notification(s)</span>
            </Typography>
          </SWToolTip>
        )}
      </Box>
      {/* <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.wheat
            : ThemePalette.typography.black,
          fontSize: '12px',
        }}
      >
        Estimated Life
      </Typography> */}
      {/* <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          fontSize: '14px',
          paddingTop: '5px',
          fontWeight: '700',
        }}
      >
        4 Months
      </Typography> */}
      {/* <Box className="flex items-center">
        <Box className="w-2 h-2 rounded-full bg-green-400 mr-2" />
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontSize: '12px',
            paddingTop: '5px',
          }}
        >
          N2-filled
        </Typography>
      </Box> */}
    </Box>
  );
};

export default TirePressure;
