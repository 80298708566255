import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { useCallback, useMemo } from 'react';
import {
  type DeviceEngineHour,
  type EngineHourPerDayInstance,
} from 'store/engine.hours.slice';
import { EngineHoursAreaChart, formatTimestamp } from './EngineHoursAreaChart';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useAppSelector } from 'store/hook';
import { CircularProgress, Typography } from '@mui/material';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function isDateBetween(dateToCheck: any, startDate: any, endDate: any) {
  // Parse the dates using Day.js
  const parsedDateToCheck = dayjs(dateToCheck);
  const parsedStartDate = dayjs(startDate);
  const parsedEndDate = dayjs(endDate);

  // Check if the date falls between the start and end dates, including midnight
  return (
    parsedDateToCheck.isSameOrAfter(parsedStartDate.startOf('day')) &&
    parsedDateToCheck.isSameOrBefore(parsedEndDate.endOf('day'))
  );
}

interface IndividualEngineHoursProps {
  showDailyData: boolean;
  startDate: Date;
  endDate: Date;
  selectedAsset: string;
}

const IndividualEngineHours = ({
  showDailyData,
  startDate,
  endDate,
  selectedAsset,
}: IndividualEngineHoursProps) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const {
    deviceEngineHourPerDay,
    deviceEngineHour,
    isLoading: { loadingEngineHoursForDevice },
  } = useAppSelector((state) => state.engineHoursReducer);
  const formatChartData = useCallback(function (
    data: DeviceEngineHour[] | EngineHourPerDayInstance[],
    startDate: any,
    endDate: any,
    format?: string
  ) {
    const formattedData: DeviceEngineHour[] | EngineHourPerDayInstance[] =
      data.map((item: any) => {
        return {
          ...item,
          engine_hour_total: +convertToTwoDecimalPlaces(item.engine_hour_total),
          hour: formatTimestamp(item.hour, format),
        };
      });

    if (startDate && endDate) {
      const filteredData = formattedData.filter((item: any) =>
        isDateBetween(item.hour, startDate, endDate)
      );
      return filteredData;
    }
    return formattedData;
  },
  []);
  const dataToDisplay: DeviceEngineHour[] | EngineHourPerDayInstance[] =
    useMemo(() => {
      if (showDailyData) {
        return formatChartData(
          deviceEngineHourPerDay,
          startDate,
          endDate,
          'YYYY-MM-DD'
        );
      }
      return formatChartData(deviceEngineHour, startDate, endDate);
    }, [
      showDailyData,
      deviceEngineHourPerDay,
      deviceEngineHour,
      startDate,
      endDate,
    ]);
  return (
    <section
      id="individual-engine-hours"
      style={{
        marginTop: '20px',
        marginBottom: '20px',
        height: '430px',
        width: '100%',
      }}
    >
      {loadingEngineHoursForDevice ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        selectedAsset && (
          <>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: '600',
                color: `${
                  enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black
                }`,
              }}
            >
              Engine Hours for asset
              <span
                style={{
                  color: '#8884d8',
                }}
              >{`  ${selectedAsset}`}</span>
            </Typography>
            <div
              id="individual-engine-hours-area-chart"
              style={{
                width: '100%',
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <EngineHoursAreaChart chartType={'area'} data={dataToDisplay} />
            </div>
          </>
        )
      )}
    </section>
  );
};

export default IndividualEngineHours;
