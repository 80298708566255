import { Box, Typography } from '@mui/material';
import FuelAnalyticsHandler from 'handlers/fuelAnalytics.handler';
import { useEffect } from 'react';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { fuelValueConversion } from 'views/FuelHistoricalAnalysis/utils';

const TreeMapDiv = ({
  enableDarkTheme,
  handleClicked,
  data,
  fuelMeasure,
  baseCost,
  selectedAssetType,
  isLoading,
  unitSystem,
  fuelUnit,
}: {
  enableDarkTheme: boolean;
  handleClicked: (device: string) => void;
  data: any;
  fuelMeasure: 'consumption' | 'co2 emission' | 'cost';
  baseCost: {
    diesel: number;
    gas: number;
  };
  selectedAssetType: string;
  isLoading: boolean;
  unitSystem: string;
  fuelUnit: 'ltr' | 'gal';
}) => {
  const fuelAnalyticsHandler = new FuelAnalyticsHandler();

  let totalSize = 0;
  const transformedData = data
    ?.map((item: any) => {
      const size =
        fuelMeasure === 'consumption'
          ? +fuelValueConversion(
              +item.total_fuel_consumption,
              unitSystem,
              fuelUnit
            ).toFixed(2)
          : fuelMeasure === 'co2 emission'
          ? +(+item.CO2).toFixed(2)
          : fuelMeasure === 'cost'
          ? +(
              fuelValueConversion(
                +item.total_fuel_consumption,
                unitSystem,
                fuelUnit,
                'cost'
              ) * baseCost[item.fuelSource.toLowerCase() as 'gas' | 'diesel']
            ).toFixed(2)
          : 0;
      totalSize += size;
      if (
        selectedAssetType === 'haul_truck' &&
        item.asset_type === 'haul_truck'
      ) {
        return {
          name: item.bumper_number,
          size,
        };
      } else if (selectedAssetType === item.asset_type) {
        return {
          name: item.bumper_number,
          size,
        };
      }
      return null;
    })
    .filter(Boolean);
  const unit =
    fuelMeasure === 'consumption'
      ? fuelUnit
      : fuelMeasure === 'co2 emission'
      ? ''
      : fuelMeasure === 'cost'
      ? '$'
      : '';

  const getAirFilters = async (devices: string[]) => {
    await fuelAnalyticsHandler.getAirFilters(devices);
  };
  useEffect(() => {
    const deviceIds: string[] = [];
    if (data.length > 1) {
      data.map((item: any) => deviceIds.push(item.device));
      void getAirFilters(deviceIds);
    }
  }, [data]);

  return (
    <Box
      sx={{
        padding: '10px',
        border: '1px solid rgba(98, 98, 98, 0.2)',
        borderRadius: '4px',
        width: '80%',
      }}
    >
      {!transformedData.length && !isLoading ? (
        <Box
          sx={{
            padding: '50px',
            border: '1px solid rgba(98, 98, 98, 0.2)',
            borderRadius: '4px',
          }}
        >
          <Typography>No data available yet</Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',
            }}
          >
            <Typography
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {/* change this to selected tiem frame */}
              {/* 17 Sept - 23 Oct */}
            </Typography>
            <Typography style={{ fontSize: '13px', fontWeight: 'bold' }}>
              <span style={{ fontWeight: 'normal' }}>
                {fuelMeasure.toUpperCase()}:
              </span>{' '}
              {totalSize.toFixed(2)} {unit.toUpperCase()}
            </Typography>
          </Box>
          <ResponsiveContainer width="100%" height={500}>
            <Treemap
              data={transformedData}
              dataKey="size"
              aspectRatio={4 / 3}
              stroke="#000"
              fill="#4A7BFA"
              content={
                <CustomTreemapContent
                  handleClicked={handleClicked}
                  unit={unit}
                />
              }
            >
              <Tooltip
                content={({ payload }) => {
                  if (payload && payload.length > 0) {
                    const { name, value } = payload[0].payload;
                    return (
                      <div
                        style={{
                          backgroundColor: '#fee',
                          padding: '6px',
                          border: '1px solid #ccc',
                          color: '#000',
                        }}
                      >
                        <p style={{ fontSize: 'smaller' }}>
                          Asset name: <b>{name}</b>
                        </p>
                        <p style={{ fontSize: 'smaller' }}>
                          {capitalizeFirstLetter(fuelMeasure)}: <b>{value}</b>{' '}
                          {unit}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </Treemap>
          </ResponsiveContainer>
        </>
      )}
    </Box>
  );
};

const CustomTreemapContent = ({
  depth = 0,
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  name = '',
  size = '',
  handleClicked,
  unit = '',
}: {
  depth?: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  name?: string;
  size?: string;
  handleClicked: (device: string) => void;
  unit: string;
}) => {
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="#000"
        strokeWidth={3}
        fill="#4A7BFA"
        onClick={() => {
          handleClicked(name);
        }}
        style={{ cursor: 'pointer' }}
      />
      {width > 50 && height > 20 && (
        <>
          <text
            x={x + width / 2}
            y={y + height / 4}
            textAnchor="middle"
            fill="#fff"
            stroke="none"
            style={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {name}
          </text>

          <text
            x={x + width / 2}
            y={y + height / 2}
            textAnchor="middle"
            fill="#000"
            stroke="none"
            style={{ fontSize: 10, fontWeight: 'bold' }}
          >
            {size} {unit}
          </text>
        </>
      )}
    </g>
  );
};

export default TreeMapDiv;
