import { ButtonGroup, Button } from '@mui/material';
import { ThemePalette } from 'mui.theme';

const regularButtonGroupList = ['1D', '7D', '14D', '1Y', 'All', 'Custom'];

export const FilterButtonGroup = ({
  enableDarkTheme,
  activeButton,
  setActiveButton,
  dataType,
}: {
  enableDarkTheme: boolean;
  activeButton: string;
  setActiveButton: any;
  dataType: string;
}) => {
  const btnList = regularButtonGroupList;
  return (
    <ButtonGroup
      size="small"
      aria-label="Small button group"
      sx={{
        border: '1px solid #38465F',
      }}
    >
      {btnList.map((item, index) => {
        return (
          <Button
            key={index}
            sx={{
              backgroundColor:
                activeButton === item
                  ? '#3666EC'
                  : enableDarkTheme
                  ? '#172946'
                  : ThemePalette.light.boxBackground,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'none',
            }}
            onClick={() => {
              setActiveButton(item);
            }}
          >
            {item}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
