import { Box, Grid, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { AssetDetail } from 'views/VehicleStatusView/VehicleStatusView/Header/AssetDetail';
import { type Asset } from 'types/asset.types';
import { AssetSelector } from './AssetSelector';
import { getNotificationIndex } from 'views/FleetOverview/components/AssetCardsV2/utils';
import { useAppSelector } from 'store/hook';
import dayjs from 'dayjs';

const Header = ({
  enableDarkTheme,
  selectedAsset,
  latestTagData,
  hierarchyInfo,
  makeModels,
  assetBumperIds,
  setSelectedAsset,
  selectedMakeModel,
  setSelectedMakeModel,
}: {
  enableDarkTheme: boolean;
  selectedAsset: Asset;
  latestTagData: any;
  hierarchyInfo: any;
  makeModels: string[];
  assetBumperIds: string[];
  setSelectedAsset: any;
  selectedMakeModel: string;
  setSelectedMakeModel: any;
}) => {
  const { notifications } = useAppSelector(
    (state) => state.ruleNotificationsReducer
  );

  const assetNotifications = notifications.filter(
    (item) => item.assetId === selectedAsset.assetId
  );
  const notificationIndex = getNotificationIndex(assetNotifications);

  return (
    <Grid container>
      <Grid item sm={12} md={6} lg={3}>
        <Box
          sx={{
            borderRadius: '5px',
            marginTop: '17px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            paddingBottom: '5px',
            display: 'flex',
            minHeight: '100px',
          }}
        >
          <AssetSelector
            makeModels={makeModels}
            enableDarkTheme={enableDarkTheme}
            selectedMakeModel={selectedMakeModel}
            setSelectedMakeModel={setSelectedMakeModel}
            assetBumperIds={assetBumperIds}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            inputWidth="130px"
          />
        </Box>
      </Grid>
      <Grid item sm={12} md={6} lg={3}>
        <Box
          sx={{
            borderRadius: '5px',
            marginLeft: '10px',
            marginTop: '17px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            paddingBottom: '15px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Fuel Health
            <span>
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: 'normal',
                }}
              >
                Last Updated:
              </span>
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: '600',
                  paddingLeft: '5px',
                }}
              >
                {dayjs(notificationIndex.time).format('DD/MM/YYYY h:mmA')}
              </span>
            </span>
          </Typography>

          <Box
            sx={{
              width: '95%',
              textAlign: 'center',
              margin: '20px auto 0 auto',
              borderRadius: '5px',
              height: '39px',
              backgroundColor: notificationIndex.color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>{notificationIndex.message}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={12} lg={6}>
        <AssetDetail
          enableDarkTheme={enableDarkTheme}
          selectedAsset={selectedAsset}
          latestTagData={latestTagData}
          hierarchyInfo={hierarchyInfo}
          injestedTime={{ 'Upcoming Service': '24/05/2024  ???' }}
        />
      </Grid>
    </Grid>
  );
};

export default Header;
