// src/components/AssetDetailsTable.tsx
import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { GetIcons } from 'views/SurvivalAnalysis/Helper/Icons/GetIcons.function';
import componentsImage from 'views/SurvivalAnalysis/Helper/Icons/componenent.png';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import { type ComponentDetails } from 'types/squares.type';
import { SVG } from 'components/Asset/SVG';
import { StyledTableCell } from 'components/Table/StyledTableCell';

interface AssetDetailsTableProps {
  enableDarkTheme: boolean;
  details: ComponentDetails[];
  filteredAssets: any[];
}

const AssetDetailsTable: React.FC<AssetDetailsTableProps> = ({
  enableDarkTheme,
  details,
  filteredAssets,
}) => {
  const getBorderColor = (criticality: string): string => {
    switch (criticality?.toLowerCase()) {
      case 'low':
        return ThemePalette.typography.success;
      case 'med':
        return ThemePalette.typography.warning;
      default:
        return ThemePalette.typography.danger;
    }
  };
  function getWorkOrderAgeCategory(
    assetId: string,
    componentsCategory: string,
    subComponent: string
  ) {
    if (!filteredAssets || filteredAssets.length === 0) {
      console.error('filteredAssets is empty or undefined.');
      return {
        value: null,
        actions_performed: '',
      };
    }

    const result = filteredAssets.find(
      (item) =>
        item.asset_id === assetId &&
        item.components_category === componentsCategory &&
        item.sub_component === subComponent
    );

    if (!result) {
      console.error('No matching item found for:', {
        assetId,
        componentsCategory,
        subComponent,
      });
      return {
        value: null,
        actions_performed: '',
      };
    }

    let riskValue = result?.work_order_age_category ?? null;

    if (riskValue === 'eol') {
      riskValue = 'high';
    }
    return {
      value: riskValue ? riskValue?.toUpperCase() : null,
      actions_performed: result?.actions_performed ?? null,
    };
  }

  const getUniqueDetailsByEarliestFutureDate = useCallback((data: any[]) => {
    // Create a map to track the record with the earliest future squares_recommended_date
    const uniqueMap = new Map();

    data.forEach((item) => {
      const key = `${item.bumper_number}-${item.component}-${item.sub_component}`;
      const currentDate = new Date().toISOString().split('T')[0]; // Current date as string

      if (new Date(item.squares_recommended_date) >= new Date(currentDate)) {
        if (
          !uniqueMap.has(key) ||
          new Date(uniqueMap.get(key).squares_recommended_date) >
            new Date(item.squares_recommended_date)
        ) {
          uniqueMap.set(key, item);
        }
      }
    });

    // Convert the map back to an array
    return Array.from(uniqueMap.values());
  }, []);

  const transformedData = useMemo(() => {
    const result = details
      .map((detail) => {
        const { value, actions_performed: actionsPerformed } =
          getWorkOrderAgeCategory(
            detail.bumper_number,
            detail.component,
            detail.sub_component
          );

        return {
          ...detail,
          work_order_age_category: value,
          actions_performed: actionsPerformed,
        };
      })
      .filter((item) => item.work_order_age_category);

    return result;
  }, [details]);

  const filterBasedOnCategoryOfRiskOrCriticality = useCallback(
    (data: typeof transformedData) => {
      const filterHigh = data?.filter(
        (item) =>
          item.criticality?.toLowerCase() === 'high' ||
          item.criticality?.toLowerCase() === 'eol' ||
          item.work_order_age_category?.toLowerCase() === 'high' ||
          item.work_order_age_category?.toLowerCase() === 'eol'
      );
      if (filterHigh.length > 0) {
        return filterHigh;
      }
      const filterMedium = data?.filter(
        (item) =>
          item.criticality?.toLowerCase() === 'med' ||
          item.work_order_age_category?.toLowerCase() === 'med'
      );
      if (filterMedium.length > 0) {
        return filterMedium;
      }
      const filterLow = data?.filter(
        (item) =>
          item.criticality?.toLowerCase() === 'low' ||
          item.work_order_age_category?.toLowerCase() === 'low'
      );
      if (filterLow.length > 0) {
        return filterLow;
      }
      return data;
    },
    []
  );

  const enhancedDetails = useMemo(() => {
    return filterBasedOnCategoryOfRiskOrCriticality(transformedData);
  }, [transformedData]);

  const filteredEnhancedDetails = useMemo(() => {
    return getUniqueDetailsByEarliestFutureDate(enhancedDetails);
  }, [enhancedDetails]);

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table
        style={{
          background: enableDarkTheme
            ? ThemePalette.dark.tableBackground
            : ThemePalette.light.tableBackground,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Component
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Recommended OEM Service Frequency
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Age (Last Maintenance)
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Manufacturer Rec. Date
            </TableCell>
            <StyledTableCell>Recommended Maintenance Action</StyledTableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              System Recommended Date
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Risk
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Criticality
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          style={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.subTableBackground
              : ThemePalette.light.subTableBackground,
          }}
        >
          {filteredEnhancedDetails.map((detail, index) => (
            <TableRow
              key={`${detail.bumper_number}-${index}`}
              sx={{
                borderLeft: `4px solid ${getBorderColor(detail.criticality)}`,
              }}
            >
              {/* Component Details */}
              <TableCell>
                <Box display="flex" alignItems="center" gap={2}>
                  <GetIcons
                    icon={componentsImage}
                    enableDarkTheme={enableDarkTheme}
                  />
                  <Box>
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      {detail.component}
                    </Typography>
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.grey,
                      }}
                      variant="body2"
                    >
                      {detail.extracted_sub_component}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>

              {/* Recommended Service Frequency */}
              <TableCell>
                <Box>
                  {detail.freq_calendar_days && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      {detail?.freq_calendar_days && (
                        <CalendarToday sx={{ fontSize: '12px' }} />
                      )}
                      {detail?.freq_calendar_days &&
                        ` Every ${detail.freq_calendar_days}`}
                      {detail.freq_engine_hours &&
                        detail.freq_calendar_days && <span> or </span>}
                    </Typography>
                  )}

                  {detail.freq_engine_hours && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      {detail.freq_engine_hours !== 'No Recommendation' && (
                        <SVG name="streetIcon" />
                      )}
                      {detail.freq_engine_hours !== 'No Recommendation' &&
                        ` Every ${detail.freq_engine_hours}`}
                    </Typography>
                  )}
                  {detail.maintenance_type && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      {detail.maintenance_type}
                    </Typography>
                  )}
                  {((!detail.freq_calendar_days && !detail.freq_engine_hours) ||
                    detail.freq_engine_hours === 'No Recommendation') && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      On Need Basis
                    </Typography>
                  )}
                </Box>
              </TableCell>

              {/* Age (Last Maintenance) */}
              <TableCell>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  <SVG name="engineLight" /> {Number(detail.age_in_days) * 24}{' '}
                  Hours
                </Typography>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.grey,
                  }}
                  variant="body2"
                >
                  <CalendarToday sx={{ fontSize: '12px' }} />{' '}
                  {new Date(
                    new Date().setDate(
                      new Date().getDate() - Number(detail.age_in_days)
                    )
                  ).toLocaleDateString()}
                </Typography>
              </TableCell>

              {/* Manufacturer Rec. Date */}
              <TableCell>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                    variant="body1"
                  >
                    {detail.manufacture_recommended_date}
                  </Typography>
                  <Typography
                    style={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.lightGrey
                        : ThemePalette.typography.grey,
                    }}
                    variant="subtitle2"
                    color="grey"
                  >
                    {(() => {
                      const daysDifference = Math.ceil(
                        (new Date(
                          detail.manufacture_recommended_date
                        ).getTime() -
                          new Date().getTime()) /
                          (1000 * 60 * 60 * 24)
                      );

                      if (daysDifference > 0) {
                        return `${daysDifference} Days from now`;
                      } else if (daysDifference < 0) {
                        return `${Math.abs(daysDifference)} Days overdue`;
                      } else {
                        return 'Immediate';
                      }
                    })()}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {/* TODO: Update this once the data starts flowing in the api. */}
                  {detail?.actions_performed
                    ? detail?.actions_performed
                    : detail?.maintenance_type}
                </Typography>
              </TableCell>

              {/* Squares Recommended Date */}
              <TableCell>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {detail.squares_recommended_date}
                </Typography>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.grey,
                  }}
                  variant="subtitle2"
                  color="grey"
                >
                  {(() => {
                    const daysDifference = Math.ceil(
                      (new Date(detail.squares_recommended_date).getTime() -
                        new Date().getTime()) /
                        (1000 * 60 * 60 * 24)
                    );

                    if (daysDifference > 0) {
                      return `${daysDifference} Days from now`;
                    } else if (daysDifference < 0) {
                      return `${Math.abs(daysDifference)} Days overdue`;
                    } else {
                      return 'Immediate';
                    }
                  })()}
                </Typography>
              </TableCell>

              {/* Risk */}
              <TableCell>
                <Typography
                  style={{
                    color:
                      detail.work_order_age_category === 'HIGH' ||
                      detail.work_order_age_category?.toLowerCase() === 'eol'
                        ? getBorderColor(detail.work_order_age_category)
                        : detail.work_order_age_category === 'MED'
                        ? getBorderColor(detail.work_order_age_category)
                        : detail.work_order_age_category === 'LOW'
                        ? getBorderColor(detail.work_order_age_category)
                        : enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {detail?.work_order_age_category}{' '}
                  {/* Use the pre-calculated value */}
                </Typography>
              </TableCell>

              {/* Criticality */}
              <TableCell>
                <Typography
                  style={{
                    color:
                      detail.criticality === 'high' ||
                      detail.criticality === 'eol'
                        ? getBorderColor(detail?.criticality)
                        : detail.criticality === 'med'
                        ? getBorderColor(detail?.criticality)
                        : detail.criticality === 'low'
                        ? getBorderColor(detail?.criticality)
                        : enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {detail?.criticality?.toUpperCase()}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetDetailsTable;
