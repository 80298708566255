import type { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Typography } from '@mui/material';
import Stats from './Stats';
import { ThemePalette } from 'mui.theme';
import type { DriverBehaviorData } from 'types/driver.behavior.types';
import {
  isAllTimeLineDataEmpty,
  transformDriverBehaviorData,
} from 'utils/driverBehavior';
import type { Asset } from 'types/asset.types';
import { tooltip } from 'views/EngineHours/Components/tooltip';
import { useAppSelector } from 'store/hook';
// import { ASSET_TYPES } from 'utils/enums';

interface Props {
  enableDarkTheme: boolean;
  data: DriverBehaviorData;
  assets: Asset[];
  timelineDate: string;
}

const Assets = ({ enableDarkTheme, data, assets, timelineDate }: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const chartOptions: ApexOptions = {
    chart: {
      height: 950,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      custom: (props) =>
        tooltip({
          ...props,
          unitSystem,
        }),
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        borderRadius: 4,
      },
    },
    colors: ['#FEB019', '#FF4560', '#00E396', '#325C9F', '#D2448F', '#777777'],
    xaxis: {
      type: 'datetime',
      position: 'top',
      labels: {
        datetimeUTC: false,
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
        formatter: function (value) {
          // const asset = assets.find(
          //   (item) => item.bumperNumber === value.toString()
          // );
          // if (asset) {
          //   return `${value}\n${
          //     ASSET_TYPES.find((item) => item.id === asset?.assetType)
          //       ?.display ?? ''
          //   }`;
          // }
          return `${value}`;
        },
      },
    },
    grid: {
      column: {
        colors: ['#152A4B', '#162C4E'],
      },
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: 'solid',
      opacity: 0.6,
    },
    legend: {
      position: 'top',
      labels: {
        colors: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      },
    },
  };

  const startTimelineDateMs = new Date(`${timelineDate} 00:00`).getTime();
  const endTimelineDateMs = new Date(`${timelineDate} 23:59`).getTime();

  const timeLineData = transformDriverBehaviorData(data)?.map((metricData) => {
    return {
      ...metricData,
      data: metricData?.data?.filter((individualData) => {
        if (!timelineDate) return true;
        if (individualData?.y?.[0] && individualData?.y?.[1]) {
          // if the start and end time are within the selected time range
          return (
            (individualData?.y?.[0] >= startTimelineDateMs &&
              individualData?.y?.[0] <= endTimelineDateMs) ||
            (individualData?.y?.[1] >= startTimelineDateMs &&
              individualData?.y?.[1] <= endTimelineDateMs) ||
            // if start time is efore the start time and end time is after the end time but inside the selected time range
            (individualData?.y?.[0] < startTimelineDateMs &&
              individualData?.y?.[1] > endTimelineDateMs)
          );
        }
        return false;
      }),
    };
  });

  return (
    <>
      <Stats enableDarkTheme={enableDarkTheme} data={data} />
      <div
        style={{
          padding: '20px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        <h5>Showing Timeline for all assets</h5>
        {isAllTimeLineDataEmpty(timeLineData) ? (
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '12px',
            }}
          >
            No data available for the selected time range.
          </Typography>
        ) : (
          <ReactApexChart
            options={chartOptions}
            series={timeLineData}
            type="rangeBar"
            height={1500}
          />
        )}
      </div>
    </>
  );
};

export default Assets;
