export function getFuelLabelWrtUnitSystem(
  unitSystem: string,
  measure: { metric: string; imperial: string }
): string {
  return unitSystem === 'metric' ? measure.metric : measure.imperial;
}

export function getHourlyFuelEfficencyLabelWrtUnitSystem(
  unitSystem: string,
  measure: { metric: string; imperial: string }
): string {
  return unitSystem === 'metric' ? 'L/hr' : 'Gal/hr';
}

export const loadedFuelEfficiencyLabels = {
  CO2: { metric: 'Kg/tonne-km', imperial: 'Lb/tonne-miles' },
  loaded_fuel_efficiency: {
    metric: 'L / tonne - km',
    imperial: 'Gal / tonne - miles',
  },
};

export const unLoadedFuelEfficiencyLabels = {
  CO2: { metric: 'Kg/km', imperial: 'Lb/miles' },
  unloaded_fuel_efficiency: { metric: 'L/km', imperial: 'Gal/miles' },
};

export const fuelIdleConsumptionLabels = {
  CO2: { metric: 'Kg', imperial: 'Lbs' },
  total_fuel_consumed: { metric: 'L', imperial: 'Gal' },
  total_idle_time_events: { metric: '#', imperial: '#' },
};

export const fuelNonIdleConsumptionLabels = {
  CO2: { metric: 'Kg', imperial: 'Lbs' },
  total_fuel_consumed: { metric: 'L', imperial: 'Gal' },
  total_idle_time_minutes: { metric: 'mins', imperial: 'mins' },
};

export const fuelConsumptionLabels = {
  CO2: { metric: 'Kg', imperial: 'Lbs' },
};

export const productionLabels = {
  Distance: { metric: 'Kms', imperial: 'Miles' },
  Load: { metric: 'Tons', imperial: 'iTons' },
};
