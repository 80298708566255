import BaseAPI from './base.api';

export default class RoughRoadAPI extends BaseAPI {
  getRoughRoadPatches = async () => {
    const response = await this.API.get('accelerometer/rough-road-patches');
    return response.data;
  };

  toggleRoughRoadPatchStatus = async (patchId: string) => {
    const response = await this.API.patch(
      `accelerometer/${patchId}/toggle-status`
    );
    return response.data;
  };
}
