import { DetailsBar, DetailsBarGeolocation } from './styles';
import { CameraFlyTo, Entity, PointGraphics, Viewer } from 'resium';
import { Cartesian3, Color, Ion } from 'cesium';
import { dummyCredit } from 'views/FleetOverview/components/AssetMap';
import { coordsMap } from 'utils/helpers/general';
import { getEnvVariable } from 'utils/helpers/getEnvVariable';
import useFullscreenChange from 'hooks/geoMaps';
import { Fragment, useEffect, useRef } from 'react';
import PulsatingPointGraphics from './PulsatingPointGraphics';

interface Props {
  selectedData: any;
  selectedPatchSequence: any;
  code: string;
  filteredPatches: any;
  isRowClicked: boolean;
  setIsRowClicked: any;
}

const RoadConditionMap = ({
  selectedData,
  selectedPatchSequence,
  code,
  filteredPatches,
  isRowClicked,
  setIsRowClicked,
}: Props) => {
  const viewerRef = useRef<any>(null);
  const defaultCoords: number[] = coordsMap[code] || coordsMap.default;
  const flattenedCoordinates = selectedPatchSequence?.flatMap(
    ([lat, lon]: any) => [lon, lat, 0]
  );

  Ion.defaultAccessToken = getEnvVariable(
    'REACT_APP_CESIUM_ION_DEFAULT_ACCESS_TOKEN'
  );
  useFullscreenChange(viewerRef);

  const homeCoordinates = {
    longitude: selectedData
      ? selectedData.patchSequence[0][1]
      : defaultCoords[1],
    latitude: selectedData
      ? selectedData.patchSequence[0][0]
      : defaultCoords[1],
    altitude: isRowClicked ? 1000 : 2500,
  };

  useEffect(() => {
    const initializeHomeButton = () => {
      if (viewerRef.current ?? viewerRef.current.cesiumElement) {
        const viewer = viewerRef.current.cesiumElement;

        if (viewer.homeButton) {
          // Override the home button behavior
          viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
            (event: any) => {
              event.cancel = true; // Prevent the default home action
              setIsRowClicked(false);
              viewer.camera.flyTo({
                destination: Cartesian3.fromDegrees(
                  homeCoordinates.longitude,
                  homeCoordinates.latitude,
                  homeCoordinates.altitude
                ),
                duration: 2,
              });
            }
          );
        }
      }
    };

    // Delay the setup to ensure the viewer is fully initialized
    const timeout = setTimeout(initializeHomeButton, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [homeCoordinates]);

  const handleHomeClick = () => {
    const viewer = viewerRef.current?.cesiumElement;
    setIsRowClicked(false);
    if (viewer) {
      viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(
          homeCoordinates.longitude,
          homeCoordinates.latitude,
          homeCoordinates.altitude
        ),
        duration: 2,
      });
    }
  };

  return (
    <DetailsBar>
      <DetailsBarGeolocation>
        <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
          <Viewer
            creditContainer={dummyCredit}
            timeline={false}
            navigationHelpButton={false}
            homeButton={true}
            animation={false}
            ref={viewerRef}
            geocoder={false}
          >
            <div
              style={{
                position: 'absolute',
                top: 10,
                left: 10,
                zIndex: 1,
                backgroundColor: '#ABABE2',
                padding: '5px 10px',
                borderRadius: '5px',
                cursor: 'pointer',
                boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
                color: 'black',
              }}
              onClick={handleHomeClick}
            >
              Return to default view
            </div>
            {selectedData ? (
              <CameraFlyTo
                duration={5}
                destination={Cartesian3.fromDegrees(
                  homeCoordinates.longitude,
                  homeCoordinates.latitude,
                  homeCoordinates.altitude
                )}
              />
            ) : (
              <CameraFlyTo
                duration={5}
                destination={Cartesian3.fromDegrees(
                  homeCoordinates.longitude,
                  homeCoordinates.latitude,
                  homeCoordinates.altitude
                )}
              />
            )}

            {selectedPatchSequence?.map((path: any, index: number) => {
              return (
                <Entity
                  key={index}
                  name={`${selectedData.roadConditionId}`}
                  // description={`Path Sequence ${index + 1}`}
                  position={Cartesian3.fromDegrees(path[1], path[0], 0)}
                >
                  {/* <PolylineGraphics
                  positions={Cartesian3.fromDegreesArrayHeights(
                    flattenedCoordinates
                  )}
                  width={4}
                  material={
                    new PolylineDashMaterialProperty({
                      color:
                        selectedData?.dataJson[0].color === 'red'
                          ? Color.RED
                          : selectedData?.dataJson[0].color === 'yellow'
                          ? Color.YELLOW
                          : selectedData?.dataJson[0].color === 'orange'
                          ? Color.ORANGE
                          : Color.GREENYELLOW,
                    })
                  }
                  clampToGround={true}
                /> */}
                  <PulsatingPointGraphics
                    color={
                      selectedData?.dataJson[0].color === 'red'
                        ? Color.RED
                        : selectedData?.dataJson[0].color === 'yellow'
                        ? Color.YELLOW
                        : selectedData?.dataJson[0].color === 'orange'
                        ? Color.ORANGE
                        : Color.GREENYELLOW
                    }
                    pixelSize={10}
                  />
                </Entity>
              );
            })}

            {/* more patches selected */}
            {isRowClicked
              ? null
              : filteredPatches
                  // .slice(1, Math.min(4, filteredPatches.length))
                  .map((patch: any, patchIndex: any) => (
                    <Fragment key={patchIndex}>
                      {patch.patchSequence.map((path: any, index: number) => (
                        <Entity
                          key={index}
                          name={`${patch.roadConditionId}`}
                          position={Cartesian3.fromDegrees(path[1], path[0], 0)}
                        >
                          <PointGraphics
                            color={
                              patch?.dataJson[0].color === 'red'
                                ? Color.RED
                                : patch?.dataJson[0].color === 'yellow'
                                ? Color.YELLOW
                                : patch?.dataJson[0].color === 'orange'
                                ? Color.ORANGE
                                : Color.GREENYELLOW
                            }
                            pixelSize={5}
                          />
                        </Entity>
                      ))}
                    </Fragment>
                  ))}
          </Viewer>
        </div>
      </DetailsBarGeolocation>
    </DetailsBar>
  );
};

export default RoadConditionMap;
