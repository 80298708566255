import * as React from 'react';
import { useAppSelector } from 'store/hook';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import {
  Rule,
  Status,
  DeleteNotification,
  DeactivateNotification,
  AlertDelivery,
  LastEdited,
  NotificationConditions,
  AppliesTo,
  SeverityDisplay,
  notificationsConditionsValueGetter,
  alertDeliveryValueGetter,
} from './NotificationListCellRenderer';
import { UpdateRuleModal } from './modals/updateRuleModal';
import RuleHandler from 'handlers/rule.handler';
import { DeleteRuleModal } from './modals/DeleteRule';

enum OpenModal {
  None,
  EditRule,
  DeleteRule,
}

interface Props {
  exportTo: boolean;
  handleExport: (val: boolean) => void;
  enableDarkTheme: boolean;
  selectedViewOption: string;
}

export const NotificationList = ({
  exportTo,
  handleExport,
  enableDarkTheme,
  selectedViewOption,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const ruleHandler = new RuleHandler();
  const [selectedRuleId, setSelectedRuleId] = React.useState('');
  const { rules } = useAppSelector((state) => state.ruleReducer);
  const filteredRules = rules.filter((rule) => {
    return !rule.isDeleted && selectedViewOption === 'active'
      ? rule.isActive
      : selectedViewOption === 'inactive'
      ? !rule.isActive
      : selectedViewOption === 'all'
      ? rule.isActive || !rule.isActive
      : null;
  });
  const [modalOpen, setModalOpen] = React.useState(OpenModal.None);
  const [columnDefs] = React.useState([
    {
      field: 'ruleName',
      headerName: 'Rule Name',
      width: 340,
      cellRenderer: Rule,
      cellRendererParams: {
        field: 'ruleId',
        handleRuleNameClick: async (ruleId: string) => {
          setSelectedRuleId(ruleId);
          setModalOpen(OpenModal.EditRule);
        },
      },
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'bumperNumbers',
      headerName: 'Applies To',
      width: 300,
      cellRenderer: AppliesTo,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'conditions',
      headerName: 'Condition(s)',
      suppressSizeToFit: true,
      valueGetter: (params: any) =>
        notificationsConditionsValueGetter(params, unitSystem),
      cellRenderer: NotificationConditions,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'notificationLevel',
      headerName: 'Severity',
      cellRenderer: SeverityDisplay,
      sortable: true,
    },
    {
      field: 'alertByEmail',
      headerName: 'Alert Delivery Method(s)',
      maxWidth: 350,
      cellRenderer: AlertDelivery,
      valueGetter: alertDeliveryValueGetter,
      sortable: true,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      maxWidth: 250,
      cellRenderer: Status,
      sortable: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Edited',
      maxWidth: 250,
      cellRenderer: LastEdited,
      sortable: true,
    },
    {
      field: 'ruleId',
      headerName: 'Activate/Deactivate',
      maxWidth: 280,
      cellStyle: { textAlign: 'center' },
      cellRenderer: DeactivateNotification,
      cellRendererParams: {
        field: 'ruleId',
        handleRuleStatusClick: async (ruleId: string) => {
          await ruleHandler.updateRuleStatus(ruleId);
        },
      },
    },
    {
      field: 'ruleId',
      headerName: 'Delete',
      maxWidth: 280,
      cellStyle: { textAlign: 'center' },
      cellRenderer: DeleteNotification,
      cellRendererParams: {
        field: 'ruleId',
        handleRuleStatusClick: async (ruleId: string) => {
          setSelectedRuleId(ruleId);
          setModalOpen(OpenModal.DeleteRule);
        },
      },
    },
  ]);

  const selectedRule = filteredRules.find(
    (rule) => rule.ruleId === selectedRuleId
  );

  return (
    <>
      {selectedRule && modalOpen === OpenModal.DeleteRule && (
        <DeleteRuleModal
          open={modalOpen === OpenModal.DeleteRule}
          key={`${modalOpen.toString()}-delete-rule-modal}`}
          rule={selectedRule}
          handleAbort={() => {
            setModalOpen(OpenModal.None);
          }}
          enableDarkTheme={enableDarkTheme}
        />
      )}
      {selectedRule && modalOpen === OpenModal.EditRule && (
        <>
          <UpdateRuleModal
            key={`${modalOpen.toString()}-edit-rule-modal`}
            open={modalOpen === OpenModal.EditRule}
            handleAbort={() => {
              setModalOpen(OpenModal.None);
            }}
            title="Edit Rule"
            ruleData={selectedRule}
            enableDarkTheme={enableDarkTheme}
          />
        </>
      )}
      <PaddedListWrapper>
        <BaseTableList
          rowData={filteredRules}
          columnDefs={columnDefs}
          exportTo={exportTo}
          handleExport={handleExport}
        />
      </PaddedListWrapper>
    </>
  );
};
