import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { type Asset } from 'types/asset.types';
import LoadingDots from '../../LoadingDots';
import { truncateTo2dp } from 'utils/helpers/general';
import { getFuelUsageDiff } from '../../utils';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import { ThemePalette } from 'mui.theme';
import { SWToolTip } from 'components/SWToolTip';

interface Props {
  asset: Asset;
  enableDarkTheme: boolean;
  isLoadingAllAssets: boolean;
  dateComparer: string;
}

const AssetCardUtilization = ({
  asset,
  enableDarkTheme = false,
  isLoadingAllAssets,
  dateComparer,
}: Props) => {
  const assetDiff = getFuelUsageDiff(
    asset.fuelUsage,
    asset.utilization,
    asset.productivity,
    dateComparer
  );

  const navigate = useNavigate();
  const type = assetTypeMappings[asset.assetType];
  return (
    <SWToolTip
      title={`Productive Hours: ${truncateTo2dp(
        asset.utilization?.engine_working_minute !== undefined
          ? asset.utilization.engine_working_minute / 60
          : 0
      )} hrs | Click for drill-down to view utilization for ${
        asset.bumperNumber
      }`}
    >
      <div
        onClick={() => {
          if (!isLoadingAllAssets) {
            navigate(`/utilization`, {
              state: {
                type,
                value: 0,
                device: asset.device.deviceId,
              },
            });
          }
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={{
            fontSize: '11px',
          }}
        >
          Utilization
        </Typography>
        <Typography
          style={{
            fontSize: '16px',
          }}
        >
          {asset.utilization?.util_score ? (
            <>{truncateTo2dp(asset.utilization?.util_score)}%</>
          ) : (
            <>
              {isLoadingAllAssets ? (
                <LoadingDots enableDarkTheme={enableDarkTheme} />
              ) : (
                <>n/a</>
              )}
            </>
          )}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {assetDiff?.utilization ? (
            <>
              {assetDiff.utilization < 0 ? (
                <CallReceivedIcon
                  sx={{
                    paddingTop: '3px',
                    paddingRight: '5px',
                    fontSize: '10px',
                    color: ThemePalette.typography.lightDanger,
                  }}
                />
              ) : (
                <CallMadeIcon
                  sx={{
                    paddingTop: '3px',
                    paddingRight: '5px',
                    fontSize: '10px',
                    color: ThemePalette.typography.lightSuccess,
                  }}
                />
              )}
              <span
                style={{
                  color:
                    assetDiff.utilization < 0
                      ? ThemePalette.typography.lightDanger
                      : ThemePalette.typography.lightSuccess,
                }}
              >
                {truncateTo2dp(Math.abs(assetDiff.utilization))}%
              </span>
            </>
          ) : (
            <>
              {isLoadingAllAssets ? (
                <LoadingDots enableDarkTheme={enableDarkTheme} />
              ) : (
                <>n/a</>
              )}
            </>
          )}
        </Typography>
      </div>
    </SWToolTip>
  );
};

export default AssetCardUtilization;
