import { Grid } from '@mui/material';
import CardAnalytics from 'views/Production/components/CardAnalytics/CardAnalytics';
import type {
  utilizationFleetScore,
  FleetSummary,
  chartDataResponseItem,
} from 'types/utilization.types';
import { convertsecondsToHours, ShiftDetails } from 'views/Utilization/utils';
import { truncateTo2dp } from 'utils/helpers/general';
import {
  evaluateNumber,
  numberEvaluationKeyMappings,
  getFleetKpiThreshold,
} from 'views/FleetOverview/components/AssetCardsV2/utils';

interface Props {
  enableDarkTheme: boolean;
  isLoading: boolean;
  unitSystem: string;
  assetOverview: any;
  summary: FleetSummary;
  score: utilizationFleetScore;
  data: chartDataResponseItem[];
  assetType: 'total' | 'Haul Truck' | 'Loader' | 'Others';
  isSelected: string;
  aggregation?: string;
  customerCode: string;
  shiftNumber: number;
  shiftWiseAnalysis: boolean;
}

const GridAnalytics = ({
  enableDarkTheme,
  isLoading,
  assetOverview,
  summary,
  score,
  data,
  assetType,
  aggregation,
  isSelected,
  customerCode,
  shiftNumber,
  shiftWiseAnalysis,
}: Props) => {
  const isNegative = (value: number) => value < 0;
  const shiftOptions = ShiftDetails[customerCode] || ShiftDetails.others;
  const shift = shiftOptions[shiftNumber - 1];
  const { startHour, endHour } = shift;
  const shiftHours =
    endHour > startHour ? endHour - startHour : 24 - startHour + endHour;
  const returnAnalysis = (value: number | string) => {
    return `${Math.abs(isNaN(Number(value)) ? 0 : Number(value))}% ${
      isNegative(Number(value)) ? 'less' : 'more'
    }`;
  };

  const entries = Object.values(score).filter(
    (item): item is any => typeof item === 'object' && 'asset_type' in item
  );

  // Create key-value pairs of asset_type and util_score
  const keyValueObject = entries.reduce<Record<string, number>>((acc, item) => {
    acc[item.asset_type] = item.util_score;
    return acc;
  }, {});
  // Calculate the total average util_score
  const totalSum = entries.reduce(
    (sum, item) => Number(sum) + Number(item.util_score),
    0
  );
  const totalCount = entries.length;
  const totalAverage = totalSum / totalCount;

  // Add the total key to the keyValueObject
  keyValueObject.total = totalAverage;

  const kpiThresholds = getFleetKpiThreshold(assetOverview.kpiThresholds);
  const toSnakeCase = (str: string): string =>
    str.toLowerCase().replace(/\s+/g, '_');
  const thresholdMappings = numberEvaluationKeyMappings(assetType);
  const utilScore = keyValueObject?.[toSnakeCase(assetType)];

  const utilizationScore = evaluateNumber(
    utilScore,
    thresholdMappings.utilization,
    kpiThresholds
  );
  const utilizationChart = data.map((item) => {
    return {
      name: item.date,
      value: item.avg_utilization_engine_by_shift_hours * 100,
    };
  });

  const engineHoursChart = data.map((item) => {
    return {
      name: item.date,
      value: item.avg_utilization_engine_active_minute / 60,
    };
  });

  const idlingHoursChart = data.map((item) => {
    return {
      name: item.date,
      value: item.avg_idling_duration / 3600,
    };
  });

  const productiveHoursChart = data.map((item) => {
    return {
      name: item.date,
      value: item.avg_utilization_engine_working_minute / 60,
    };
  });

  const tr = [
    {
      title: 'Utilization',
      pointingUp: !isNegative(summary.utilizationDiff),
      value: truncateTo2dp(summary.utilization),
      unit: '%',
      isGreen: !isNegative(summary.utilizationDiff),
      analysis: returnAnalysis(truncateTo2dp(summary.utilizationDiff)),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: true,
      progressBarValue: utilizationScore,
      chartData: utilizationChart,
    },
    {
      title: `Avg ${
        aggregation?.toLowerCase() === 'daily'
          ? 'Daily'
          : aggregation?.toLowerCase() === 'monthly' &&
            ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? 'Monthly'
          : 'Daily'
      } Engine Hours ${
        shiftWiseAnalysis
          ? `( Shift-${shiftNumber} ${shift.startHour}:00 - ${shift.endHour}:00)`
          : ''
      }`,
      pointingUp: !(summary.engineActiveMinutesDiff < 0),
      value: truncateTo2dp(summary.engineActiveMinutes / 60),
      unit: 'Hrs per asset',
      isGreen: !(summary.engineActiveMinutesDiff < 0),
      analysis: returnAnalysis(truncateTo2dp(summary.engineActiveMinutesDiff)),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      progressBarValue: 78,
      chartData: engineHoursChart,
    },
    {
      title: `Avg ${
        aggregation?.toLowerCase() === 'daily'
          ? 'Daily'
          : aggregation?.toLowerCase() === 'monthly' &&
            ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? 'Monthly'
          : 'Daily'
      } Idling Hours ${
        shiftWiseAnalysis
          ? `( Shift-${shiftNumber} ${shift.startHour}:00 - ${shift.endHour}:00)`
          : ''
      }`,
      pointingUp: !(summary.idlingDiff < 0),
      value: truncateTo2dp(convertsecondsToHours(summary?.idling)),
      unit: 'Hrs per asset',
      isGreen: summary.idlingDiff < 0,
      analysis: returnAnalysis(truncateTo2dp(summary.idlingDiff)),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      progressBarValue: 78,
      chartData: idlingHoursChart,
    },
    {
      title: `Avg ${
        aggregation?.toLowerCase() === 'daily'
          ? 'Daily'
          : aggregation?.toLowerCase() === 'monthly' &&
            ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? 'Monthly'
          : 'Daily'
      } Productive Hours ${
        shiftWiseAnalysis
          ? `( Shift-${shiftNumber} ${shift.startHour}:00 - ${shift.endHour}:00)`
          : ''
      }`,
      pointingUp: !(summary.engineHoursDiff < 0),
      value: truncateTo2dp(summary.engineHours / 60),
      unit: 'Hrs per asset',
      isGreen: !(summary.engineHoursDiff < 0),
      analysis: returnAnalysis(truncateTo2dp(summary.engineHoursDiff)),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      progressBarValue: 78,
      chartData: productiveHoursChart,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{
        paddingTop: '15px',
      }}
    >
      {tr.map((item, index) => {
        return (
          <Grid item key={index} lg={3} md={6} sm={12} xs={12}>
            <CardAnalytics
              enableDarkTheme={enableDarkTheme}
              analytics={item}
              isLoading={isLoading}
              customerCode={customerCode}
              isSelected={isSelected}
              aggregation={aggregation}
              shiftHours={shiftHours}
              shiftWiseAnalysis={shiftWiseAnalysis}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GridAnalytics;
