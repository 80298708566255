import Grid from '@mui/material/Grid';
import * as React from 'react';
import { type Asset } from 'types/asset.types';
import { AssetCardsDiv } from './AssetCard.styled';
import { notificationLevel } from 'views/FleetOverview/helpers/asset.helpers';

import { CompareAssetModal } from './modals/CompareModal';
import AssetCardItem from './AssetCardItem';
import { Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  filteredAssets: Asset[];
  searchValue: string;
  enableDarkTheme: boolean;
  dateComparer: string;
  cardSort: string;
  isLoadingAllAssets: boolean;
}

const AssetCardV2 = ({
  filteredAssets,
  searchValue,
  enableDarkTheme,
  dateComparer,
  cardSort,
  isLoadingAllAssets,
}: Props) => {
  const [filteredArray, setFilteredArray] = React.useState(filteredAssets);
  const [selectedAssetId, setSelectedAssetId] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  React.useEffect(() => {
    const filteredData = filteredAssets.filter(
      (asset) =>
        asset.assetType.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.model.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.bumperNumber.toLowerCase().includes(searchValue.toLowerCase())
    );

    if (cardSort === 'severity') {
      filteredData.sort((a, b) => {
        const level1 = notificationLevel(a.notifications).level;
        const level2 = notificationLevel(b.notifications).level;
        return level2 - level1;
      });
    }

    if (cardSort === 'performance') {
      filteredData.sort((a, b) => {
        return (
          (b.productivity?.prod_score ?? 0) - (a.productivity?.prod_score ?? 0)
        );
      });
    }

    if (cardSort === 'utilization') {
      filteredData.sort((a, b) => {
        return (
          (b.utilization?.util_score ?? 0) - (a.utilization?.util_score ?? 0)
        );
      });
    }

    setFilteredArray(filteredData);
  }, [searchValue, filteredAssets, cardSort]);

  function handleAssetSelected(assetId: string) {
    setSelectedAssetId(assetId);
    setShowModal(true);
  }

  return (
    <>
      {showModal ? (
        <CompareAssetModal
          key="compre-assets-modal"
          title="Compare Assets"
          handleAbort={() => {
            setShowModal(false);
          }}
          open={showModal}
          enableDarkTheme={enableDarkTheme}
          selectedAsset={[selectedAssetId]}
          filteredAssets={filteredArray}
        />
      ) : (
        <></>
      )}
      <AssetCardsDiv>
        <Typography
          sx={{
            marginBottom: '4px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontSize: '12px',
          }}
        >
          ** Stats shown below are last known values for these assets
        </Typography>
        <Grid
          container
          spacing={2}
          id="assetCards"
          sx={{
            paddingRight: '15px',
            paddingBottom: '15px',
          }}
        >
          {filteredArray.map((asset) => {
            return (
              <AssetCardItem
                key={asset.assetId}
                asset={asset}
                enableDarkTheme={enableDarkTheme}
                checkedList={[]}
                showCheckedButton={false}
                dateComparer={dateComparer}
                handleAssetSelected={handleAssetSelected}
                isLoadingAllAssets={isLoadingAllAssets}
              />
            );
          })}
        </Grid>
      </AssetCardsDiv>
    </>
  );
};

export default AssetCardV2;
