import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import AssetItem from './AssetItem';
import type { productionAsset } from 'types/production.types';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  data: productionAsset[];
  isLoading: boolean;
  unitSystem: string;
  customerCode: string;
  isPrinting: boolean;
}

const options = [
  'Production',
  'Production Rate',
  'Utilization',
  'Fuel',
  'Idling Hours',
  'Productive Hours',
];

const sortOptions = ['Least Production', 'Most Production'];

const AssetWrapper = ({
  enableDarkTheme,
  title,
  data,
  isLoading,
  unitSystem,
  customerCode,
  isPrinting,
}: Props) => {
  const [selectedSource, setSelectedSource] = useState<string>('Production');
  const [selectedSort, setSelectedSort] = useState<string>('Most Production');

  const sortedData = data?.sort((a, b) => {
    if (selectedSort === 'Least Production') {
      return a.max_load_sum - b.max_load_sum;
    }
    return b.max_load_sum - a.max_load_sum;
  });

  return (
    <Grid item lg={6} md={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: '600',
                paddingTop: '5px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '600',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                paddingRight: '15px',
                paddingTop: '5px',
              }}
            >
              Show
            </Typography>

            <ProductionSelector
              enableDarkTheme={enableDarkTheme}
              options={options}
              selected={selectedSource}
              setSelected={setSelectedSource}
              minWidth="150px"
            />

            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '600',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                paddingLeft: '10px',
                paddingRight: '15px',
                paddingTop: '5px',
              }}
            >
              Sort By
            </Typography>

            <ProductionSelector
              enableDarkTheme={enableDarkTheme}
              options={sortOptions}
              selected={selectedSort}
              setSelected={setSelectedSort}
              minWidth="150px"
            />
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: '20px',
          }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="200px" />
          ) : (
            <>
              {sortedData?.length < 1 ? (
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    fontSize: '11px',
                  }}
                >
                  No assets available to display
                </Typography>
              ) : (
                <>
                  {sortedData?.map((item, index) => {
                    const isFifthItem = (index + 1) % 4 === 0; // Check if it's every fifth item
                    return (
                      <div
                        key={index}
                        style={
                          isFifthItem && isPrinting
                            ? { marginBottom: '45%' }
                            : {}
                        }
                      >
                        <AssetItem
                          enableDarkTheme={enableDarkTheme}
                          data={item}
                          key={index}
                          selectedSource={selectedSource}
                          unitSystem={unitSystem}
                          customerCode={customerCode}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default AssetWrapper;
