import { useState } from 'react';
import { addHours } from 'date-fns';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { ThemePalette } from 'mui.theme';
import Selector from './Selector';
import { Chart } from './Chart';
import type { Asset } from 'types/asset.types';
import { VehicleSelector } from './Components/VehicleSelector';
import { tireCodeNames } from 'views/TirePressure/util';
import CompareContent from './CompareContent';

export const Content = ({
  enableDarkTheme,
  selected,
  setSelected,
  tireData,
  selectedAsset,
  limits,
  dataType,
  setDataType,
  startDateTime,
  endDateTime,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  unitSystem,
  metricType,
  setMetricType,
  comparingAsset,
  assets,
}: {
  enableDarkTheme: boolean;
  selected: string;
  setSelected: any;
  limits: any;
  tireData: any;
  selectedAsset: Asset | undefined;
  dataType: string;
  setDataType: any;
  startDateTime: any;
  endDateTime: any;
  handleStartDateTimeChange: any;
  handleEndDateTimeChange: any;
  unitSystem: string;
  metricType: string;
  setMetricType: any;
  comparingAsset: string;
  assets: Asset[];
}) => {
  const getEndDateTime = (start: any) => {
    return addHours(start, 4);
  };

  const maxEndTime = getEndDateTime(startDateTime);
  let assetTireData = { pressureData: {} };

  if (selectedAsset) {
    assetTireData = tireData[selectedAsset?.device.deviceId] ?? {
      pressureData: {},
    };
  }

  const hasData = (tagData: any) => {
    if (Object.values(tireData).length === 0 || !tagData.pressureData) {
      return false;
    }
    return Object.values(tagData.pressureData).some(
      (array: any) => array.length > 0
    );
  };

  const [profiles, setProfiles] = useState<string[]>(
    Object.keys(assetTireData.pressureData)
  );

  const colors = [
    '#039161',
    '#59BFAF',
    '#F08733',
    '#327FF1',
    '#D301D7',
    '#EB5758',
  ];

  let tireCodes = {};
  if (selectedAsset) {
    // @ts-expect-error ignore const defined
    tireCodes = tireCodeNames[selectedAsset?.assetType];
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={5} sm={12} md={6} xs={12}>
          <Box
            sx={{
              border: '1px solid #2F445E',
              borderRadius: '4px',
              padding: '10px 10px 5px 10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: '10px',
              }}
            >
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                {`Tire ${metricType} Trend for  ${
                  selectedAsset ? selectedAsset.bumperNumber : ''
                }`}
              </Typography>
              <Box>
                <Button
                  size="small"
                  sx={{
                    border: '1px solid #2B53BC',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    borderRadius: '5px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    textTransform: 'none',
                    marginLeft: '10px',
                    marginRight: '10px',
                    marginTop: '2px',
                  }}
                  onClick={() => {
                    setDataType(
                      dataType === 'regular' ? 'highGrain' : 'regular'
                    );
                  }}
                >
                  <ShowChartIcon
                    sx={{
                      paddingRight: '8px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      fontSize: '16px',
                    }}
                  />
                  {dataType === 'regular'
                    ? 'View High Grain Data'
                    : 'View Regular Data'}
                </Button>

                {dataType === 'regular' && (
                  <Selector
                    enableDarkTheme={enableDarkTheme}
                    options={['Last 7 Days', 'Last 2 Weeks', 'Last 6 Months']}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
              </Box>
            </Box>

            {dataType !== 'regular' && (
              <Box>
                <label
                  htmlFor="start-datetime"
                  style={{
                    fontSize: '12px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.black,
                  }}
                >
                  From:
                </label>{' '}
                <DatePicker
                  id="start-datetime"
                  selected={startDateTime}
                  onChange={handleStartDateTimeChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  // maxTime={
                  //   dayjs(startDateTime).isSame(dayjs(), 'day')
                  //     ? dayjs().toDate()
                  //     : dayjs().endOf('day').toDate()
                  // }
                  // minTime={dayjs().startOf('day').toDate()}
                  maxDate={dayjs().toDate()}
                  minDate={dayjs('2000-01-01').toDate()}
                  dateFormat="yyyy-MM-dd HH:mm"
                  className={
                    enableDarkTheme
                      ? 'custom-datetimepicker-prod'
                      : 'custom-datetimepicker-prod-light-theme'
                  }
                />{' '}
                <label
                  htmlFor="end-datetime"
                  style={{
                    fontSize: '12px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.black,
                  }}
                >
                  To:
                </label>{' '}
                <DatePicker
                  id="end-datetime"
                  selected={endDateTime}
                  onChange={handleEndDateTimeChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="yyyy-MM-dd HH:mm"
                  minDate={startDateTime}
                  maxDate={maxEndTime}
                  minTime={startDateTime}
                  maxTime={maxEndTime}
                  className={
                    enableDarkTheme
                      ? 'custom-datetimepicker-prod'
                      : 'custom-datetimepicker-prod-light-theme'
                  }
                />
              </Box>
            )}
            {dataType !== 'regular' && (
              <Typography
                sx={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  fontWeight: '400',
                  fontSize: '12px',
                  marginBottom: '20px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                }}
              >
                * only 4 hours time window can be selected
              </Typography>
            )}
            {hasData(assetTireData) && (
              <Box>
                <Chart
                  enableDarkTheme={enableDarkTheme}
                  data={assetTireData}
                  colors={colors}
                  allowedProfiles={profiles}
                  dataType={dataType}
                  unitSystem={unitSystem}
                  tireCodes={tireCodes}
                  metricType={metricType}
                  setMetricType={setMetricType}
                />
                <Box
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {Object.keys(assetTireData?.pressureData).map(
                    (item, index) => {
                      const values = Object.values(assetTireData?.pressureData);
                      // @ts-expect-error ignoring for checks
                      const hasNoData = values[index].length === 0;
                      return (
                        <FormControlLabel
                          key={index}
                          label={
                            <span style={{ fontSize: '12px' }}>
                              {
                                // @ts-expect-error ignore this line
                                tireCodes[item] ?? item
                              }
                            </span>
                          }
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                color: hasNoData
                                  ? ThemePalette.typography.lightGrey
                                  : colors[index],
                                '&.Mui-checked': {
                                  color: hasNoData
                                    ? ThemePalette.typography.lightGrey
                                    : colors[index],
                                },
                                '& svg': {
                                  fontSize: '16px',
                                },
                              }}
                              checked={
                                hasNoData ? false : profiles.includes(item)
                              }
                              onChange={() => {
                                if (hasNoData) {
                                  return;
                                }
                                if (profiles.includes(item)) {
                                  setProfiles(
                                    profiles.filter((e) => e !== item)
                                  );
                                } else {
                                  setProfiles([...profiles, item]);
                                }
                              }}
                            />
                          }
                          sx={{
                            color: hasNoData
                              ? ThemePalette.typography.lightGrey
                              : colors[index],
                          }}
                        />
                      );
                    }
                  )}
                </Box>
              </Box>
            )}
          </Box>
          {!hasData(assetTireData) && (
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                marginTop: '10px',
              }}
            >
              No Tire pressure data available
            </Typography>
          )}
        </Grid>
        <Grid item lg={7} sm={12} md={6} xs={12}>
          {hasData(assetTireData) && (
            <Box
              sx={{
                border: '1px solid #2E435E',
                padding: '10px',
                borderRadius: '4px',
              }}
            >
              <Button
                size="small"
                sx={{
                  border: '1px solid #2B53BC',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  borderRadius: '5px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  textTransform: 'none',
                  marginLeft: '10px',
                  marginRight: '10px',
                  marginTop: '2px',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  setMetricType(
                    metricType === 'Pressure' ? 'Temperature' : 'Pressure'
                  );
                }}
              >
                {metricType === 'Pressure'
                  ? 'View Temperature Data'
                  : 'View Pressure Data'}
              </Button>
              <Grid container spacing={2}>
                <VehicleSelector
                  tireData={assetTireData}
                  limits={limits}
                  assetType={selectedAsset?.assetType ?? ''}
                  enableDarkTheme={enableDarkTheme}
                  unitSystem={unitSystem}
                  tireCodes={tireCodes}
                  metricType={metricType}
                />
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
      {comparingAsset !== '' && (
        <CompareContent
          enableDarkTheme={enableDarkTheme}
          comparingAsset={comparingAsset}
          assets={assets}
          tireData={tireData}
          colors={colors}
          dataType={dataType}
          unitSystem={unitSystem}
          tireCodes={tireCodes}
          metricType={metricType}
          setMetricType={setMetricType}
          limits={limits}
        />
      )}
    </>
  );
};
