import { createSlice } from '@reduxjs/toolkit';
import { type GeoTrip } from 'types/geo.types';

export interface geoState {
  data: {
    latestTripsForEachFleetAsset: GeoTrip[];
  };
  isGeoLoading: boolean;
}

const initialState: geoState = {
  data: {
    latestTripsForEachFleetAsset: [
      {
        cycle_index: '',
        loading_phase: '',
        loading_field: '',
        loading_area: '',
        loading_start_ts: '',
        loading_end_ts: '',
        loading_duration_sec: 0,
        loading_longitude: 0,
        loading_latitude: 0,
        unloading_phase: '',
        unloading_field: '',
        unloading_area: '',
        unloading_start_ts: '',
        unloading_end_ts: '',
        unloading_duration_sec: 0,
        unloading_longitude: 0,
        unloading_latitude: 0,
        device: '',
        loaded_trip_start_ts: '',
        loaded_trip_end_ts: '',
        loaded_trip_duration_sec: 0,
        unloaded_trip_start_ts: '',
        unloaded_trip_end_ts: '',
        unloaded_trip_duration_sec: 0,
        loading_fuel_consumption_l: 0,
        unloading_fuel_consumption_l: 0,
        loaded_trip_fuel_consumption_l: 0,
        unloaded_trip_fuel_consumption_l: 0,
        loaded_trip_travel_distance_km: 0,
        unloaded_trip_travel_distance_km: 0,
        max_load_ton: 0,
        bucket_count: 0,
        process_date: '',
        customer: '',
        loading_start_unix_ts: 0,
        trip: 0,
        paths: {
          Items: [
            {
              gpslongitude: 0,
              gpslatitude: 0,
              gpsaltitude: 0,
              sample_timestamp: '',
            },
          ],
        },
        loaded_stop_duration: 0,
      },
    ],
  },
  isGeoLoading: false,
};

export const geoSlice = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    setLatestTripsForEachFleetAsset(state, { payload }) {
      state.data.latestTripsForEachFleetAsset = [
        ...state.data.latestTripsForEachFleetAsset,
        ...payload.data,
      ];
      state.isGeoLoading = false;
    },
    setGeoLoading(state, { payload }) {
      state.isGeoLoading = payload;
    },
  },
});

export const { setLatestTripsForEachFleetAsset, setGeoLoading } =
  geoSlice.actions;
export default geoSlice.reducer;
