import { Box, Grid, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ThemePalette } from 'mui.theme';
import { AssetHeader } from './Header';
import type { Asset } from 'types/asset.types';

export const AssetDetail = ({
  enableDarkTheme,
  selectedAsset,
  latestTagData,
  hierarchyInfo,
  setIsSelectModalOpen,
  metricType,
}: {
  enableDarkTheme: boolean;
  selectedAsset: Asset;
  latestTagData: any;
  hierarchyInfo: any;
  setIsSelectModalOpen: any;
  metricType: string;
}) => {
  return (
    <Grid container spacing={2}>
      <AssetHeader
        enableDarkTheme={enableDarkTheme}
        selectedAsset={selectedAsset}
        latestTagData={latestTagData}
        hierarchyInfo={hierarchyInfo}
      />
      <Grid item sm={12} md={12} lg={3} xs={12}>
        <Box
          sx={{
            marginTop: '30px',
          }}
        >
          {metricType === 'Pressure' && (
            <>
              <Button
                sx={{
                  border: '1px solid #3666EC',
                  textTransform: 'none',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  fontSize: '12px',
                  padding: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
                onClick={() => {
                  setIsSelectModalOpen(true);
                }}
              >
                <AddCircleIcon
                  fontSize="small"
                  sx={{
                    paddingRight: '5px',
                    fontSize: '14px',
                  }}
                />
                Set tire pressure limits or map sensors
              </Button>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
