import styled from 'styled-components';

export const DetailsBar = styled.div`
  display: flex;
  margin: 10px 0px;
`;

export const DetailsBarGeolocation = styled.div`
  background-color: #092441;
  width: 100%;
  padding: 10px;
  color: #fff;
  align-content: center;
  border-radius: 5px;
`;
