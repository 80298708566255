import DtcAPI from 'api/dtc.api';
import { getAll, setLoading } from 'store/dtc.slice';
import type { DTC } from 'types/dtc.types';

import { BaseHandler } from './base.handler';

export default class DtcHandler extends BaseHandler {
  private readonly api: DtcAPI;

  constructor() {
    super();

    this.api = new DtcAPI();
  }

  async get(): Promise<DTC[]> {
    try {
      const cacheKey = `dtc_cache`;
      const cachedData = sessionStorage.getItem(cacheKey);

      const payload = {
        startDate: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split('T')[0], // 5 days ago
        endDate: new Date().toISOString().split('T')[0], // Current day
      };

      if (cachedData) {
        const data = JSON.parse(cachedData);

        // Check if the cache is stale (older than 24 hours)
        const cacheTimestamp = new Date(data.timestamp);
        const currentTime = new Date();
        const hoursDifference =
          (currentTime.getTime() - cacheTimestamp.getTime()) / (1000 * 60 * 60);

        if (hoursDifference < 24) {
          this.dispatch(getAll(data));

          return data;
        }
      }
      this.dispatch(setLoading(true));
      const dtcData = await this.api.getAll(payload);
      sessionStorage.setItem(
        cacheKey,
        JSON.stringify({ ...dtcData, timestamp: new Date() })
      );

      this.dispatch(getAll(dtcData));

      return dtcData;
    } catch (_) {
      this.dispatch(setLoading(false));
      this.handleError('An error occurred while fetching dtc data.');
    }

    return [];
  }

  async getAll(payload: {
    startDate: string;
    endDate: string;
  }): Promise<DTC[]> {
    this.dispatch(setLoading(true));
    try {
      const dtcData = await this.api.getAll(payload);

      this.dispatch(getAll(dtcData));

      return dtcData;
    } catch (_) {
      this.dispatch(setLoading(false));
      this.handleError('An error occurred while fetching dtc data.');
    }

    return [];
  }
}
