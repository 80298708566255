import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { type RootState } from 'store';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { modalInputFieldStyle } from 'styles/global.css';
import RuleHandler from 'handlers/rule.handler';
import type { Notification } from 'types/notification.types';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { createRuleGeneralInfo } from 'utils/schemas/rule.schema';
import { NOTIFICATION_LEVEL } from 'utils/enums';
import {
  handleSelectNotificationEmail,
  handleSelectAllNotificationEmail,
  handleSelectAllNotificationSms,
  handleSelectNotificationSms,
} from 'views/Settings/Rules/helpers/common';
import type { SelectOptions } from 'types/utils.types';
import { ThemePalette } from 'mui.theme';
import { useAppSelector } from 'store/hook';

interface Props {
  open: boolean;
  handleAbort: () => void;
  title: string;
  ruleData: Notification;
  confirmCancellation?: boolean;
  enableDarkTheme: boolean;
}

/**
 *
 * The UpdateRuleModal modal is a modal
 * that is used to update rule's information.
 */
export const UpdateRuleModal = ({
  open,
  handleAbort,
  title,
  ruleData,
  enableDarkTheme,
}: Props) => {
  const ruleHandler = new RuleHandler();
  const { rules } = useAppSelector((state) => state.ruleReducer);

  const { externalUserList: externalUserListState } = useSelector(
    (state: any) => state.externalUserReducer
  );

  const { internalUserList: internalUserListState } = useSelector(
    (state: RootState) => state.internalUserReducer
  );

  const userRole = useSelector((state: RootState) => state.authReducer.role);

  // Memoize user lists to prevent unnecessary recalculations
  const userEmails: SelectOptions[] = useMemo(() => {
    if (userRole === 'external-user' || userRole === 'external-admin') {
      return externalUserListState.map((eUser: any) => ({
        id: eUser.userId,
        display: eUser.email,
      }));
    }
    return [
      ...externalUserListState.map((eUser: any) => ({
        id: eUser.userId,
        display: eUser.email,
      })),
      ...internalUserListState.map((iUser) => ({
        id: iUser.userId,
        display: iUser.email,
      })),
    ];
  }, [externalUserListState, internalUserListState, userRole]);

  const userPhones: SelectOptions[] = useMemo(() => {
    const usersWithPhones =
      userRole === 'external-user' || userRole === 'external-admin'
        ? externalUserListState.map((eUser: any) => ({
            id: eUser.userId,
            display: eUser.phoneNumber ?? '',
            name: `${eUser.firstName} ${eUser.lastName}`,
          }))
        : [
            ...externalUserListState.map((eUser: any) => ({
              id: eUser.userId,
              display: eUser.phoneNumber ?? '',
              name: `${eUser.firstName} ${eUser.lastName}`,
            })),
            ...internalUserListState.map((iUser) => ({
              id: iUser.userId,
              display: iUser.phoneNumber ?? '',
              name: `${iUser.firstName} ${iUser.lastName}`,
            })),
          ];

    return usersWithPhones
      .filter((item: any) => item.display !== '')
      .map((item: any) => ({
        id: item.id,
        display: `${item.name} (+${item.display})`,
      }));
  }, [externalUserListState, internalUserListState, userRole]);

  const [isRuleNameError, setIsRuleNameError] = useState({
    length: false,
    duplicate: false,
  });

  const [rule, setRule] = useState<{
    ruleName: string;
    notificationLevel: any;
    alertByEmail: boolean;
    alertBySms: boolean;
    alertByUI: boolean;
    alertByNotificationCenter: boolean;
    distributionUsers: any;
    smsUsers: any;
  }>({
    ruleName: ruleData.ruleName,
    notificationLevel: ruleData.notificationLevel,
    alertByEmail: ruleData.alertByEmail,
    alertBySms: ruleData.alertBySms,
    alertByNotificationCenter: ruleData.alertByNotificationCenter,
    alertByUI: ruleData.alertByUI,
    distributionUsers: userEmails.filter((item) =>
      ruleData.distributionUsers.includes(item.id)
    ),
    smsUsers: userPhones.filter((item) => ruleData.smsUsers?.includes(item.id)),
  });

  const [isValidForm, setFormValidity] = useState(false);

  // Memoize form validation to prevent unnecessary validations
  const validateForm = useCallback(async () => {
    const isValid = await validateYupSchema(rule, createRuleGeneralInfo);
    setFormValidity(isValid);
  }, [rule]);

  useEffect(() => {
    setRule(ruleData);
  }, [ruleData]);

  useEffect(() => {
    void validateForm();
  }, [validateForm]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const isLengthError = value.length > 40;

      setIsRuleNameError({
        duplicate: false,
        length: isLengthError,
      });
      if (rules.some((rule) => rule.ruleName === value)) {
        setIsRuleNameError({ ...isRuleNameError, duplicate: true });
      }

      setRule((prevRule) => ({
        ...prevRule,
        [name]: value,
      }));
    },
    []
  );

  const handleNotificationLevelChange = useCallback((event: any) => {
    setRule((prevRule) => ({
      ...prevRule,
      notificationLevel: event.target.value.toString(),
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    await ruleHandler.updateRule(ruleData.ruleId, rule);
    handleAbort();
  }, [ruleHandler, ruleData.ruleId, rule, handleAbort]);

  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: handleSubmit,
        disabled:
          Object.values(isRuleNameError).some((value) => value) || !isValidForm,
        label: 'SAVE EDITS',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      <SWTextField
        key="ruleName"
        name="ruleName"
        label="Rule Name"
        placeholder="Enter Rule Name"
        TextFieldProps={{
          name: 'ruleName',
          value: rule.ruleName,
          onChange: handleChange,
          InputProps: {
            sx: {
              ...modalInputFieldStyle,
              minWidth: '400px',
            },
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {isRuleNameError.length || isRuleNameError.duplicate ? (
        <Typography
          variant="small"
          sx={{
            paddingTop: '0px',
            color: '#e57373',
          }}
        >
          {isRuleNameError.length
            ? 'Rule name cannot be more than 40 characters'
            : 'Another Rule currently uses this rule name.'}
        </Typography>
      ) : (
        <Typography
          variant="small"
          sx={{
            paddingTop: '0px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Only underscores, spaces and dashes are permitted as special
          characters.
        </Typography>
      )}

      <Selector
        key="notification-level-selector"
        value={rule.notificationLevel}
        onChange={handleNotificationLevelChange}
        selectorOptions={NOTIFICATION_LEVEL}
        selectSx={{
          minWidth: '100px',
          maxWidth: '200px',
          marginRight: '0px',
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/** Checkbox group to get Notification Distribution method */}
      <List>
        <ListItem>
          <Box key="emailNotification">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                />
              }
              checked={rule.alertByEmail}
              label="By Email"
              onChange={() => {
                setRule({
                  ...rule,
                  alertByEmail: !rule.alertByEmail,
                  distributionUsers: !rule.alertByEmail
                    ? rule.distributionUsers
                    : [],
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
          </Box>

          <MultiSelector
            value={rule.distributionUsers}
            renderValue={(selected) => {
              return `${selected?.length || 0} Selected`;
            }}
            handleSelectOne={(event: any) => {
              handleSelectNotificationEmail(event, rule, setRule);
            }}
            handleSelectAll={() => {
              handleSelectAllNotificationEmail(userEmails, rule, setRule);
            }}
            selectorOptions={userEmails}
            minWidth="400px"
            enableDarkTheme={enableDarkTheme}
          />
        </ListItem>
      </List>

      {/** SMS Notification Selector */}
      <List>
        <ListItem>
          <Box key="smsNotification">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              checked={rule.alertBySms}
              label="By SMS"
              onChange={() => {
                setRule({
                  ...rule,
                  alertBySms: !rule.alertBySms,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
          </Box>

          <MultiSelector
            value={rule.smsUsers}
            renderValue={(selected) => {
              return `${selected?.length || 0} Selected`;
            }}
            handleSelectOne={(event) => {
              handleSelectNotificationSms(event, rule, setRule);
            }}
            handleSelectAll={() => {
              handleSelectAllNotificationSms(userPhones, rule, setRule);
            }}
            selectorOptions={userPhones}
            minWidth="400px"
            enableDarkTheme={enableDarkTheme}
          />
        </ListItem>
      </List>

      <List>
        <ListItem>
          <Box key="applicationUi">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                />
              }
              checked={rule.alertByUI}
              label="In Application Interface/UI"
              onChange={() => {
                setRule({
                  ...rule,
                  alertByUI: !rule.alertByUI,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
          </Box>
        </ListItem>
        <ListItem>
          <Box key="NotificationCenter">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                />
              }
              checked={rule.alertByNotificationCenter}
              label="In Notification Center"
              onChange={() => {
                setRule({
                  ...rule,
                  alertByNotificationCenter: !rule.alertByNotificationCenter,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
          </Box>
        </ListItem>
      </List>
    </InteractiveModal>
  );
};
