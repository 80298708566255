import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import TireProfileAPI from 'api/tire-pressure';
import {
  setLimits,
  setDeviceTireData,
  setFleetData,
  setIsLoadingFleet,
  setIsLoadingVehicle,
} from 'store/tireProfile.slice';

export default class TireProfileHandler extends BaseHandler {
  private readonly api: TireProfileAPI;
  constructor() {
    super();

    this.api = new TireProfileAPI();
  }

  /**
   * Gets Fleet Data
   */
  async getFleetTireProfile() {
    this.dispatch(setIsLoadingFleet(true));
    try {
      const data = await this.api.getFleetData();
      this.dispatch(setFleetData(data));
      this.handleSuccess();
      this.dispatch(setIsLoadingFleet(false));
    } catch (error: any) {
      this.dispatch(setIsLoadingFleet(false));
      let msg: string = '';

      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the fleet tire profile data: ' + msg
      );
    }
  }

  /**
   * Gets profile limits
   */
  async get() {
    this.dispatch(updateLoadingState(true));
    try {
      const profiles = await this.api.getTireProfileLimits();
      this.dispatch(setLimits(profiles));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the tire profile limits: ' + msg
      );
    }
  }

  /**
   * Sets profile limits
   */
  async setTireProfileLimits(payload: any) {
    this.dispatch(updateLoadingState(true));
    try {
      const profiles = await this.api.setTireProfileLimits(payload);
      this.dispatch(setLimits(profiles));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while setting the tire profile limits: ' + msg
      );
    }
  }

  /**
   * Gets Asset Tire Data
   */
  async getAssetTireData(
    deviceId: string,
    dataType: string,
    assetType: string
  ) {
    this.dispatch(setIsLoadingVehicle(true));
    try {
      const tireData = await this.api.getAssetTireData(
        deviceId,
        dataType,
        assetType
      );

      this.dispatch(
        setDeviceTireData({
          device: deviceId,
          data: tireData,
        })
      );

      this.handleSuccess();
      this.dispatch(setIsLoadingVehicle(false));
    } catch (error: any) {
      this.dispatch(setIsLoadingVehicle(false));
      let msg: string = '';

      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error?.response?.data?.message as string;
      }

      this.handleError(
        'An error occured while retrieving the asset tire data: ' + msg
      );
    }
  }

  /**
   * Gets Asset Tire Grain Data
   */
  async getAssetTireGrainData(
    deviceId: string,
    dataType: string,
    assetType: string,
    startDate: Date,
    endDate: Date
  ) {
    this.dispatch(setIsLoadingVehicle(true));
    try {
      const tireData = await this.api.getAssetTireGrainData(
        deviceId,
        dataType,
        assetType,
        startDate,
        endDate
      );

      this.dispatch(
        setDeviceTireData({
          device: deviceId,
          data: tireData,
        })
      );

      this.handleSuccess();
      this.dispatch(setIsLoadingVehicle(false));
    } catch (error: any) {
      this.dispatch(setIsLoadingVehicle(false));
      let msg: string = '';

      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the asset tire data: ' + msg
      );
    }
  }
}
