import type {
  fleetDataSummary,
  productionAsset,
  totalFleetDetails,
} from 'types/production.types';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import { truncateTo2dp } from 'utils/helpers/general';

import { percentageLeft } from '../Hauler/component/utils';

export function getHaulerSummary(arg: fleetDataSummary) {
  return [
    {
      name: 'Unloading',
      time: `${truncateTo2dp(convertSeconds(arg.haul_truck_dump ?? 0).value)} ${
        convertSeconds(arg.haul_truck_dump ?? 0).unit
      }`,
      value: arg.haul_truck_dump ?? 0,
      direction: arg.haul_truck_dump_percentage_diff >= 0 ? 'up' : 'down',
      color: '#FF0068',
    },
    {
      name: 'Loading',
      time: `${truncateTo2dp(
        convertSeconds(arg.haul_truck_loading_duration ?? 0).value
      )} ${convertSeconds(arg.haul_truck_loading_duration ?? 0).unit}`,
      value: arg.haul_truck_loading_duration ?? 0,
      direction:
        arg.haul_truck_loading_duration_percentage_diff >= 0 ? 'up' : 'down',
      color: '#A776FF',
    },
    {
      name: 'Unloaded Trip',
      time: `${truncateTo2dp(
        convertSeconds(arg.haul_truck_travel_empty ?? 0).value
      )} ${convertSeconds(arg.haul_truck_travel_empty ?? 0).unit}`,
      value: arg.haul_truck_travel_empty ?? 0,
      direction:
        arg.haul_truck_travel_empty_percentage_diff >= 0 ? 'up' : 'down',
      color: '#165BAA',
    },
    {
      name: 'Loaded Trip',
      time: `${truncateTo2dp(
        convertSeconds(arg.haul_truck_travel_loaded ?? 0).value
      )} ${convertSeconds(arg.haul_truck_travel_loaded ?? 0).unit}`,
      value: arg.haul_truck_travel_loaded ?? 0,
      direction:
        arg.haul_truck_travel_loaded_percentage_diff >= 0 ? 'up' : 'down',
      color: '#FF9C2C',
    },
  ];
}

export function getLoaderSummary(arg: fleetDataSummary) {
  return [
    {
      name: 'Dig',
      time: `${0 / 60} Mins`,
      value: 0,
      direction: 'up',
      color: '#A776FF',
    },
    {
      name: 'Dump',
      time: `${truncateTo2dp((arg.loader_dump ?? 0 ?? 0) / 60)} Mins`,
      value: arg.loader_dump ?? 0,
      direction: 'up',
      color: '#FF0068',
    },
    {
      name: 'Travel Empty',
      time: `${truncateTo2dp((arg.loader_travel_empty ?? 0 ?? 0) / 60)} Mins`,
      value: arg.loader_travel_empty ?? 0,
      direction: 'up',
      color: '#165BAA',
    },
    {
      name: 'Travel Loaded',
      time: `${truncateTo2dp((arg.loader_travel_loaded ?? 0 ?? 0) / 60)} Mins`,
      value: arg.loader_travel_loaded ?? 0,
      direction: 'up',
      color: '#FF9C2C',
    },
  ];
}

export function getProductionAssetsByTypeMapping(
  assets: productionAsset[],
  type: string
) {
  return assets?.filter(
    (asset: productionAsset) => assetTypeMappings[asset.asset_type] === type
  );
}

export function getAssetChartDataSource(selectedSource: string) {
  const type = selectedSource.toLowerCase().replace(/ /g, '');
  if (type === 'production') {
    return 'productionValue';
  }
  if (type === 'productionrate') {
    return 'load_per_hour';
  }

  if (type === 'utilization') {
    return 'utilization_by_shift_hours';
  }

  if (type === 'idlinghours') {
    return 'idling_duration';
  }
  if (type === 'productivehours') {
    return 'productive_duration';
  }
  if (type === 'fuel') {
    return 'fuel_consumption_l';
  }

  return 'max_load_ton';
}

export function getFleetProduction(selectedSource: string) {
  if (selectedSource === 'Last 7 Days') {
    return 'last_7_days';
  }

  if (selectedSource === 'Last 14 Days') {
    return 'last_14_days';
  }

  if (selectedSource === 'Last 4 Weeks') {
    return 'last_1_month';
  }

  if (selectedSource === 'Last 6 Months') {
    return 'last_6_months';
  }

  return 'last_7_days';
}

export function convertSeconds(seconds: number) {
  const units = [
    { name: 'yr', seconds: 31536000 },
    { name: 'wk', seconds: 604800 },
    { name: 'day', seconds: 86400 },
    { name: 'hr', seconds: 3600 },
    { name: 'min', seconds: 60 },
    { name: 'sec', seconds: 1 },
  ];

  for (const unit of units) {
    if (seconds >= unit.seconds || unit.name === 'sec') {
      const value = seconds / unit.seconds;
      return {
        value: parseFloat(value.toFixed(6)),
        unit: unit.name + (value !== 1 ? 's' : ''),
      };
    }
  }

  return { value: 0, unit: 'secs' };
}

export const calculateCurrentDailyAverage = (
  fleetPeriodSummary: any[]
): number => {
  // If the chartData is empty, return 0; otherwise, extract seven-day working minutes and return the average
  if (fleetPeriodSummary?.length > 0) {
    const sevenDayDataObject =
      fleetPeriodSummary?.find(
        (dailyProductionObject) =>
          dailyProductionObject?.time_range === 'last_7_days'
      )?.daily_production ?? {};

    const sevenDayData = Object.values(sevenDayDataObject ?? {}).map(
      (value: any) => +value
    );

    const sevenDayTotalWorkingMinutes = sevenDayData.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );

    return sevenDayTotalWorkingMinutes / sevenDayData?.length;
  }

  return 0;
};

export const hasMetTarget = (data: totalFleetDetails) => data.tons > 0;
export const targetPercentageDiff = (data: totalFleetDetails) =>
  percentageLeft(data.tons, data.annualTarget);
