import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { ThemePalette } from 'mui.theme';

// Function to calculate the position of the arrow
const getArrowPosition = (value: number) => {
  let valueToUse = value;
  if (value === 100) {
    valueToUse = 99.8;
  }
  const position = (valueToUse / 100) * 100; // Calculate percentage
  return `calc(${position}% - 8px)`; // Adjust the position for the arrow
};

export const Guage = ({
  value,
  enableDarkTheme,
  maxValue,
  minValue,
  unitSystem,
  metricType,
  startRange,
  endRange,
}: {
  value: number;
  enableDarkTheme: boolean;
  maxValue: number;
  minValue: number;
  startRange: number;
  endRange: number;
  unitSystem: string;
  metricType: string;
}) => {
  function calculatePercentage(
    currentValue: number,
    minValue: number,
    maxValue: number
  ) {
    if (currentValue < minValue) {
      return 0;
    }
    if (currentValue > maxValue) {
      return 100;
    }
    return ((currentValue - minValue) / (maxValue - minValue)) * 100;
  }

  const greenStart = (minValue / endRange) * 100;
  const greenEnd = (maxValue / endRange) * 100;

  const percentageValue = calculatePercentage(value, startRange, endRange);

  return (
    <Box position="relative" width="100%">
      {/* Arrow */}
      <Typography
        variant="body2"
        style={{
          position: 'absolute',
          top: -10,
          zIndex: 1,
          left: getArrowPosition(percentageValue),
          transform: 'rotate(180deg)', // Rotate the arrow to point downwards
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          opacity: 1,
        }}
      >
        ▲
      </Typography>

      {/* Gradient Progress Bar */}
      <LinearProgress
        variant="determinate"
        value={100} // Set to 100 since the gradient spans the full width
        sx={{
          height: 10,
          borderRadius: 5,
          background: 'lightgray',
          '& .MuiLinearProgress-bar': {
            borderRadius: 5,
            background: `linear-gradient(
              to right,
              #eb5757 0%, 
              #eb5757 ${greenStart - 5}%, 
              #ff7f50 ${greenStart}%, 
              #228b22 ${greenStart + 5}%, 
              #228b22 ${greenEnd - 5}%, 
              #ff7f50 ${greenEnd}%, 
              #eb5757 ${greenEnd + 5}%, 
              #eb5757 100%
            )`,
          },
        }}
      />

      {/* Labels */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 1,
        }}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
            fontSize: '12px',
          }}
        >
          {startRange}
        </Typography>
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
            fontSize: '12px',
          }}
        >
          {endRange}
        </Typography>
      </Box>
    </Box>
  );
};
