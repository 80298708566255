import BaseAPI from './base.api';

export default class GeoAPI extends BaseAPI {
  getTrips = async (deviceId: string, tripStartTime: string) => {
    const response = await this.API.get('geo/gpsTrips', {
      params: {
        deviceId,
        tripStartTime,
      },
    });
    return response.data;
  };

  getAssetTypeTrips = async (tripStartTime: string) => {
    const response = await this.API.get('geo/gpsTripsByAssetTypes', {
      params: {
        tripStartTime,
      },
    });
    return response.data;
  };

  getAssetTypeTripsDateRange = async (
    tripStartTime: string,
    tripEndTime: string
  ) => {
    const response = await this.API.get('geo/gpsTripsByAssetTypesDateRange', {
      params: {
        tripStartTime,
        tripEndTime,
      },
    });
    return response.data;
  };

  getLatestTripsByFleetAssets = async () => {
    const response = await this.API.get('geo/gpsFleetLatestTripsByAsset');
    return response.data;
  };
}
