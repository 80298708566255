import { createSlice } from '@reduxjs/toolkit';

export interface tagThresholdState {
  thresholds: any[];
}

const initialState: tagThresholdState = {
  thresholds: [],
};

export const tagThresholdSlice = createSlice({
  name: 'tagThreshold',
  initialState,
  reducers: {
    setThresholds(state, { payload }) {
      state.thresholds = payload;
    },
  },
});

export const { setThresholds } = tagThresholdSlice.actions;

export default tagThresholdSlice.reducer;
