import { Box, Tabs, Tab } from '@mui/material';
import DateRange from './components/DateRange';
import { ThemePalette } from 'mui.theme';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  enableDarkTheme: boolean;
  ignoreYtd?: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  value: number;
  setHandleChange: any;
  isLoading: boolean;
}

const Header = ({
  value,
  enableDarkTheme,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  setHandleChange,
  isLoading,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <DateRange
        enableDarkTheme={enableDarkTheme}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        yesterday={yesterday}
        handleDateChange={handleDateChange}
        isLoading={isLoading}
      />
      <Box
        sx={{
          marginLeft: '20px',
          marginTop: '8px',
        }}
      >
        <Tabs
          value={value}
          onChange={setHandleChange}
          aria-label="basic tabs"
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#5E85F0',
            },
          }}
        >
          <Tab
            label="Fleet"
            {...a11yProps(0)}
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'capitalize',
            }}
            disabled={isLoading}
          />
          <Tab
            label="Timeline"
            {...a11yProps(1)}
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}
            disabled={isLoading}
          />
        </Tabs>
      </Box>
    </div>
  );
};

export default Header;
