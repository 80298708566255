import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import TableItem from 'views/Production/components/Table/TableItem';

interface Props {
  enableDarkTheme: boolean;
  data: Array<{
    name: string;
    time: number | string;
    direction: string;
    color: string;
  }>;
  unit?: string;
  subItems?: any;
}

const TableList = ({
  enableDarkTheme,
  data,
  unit = 'Hours',
  subItems = {},
}: Props) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ borderBottom: '1px solid #2F445F' }}>
            <Typography
              color={
                enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black
              }
              sx={{
                fontSize: '14px',
              }}
            >
              State
            </Typography>
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: '1px solid #2F445F' }}>
            <Typography
              color={
                enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black
              }
              sx={{
                fontSize: '14px',
              }}
            >
              {unit}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((state, index) => {
          const subItem = subItems[state.name];
          return (
            <TableItem
              enableDarkTheme={enableDarkTheme}
              item={state}
              key={index}
              subItems={subItem}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableList;
