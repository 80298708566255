import { Box, Typography, Button } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ThemePalette } from 'mui.theme';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FlexBox } from 'components/Containers/FlexBox';
import type { DriverBehavior } from 'types/driver.behavior.types';
import { aggregateEvents } from 'utils/driverBehavior';

const ItemChart = ({
  enableDarkTheme,
  title,
  selectedAsset,
  handleChange,
  assetData,
  setSelectedAsset,
}: {
  enableDarkTheme: boolean;
  title: string;
  selectedAsset: string;
  handleChange: any;
  fleetData: DriverBehavior;
  assetData: DriverBehavior[];
  setSelectedAsset: any;
}) => {
  const goToTimeline = () => {
    handleChange(1, selectedAsset);
  };

  let chartData: DriverBehavior[] = assetData;

  if (selectedAsset) {
    const selectedAssetData = assetData.filter(
      (item) => item.bumper_id === selectedAsset
    );
    chartData = selectedAssetData;
  }

  const aggregatedEvents = aggregateEvents(chartData);
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            marginTop: '10px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {selectedAsset !== '' && (
              <ChevronLeftIcon
                sx={{ marginRight: '5px' }}
                fontSize="small"
                onClick={() => {
                  setSelectedAsset('');
                }}
              />
            )}
            Showing {title} for{' '}
            <span
              style={{
                color: '#3666E6',
                fontWeight: '700',
                marginLeft: '5px',
              }}
            >
              {selectedAsset === '' ? 'All Assets' : selectedAsset}
            </span>
          </Typography>

          {/* <Typography
            sx={{
              fontSize: '12px',
              color: ThemePalette.typography.grey,
              paddingTop: '5px',
            }}
          >
            Click on the bar to view details of that day
          </Typography> */}
        </Box>
        <Button
          sx={{
            textAlign: 'center',
            marginTop: '5px',
            borderRadius: '5px',
            height: '45px',
            marginRight: '20px',
            border: '1px solid #3666E6',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textTransform: 'none',
            paddingRight: '20px',
            paddingLeft: '20px',
          }}
          onClick={() => {
            goToTimeline();
          }}
        >
          <LegendToggleIcon
            fontSize="small"
            sx={{
              paddingRight: '4px',
            }}
          />
          View on timeline
        </Button>
      </Box>
      <Box>
        <FlexBox
          sx={{
            maxWidth: 'inherit',
            paddingTop: '20px',
            marginRight: '10px',
            paddingBottom: '10px',
            height: '700px',
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={800}
              height={400}
              data={aggregatedEvents}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={60}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={ThemePalette.dark.cartesianGrid}
                vertical={false}
                opacity={0.7}
              />
              <XAxis
                dataKey="start_ts"
                tick={{ fill: '#9CA3AF', fontSize: 10 }}
                axisLine={{ stroke: '#4B5563' }}
              />
              <YAxis
                tick={{ fill: '#9CA3AF', fontSize: 12 }}
                axisLine={{ stroke: '#4B5563' }}
                label={{
                  value: `${title}`,
                  angle: -90,
                  position: 'inside',
                  fill: '#9CA3AF',
                  dy: 10,
                  dx: -30,
                }}
              />
              <Tooltip
                cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
                content={<CustomTooltip />}
              />
              <Bar dataKey="noOfEvents" fill="#4B7BFA" radius={[4, 4, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </FlexBox>
      </Box>
    </div>
  );
};

export default ItemChart;

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload && payload.length) {
    const { noOfEvents } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: 'rgba(217, 229, 247, 0.8) ',
          border: '1px solid #ccc',
          color: '#000',
          padding: '3px 5px',
          borderRadius: '4px',
        }}
      >
        <p>{`Event Count: ${noOfEvents}`}</p>
      </div>
    );
  }
  return null;
};
