import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Base } from 'components/Containers/BaseContainer';
import SWTab from 'components/Layout/Tabs/SWTab';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import { useAppSelector } from 'store/hook';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import FuelAnalyticsHandler from 'handlers/fuelAnalytics.handler';
import { IdleDashboard } from './IdleDashboard';
import { isDarkTheme } from 'utils/theme';

export const UsageDash = () => {
  const location = useLocation();
  const { type, device } = location.state || {};
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const customerCode = useAppSelector((state) => state.persistedReducer)
    .customer.code;
  const enableDarkTheme = isDarkTheme(theme);
  const fuelAnalyticsHandler = new FuelAnalyticsHandler();

  const [selectedData, setSelectedData] = useState<string | null>(null);
  const [selectedDevice, setSelecedDevice] = useState<string | undefined>(
    device ?? undefined
  );

  const [selectedMakeModel, setSelectedMakeModel] = useState<string>('');

  const [selectedAssetType, setSelectedAssetType] = useState(type ?? 'total');

  const [selectedStartDate, setStartDate] = useState(
    new Date(new Date().getTime() - 4 * 24 * 60 * 60 * 1000)
  );
  const [selectedEndDate, setEndDate] = useState(new Date());

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getIdleData = async () => {
    const startDate = dayjs(selectedStartDate).format('YYYY-MM-DD');
    const endDate = dayjs(selectedEndDate).format('YYYY-MM-DD');
    if (selectedStartDate !== null && selectedEndDate !== null) {
      await fuelAnalyticsHandler.getIdleAnalytics(startDate, endDate);
    }
  };
  useEffect(() => {
    void getIdleData();
    setSelectedData(null);
  }, [selectedEndDate, customerCode]);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Tabs>
        <TabList>
          <ToolbarContainer
            style={{
              paddingBottom: 0,
            }}
            enableDarkTheme={enableDarkTheme}
          >
            <SWTab enableDarkTheme={enableDarkTheme}>Idling Analysis </SWTab>
            <SWTab enableDarkTheme={enableDarkTheme}>
              Productivity Analysis
            </SWTab>
          </ToolbarContainer>
        </TabList>

        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <IdleDashboard
            enableDarkTheme={enableDarkTheme}
            selectedEndDate={selectedEndDate}
            selectedStartDate={selectedStartDate}
            customerCode={customerCode}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            handleDateChange={handleDateChange}
            isProductivity={false}
            type={type}
            selectedDevice={selectedDevice}
            setSelecedDevice={setSelecedDevice}
            selectedMakeModel={selectedMakeModel}
            setSelectedMakeModel={setSelectedMakeModel}
            selectedAssetType={selectedAssetType}
            setSelectedAssetType={setSelectedAssetType}
          />
        </TabPanel>

        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <IdleDashboard
            enableDarkTheme={enableDarkTheme}
            selectedEndDate={selectedEndDate}
            selectedStartDate={selectedStartDate}
            customerCode={customerCode}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            handleDateChange={handleDateChange}
            isProductivity={true}
            type={type}
            selectedDevice={selectedDevice}
            setSelecedDevice={setSelecedDevice}
            selectedMakeModel={selectedMakeModel}
            setSelectedMakeModel={setSelectedMakeModel}
            selectedAssetType={selectedAssetType}
            setSelectedAssetType={setSelectedAssetType}
          />
        </TabPanel>
      </Tabs>
    </Base>
  );
};
