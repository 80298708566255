import { Box, CircularProgress, type SelectChangeEvent } from '@mui/material';
import EngineHoursGraphs from '../Components/EngineHoursGraph';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import QuickRange from '../Components/QuickRange';
import dayjs from 'dayjs';
import EngineHoursHandler from 'handlers/engine.hours.handler';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';
import { useCustomDropdownEffect } from 'utils/exports/hooks';
import { isDarkTheme } from 'utils/theme';
import { selectExportFormat } from 'store/exportData.slice';
import { exportToPdf } from 'utils/exports/pdf';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import { exportEngineHoursToExcel } from 'utils/exports/excel';
import { updateRangeSelected } from 'store/engine.hours.slice';
import { calculateDaysDifference } from 'views/FuelAnalytics/IdleDashboard/utils';
import IndividualEngineHours from '../Components/IndividualEngineHours';
import { formatStartEndDate } from '../utils/utils';

const EngineHoursOverview = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const customerCode = useAppSelector((state) => state.authReducer)?.customer
    ?.code;

  const [today] = useState(new Date());
  const engineHoursHandler = new EngineHoursHandler();

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const [selectedAsset, setSelectedAsset] = useState<string>('');

  const { data, isLoading, rangeSelected } = useAppSelector(
    (state) => state.engineHoursReducer
  );

  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const setRangeSelected = (range: string) => {
    dispatch(updateRangeSelected(range));
  };

  const handleQuickRangeSelect = (range: string) => {
    setRangeSelected(range);
  };

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const [showDailyData, setShowDailyData] = useState(false);

  useEffect(() => {
    // if rangeSelected is 14D or 1M, or if gap between startDate and endDate is greater than 14 days, set showDailyData to true
    if (rangeSelected === '1M') {
      setShowDailyData(true);
    } else if (startDate && endDate && rangeSelected === 'Custom') {
      const daysDifference = calculateDaysDifference(startDate, endDate);
      if (daysDifference > 14) {
        setShowDailyData(true);
      }
    } else {
      setShowDailyData(false);
    }
  }, [rangeSelected, startDate, endDate]);

  const getEngineHourForSelectedDevice = async (
    deviceId: string,
    startApiDate: string,
    endApiDate: string
  ) => {
    const promises = [
      engineHoursHandler.getDeviceEngineHour(
        deviceId,
        startApiDate,
        endApiDate
      ),
      engineHoursHandler.getDeviceEngineHourPerDay(
        deviceId,
        startApiDate,
        endApiDate
      ),
    ];
    await Promise.all(promises);
  };

  /**
   * Handle date range change action.
   * @param startDate - The start date
   * @param endDate - The end date
   * @returns {Promise<void>}
   * This function will be called when both dates are selected. It will fetch the engine hours for the selected device within the date range, and also fetch the engine hours summary within the date range.
   */
  const onDateRangeChangeAction = async (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setStartDate(startDate ?? new Date());
    setEndDate(endDate ?? new Date());
    const startApiDate = dayjs(startDate).format('YYYY-MM-DD');
    const endApiDate = dayjs(endDate).format('YYYY-MM-DD');
    const promises = [];
    promises.push(
      engineHoursHandler.getEngineHoursSummaryWithinDateRange(
        startApiDate,
        endApiDate
      )
    );
    if (selectedDeviceId) {
      promises.push(
        getEngineHourForSelectedDevice(
          selectedDeviceId,
          startApiDate,
          endApiDate
        )
      );
    }
    try {
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  };

  const [exportDropdownTo, setExportDropdownTo] = useState('');

  const [exportToLabel, setExportToLabel] = useState('Export To');

  const [exportTo, setExportTo] = useState(false);
  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportDropdownTo(event.target.value);
  };

  const exportFormat = useAppSelector(selectExportFormat);
  useCustomDropdownEffect(
    exportDropdownTo,
    handleExportClick,
    setExportToLabel,
    setExportDropdownTo,
    [exportDropdownTo],
    ['Engine Hours', 'Page1', 'Engine Hours - ']
  );

  useEffect(() => {
    const exportData = async () => {
      if (exportTo) {
        switch (exportFormat) {
          case 'pdf': {
            await exportToPdf(
              'engine-hour-div',
              `Engine Hours for ${selectedAsset}- ${formatDate(new Date())}`,
              `Engine Hours - ${dayjs(startDate).format(
                'YYYY-MM-DD'
              )} - ${dayjs(endDate).format('YYYY-MM-DD')}`,
              enableDarkTheme,
              'landscape'
            );
            break;
          }
          case 'excel': {
            await exportEngineHoursToExcel({
              dataWithKey: data,
              key: 'engine_hours',
              filename: `Engine Hours`,
            });
            break;
          }
          default: {
            break;
          }
        }
        handleExportClick(false);
      }
    };

    void exportData();
  }, [exportTo, handleExportClick, exportFormat]);

  useEffect(() => {
    if (rangeSelected !== 'Custom') {
      const formattedDates = formatStartEndDate(
        rangeSelected,
        startDate,
        endDate
      );
      if (formattedDates) {
        const { startApiDate, endApiDate } = formattedDates;
        setStartDate(new Date(startApiDate));
        setEndDate(new Date(endApiDate));
      }
    }
  }, [rangeSelected]);

  const loadData = async () => {
    const formattedDates = formatStartEndDate(
      rangeSelected,
      startDate,
      endDate
    );
    console.log({ formattedDates });
    if (!formattedDates) {
      return;
    }
    const { startApiDate, endApiDate } = formattedDates;
    const promises = [];
    promises.push(
      engineHoursHandler.getEngineHoursSummaryWithinDateRange(
        startApiDate,
        endApiDate
      )
    );
    if (selectedDeviceId) {
      promises.push(
        getEngineHourForSelectedDevice(
          selectedDeviceId,
          startApiDate,
          endApiDate
        )
      );
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (!customerCode) return;
    void loadData();
  }, [startDate, endDate, customerCode]);

  const fetchDataforSelectedDeviceId = async () => {
    const formattedDates = formatStartEndDate(
      rangeSelected,
      startDate,
      endDate
    );
    if (!formattedDates) {
      return null;
    }
    const { startApiDate, endApiDate } = formattedDates;
    const promises = [];
    promises.push(
      getEngineHourForSelectedDevice(selectedDeviceId, startApiDate, endApiDate)
    );
    try {
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!selectedDeviceId) return;
    void fetchDataforSelectedDeviceId();
  }, [selectedDeviceId]);

  return (
    <Box width={'100%'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <QuickRange
          isSelected={rangeSelected}
          handleSelect={handleQuickRangeSelect}
          startDate={startDate}
          endDate={endDate}
          handleDateChange={handleDateChange}
          onDateRangeChangeAction={onDateRangeChangeAction}
          maxDate={today}
        />
        <ExportSelector
          view={'list'}
          exportToLabel={exportToLabel}
          exportTo={exportDropdownTo}
          onExportToChange={handleExportDropDownChange}
          enableDarkTheme={enableDarkTheme}
        />
      </div>
      <div
        id="engine-hour-div"
        style={{
          width: '100%',
          height: '520px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLoading.loadingEngineHoursWithinRange ? (
          <CircularProgress color="secondary" />
        ) : (
          <EngineHoursGraphs
            setSelectedAsset={setSelectedAsset}
            setSelectedRow={setSelectedDeviceId}
          />
        )}
      </div>
      {data && data.length > 0 && !isLoading.loadingEngineHoursWithinRange && (
        <IndividualEngineHours
          showDailyData={showDailyData}
          startDate={startDate}
          endDate={endDate}
          selectedAsset={selectedAsset}
        />
      )}
    </Box>
  );
};

export default EngineHoursOverview;
