import { Box } from '@mui/material';
import TreeMapDiv from './TreeMapDiv';
import RightCards from './RightCards';
import AssetDetail from './AssetDetail';
import { useState } from 'react';

const BodyDiv = ({
  enableDarkTheme,
  deviceData,
  fuelMeasure,
  baseCost,
  allFleetData,
  selectedAssetType,
  isLoading,
  unitSystem,
  fuelUnit,
}: {
  enableDarkTheme: boolean;
  deviceData: any;
  fuelMeasure: 'consumption' | 'co2 emission' | 'cost';
  baseCost: {
    diesel: number;
    gas: number;
  };
  allFleetData: any;
  selectedAssetType: string;
  isLoading: boolean;
  unitSystem: string;
  fuelUnit: 'ltr' | 'gal';
}) => {
  const [clickedDevice, setClickedDevice] = useState<any>();

  const handleClicked = (device: string) => {
    const val = deviceData.find((item: any) => item.bumper_number === device);
    setClickedDevice(val);
  };
  return (
    <Box
      sx={{
        padding: '10px',
        backgroundColor: enableDarkTheme ? '#172A47' : '#fff',
        marginTop: '-80px',
        display: 'flex',
        color: enableDarkTheme ? '#fff' : '#000',
      }}
    >
      {clickedDevice ? (
        <AssetDetail
          enableDarkTheme={enableDarkTheme}
          device={clickedDevice}
          handleClicked={handleClicked}
          allFleetData={deviceData}
          baseCost={baseCost}
          unitSystem={unitSystem}
          fuelUnit={fuelUnit}
        />
      ) : deviceData ? (
        <TreeMapDiv
          enableDarkTheme={enableDarkTheme}
          handleClicked={handleClicked}
          data={deviceData}
          fuelMeasure={fuelMeasure}
          baseCost={baseCost}
          selectedAssetType={selectedAssetType}
          isLoading={isLoading}
          unitSystem={unitSystem}
          fuelUnit={fuelUnit}
        />
      ) : null}
      <RightCards />
    </Box>
  );
};

export default BodyDiv;
