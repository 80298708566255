import React, { useEffect, useState } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { ThemePalette } from 'mui.theme';

const ScrollableBox = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});

const ChipContainer = styled(Box)({
  display: 'flex',
  gap: '8px',
  padding: '8px',
});

const StyledChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #2B55C1',
  color: '#fff',
  '&:hover, &:focus': {
    backgroundColor: '#2c3a56',
  },
}));

const ScrollButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#2B55C1',
  '&:hover': {
    backgroundColor: '#2c3a56',
  },
}));

const ScrollableChipBar = ({
  items,
  handleClick,
  enableDarkTheme,
  noDataItems,
}: any) => {
  const scrollContainerRef = React.useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const checkScrollable = () => {
      const container: any = scrollContainerRef.current;
      if (container) {
        setIsScrollable(container.scrollWidth > container.clientWidth);
      }
    };

    checkScrollable();
    window.addEventListener('resize', checkScrollable);

    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [items]);

  const handleScroll = (direction: any) => {
    const container: any = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: direction * 200, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        borderRadius: '2px',
      }}
    >
      {items.length > 0 ? (
        <>
          {isScrollable && (
            <ScrollButton
              onClick={() => {
                handleScroll(-1);
              }}
            >
              <ChevronLeftIcon
                fontSize="small"
                sx={{
                  fontSize: '12px',
                }}
              />
            </ScrollButton>
          )}
          <ScrollableBox ref={scrollContainerRef}>
            <ChipContainer>
              {items.map((item: any, index: any) => (
                <StyledChip
                  key={index}
                  label={
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: enableDarkTheme
                          ? noDataItems.includes(item)
                            ? ThemePalette.typography.lightDanger
                            : ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                    >
                      {item}
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(item);
                        }}
                        style={{
                          cursor: 'pointer',
                          marginLeft: '6px',
                          fontSize: '15px',
                        }}
                      />
                    </span>
                  }
                  sx={{
                    fontSize: '11px',
                  }}
                />
              ))}
            </ChipContainer>
          </ScrollableBox>
          {isScrollable && (
            <ScrollButton
              onClick={() => {
                handleScroll(1);
              }}
            >
              <ChevronRightIcon
                fontSize="small"
                sx={{
                  fontSize: '12px',
                }}
              />
            </ScrollButton>
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ScrollableChipBar;
