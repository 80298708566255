import { Box, Grid } from '@mui/material';
import { type Asset } from 'types/asset.types';
import { useAppSelector } from 'store/hook';
import { notificationLevel } from 'views/FleetOverview/helpers/asset.helpers';
import AssetCardDetails from './AssetCardItem/AssetCardDetails';
import AssetCardInsights from './AssetCardItem/AsserCardInsight';

import { useNavigate } from 'react-router-dom';
import { ThemePalette } from 'mui.theme';

interface Props {
  asset: Asset;
  enableDarkTheme: boolean;
  showCheckedButton: boolean;
  checkedList: string[];
  handleCheckBoxSelected?: any;
  dateComparer?: string;
  handleAssetSelected?: any;
  isLoadingAllAssets?: boolean;
}

const AssetCardItem = ({
  asset,
  enableDarkTheme = false,
  showCheckedButton,
  checkedList = [],
  handleCheckBoxSelected,
  dateComparer = 'day',
  handleAssetSelected,
  isLoadingAllAssets = false,
}: Props) => {
  const navigateTo = useNavigate();
  const { notifications } = useAppSelector(
    (state) => state.ruleNotificationsReducer
  );
  const { assetId, bumperNumber } = asset;

  const assetNotifications = notifications.filter(
    (item) => item.assetId === assetId
  );
  const { warning } = notificationLevel(assetNotifications);

  const handleAlertClick = async () => {
    if (notifications && notifications.length > 0 && warning !== 'No Alerts') {
      const dataToPass = {
        bumperNumber,
      };
      navigateTo('/notifications', {
        state: {
          data: dataToPass,
        },
      });
    }
  };

  return (
    <Grid
      item
      xs={12}
      md={showCheckedButton ? 12 : 6}
      lg={showCheckedButton ? 6 : 3}
      key={assetId}
    >
      <Box
        sx={{
          border: `1px solid ${
            enableDarkTheme ? '#26364C' : ThemePalette.border
          }`,
          minHeight: '220px',
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '5px',
        }}
      >
        <AssetCardDetails
          asset={asset}
          enableDarkTheme={enableDarkTheme}
          showCheckedButton={showCheckedButton}
          handleCheckBoxSelected={handleCheckBoxSelected}
          checkedList={checkedList}
        />
        <AssetCardInsights
          asset={asset}
          enableDarkTheme={enableDarkTheme}
          showCheckedButton={showCheckedButton}
          handleAssetSelected={handleAssetSelected}
          isLoadingAllAssets={isLoadingAllAssets}
          dateComparer={dateComparer}
          assetNotifications={assetNotifications}
          handleAlertClick={handleAlertClick}
        />
      </Box>
    </Grid>
  );
};

export default AssetCardItem;
