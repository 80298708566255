import { Box, TextField, Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { Selector } from 'components/Form/Selectors/Selector';
import UserHandler from 'handlers/internal.user.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { type InternalUser } from 'types/user.types';
import { INTERNAL_USER_DOMAIN, USER_DEPARTMENT_OPTIONS } from 'utils/enums';
import { createOrUpdateInternalUserRequestBodySchema } from 'utils/schemas/user.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import {
  handleSelectCustomerAll,
  handleSelectCustomerSingle,
} from '../../helpers/common';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { type ICreateInternalUserPayload } from 'types/payloads/user.payload.types';
import { useAppSelector } from 'store/hook';
import { type Customer } from 'types/customer.types';
import { ThemePalette } from 'mui.theme';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
}

export const CreateInternalUser = ({
  title,
  handleAbort,
  open,
  enableDarkTheme,
}: Props) => {
  const userHandler = new UserHandler();

  const customers = useAppSelector((state) => state.customerReducer.customers);

  const [isValidForm, setFormValidity] = useState(false);
  const [internalUser, setInternalUser] = useState<ICreateInternalUserPayload>({
    firstName: '',
    lastName: '',
    email: '',
    department: '',
    userCustomerAssociations: [],
    role: 'internal-user',
    phoneNumber: '',
  });

  const customerState: InternalUser['userCustomerAssociations'] = customers.map(
    (customer: Customer) => {
      return {
        id: customer.customerId,
        display: customer.customerName,
      };
    }
  );

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        {
          ...internalUser,
        },
        createOrUpdateInternalUserRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [internalUser]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInternalUser({
      ...internalUser,
      [name]: value,
    });
  };

  const handleSuccess = async () => {
    await userHandler.createInternalUser(internalUser);
    // this open/close setter will not be abstracted out because it is specific to this view
    handleAbort();
  };

  const handlePhoneChange = (value: string) => {
    setInternalUser({
      ...internalUser,
      phoneNumber: value,
    });
  };

  /**
   * This is the form that will be rendered in the modal
   *
   * First name
   * Last name
   * Email
   * Department (dropdown)
   * Associated Customer Accounts (dropdown)
   */
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'ADD NEW USER',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      {/* First Name TextField */}
      <SWTextField
        key="first-name"
        name="firstName"
        label="First Name*"
        TextFieldProps={{
          name: 'firstName',
          value: internalUser.firstName,
          onChange: handleChange,
          InputProps: {
            sx: { ...modalInputFieldStyle, height: '37px' },
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Last Name TextField */}
      <SWTextField
        key="last-name"
        name="lastName"
        label="Last Name*"
        TextFieldProps={{
          name: 'lastName',
          value: internalUser.lastName,
          onChange: handleChange,
          InputProps: {
            sx: { ...modalInputFieldStyle, height: '37px' },
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Email  TextField */}
      <Box key="email">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Email Address*
        </Typography>
        <FlexBox>
          <TextField
            name="email"
            onChange={handleChange}
            value={internalUser.email} // will have to omit domain
            InputProps={{
              sx: {
                height: '37px',
                minWidth: '131px',
                backgroundColor: ThemePalette.typography.white,
              },
            }}
          />
          <Typography
            variant="body2"
            fontSize="12px"
            display="flex"
            alignItems="center"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {INTERNAL_USER_DOMAIN}
          </Typography>
        </FlexBox>
      </Box>

      {/* Department selector drop down single */}
      <Box key="department">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Department*
        </Typography>
        <Selector
          value={internalUser.department}
          onChange={(event, child) => {
            setInternalUser({
              ...internalUser,
              department: event.target.value.toString(),
            });
          }}
          selectorOptions={USER_DEPARTMENT_OPTIONS}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      {/* Customer account multi selector */}
      <Box key="customer-accounts">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Associated Customer Accounts
        </Typography>
        <MultiSelector
          value={internalUser.userCustomerAssociations}
          renderValue={(selected) => {
            return `${selected?.length || 0} Customer(s) Selected`;
          }}
          handleSelectOne={(event) => {
            handleSelectCustomerSingle(event, internalUser, setInternalUser);
          }}
          handleSelectAll={() => {
            handleSelectCustomerAll(
              customerState,
              internalUser,
              setInternalUser
            );
          }}
          /*
        This stubbed response be replaced with real customer accounts
        */
          selectorOptions={customerState}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      {/** Phone number box */}
      <Box key="phone-number">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Phone Number
        </Typography>
        <PhoneInput
          country={'ca'}
          value={internalUser.phoneNumber}
          onChange={handlePhoneChange}
          inputStyle={{ width: '270px', height: '20px', minWidth: '270px' }}
          enableSearch={true}
          enableAreaCodes={true}
          buttonStyle={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
          placeholder=""
        />
      </Box>
    </InteractiveModal>
  );
};
