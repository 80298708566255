import { styled, TableCell, type TableCellProps } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
export const StyledTableCell = ({ ...props }: TableCellProps) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: enableDarkTheme
      ? ThemePalette.typography.lightGrey
      : ThemePalette.typography.grey,
  }));
  return <StyledTableCell {...props} />;
};
