import TagAPI from 'api/tag.api';
import { updateLoadingState } from 'store/app.slice';

import {
  setTagList,
  setTagChartData,
  setTagPayload,
  setTagGrainData,
  setLoadingState,
} from 'store/tag.slice';
import { type Device } from 'types/device.types';
import { type TagAlertDetails, type ITag } from 'types/tag.type';
import { BaseHandler } from './base.handler';
import { type IGetSingleDetailTagPayload } from 'types/payloads/tag.payload.types';
import { arraysEqual } from 'utils/helpers/arrays';
import { cloneData, setIsValidRangeForTags } from 'utils/charts/tags';

export default class TagHandler extends BaseHandler {
  private readonly api: TagAPI;

  constructor() {
    super();

    this.api = new TagAPI();
  }

  /**
   * Gets all unassociated devices from the backend for this particular customer
   * The devices are then stored in the redux store. Associated devices are retrieved with
   * in the asset handler using joins. This is to avoid programatically joining the devices
   * to the assets.
   *
   * @returns Promise<Device[]>
   *
   */
  async getAll(): Promise<Device[]> {
    this.dispatch(updateLoadingState(true));

    try {
      const tags = await this.api.get();

      this.dispatch(updateLoadingState(false));
      this.dispatch(setTagList(tags));
      return tags;
    } catch (_) {
      this.handleError('An error occurred while fetching tags.');
    }

    return [];
  }

  async updateTags(
    // list of tags to update
    tagsToUpdate: string[],
    // unique list of tags with updated values
    tagList: ITag[],
    // non unique list of tags
    tagListFull: ITag[]
  ) {
    if (!tagsToUpdate.length) return;

    this.dispatch(updateLoadingState(true));

    // convert taglist to map
    const tagListMap = tagList.reduce((acc: Record<string, ITag>, tag) => {
      if (!tag) return acc;

      acc[tag.tagName] = tag;

      return acc;
    }, {});

    const updatedTags = tagListFull.filter((tag) =>
      tagsToUpdate.includes(tag.tagName)
    );
    /*
    for each tag that is being updated
    find the tag in the full tag list and update it.
    send the updated tag list to the backend
    */
    updatedTags.forEach((tag) => {
      if (!tagsToUpdate.includes(tag.tagName)) return;

      const updatedTag = tagListMap[tag.tagName];

      if (!updatedTag) return;

      tag.isActive = updatedTag.isActive;
      tag.showOnFleetOverviewCard = updatedTag.showOnFleetOverviewCard;
      tag.showOnFleetOverviewMap = updatedTag.showOnFleetOverviewMap;
      tag.showOnFleetOverviewTable = updatedTag.showOnFleetOverviewTable;
      tag.unit = updatedTag.unit;
      tag.tagAlias = updatedTag.tagAlias;
    });

    try {
      await this.api.update({ updatedTags });

      await this.getAll();

      this.dispatch(updateLoadingState(false));
      this.handleSuccess(`Successfully updated tags. Just few more seconds...`);

      return true;
    } catch (e) {
      this.handleError('Unable to update tags at this time.');
    }
  }

  async getTagDetail(
    deviceId: string,
    payload: IGetSingleDetailTagPayload,
    tagPayload: Record<string, any>,
    tagChartData: Array<Record<string, unknown>>
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const isPayloadSame = arraysEqual(
        tagPayload.payload?.tagNames || [],
        payload?.tagNames || []
      );
      if (isPayloadSame && tagPayload?.deviceId === deviceId) {
        // check if each item in tagChartData falls in the selected range from payload.timeFrame
        const clonedTagChartData = cloneData(tagChartData);
        setIsValidRangeForTags(clonedTagChartData, payload);

        this.dispatch(setTagChartData([...clonedTagChartData]));
        this.handleSuccess();
      } else {
        this.dispatch(setTagChartData([]));
        const tagDetails = await this.api.getSingleTagDetails(
          deviceId,
          payload
        );
        const tagChartData = Object.entries(tagDetails).map(
          ([tagName, tagDetails]) => ({
            tagName,
            tagDetails,
            isValidRange: true,
          })
        );
        const clonedTagChartData = cloneData(tagChartData);
        setIsValidRangeForTags(clonedTagChartData, payload);

        this.dispatch(setTagChartData([...clonedTagChartData])); /// do the same tagChartData check here for latestTs
        this.dispatch(setTagPayload({ payload, deviceId }));
        this.handleSuccess();
      }
    } catch (error) {
      this.handleError(
        'Asset was not able to transmit value for the selected sensor during this time period'
      );
    }
  }

  async getNotificationTagDetails({
    deviceId,
    notificationTime,
    tagDetails,
  }: TagAlertDetails) {
    this.dispatch(updateLoadingState(true));
    try {
      if (!deviceId || !notificationTime) {
        this.dispatch(updateLoadingState(false));
        return null;
      }
      const tags = await this.api.getNotificationTagDetails({
        deviceId,
        notificationTime,
        tagDetails,
      });
      this.dispatch(updateLoadingState(false));
      return tags;
    } catch (error) {
      this.handleError('Unable to get notification tags details at this time.');
    }
  }

  async getTagGrainDetails(
    deviceId: string,
    payload: IGetSingleDetailTagPayload,
    startDate: Date,
    endDate: Date
  ) {
    const cacheKey = JSON.stringify({ deviceId, payload, startDate, endDate });

    const cachedData = sessionStorage.getItem(cacheKey);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      this.dispatch(setTagGrainData(parsedData));
      return parsedData;
    }

    this.dispatch(updateLoadingState(true));
    this.dispatch(setLoadingState(true));

    try {
      const tagDetails = await this.api.getTagGrainDetails(
        deviceId,
        payload,
        startDate,
        endDate
      );

      sessionStorage.setItem(cacheKey, JSON.stringify(tagDetails));

      this.dispatch(setTagGrainData(tagDetails));
      this.handleSuccess();
      this.dispatch(setLoadingState(false));
      return tagDetails;
    } catch (error) {
      this.handleError(
        'Asset was not able to transmit value for the selected sensor during this time period'
      );
      this.dispatch(setLoadingState(false));
      throw error;
    }
  }
}
