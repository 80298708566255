import { Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import Details from './Details';
import Chart from './Chart';
import type {
  utilizationLoadingStates,
  FleetDetailsItem,
} from 'types/utilization.types';
import { useMemo, useState } from 'react';
import { calculateUtilCurrentDailyAverage } from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  customerCode: string;
  loadingStates: utilizationLoadingStates;
  chartData: any[];
  detailsData: FleetDetailsItem;
  assetType: string;
}

const ProductiveHours = ({
  enableDarkTheme,
  unitSystem,
  customerCode,
  chartData,
  loadingStates,
  detailsData,
  assetType,
}: Props) => {
  const [rollingDailyAverage, setRollingDailyAverage] = useState<number>(0);

  const currentDailyAverage = useMemo(() => {
    return calculateUtilCurrentDailyAverage(chartData ?? []);
  }, [chartData]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      <Details
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        customerCode={customerCode}
        isLoading={loadingStates.isLoadingFleetDetails}
        data={detailsData}
        currentDailyAvg={currentDailyAverage}
        rollingDailyAverage={rollingDailyAverage}
        assetType={assetType}
        isLoadingFleetPeriodSummary={loadingStates.isLoadingFleetPeriodSummary}
      />
      <Chart
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        isLoading={loadingStates.isLoadingFleetPeriodSummary}
        data={chartData}
        setRollingDailyAverage={setRollingDailyAverage}
      />
    </Grid>
  );
};

export default ProductiveHours;
