import { Box, Grid, Typography } from '@mui/material';
import { Treemap, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { ThemePalette } from 'mui.theme';
import type { productionAsset } from 'types/production.types';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { getDataKeyResponse, getDataUnit } from './utils';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import AssetItem from './AssetItem';
import { truncateTo2dp } from 'utils/helpers/general';
import dayjs from 'dayjs';
import {
  convertMinutesToHours,
  convertsecondsToHours,
  getColor,
  getShiftHours,
} from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  customerCode: string;
  isLoading: boolean;
  data: productionAsset[];
  isSelected: string;
  startDate: Date;
  endDate: Date;
  isPrinting: boolean;
}

const options = [
  'Production',
  'Production Rate',
  'Utilization',
  'Fuel',
  'Idling Hours',
  'Productive Hours',
];

const sortOptions = ['Least Production', 'Most Production'];

const CustomContent = ({
  x,
  y,
  width,
  height,
  name,
  color,
  value,
  unit,
}: any) => {
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        stroke="#fff"
      />
      {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
      <text
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        x={x + width / 2}
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        y={y + height / 2}
        textAnchor="middle"
        fill="#fff"
        fontSize={14}
      >
        {name}
      </text>
      {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
      <text
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        x={x + width / 2}
        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
        y={y + height / 2 + 20}
        textAnchor="middle"
        fill="#fff"
        fontSize={12}
      >
        {`${convertToTwoDecimalPlaces(value)} ${unit ?? ''}`}
      </text>
    </g>
  );
};

const AssetView = ({
  enableDarkTheme,
  unitSystem,
  customerCode,
  isLoading,
  data,
  startDate,
  endDate,
  isPrinting,
}: Props) => {
  const [selectedSource, setSelectedSource] = useState<string>('Production');
  const [selectedSort, setSelectedSort] = useState<string>('Most Production');

  const dataKey: string = getDataKeyResponse(selectedSource);
  const dataUnit: string | undefined = getDataUnit(selectedSource, unitSystem);
  // const noOfDays =
  //   startDate === endDate ? 1 : endDate?.getDate() - startDate?.getDate();
  const noOfDays =
    startDate === endDate ? 1 : dayjs(endDate).diff(dayjs(startDate), 'day');
  const formattedData = data?.map((item) => {
    return {
      ...item,
      productionValue: Number(item.max_load_sum),
      productionRate: Number(item.avg_load_per_hour),
      fuelValue: item.fuel_consumption_sum,
      utilizationValue: item.avg_utilization_by_shift_hours * 100,
      idlingValue: convertsecondsToHours(item.total_idling_duration),
      productiveValue: convertMinutesToHours(item.total_engine_working_minutes),
    };
  });

  const sortedData = formattedData?.sort((a, b) => {
    if (selectedSort === 'Least Production') {
      return a.productionValue - b.productionValue;
    }
    return b.productionValue - a.productionValue;
  });

  let chartData = sortedData?.map((item, index, arr) => {
    const isMin =
      selectedSort === 'Least Production'
        ? index === 0
        : index === arr.length - 1;
    return {
      name: `${item.bumper_id}`,
      value:
        // @ts-expect-error: string being returned
        item[dataKey],
      color: getColor(isMin),
      unit: dataUnit,
    };
  });

  chartData = chartData?.map((item) => {
    const newValue = Number(truncateTo2dp(item?.value));

    return {
      ...item,
      value: newValue,
    };
  });

  return (
    <Box
      sx={{
        marginTop: isPrinting ? '40%' : '20px',
        padding: 2,
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        borderRadius: '5px',
        border: '1px solid #2F445D',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '600',
              paddingTop: '5px',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            Detailed Analysis
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
              paddingRight: '15px',
              paddingTop: '5px',
            }}
          >
            Show on chart
          </Typography>

          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={options}
            selected={selectedSource}
            setSelected={setSelectedSource}
            minWidth="150px"
          />

          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
              paddingLeft: '10px',
              paddingRight: '15px',
              paddingTop: '5px',
            }}
          >
            Sort By
          </Typography>

          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={sortOptions}
            selected={selectedSort}
            setSelected={setSelectedSort}
            minWidth="150px"
          />
        </Box>
      </Box>

      {isLoading ? (
        <MultiColorCircularLoader height="200px" />
      ) : (
        <Grid
          container
          sx={{
            marginTop: '10px',
          }}
        >
          <Grid lg={12} sm={12} md={12}>
            <Box>
              <FlexBox
                sx={{
                  maxWidth: 'inherit',
                  height: '230px',
                  paddingTop: '20px',
                  marginRight: '10px',
                  paddingBottom: '10px',
                }}
              >
                {chartData?.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <Treemap
                      width={800}
                      height={600}
                      data={chartData}
                      dataKey="value"
                      stroke="#fff"
                      fill={ThemePalette.typography.lightGrey}
                      content={<CustomContent />}
                    ></Treemap>
                  </ResponsiveContainer>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    No Data available for the selected date range
                  </Typography>
                )}
              </FlexBox>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                * coloring is based on least productive asset
              </Typography>
            </Box>
          </Grid>
          <Grid lg={12} sm={12} md={12}>
            <Box
              sx={{
                marginTop: '15px',
              }}
            >
              {sortedData?.length > 0 ? (
                <>
                  {sortedData?.map((item, index) => {
                    const isFourthItem = (index + 1) % 4 === 0; // Check if it's every fourth item

                    return (
                      <div
                        key={index}
                        style={
                          isFourthItem && isPrinting
                            ? { marginBottom: '35%' }
                            : {}
                        }
                      >
                        <AssetItem
                          enableDarkTheme={enableDarkTheme}
                          key={index}
                          data={item}
                          unitSystem={unitSystem}
                          customerCode={customerCode}
                          selectedSource={selectedSource}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AssetView;
