import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { type ColDef } from 'ag-grid-community';
import { ListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import {
  DeviceStatusForTable,
  AssetTypeForTable,
  BumperNumberForTable,
  OperationalStatusForTable,
  AssetLiveTagDataColumnRenderer,
  AlertStatusForTable,
  DeviceIngestDateForTable,
  formatDataToSort,
} from './AssetListRenderer';
import type { ChartProps } from 'types/FleetOverview.types';
import './AssetList.styles.css';
import type { TagDisplay } from 'views/FleetOverview/AssetDisplay';
import { DeviceInfoModal } from 'views/Settings/ExternalUsers/DeviceView/components/modal/DeviceInfoModal';
import { type RuleNotifications } from 'types/notification.types';
import { useAppSelector } from 'store/hook';
import { UNITS } from 'utils/enums';

enum OpenModal {
  None,
  DeviceInfo,
}
/**
 * A component that displays a list of assets for the dashboard
 */
export const AssetList = ({
  searchValue,
  filteredAssets,
  tags,
  exportTo,
  handleExport,
}: Pick<ChartProps, 'filteredAssets'> & {
  searchValue: string;
  tags: TagDisplay[];
  exportTo: boolean;
  handleExport: (val: boolean) => void;
}) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const [selectedAssetId, setSelectedAssetId] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(OpenModal.None);
  const navigateTo = useNavigate();

  const selectedAsset = filteredAssets.find(
    (asset) => asset.assetId === selectedAssetId
  );

  const initialColumnDefs = [
    {
      field: 'assetType',
      headerName: 'Asset Type',
      headerClass: ['ag-header-icon'],
      minWidth: 150,
      cellRenderer: AssetTypeForTable,
      filter: 'agTextColumnFilter',
      sortable: true,
      cellRendererParams: {
        handleIdClick: (
          assetId: string,
          assetVin: string,
          make: string,
          model: string,
          bumperNumber: string
        ) => {
          setSelectedAssetId(assetId);
          navigateTo(`/vehicle-status?assetVin=${assetVin}`, {
            state: {
              makemodel: {
                make,
                model,
                bumperNumber,
              },
            },
          });
        },
      },
    },
    {
      field: 'bumperNumber',
      headerName: 'Bumper ID',
      minWidth: 150,
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: BumperNumberForTable,
      cellRendererParams: {
        handleIdClick: (
          assetId: string,
          assetVin: string,
          make: string,
          model: string,
          bumperNumber: string
        ) => {
          setSelectedAssetId(assetId);
          navigateTo(`/vehicle-status?assetVin=${assetVin}`, {
            state: {
              makemodel: {
                make,
                model,
                bumperNumber,
              },
            },
          });
        },
      },
    },
    {
      field: 'isActive',
      headerName: 'Status',
      minWidth: 150,
      sortable: true,
      cellRenderer: OperationalStatusForTable,
    },
    {
      field: 'make',
      headerName: 'Make',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'model',
      headerName: 'Model',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'device.deviceStatus',
      headerName: 'Device Status',
      minWidth: 150,
      cellRenderer: DeviceStatusForTable,
      sortable: true,
      cellRendererParams: {
        handleDeviceClick: (assetId: string) => {
          setSelectedAssetId(assetId);
          setModalOpen(OpenModal.DeviceInfo);
        },
      },
    },
    {
      field: 'device.ingestDate',
      headerName: 'Last Ingested Time',
      minWidth: 150,
      cellRenderer: DeviceIngestDateForTable,
      sortable: true,
    },
    {
      field: 'notifications',
      headerName: 'Alert Status',
      minWidth: 150,
      cellRenderer: AlertStatusForTable,
      cellRendererParams: {
        field: 'bumperNumber',
        handleAlertClick: async (
          notifications: RuleNotifications[] | undefined,
          bumperNumber: string
        ) => {
          if (notifications && notifications?.length > 0) {
            const dataToPass = {
              bumperNumber,
            };
            navigateTo(`/notifications`, {
              state: {
                data: dataToPass,
              },
            });
          }
        },
      },
      sortable: true,
    },
  ];

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(initialColumnDefs);

  useEffect(() => {
    const tagColumnDefs = tags.map((tag) => {
      return {
        field: tag.id,
        headerName: `${tag.display}  (${getTagUnit(
          tag?.unit ?? ' ',
          unitSystem
        )})`,
        sortable: true,
        minWidth: 150,
        cellRenderer: AssetLiveTagDataColumnRenderer,
        comparator: (
          a: any,
          b: any,
          nodeA: any,
          nodeB: any,
          isDescending: any
        ) => {
          const nodeAValue = formatDataToSort(
            nodeA.data,
            nodeA.data.liveTagData[tag.id],
            tag.id
          );
          const nodeBValue = formatDataToSort(
            nodeB.data,
            nodeB.data.liveTagData[tag.id],
            tag.id
          );

          const getValue = (value: any): number => {
            switch (true) {
              case value === '--':
                return Number.NEGATIVE_INFINITY; // Treat '--' as the smallest value
              case value === null:
              case value === undefined:
              case typeof value === 'boolean':
                return Number.NEGATIVE_INFINITY; // Treat null, undefined, and boolean as the smallest value too
              default:
                return typeof value === 'string' ? parseFloat(value) : value;
            }
          };

          const numA = getValue(nodeAValue);
          const numB = getValue(nodeBValue);

          return numA - numB;
        },
      };
    });
    const updatedColumnDefs = [...initialColumnDefs, ...tagColumnDefs];

    setColumnDefs(updatedColumnDefs);
  }, [tags]);

  return (
    <>
      {selectedAsset && (
        <DeviceInfoModal
          key={`${modalOpen.toString()}-device-info-modal}`}
          deviceData={selectedAsset.device}
          title="Edit Device"
          open={modalOpen === OpenModal.DeviceInfo}
          handleAbort={() => {
            setModalOpen(OpenModal.None);
          }}
        />
      )}
      <ListWrapper>
        <BaseTableList
          rowData={filteredAssets}
          columnDefs={columnDefs}
          cacheQuickFilter={true}
          searchValue={searchValue}
          exportTo={exportTo}
          handleExport={handleExport}
        />
      </ListWrapper>
    </>
  );
};
function getTagUnit(unit: string, unitSystem: string) {
  const unitId = UNITS.find((unitObj) => unitObj.id === unit);
  return unitSystem === 'metric' ? unitId?.display : unitId?.imperial;
}
