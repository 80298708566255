import { Box, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import TirePressure from '../TirePressure';
import SixWheels from '../Schematics/SixWheel';
import { fixedLimts } from 'views/TirePressure/util';

export const SixTires = ({
  tireData,
  limits,
  enableDarkTheme,
  unitSystem,
  tireCodes,
  metricType,
}: {
  tireData: any;
  limits: any;
  enableDarkTheme: boolean;
  unitSystem: string;
  tireCodes: any;
  metricType: string;
}) => {
  const [currentValues, setCurrentValues] = useState<any>({});
  const tireNumberSystem = ['0x17', '0x19', '0x26', '0x27', '0x29', '0x28'];

  // @ts-expect-error ignore this we have it defined
  const limitsData = fixedLimts[unitSystem][metricType.toLowerCase()];

  useEffect(() => {
    const values =
      metricType === 'Pressure'
        ? tireData.tireCurrentStatus
        : tireData.tireCurrentTemperature;
    setCurrentValues(values || {});
  }, [metricType, tireData]);

  const isOverMax = (code: string, value: number, limitData: any) => {
    if (!limitData) return false;

    const { maxValue } = limitData;
    const { minValue } = limitData;

    return !(value >= minValue && value <= maxValue);
  };

  const data = useMemo(() => {
    const result: any = {};

    tireNumberSystem.forEach((code, index) => {
      const tireNumber = index + 1;
      const value = Number(currentValues[code] ?? 0);
      const tireLimit = limits.find((item: any) => item.tireCode === code);

      result[`p${tireNumber}`] = value;
      result[`p${tireNumber}Limits`] = tireLimit;
      result[`isP${tireNumber}WarninigMax`] = isOverMax(
        code,
        value,
        limitsData
      );
    });

    return result;
  }, [currentValues, limits, metricType]);

  const leftTires = [1, 4, 3];
  const rightTires = [2, 5, 6];

  const slowLeaks = tireData.tireCurrentStatus?.slowLeaks ?? [];

  const fastLeaks = tireData.tireCurrentStatus?.fastLeaks ?? [];

  return (
    <>
      <Grid item lg={3}>
        {leftTires.map((item) => (
          <TirePressure
            key={item}
            enableDarkTheme={enableDarkTheme}
            value={data[`p${item}`]}
            limit={data[`p${item}Limits`]}
            tireCode={tireNumberSystem[item - 1]}
            unitSystem={unitSystem}
            tireCodes={tireCodes}
            metricType={metricType}
            slowLeaks={slowLeaks}
            fastLeaks={fastLeaks}
          />
        ))}
      </Grid>
      <Grid item lg={6}>
        <Box
          sx={{
            textAlign: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <SixWheels name="tireVehicleSchematicIcon" values={data} />
        </Box>
      </Grid>
      <Grid item lg={3}>
        {rightTires.map((item) => (
          <TirePressure
            key={item}
            enableDarkTheme={enableDarkTheme}
            value={data[`p${item}`]}
            limit={data[`p${item}Limits`]}
            tireCode={tireNumberSystem[item - 1]}
            unitSystem={unitSystem}
            tireCodes={tireCodes}
            metricType={metricType}
            slowLeaks={slowLeaks}
            fastLeaks={fastLeaks}
          />
        ))}
      </Grid>
    </>
  );
};
