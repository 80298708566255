import { useCallback, useEffect, useRef, useState } from 'react';
import TimeLine from '../Components/TimeLine';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { Box } from '@mui/material';
import QuickRange from '../Components/QuickRange';
import DriverBehavorHandler from 'handlers/driver.behavior.handler';
import { setTimelineError } from 'store/driver.behavior.slice';
import ExportShiftsToExcel from '../Components/ExportShiftsToExcel';
import DailyShiftTimelineSwitch from '../Components/DailyShiftTimelineSwitch';
import useStartEndDates from '../hooks/useStartEndDates';

const EngineHoursTimeline = () => {
  const driverBehaviorHandler = new DriverBehavorHandler();
  const today = new Date();

  const customerCode = useAppSelector((state) => state.authReducer).customer
    .code;

  const { isLoading } = useAppSelector((state) => state.driverBehaviorReducer);

  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const [showShiftWise, setShowShiftWise] = useState(true);

  const { startDateWithTime, endDateWithTime, difference, shiftsWithTime } =
    useStartEndDates({
      showShiftWise,
      startDate,
      endDate,
    });

  const fetchEngineHoursTimelineData = useCallback(async () => {
    try {
      if (startDateWithTime && endDateWithTime && difference !== undefined) {
        if (difference > 1) {
          dispatch(
            setTimelineError('Kindly select maximum 1 day for timeline chart')
          );
          return;
        }
        await driverBehaviorHandler.getAll(
          {
            startDate: startDateWithTime,
            endDate: endDateWithTime,
          },
          true
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [startDateWithTime, endDateWithTime, difference]);

  const lastCalledTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (customerCode && startDateWithTime && endDateWithTime) {
      if (startDate && endDate) {
        if (lastCalledTimeoutRef.current) {
          clearTimeout(lastCalledTimeoutRef.current);
        }

        lastCalledTimeoutRef.current = setTimeout(() => {
          void fetchEngineHoursTimelineData();
        }, 1000);
      }
    }

    return () => {
      if (lastCalledTimeoutRef.current) {
        clearTimeout(lastCalledTimeoutRef.current);
      }
    };
  }, [
    startDate,
    endDate,
    startDateWithTime,
    endDateWithTime,
    customerCode,
    difference,
  ]);

  const [showTimeline, setShowTimeline] = useState(false);

  const showTimelineRef = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    if (showTimelineRef.current) {
      clearTimeout(showTimelineRef.current);
    }
    showTimelineRef.current = setTimeout(() => {
      setShowTimeline(true);
    }, 1000);
    return () => {
      if (showTimelineRef.current) {
        clearTimeout(showTimelineRef.current);
      }
    };
  }, []);

  return (
    <Box width={'100%'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <QuickRange
            isSelected={'Custom'}
            startDate={startDate}
            endDate={endDate}
            handleDateChange={handleDateChange}
            maxDate={today}
            showHotLinks={false}
          />
          <DailyShiftTimelineSwitch
            showShiftWise={showShiftWise}
            setShowShiftWise={setShowShiftWise}
            onLabel="Shift Wise"
            offLabel="Daily"
            disabled={isLoading}
          />
        </div>
        <ExportShiftsToExcel />
      </div>
      <div
        style={{
          height: '550px',
          width: '100%',
        }}
      >
        {showTimeline && startDateWithTime && endDateWithTime && (
          <TimeLine
            startDateWithTime={dayjs(startDateWithTime)
              .subtract(4, 'hours')
              .format('YYYY-MM-DD HH:mm:ss')}
            endDateWithTime={dayjs(endDateWithTime)
              .add(4, 'hours')
              .format('YYYY-MM-DD HH:mm:ss')}
            shiftsWithTime={shiftsWithTime}
          />
        )}
      </div>
    </Box>
  );
};

export default EngineHoursTimeline;
