import { Box, Typography, Grid } from '@mui/material';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { ThemePalette } from 'mui.theme';
import { SVG } from 'components/Asset/SVG';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import type { productionAsset } from 'types/production.types';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { convertSeconds } from '../../AllFleet/utils';

import AssetItemChart from 'views/Production/AllFleet/components/AssetItem/AssetItemChart';

const AssetItem = ({
  enableDarkTheme,
  data,
  unitSystem,
  selectedSource,
  customerCode,
}: {
  enableDarkTheme: boolean;
  data: productionAsset;
  unitSystem: string;
  customerCode: string;
  selectedSource: string;
}) => {
  return (
    <Box
      sx={{
        height: '350px',
        backgroundColor: enableDarkTheme
          ? '#19304F'
          : ThemePalette.light.toolBarBackground,
        marginBottom: '15px',
        padding: '10px',
        borderRadius: '4px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <SVG
          name={mapVehiclesAssets('haul_truck')}
          style={{
            fill: '#E2C171',
            height: '30px',
            width: '60px',
            paddingRight: '10px',
          }}
        />
        <Box>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
              fontSize: '13px',
            }}
          >
            {data?.bumper_id}
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <PowerSettingsNewIcon
              sx={{
                color: data.device === 'aa' ? '#00C853' : '#E63946',
                marginRight: '8px',
                fontSize: '12px',
              }}
            />
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {data?.make} - {data?.model}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: '10px',
        }}
      >
        <Grid
          container
          sx={{
            fontSize: '13px',
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          <Grid item lg={6} sm={12} xs={12}>
            <Typography
              sx={{
                fontSize: '13px',
              }}
            >
              Production
            </Typography>
            <Typography
              sx={{
                marginTop: '5px',
                fontSize: '11px',
              }}
            >
              {convertToTwoDecimalPlaces(data?.max_load_sum?.toString())}
              {unitSystem === 'imperial' ? ' iTons' : ' Tons'}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Typography
              sx={{
                fontSize: '13px',
              }}
            >
              Loads / Day
            </Typography>

            <Typography
              sx={{
                marginTop: '5px',
                fontSize: '11px',
              }}
            >
              {convertToTwoDecimalPlaces(
                data?.avg_max_load_per_day?.toString()
              )}{' '}
              {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            fontSize: '13px',
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          <Grid item lg={6} sm={12} xs={12}>
            <Typography
              sx={{
                marginTop: '10px',
                fontSize: '13px',
              }}
            >
              Avg Cycle Time
            </Typography>
            <Typography
              sx={{
                marginTop: '5px',
                fontSize: '11px',
              }}
            >
              {convertToTwoDecimalPlaces(
                convertSeconds(data?.avg_cycle_time).value.toString()
              )}{' '}
              {convertSeconds(data?.avg_cycle_time).unit}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Typography
              sx={{
                marginTop: '10px',
                fontSize: '13px',
              }}
            >
              Pass Count / Cycle
            </Typography>
            <Typography
              sx={{
                marginTop: '5px',
                fontSize: '11px',
              }}
            >
              {convertToTwoDecimalPlaces(data?.pass_count_cycle?.toString())}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <AssetItemChart
        enableDarkTheme={enableDarkTheme}
        data={data}
        selectedSource={selectedSource}
        customerCode={customerCode}
        unitSystem={unitSystem}
        height="180px"
      />
    </Box>
  );
};

export default AssetItem;
