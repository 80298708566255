import { Grid, Typography, Box } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { ThemePalette } from 'mui.theme';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { SVG } from 'components/Asset/SVG';
import type { productionAsset } from 'types/production.types';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { convertSeconds } from '../../utils';

interface Props {
  enableDarkTheme: boolean;
  data: productionAsset;
  unitSystem: string;
  customerCode: string;
}

const AssetItemDetails = ({
  enableDarkTheme,
  data,
  unitSystem,
  customerCode,
}: Props) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SVG
        name={mapVehiclesAssets(data?.asset_type)}
        style={{
          fill: '#E2C171',
          height: '30px',
          width: '60px',
          paddingRight: '10px',
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '13px',
              }}
            >
              {data?.bumper_id}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PowerSettingsNewIcon
                sx={{
                  color: data.device === 'aa' ? '#00C853' : '#E63946',
                  marginRight: '8px',
                  fontSize: '12px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '10px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {data?.make} - {data?.model}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Production
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(data?.max_load_sum?.toString())}{' '}
              {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Loads / Day
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(
                data?.avg_max_load_per_day?.toString()
              )}{' '}
              {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Avg Cycle Time
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(
                convertSeconds(data?.avg_cycle_time).value.toString()
              )}{' '}
              {convertSeconds(data?.avg_cycle_time).unit}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Pass Count / Cycle
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(data?.pass_count_cycle?.toString())}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AssetItemDetails;
