import { Close, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import TargetHandler from 'handlers/targets.handler';
import { ThemePalette } from 'mui.theme';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hook';

interface Props {
  enableDarkTheme: boolean;
  assetType: string;
  metricName?: 'utilization' | 'production';
}

const TargetSetter = ({
  enableDarkTheme,
  assetType,
  metricName = 'utilization',
}: Props) => {
  const initialTargetValue = useAppSelector(
    (state) => state?.targetReducer?.data?.target?.targetValue
  );
  const isLoadingTarget = useAppSelector(
    (state) => state?.targetReducer.loadingStates?.isLoadingTarget
  );

  const customerId = useAppSelector((state) => state.persistedReducer).customer
    ?.id;

  const targetHandler = new TargetHandler();

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const [targetValue, setTargetValue] = useState<number | string>(
    initialTargetValue
  );

  const handleTargetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || e?.target?.value === 'e') {
      setTargetValue('');
      return;
    } else if (isNaN(Number(e.target.value))) {
      return;
    }
    setTargetValue(Number(e.target.value));
  };

  const handleTargetSubmit = async () => {
    try {
      if (!targetValue) {
        return;
      }
      await targetHandler.postTarget(
        customerId,
        metricName,
        +targetValue,
        new Date().getFullYear(),
        assetType
      );
      toggleOpen();
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDownForEKey = (e: React.KeyboardEvent) => {
    if (e.key === 'e') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setTargetValue(initialTargetValue);
  }, [initialTargetValue]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {/* <Typography
        sx={{
          fontSize: '12px',
        }}
      >
        Mine Production Plan:
      </Typography> */}
      <Button
        size="small"
        sx={{
          border: '1px solid #4A7BFA',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          textTransform: 'none',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '11px',
        }}
        startIcon={<Edit />}
        onClick={toggleOpen}
      >
        Set annual target
      </Button>
      <Dialog
        open={open}
        onClose={toggleOpen}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: ThemePalette.light.boxBackground,
          },
          p: 4,
        }}
      >
        <DialogTitle
          sx={{
            color: ThemePalette.typography.black,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Set Target</Typography>
            <IconButton
              onClick={toggleOpen}
              sx={{
                color: ThemePalette.typography.black,
                borderRadius: '8px',
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            p: 2,
            minWidth: '250px',
          }}
        >
          <TextField
            size="small"
            sx={{}}
            label="Target"
            variant="outlined"
            type="number"
            value={targetValue}
            onChange={handleTargetChange}
            disabled={isLoadingTarget}
            onKeyDown={handleKeyDownForEKey}
          />
          <Button
            variant="contained"
            onClick={handleTargetSubmit}
            disabled={isLoadingTarget}
          >
            Save
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default TargetSetter;
