import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import type { ICreateRulePayload } from 'types/payloads/rule.payload.types';
import type { Asset } from 'types/asset.types';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { RuleConditionComponent } from './RuleCondition/RuleCondition';
import { RelationalOperatorList } from './RuleCondition/RuleConditionWrapper';
import { removeRuleCondition } from 'store/rule.slice';
import { replaceArrayElement } from 'views/Settings/Rules/helpers/common';
import { RuleModalHeader } from './RuleModalHeader';

interface Props {
  open: boolean;
  handleAbort: () => void;
  handleConfirm: () => void;
  title: string;
  newRule: ICreateRulePayload;
  assets: Asset[];
  enableDarkTheme: boolean;
}

/**
 *
 * The createRuleCondition modal is a modal
 * that is used to create a rule Condition form.
 */
export const CreateRuleConditionsModal = ({
  open,
  handleAbort,
  handleConfirm,
  title,
  newRule,
  assets,
  enableDarkTheme,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const { typography } = useTheme();
  const dispatch = useAppDispatch();
  const [row, setRow] = useState<string[]>([]);
  const [isValidForm, setFormValidity] = useState(false);

  const addRow = (index: number, relationalOperator: string) => {
    if (row[index] === undefined) {
      setRow([...row, relationalOperator]);
    } else {
      const result: string[] = replaceArrayElement(
        row,
        index,
        relationalOperator
      );
      setRow([...result]);
    }
  };

  const deleteRow = (index: number, id: string) => {
    const dataRow = [...row];
    dataRow.splice(index, 1);
    setRow([...dataRow]);
    dispatch(removeRuleCondition(id));
  };

  // Validate form as user fills out fields
  useEffect(() => {
    const isValidDistribution =
      newRule.alertByEmail ||
      newRule.alertByNotificationCenter ||
      newRule.alertBySms ||
      newRule.alertByUI;
    const isValidRow = row.includes('THEN');
    const isValidDistributionUsers = newRule.alertByEmail
      ? newRule.distributionUsers.length > 0
      : true;
    const formValidityCheck =
      isValidDistribution && isValidRow && isValidDistributionUsers;
    setFormValidity(formValidityCheck);
  }, [newRule]);

  /**
   * Execute on render of component
   */
  useEffect(() => {
    if (newRule.conditions?.length) {
      const initialRow: string[] = newRule.conditions
        .map((item) => {
          return item.logicalOperator;
        })
        .filter((item) => item);
      setRow(initialRow);
    }
  }, []);

  return (
    <InteractiveModal
      key={'rule-condition-modal'}
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          handleConfirm();
        },
        disabled: !isValidForm,
        label: 'Next: Confirm',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      cancelButtonText={'Back'}
      enableDarkTheme={enableDarkTheme}
    >
      {/** Modal Page Number to identify what modal page  */}
      <Typography
        key="condition-text"
        variant="medium"
        sx={{
          fontFamily: typography.fontFamily,
        }}
      >
        <RuleModalHeader
          disabledPages={[3]}
          enableDarkTheme={enableDarkTheme}
        />
      </Typography>

      {/** Default or Initial Rule Condition */}
      <RuleConditionComponent
        key={'default-rule-form'}
        addRow={addRow}
        rows={row}
        newRule={newRule}
        assets={assets}
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
      />

      {/** Additional Rule Condition, Based on rows array */}
      <RelationalOperatorList
        rows={row}
        addRow={addRow}
        deleteRow={deleteRow}
        newRule={newRule}
        assets={assets}
        enableDarkTheme={enableDarkTheme}
      />
    </InteractiveModal>
  );
};
