import DtcAPI from 'api/dtc.api';
import { getAll, setLoading } from 'store/dtc.slice';
import type { DTC } from 'types/dtc.types';

import { BaseHandler } from './base.handler';

export default class DtcHandler extends BaseHandler {
  private readonly api: DtcAPI;

  constructor() {
    super();

    this.api = new DtcAPI();
  }

  async getAll(payload: {
    startDate: string;
    endDate: string;
  }): Promise<DTC[]> {
    this.dispatch(setLoading(true));
    try {
      const dtcData = await this.api.getAll(payload);

      this.dispatch(getAll(dtcData));

      return dtcData;
    } catch (_) {
      this.dispatch(setLoading(false));
      this.handleError('An error occurred while fetching dtc data.');
    }

    return [];
  }
}
