const LineAndCircle = ({
  x1,
  y1,
  x2,
  y2,
  r1,
  r2,
}: {
  x1: string;
  y1: string;
  x2: string;
  y2: string;
  r1: string;
  r2: string;
}) => {
  return (
    <>
      <line
        x1={x1} // Start: Truck part
        y1={y1}
        x2={x2} // End: InfoPanel
        y2={y2}
        stroke="#CCCCCC"
        strokeWidth="1"
      />
      <circle
        cx={x1} // X coordinate of the circle
        cy={y1} // Y coordinate of the circle
        r={r1} // Initial radius
        fill="blue" // Initial color
      >
        <animate
          attributeName="r"
          from={r1}
          to={r2}
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill"
          values="blue;white;blue"
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          values="1;0.5;1"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </circle>
    </>
  );
};

export default LineAndCircle;
