import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import Header from './Components/Header';
import KPICard from './Components/KPICard';
import SurvivalAnalysisOverviewHandler from 'handlers/survivalAnalysisHandlers/survivalAnalysisOverview.handler';
import KPIPieChart from './Helpers/KPi.Pie';
import AssetTable from './Components/AssetTable';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { type ServiceView } from 'types/survivalAnalysis.types';
import OverviewModalDetails from 'views/SurvivalAnalysis/Overview/modals/Overview-modal-details';
import {
  processAssetsWithLiveData,
  processDataAssets,
} from 'views/SurvivalAnalysis/Helper/Function/asset.helper.function';
import { selectLatestTagData } from 'store/device.slice';
import { selectNotificationsData } from 'store/notification.slice';
import { useFetchDashboardAssetsData } from 'views/SurvivalAnalysis/Helper/Function/fetch.helper.function';
import { type HealthScore } from 'types/squares.type';
import { useFetchPmVsCmVsModification } from '../CostAnalysis/MaintenanceComparison/Helper/fetchData';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import { UploadWorkOrdersView } from '../UploadWorkOrders/UploadWorkOrdersView';
import { formatToUSD } from 'views/SurvivalAnalysis/Helper/Function/helper.function';
import { AssetsViewV2 } from '../CostAnalysis/Assets/AssetsViewV2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PartsViewV2 } from '../CostAnalysis/Parts/PartsViewV2';
import { MaintenanceComparisonView } from '../CostAnalysis/MaintenanceComparison/MaintenanceComparisonView';
import { SquaresPerformanceMetrics } from 'utils/enums';
import { productionLabels } from 'utils/helpers/labels';
import { convertMetricTonsToImperialTons } from 'utils/helpers/unitSystem';

interface AssetData {
  total_cost: number;
  total_labour_cost: number;
  total_part_cost: number;
  total_hours: string;
}

interface AssetsResponse {
  assets: Record<string, AssetData>;
  timeseries: Record<string, any>;
}

interface MaintenanceData {
  category: 'PM' | 'CM';
  total_quantity: string;
  total_cost: number;
  total_hours: string;
}

export const DashbaordEntityManagementViewV2 = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const customerCode = useAppSelector(
    (state) => state.persistedReducer.customer.code
  );
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const productionUnitSystemLabel =
    unitSystem === 'metric'
      ? productionLabels.Load.metric
      : productionLabels.Load.imperial;
  const enableDarkTheme = isDarkTheme(theme);
  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );
  let initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);
  let initialEndDate = new Date();
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedStartDate, setStartDate] = useState(initialStartDate);
  const [selectedEndDate, setEndDate] = useState(initialEndDate);
  const [activeTab, setActiveTab] = useState('KPIs');
  const [selectedAsset, setSelectedAsset] = useState<ServiceView>();
  const [isUploadWorkOrder, setIsUploadWorkOrder] = useState<boolean>(false);
  const [filteredAssets, setFilteredAssets] = useState<ServiceView[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(''); // Add selectedMonth state
  const fetchDashboardAssetsData = useFetchDashboardAssetsData();
  const [showView, setShowView] = useState<string | null>(null); // Manage which view to show
  const [totalCostSaved, setTotalCostSaved] = useState([
    {
      header: '',
      value: '0', // Initial hardcoded value
    },
  ]);
  const [totalProductivityImproved, setTotalProductivityImproved] = useState([
    {
      header: '',
      value: '0', // Initial hardcoded value
    },
  ]);
  const [totalDowntimeSaved, setTotalDowntimeSaved] = useState([
    {
      header: '',
      value: '0', // Initialize with proportional value
    },
  ]);
  const [totaltonnage, setTotaltonnage] = useState([
    {
      header: '',
      value: '0', // Default value, will be updated later
    },
  ]);
  const [totaltonnageValue, setTotaltonnageValue] = useState(0);
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }
  const handleMonthSelect = (month: string | null) => {
    if (month) {
      setSelectedMonth(month);
    } else {
      setSelectedMonth(''); // Handle null by setting an empty string or a default value
    }
  };
  const { assets } = useAppSelector((state) => state.assetReducer);

  const latestTagData = useAppSelector(selectLatestTagData);
  const notificationsData = useAppSelector(selectNotificationsData);
  const fetchPmCmModification = useFetchPmVsCmVsModification();

  const [assetsItems, setAssetsItems] = useState<HealthScore[]>([]);
  const survivalAnalysisHandler = new SurvivalAnalysisOverviewHandler();
  const squaresHandler = new SquaresDashboardHandler();
  const { healthScoreData } = useAppSelector(
    (state) => state.squaresDashboardReducer
  );
  const [totalCosts, setTotalCosts] = useState({
    total_cost: 0,
    total_labour_cost: 0,
    total_part_cost: 0,
  });
  const [maintenanceData, setMaintenanceData] = useState({
    pmTotalCost: 0,
    cmTotalCost: 0,
    totalMaintenanceCost: 0,
    pmTotalHours: 0,
    cmTotalHours: 0,
    totalMaintenanceHours: 0,
    pmTotalQuantity: 0,
    cmTotalQuantity: 0,
    totalMaintenanceQuantity: 0,
  });

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      void squaresHandler
        .getDashboardAssetsData(selectedStartDate, selectedEndDate)
        .then((data: AssetsResponse) => {
          const aggregatedCosts = Object.values(data.assets).reduce(
            (acc, asset) => {
              acc.total_cost += asset.total_cost;
              acc.total_labour_cost += asset.total_labour_cost;
              acc.total_part_cost += asset.total_part_cost;
              return acc;
            },
            { total_cost: 0, total_labour_cost: 0, total_part_cost: 0 }
          );
          setTotalCosts(aggregatedCosts);
        })
        .catch((error) => {
          console.error('Error fetching asset data:', error);
        });

      void fetchPmCmModification(selectedStartDate, selectedEndDate)
        .then((data: any[]) => {
          const formattedData: MaintenanceData[] = data.map((item) => ({
            category: item.category as 'PM' | 'CM',
            total_quantity: item.total_quantity?.toString() ?? '0',
            total_cost: item.total_cost,
            total_hours: item.total_hours?.toString() ?? '0',
          }));

          const pmData = formattedData.filter((item) => item.category === 'PM');
          const cmData = formattedData.filter((item) => item.category === 'CM');

          const pmTotalCost = pmData.reduce(
            (sum, item) => sum + item.total_cost,
            0
          );
          const cmTotalCost = cmData.reduce(
            (sum, item) => sum + item.total_cost,
            0
          );
          const totalMaintenanceCost = pmTotalCost + cmTotalCost;

          const pmTotalHours = pmData.reduce(
            (sum, item) => sum + parseFloat(item.total_hours),
            0
          );
          const cmTotalHours = cmData.reduce(
            (sum, item) => sum + parseFloat(item.total_hours),
            0
          );
          const totalMaintenanceHours = pmTotalHours + cmTotalHours;

          const pmTotalQuantity = pmData.reduce(
            (sum, item) => sum + parseInt(item.total_quantity, 10),
            0
          );
          const cmTotalQuantity = cmData.reduce(
            (sum, item) => sum + parseInt(item.total_quantity, 10),
            0
          );
          const totalMaintenanceQuantity = pmTotalQuantity + cmTotalQuantity;

          setMaintenanceData({
            pmTotalCost,
            cmTotalCost,
            totalMaintenanceCost,
            pmTotalHours,
            cmTotalHours,
            totalMaintenanceHours,
            pmTotalQuantity,
            cmTotalQuantity,
            totalMaintenanceQuantity,
          });
          // Calculate proportional values based on the date range
          const downtimeSavedValue = calculateProportionalValue(
            SquaresPerformanceMetrics[customerCode].totalDowntimeSaved
          ).toFixed(2);
          const costSavedValue = calculateProportionalValue(
            SquaresPerformanceMetrics[customerCode].totalCostSaved
          );
          const productivityImprovedValue = calculateProportionalValue(
            unitSystem === 'metric'
              ? SquaresPerformanceMetrics[customerCode]
                  .totalProductivityImproved
              : convertMetricTonsToImperialTons(
                  SquaresPerformanceMetrics[customerCode]
                    .totalProductivityImproved
                )
          );

          // Update the state with the calculated values
          setTotalDowntimeSaved([
            {
              header: '',
              value: downtimeSavedValue.toString(),
            },
          ]);
          setTotalCostSaved([
            {
              header: '',
              value: `$${formatToUSD(costSavedValue, 'USD')}`,
            },
          ]);
          setTotalProductivityImproved([
            {
              header: '',
              value: formatToUSD(
                productivityImprovedValue,
                productionUnitSystemLabel
              ),
            },
          ]);
          const tonnageValues = calculateValueForDateRange();
          setTotaltonnageValue(
            unitSystem === 'metric'
              ? tonnageValues[0].value
              : convertMetricTonsToImperialTons(tonnageValues[0].value)
          );
          setTotaltonnage([
            {
              header: tonnageValues[0].header,
              value: `${formatToUSD(
                tonnageValues[0].value,
                productionUnitSystemLabel
              )}`,
            },
          ]);
        })
        .catch((error) => {
          console.error('Error fetching PM vs CM vs Modification data:', error);
        });
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (healthScoreData && Object.keys(healthScoreData).length > 0) {
      const assetsArray = Object.values(healthScoreData)
        .map((asset) => ({
          asset_details: asset.asset_details,
          component_details: asset.component_details,
        }))
        .filter((asset) => asset.asset_details?.health_score !== undefined);

      setAssetsItems(assetsArray);
    } else {
      console.warn('healthScoreData is empty or invalid.');
    }
  }, [healthScoreData]);

  const showDetails = (asset: string) => {
    const filterItem = filteredAssets.find(
      (assets) => assets.item_id === asset
    );
    setSelectedAsset(filterItem);
  };

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);

    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };

  const handleButtonClick = (tab: string) => {
    setActiveTab(tab);
  };
  const handleIsWorkOrder = (check: boolean) => {
    setIsUploadWorkOrder(check);
  };

  const fetchData = async () => {
    void survivalAnalysisHandler.getAssetsRiskAnalysis();
    void survivalAnalysisHandler.getCumulativeAssetsPerSubComponent();
    void survivalAnalysisHandler.getEOLs();
    void squaresHandler.getHealthScoreData();
  };

  useEffect(() => {
    void fetchData();
  }, []);
  const survivalAnaysisOverview = useAppSelector(
    (state) => state.survivalAnaysisOverview
  );

  const handleOpenInNewClick = (itemId: string) => {
    const filterItem = filteredAssets.find(
      (assets) => assets.item_id === itemId
    );
    setSelectedAsset(filterItem);
  };

  useEffect(() => {
    const assetWithLiveData = processAssetsWithLiveData(
      assets,
      latestTagData,
      notificationsData
    );
    const processedData = processDataAssets(
      survivalAnaysisOverview.assets,
      assetWithLiveData,
      survivalAnaysisOverview.eol
    );
    setFilteredAssets(processedData);
  }, [survivalAnaysisOverview.assets]);

  const handleModalSuccess = async () => {
    await fetchData();
  };

  // Transform `ServiceView` to `HealthScore` using `assetsItems` data
  const transformServiceViewToHealthScore = (
    serviceViews: ServiceView[],
    assetsItems: HealthScore[]
  ): HealthScore[] => {
    return serviceViews.map((serviceView) => {
      // Find the matching HealthScore from assetsItems using item_id
      const matchedHealthScore = assetsItems.find(
        (item) => item.asset_details.bumper_number === serviceView.item_id
      );

      // If matched, use the data from matchedHealthScore
      if (matchedHealthScore) {
        return matchedHealthScore;
      }

      // If not matched, create a new HealthScore from available ServiceView data
      return {
        asset_details: {
          bumper_number: serviceView.item_id,
          health_score: Number(serviceView.health_score) ?? 0, // Default to 0 if not available
          squares_recommended_date:
            typeof serviceView.squares_recommended_date === 'string'
              ? serviceView.squares_recommended_date
              : '', // Ensure it's a string
          device:
            typeof serviceView.device === 'string' ? serviceView.device : '',
          make: typeof serviceView.make === 'string' ? serviceView.make : '',
          model: typeof serviceView.model === 'string' ? serviceView.model : '',
          asset_type:
            typeof serviceView.asset_type === 'string'
              ? serviceView.asset_type
              : '',
          manufacture_recommended_date:
            typeof serviceView.manufacture_recommended_date === 'string'
              ? serviceView.manufacture_recommended_date
              : '',
          latest_work_order_date:
            typeof serviceView.latest_work_order_date === 'string'
              ? serviceView.latest_work_order_date
              : '',
          age_in_days:
            typeof serviceView.age_in_days === 'string'
              ? serviceView.age_in_days
              : String(serviceView.age_in_days) ?? '', // Convert to string if necessary
          daily_engine_hours:
            typeof serviceView.daily_engine_hours === 'string'
              ? serviceView.daily_engine_hours
              : String(serviceView.daily_engine_hours) ?? '', // Convert to string if necessary
          engine_hours:
            typeof serviceView.engine_hours === 'string'
              ? serviceView.engine_hours
              : String(serviceView.engine_hours) ?? '', // Convert to string if necessary
          lastConnected: serviceView.lastConnected ?? '',
        },
        component_details: Array.isArray(serviceView.component_details)
          ? serviceView.component_details
          : [], // Ensure it's an array
      };
    });
  };
  const handleKpiCardClick = (cardType: string) => {
    if (
      cardType === 'assetsView' ||
      cardType === 'totalMaintenanceCost' ||
      cardType === 'partsReplacement' ||
      cardType === 'workOrderCount' ||
      cardType === 'maintenanceHours'
    ) {
      setShowView(cardType); // Set the view based on the card clicked
    }
  };

  function calculateProportionalValue(annualValue: number): number {
    const startDate = selectedStartDate;
    const endDate = selectedEndDate;

    // Check if startDate or endDate is null, undefined, or not a valid Date object
    if (!startDate || !endDate) {
      console.error('Start date or end date is invalid:', {
        startDate,
        endDate,
      });
      return 0; // Return a default value or handle the error as needed
    }

    // Ensure startDate and endDate are valid Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if the date parsing was successful
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('Failed to parse start date or end date:', { start, end });
      return 0; // Return a default value or handle the error as needed
    }

    // Calculate the number of days between startDate and endDate
    const numOfDaysBetween =
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

    // If numOfDaysBetween is negative or zero, handle that scenario
    if (numOfDaysBetween <= 0) {
      console.warn(
        'Invalid date range: End date is before or same as start date.',
        { start, end }
      );
      return 0; // Return a default value or handle the error as needed
    }

    // Calculate the proportion of the year
    const proportionOfYear = numOfDaysBetween / 365;
    // Return the new proportional value
    return annualValue * proportionOfYear;
  }

  function calculateValueForDateRange() {
    const startDate = selectedStartDate;
    const endDate = selectedEndDate;

    // Check if startDate or endDate is null, undefined, or not a valid Date object
    if (!startDate || !endDate) {
      console.error('Start date or end date is invalid:', {
        startDate,
        endDate,
      });
      return [
        {
          header: '',
          value: 0, // Return '0' as a string or a default placeholder
        },
      ];
    }

    // Ensure startDate and endDate are valid Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if the date parsing was successful
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('Failed to parse start date or end date:', { start, end });
      return [
        {
          header: '',
          value: 0, // Return '0' as a string or a default placeholder
        },
      ];
    }

    // Calculate the number of days between startDate and endDate
    const numOfDaysBetween =
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

    // If numOfDaysBetween is negative or zero, handle that scenario
    if (numOfDaysBetween <= 0) {
      console.warn(
        'Invalid date range: End date is before or same as start date.',
        { start, end }
      );
      return [
        {
          header: '',
          value: 0, // Return '0' as a string or a default placeholder
        },
      ];
    }

    // Calculate the proportional value based on the proportion of the year
    const proportionalValue = 82.3 * 20 * numOfDaysBetween; // Replace with the appropriate calculation for your context

    // Return the result in the format expected by <KPICard>
    return [
      {
        header: '',
        value: proportionalValue, // Ensure value is formatted as a string
      },
    ];
  }

  const renderContent = () => {
    if (activeTab === 'KPIs') {
      const totalNumberOfAssets = [{ header: '', value: assetsItems.length }];
      const totalMaintenanceCostOfAllAssets = [
        {
          header: 'Total',
          value: `$${formatToUSD(totalCosts.total_cost, 'USD')}`,
        },
        {
          header: 'Per Tonnage',
          value: `$${formatToUSD(
            totalCosts.total_cost / Number(totaltonnageValue),
            'USD'
          )}`,
        },
      ];
      const totalCostOfPartsReplacement = [
        {
          header: '',
          value: `$${formatToUSD(totalCosts.total_part_cost, 'USD')}`,
        },
      ];
      const totalCostOfLabor = [
        {
          header: '',
          value: `$${formatToUSD(totalCosts.total_labour_cost, 'USD')}`,
        },
      ];
      const maintenanceCount = [
        {
          header: 'Total',
          value: maintenanceData.totalMaintenanceQuantity.toFixed(0),
        },
        {
          header: 'PM',
          value: maintenanceData.pmTotalQuantity.toFixed(0),
        },
        {
          header: 'CM',
          value: maintenanceData.cmTotalQuantity.toFixed(0),
        },
      ];

      const downtime = [
        {
          header: 'Scheduled',
          value: maintenanceData.pmTotalHours,
        },
        {
          header: 'unScheduled',
          value: maintenanceData.cmTotalHours,
        },
      ];

      return (
        <>
          <KPICard
            title="Total number of Assets"
            values={totalNumberOfAssets}
            enableDarkTheme={enableDarkTheme}
          />

          <KPICard
            title="Total tonnage"
            values={totaltonnage}
            enableDarkTheme={enableDarkTheme}
          />

          <KPICard
            title="Potential Downtime Savings"
            values={totalDowntimeSaved}
            enableDarkTheme={enableDarkTheme}
            totalType="hrs"
          />
          <KPICard
            title="Potential Cost Savings"
            values={totalCostSaved}
            enableDarkTheme={enableDarkTheme}
          />
          <KPICard
            title="Potential Productivity Improvement"
            values={totalProductivityImproved}
            enableDarkTheme={enableDarkTheme}
          />

          <KPICard
            title="Total Maintenance Cost of all Assets"
            values={totalMaintenanceCostOfAllAssets}
            enableDarkTheme={enableDarkTheme}
            onClick={() => {
              handleKpiCardClick('totalMaintenanceCost');
            }}
          />
          <KPICard
            title="Total Cost of Parts - Replacement"
            values={totalCostOfPartsReplacement}
            enableDarkTheme={enableDarkTheme}
            onClick={() => {
              handleKpiCardClick('partsReplacement');
            }}
          />
          <KPICard
            title="Total Cost of Labor"
            values={totalCostOfLabor}
            enableDarkTheme={enableDarkTheme}
            onClick={() => {
              handleKpiCardClick('maintenanceHours');
            }}
          />
          <KPICard
            title="Work order Count"
            values={maintenanceCount}
            enableDarkTheme={enableDarkTheme}
            onClick={() => {
              handleKpiCardClick('workOrderCount');
            }}
          />
          <KPICard
            title="Downtime"
            values={downtime}
            enableDarkTheme={enableDarkTheme}
            totalType="hrs"
          />
        </>
      );
    } else if (activeTab === 'Graphs') {
      return (
        <>
          <Box marginBottom={1}>
            <KPICard
              title="Total tonnage"
              values={totaltonnage}
              enableDarkTheme={enableDarkTheme}
              totalType="Tons"
            />
            <KPIPieChart
              title="Maintenance Costs Overview"
              label1="Parts"
              label2="Labour"
              number1={Number(totalCosts.total_part_cost.toFixed(2))}
              number2={Number(totalCosts.total_labour_cost.toFixed(2))}
              totalType="USD"
              enableDarkTheme={enableDarkTheme}
              precision={2}
              color1={ThemePalette.pie.blue}
              color2={ThemePalette.pie.green}
            />
          </Box>
          <Box marginBottom={1}>
            <KPIPieChart
              title="Maintenance Costs - PM vs CM"
              label1="PM"
              label2="CM"
              number1={maintenanceData.pmTotalCost}
              number2={maintenanceData.cmTotalCost}
              totalType="USD"
              enableDarkTheme={enableDarkTheme}
              precision={2}
              color1={ThemePalette.pie.blue}
              color2={ThemePalette.pie.green}
            />
          </Box>{' '}
          <Box marginBottom={1}>
            <KPIPieChart
              title="Work order Count - PM vs CM"
              label1="PM"
              label2="CM"
              number1={maintenanceData.pmTotalQuantity}
              number2={maintenanceData.cmTotalQuantity}
              totalType=""
              enableDarkTheme={enableDarkTheme}
              precision={0}
              color1={ThemePalette.pie.blue}
              color2={ThemePalette.pie.green}
            />
          </Box>{' '}
          <Box marginBottom={1}>
            <KPIPieChart
              title="Maintenance Hours - PM vs CM"
              label1="PM"
              label2="CM"
              number1={maintenanceData.pmTotalHours}
              number2={maintenanceData.cmTotalHours}
              totalType="hrs"
              enableDarkTheme={enableDarkTheme}
              precision={0}
              color1={ThemePalette.pie.blue}
              color2={ThemePalette.pie.green}
            />
          </Box>
        </>
      );
    }
    return null;
  };
  const renderBackButton = () => (
    <IconButton
      onClick={() => {
        setShowView(null);
      }} // Hide the current view
      sx={{
        marginBottom: 2,
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      <ArrowBackIcon /> Return to Dashboard
    </IconButton>
  );

  if (showView) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 2,
          }}
        >
          {renderBackButton()}
        </Box>

        {/* Display specific views based on the clicked KPI card */}

        {showView === 'totalMaintenanceCost' && (
          <AssetsViewV2 enableDarkTheme={enableDarkTheme} />
        )}
        {showView === 'partsReplacement' && (
          <PartsViewV2 enableDarkTheme={enableDarkTheme} />
        )}
        {showView === 'workOrderCount' && (
          <MaintenanceComparisonView enableDarkTheme={enableDarkTheme} />
        )}
        {showView === 'maintenanceHours' && (
          <MaintenanceComparisonView enableDarkTheme={enableDarkTheme} />
        )}
      </>
    );
  }
  if (isUploadWorkOrder) {
    return (
      <>
        <Header
          enableDarkTheme={enableDarkTheme}
          allAssets={assetsItems}
          handleIsWorkOrder={handleIsWorkOrder}
          isUploadWorkOrder={isUploadWorkOrder}
          onMonthSelect={handleMonthSelect} // Pass onMonthSelect to Header
        />
        <UploadWorkOrdersView />
      </>
    );
  }

  if (selectedAsset) {
    return (
      <OverviewModalDetails
        enableDarkTheme={enableDarkTheme}
        displayItems={selectedAsset}
        assets={survivalAnaysisOverview.assets}
        uniqueAssets={filteredAssets}
        onShowDetails={showDetails}
        assetsPerSubComponent={survivalAnaysisOverview.cumulative}
        fetchSquaresData={fetchDashboardAssetsData}
        detailType="Assets"
        eolDates={survivalAnaysisOverview.eol}
        handleModalSuccess={handleModalSuccess}
      />
    );
  }

  return (
    <Grid container spacing={1} marginTop={1}>
      <Grid item xs={12} md={2}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          marginBottom={1}
        >
          <Typography
            sx={{
              paddingRight: '5px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Date Range{' '}
            <DatePicker
              showIcon
              selected={selectedStartDate}
              onChange={handleDateChange}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              maxDate={new Date()}
              selectsRange
              className="custom-datepicker"
            />
          </Typography>

          <ButtonGroup
            color="secondary"
            aria-label="Medium-sized button group"
            fullWidth
          >
            <Button
              key="KPIs"
              onClick={() => {
                handleButtonClick('KPIs');
              }}
              sx={{
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom:
                  activeTab === 'KPIs' ? '2px solid #1976d2' : 'none',
                color: activeTab === 'KPIs' ? '#1976d2' : 'grey',
              }}
            >
              KPIs
            </Button>
            <Button
              key="Graphs"
              onClick={() => {
                handleButtonClick('Graphs');
              }}
              sx={{
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom:
                  activeTab === 'Graphs' ? '2px solid #1976d2' : 'none',
                color: activeTab === 'Graphs' ? '#1976d2' : 'grey',
              }}
            >
              Graphs
            </Button>
          </ButtonGroup>
        </Box>
        {renderContent()}
      </Grid>

      <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />

      <Grid item xs={12} md={9}>
        <Header
          enableDarkTheme={enableDarkTheme}
          allAssets={assetsItems}
          handleIsWorkOrder={handleIsWorkOrder}
          isUploadWorkOrder={isUploadWorkOrder}
          onMonthSelect={handleMonthSelect} // Pass onMonthSelect to Header
        />
        <AssetTable
          assets={transformServiceViewToHealthScore(
            filteredAssets,
            assetsItems
          )}
          assetsDetails={survivalAnaysisOverview.assets}
          assetsPerSubComponent={survivalAnaysisOverview.cumulative}
          filteredAssets={filteredAssets}
          enableDarkTheme={enableDarkTheme}
          onShowDetails={showDetails}
          selectedMonth={selectedMonth} // Pass selectedMonth to AssetTable
        />
      </Grid>
    </Grid>
  );
};
