import { useLocation } from 'react-router-dom';
import { PageLoader } from './Loader';

const ConditionalPageLoader = ({ children }: any) => {
  const location = useLocation();
  const excludedRoutes = ['/notifications']; // Define routes where PageLoader should not appear

  const shouldShowPageLoader = !excludedRoutes.includes(location.pathname);

  return (
    <>
      {shouldShowPageLoader && <PageLoader />}
      {children}
    </>
  );
};

export default ConditionalPageLoader;
