import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import { formatDateShortener } from 'utils/helpers/dateTime';
import FuelAnalyticsAPI from 'api/fuelAnalytics.api';
import { type Asset } from 'types/asset.types';
import TireProfileAPI from 'api/tire-pressure';
import GeoAPI from 'api/geo.api';

export default class HealthIndexHandler extends BaseHandler {
  private readonly api: FuelAnalyticsAPI;
  private readonly tireApi: TireProfileAPI;
  private readonly geoApi: GeoAPI;

  constructor() {
    super();
    this.api = new FuelAnalyticsAPI();
    this.tireApi = new TireProfileAPI();
    this.geoApi = new GeoAPI();
  }

  async getHealthData(selectedAsset: Asset) {
    this.dispatch(updateLoadingState(true));
    try {
      const twoDaysAgo = new Date(new Date().getTime() - 72 * 60 * 60 * 1000);
      const formattedDate = formatDateShortener(twoDaysAgo);
      const [airFilter, tireApi, tripInfo] = await Promise.allSettled([
        this.api.getAirFilters([selectedAsset.device?.deviceId]),
        this.tireApi.getAssetTireData(
          selectedAsset.device.deviceId,
          'Last 7 Days',
          selectedAsset.assetType
        ),
        this.geoApi.getTrips(selectedAsset.device.deviceId, formattedDate),
      ]);

      this.handleSuccess();
      return { airFilter, tireApi, tripInfo };
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel analytics data: ' + msg
      );
    }
  }
}
