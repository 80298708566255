import { useAppSelector } from 'store/hook';

import useTheme from '@mui/material/styles/useTheme';
import { isDarkTheme } from 'utils/theme';
import { SingleFadeLoader } from './SingleFadeLoader';

export const PageLoader = () => {
  const {
    palette: { primary },
  } = useTheme();

  const { isLoading } = useAppSelector((state) => state.appReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  if (!isLoading) {
    return null;
  }

  return <SingleFadeLoader />;
};
