import { Outlet } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Grid2 from '@mui/material/Unstable_Grid2';
import { CollapsableListItem, SWListItem } from 'components/Layout/ListItem';

import SideBarBG from 'assets/bg/sidebar-bg.svg';
import { SVG } from 'components/Asset/SVG';
import Permission from 'components/Permission/Permission';
import { useRemoveAuth } from 'hooks/useRemoveAuth';
import type { SidebarListItemOptions } from './types';
import { SnackBar } from 'components/SnackBar/SnackBar';
import { TopBar } from './TopBar/TopBar';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { underGroundCustomers } from 'utils/helpers/general';

interface Props {
  children?: JSX.Element | null;
  routes: SidebarListItemOptions;
  utilizationRoutes: SidebarListItemOptions;
  productionRoutes: SidebarListItemOptions;
  tireRoutes: SidebarListItemOptions;
  fuelUsage: SidebarListItemOptions;
  settingRoutes: SidebarListItemOptions;
  squaresRoutes: SidebarListItemOptions;
  survivalAnalysis: SidebarListItemOptions;
  fleetOverViewRoutes: SidebarListItemOptions;
}

export const SideBar = ({
  routes,
  utilizationRoutes,
  productionRoutes,
  tireRoutes,
  fuelUsage,
  settingRoutes,
  squaresRoutes,
  survivalAnalysis,
  fleetOverViewRoutes,
}: Props): JSX.Element => {
  const drawerWidth = '200px';
  const { execRemoveAuth } = useRemoveAuth();
  const {
    email: userEmail,
    customer: { code },
  } = useAppSelector((state) => state.authReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const isUnderGroundCustomer = underGroundCustomers.includes(code);
  const shouldShowSquares =
    userEmail === 'squares-dev@symboticware.com' ||
    userEmail.includes('scott.weinhold');

  const filteredroutes = isUnderGroundCustomer
    ? routes.filter((route) => route.displayName !== 'Road Condition Summary')
    : routes;

  return (
    <>
      <Grid2
        container
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
        }}
      >
        <Grid2 width={drawerWidth}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.pageBackground
                  : ThemePalette.light.pageBackground,
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: drawerWidth,
                height: '100%',
                color: 'white',
                backgroundColor: 'primary.main',
                backgroundImage: `url(${SideBarBG})`,
                backgroundSize: 'cover',
                paddingTop: 0,
              }}
            >
              <div style={{ flex: 1, backgroundColor: '#0A2341' }}>
                <SVG
                  name="sidebarLogoMain"
                  style={{
                    width: drawerWidth,
                    height: '100px',
                  }}
                />
                {/* FleetOverview category */}
                <CollapsableListItem
                  displayName="Fleet Overview"
                  iconName="sidebarFleetOverview"
                >
                  <>
                    {fleetOverViewRoutes.map(
                      ({ path, displayName, iconName, roles }) => (
                        <Permission
                          key={path}
                          roles={roles}
                          fallbackComponent={null}
                        >
                          <SWListItem
                            key={path}
                            path={path}
                            listItemDisplay={displayName}
                            iconName={iconName}
                            ignoreIcon
                            variant="medium"
                            listItemStyle={{
                              backgroundColor: 'primary.dark',
                            }}
                          />
                        </Permission>
                      )
                    )}
                  </>
                </CollapsableListItem>

                {filteredroutes.map(
                  ({ path, displayName, iconName, roles }) => (
                    <Permission
                      key={path}
                      roles={roles}
                      fallbackComponent={null}
                    >
                      <SWListItem
                        path={path}
                        listItemDisplay={displayName}
                        iconName={iconName}
                      />
                    </Permission>
                  )
                )}

                {/* Fuel Usage category */}
                <CollapsableListItem
                  displayName="FuelUsage"
                  iconName="sidebarFuelUsage2"
                >
                  <>
                    {fuelUsage.map(({ path, displayName, iconName, roles }) => (
                      <Permission
                        key={path}
                        roles={roles}
                        fallbackComponent={null}
                      >
                        <SWListItem
                          key={path}
                          path={path}
                          listItemDisplay={displayName}
                          iconName={iconName}
                          ignoreIcon
                          variant="medium"
                          listItemStyle={{
                            backgroundColor: 'primary.dark',
                          }}
                        />
                      </Permission>
                    ))}
                  </>
                </CollapsableListItem>

                {/* Utilization category */}
                <CollapsableListItem
                  displayName="Utilization"
                  iconName="sidebarUtilization"
                >
                  <>
                    {utilizationRoutes.map(
                      ({ path, displayName, iconName, roles }) => (
                        <Permission
                          key={path}
                          roles={roles}
                          fallbackComponent={null}
                        >
                          <SWListItem
                            key={path}
                            path={path}
                            listItemDisplay={displayName}
                            iconName={iconName}
                            ignoreIcon
                            variant="medium"
                            listItemStyle={{
                              backgroundColor: 'primary.dark',
                            }}
                          />
                        </Permission>
                      )
                    )}
                  </>
                </CollapsableListItem>

                {/* Production category */}
                <CollapsableListItem
                  displayName="Production"
                  iconName="sidebarProduction"
                >
                  <>
                    {productionRoutes.map(
                      ({ path, displayName, iconName, roles }) => (
                        <Permission
                          key={path}
                          roles={roles}
                          fallbackComponent={null}
                        >
                          <SWListItem
                            key={path}
                            path={path}
                            listItemDisplay={displayName}
                            iconName={iconName}
                            ignoreIcon
                            variant="medium"
                            listItemStyle={{
                              backgroundColor: 'primary.dark',
                            }}
                          />
                        </Permission>
                      )
                    )}
                  </>
                </CollapsableListItem>

                {/* Tire Pressure category */}
                {['gdy', 'ncat'].includes(code) && (
                  <CollapsableListItem
                    displayName="Tire Analytics"
                    iconName="sidebarTirePressure"
                  >
                    <>
                      {tireRoutes.map(
                        ({ path, displayName, iconName, roles }) => (
                          <Permission
                            key={path}
                            roles={roles}
                            fallbackComponent={null}
                          >
                            <SWListItem
                              key={path}
                              path={path}
                              listItemDisplay={displayName}
                              iconName={iconName}
                              ignoreIcon
                              variant="medium"
                              listItemStyle={{
                                backgroundColor: 'primary.dark',
                              }}
                            />
                          </Permission>
                        )
                      )}
                    </>
                  </CollapsableListItem>
                )}

                {/* Settings category */}
                <CollapsableListItem
                  displayName="Settings"
                  iconName="sidebarSettings"
                >
                  <>
                    {settingRoutes.map(
                      ({ path, displayName, iconName, roles }) => (
                        <Permission
                          key={path}
                          roles={roles}
                          fallbackComponent={null}
                        >
                          <SWListItem
                            key={path}
                            path={path}
                            listItemDisplay={displayName}
                            iconName={iconName}
                            ignoreIcon
                            variant="medium"
                            listItemStyle={{
                              backgroundColor: 'primary.dark',
                            }}
                          />
                        </Permission>
                      )
                    )}
                  </>
                </CollapsableListItem>
                {/* Fuel Analytics category */}
                {shouldShowSquares && (
                  <CollapsableListItem
                    displayName="X.Parts"
                    iconName="sidebarSquares"
                  >
                    <>
                      {squaresRoutes.map(
                        ({ path, displayName, iconName, roles }) => (
                          <Permission
                            key={path}
                            roles={roles}
                            fallbackComponent={null}
                          >
                            <SWListItem
                              key={path}
                              path={path}
                              listItemDisplay={displayName}
                              iconName={iconName}
                              ignoreIcon
                              variant="medium"
                              listItemStyle={{
                                backgroundColor: 'primary.dark',
                              }}
                            />
                          </Permission>
                        )
                      )}
                    </>
                  </CollapsableListItem>
                )}
                {shouldShowSquares && (
                  <CollapsableListItem
                    displayName="Survival Analysis"
                    iconName="sidebarAnalytics"
                  >
                    <>
                      {survivalAnalysis.map(
                        ({ path, displayName, iconName, roles }) => (
                          <Permission
                            key={path}
                            roles={roles}
                            fallbackComponent={null}
                          >
                            <SWListItem
                              key={path}
                              path={path}
                              listItemDisplay={displayName}
                              iconName={iconName}
                              ignoreIcon
                              variant="medium"
                              listItemStyle={{
                                backgroundColor: 'primary.dark',
                              }}
                            />
                          </Permission>
                        )
                      )}
                    </>
                  </CollapsableListItem>
                )}
              </div>

              {/* Log Out button */}
              <SWListItem
                onClick={() => {
                  sessionStorage.removeItem('assetSelectedTags');
                  sessionStorage.clear();
                  execRemoveAuth();
                }}
                path="/login"
                listItemDisplay="Log Out"
                ignoreIcon
                listItemStyle={{
                  backgroundColor: 'inherit',
                }}
                listItemTextStyle={{
                  textAlign: 'center',
                }}
              />
            </List>
          </Drawer>
        </Grid2>
        <Grid2 xs={true} paddingRight="16px" paddingLeft="16px">
          <TopBar />
          <Outlet />
        </Grid2>
      </Grid2>
      <SnackBar />
    </>
  );
};
