import dayjs from 'dayjs';

export const formatStartEndDate = (
  rangeSelected: string,
  startDate?: Date,
  endDate?: Date
) => {
  let startApiDate = dayjs().format('YYYY-MM-DD');
  let endApiDate = dayjs().format('YYYY-MM-DD');
  switch (rangeSelected) {
    case '1D': {
      startApiDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      break;
    }
    case '7D': {
      startApiDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      break;
    }
    case '14D': {
      startApiDate = dayjs().subtract(14, 'day').format('YYYY-MM-DD');
      break;
    }
    case '1M': {
      startApiDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
      break;
    }
    case 'Custom': {
      if (startDate && endDate) {
        startApiDate = dayjs(startDate).format('YYYY-MM-DD');
        endApiDate = dayjs(endDate).format('YYYY-MM-DD');
      } else {
        return null;
      }
      break;
    }
    default:
      break;
  }
  return { startApiDate, endApiDate };
};
