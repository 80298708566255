import {
  Cartesian3,
  Color,
  Ion,
  PolylineDashMaterialProperty,
  Math as CesiumMath,
} from 'cesium';
import {
  Viewer,
  Entity,
  PolylineGraphics,
  CameraFlyTo,
  PointGraphics,
} from 'resium';
import { type GeoTrip } from 'types/geo.types';
import { getEnvVariable } from 'utils/helpers/getEnvVariable';
import { dummyCredit } from 'views/FleetOverview/components/AssetMap';
import NoChartData from '../NoChart';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import arrowImage from '../../../assets/icons/bisqueArrowUp.png';
import React from 'react';
import useFullscreenChange from 'hooks/geoMaps';
import { formatTimestamp } from '../utils';

interface Props {
  trip: GeoTrip;
}

const createArrowEntities = ({
  coordinates,
  interval,
  description,
  color,
}: {
  coordinates: number[];
  interval: number;
  description: string;
  color: Color;
}) => {
  const arrows = [];
  const numPoints = coordinates.length / 3;

  for (let i = 0; i < numPoints - interval; i += interval) {
    const start = Cartesian3.fromDegrees(
      coordinates[i * 3],
      coordinates[i * 3 + 1],
      coordinates[i * 3 + 2]
    );
    const end = Cartesian3.fromDegrees(
      coordinates[(i + interval) * 3],
      coordinates[(i + interval) * 3 + 1],
      coordinates[(i + interval) * 3 + 2]
    );

    const midPoint = Cartesian3.midpoint(start, end, new Cartesian3());

    const direction = Cartesian3.subtract(end, start, new Cartesian3());
    const angle = Math.atan2(direction.y, direction.x);

    arrows.push(
      <Entity
        position={midPoint}
        key={`arrow-${i}`}
        billboard={{
          image: arrowImage,
          width: 16,
          height: 16,
          rotation: angle - CesiumMath.PI_OVER_TWO, // Adjust rotation since the image faces north by default
          color,
        }}
        description={`${description}`}
      />
    );
  }
  return arrows;
};

const PathViewer = ({ trip }: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const viewerRef = React.useRef<any>(null);

  const loadedTripStartTime = new Date(trip?.loaded_trip_start_ts);
  const loadedTripEndTime = new Date(trip?.loaded_trip_end_ts);

  const unloadedTripStartTime = new Date(trip?.unloaded_trip_start_ts);
  const unloadedTripEndTime = new Date(trip?.unloaded_trip_end_ts);
  if (
    !loadedTripStartTime ||
    !loadedTripEndTime ||
    !unloadedTripStartTime ||
    !unloadedTripEndTime
  ) {
    return (
      <NoChartData message="No trip data" enableDarkTheme={enableDarkTheme} />
    );
  }

  const sortedPaths = trip?.paths.Items.sort(
    (a, b) =>
      new Date(a.sample_timestamp).getTime() -
      new Date(b.sample_timestamp).getTime()
  );

  const loadedTripCoordinates = sortedPaths?.filter((coord) => {
    const timestamp = new Date(coord?.sample_timestamp);
    return timestamp >= loadedTripStartTime && timestamp <= loadedTripEndTime;
  });

  const unloadedTripCoordinates = sortedPaths?.filter((coord) => {
    const timestamp = new Date(coord.sample_timestamp);
    return (
      timestamp >= unloadedTripStartTime && timestamp <= unloadedTripEndTime
    );
  });
  if (!loadedTripCoordinates || !unloadedTripCoordinates) {
    return (
      <NoChartData message="No trip data" enableDarkTheme={enableDarkTheme} />
    );
  }

  const flatCoordinatesLoadedTrip = loadedTripCoordinates.flatMap((coord) => {
    return Object.entries(coord)
      .filter(([key]) => key !== 'sample_timestamp')
      .map(([_, value]) => value);
  });

  const flatCoordinatesUnloadedTrip = unloadedTripCoordinates.flatMap(
    (coord) => {
      return Object.entries(coord)
        .filter(([key]) => key !== 'sample_timestamp')
        .map(([_, value]) => value);
    }
  );

  Ion.defaultAccessToken = getEnvVariable(
    'REACT_APP_CESIUM_ION_DEFAULT_ACCESS_TOKEN'
  );
  const arrowsForUnloaded =
    unloadedTripCoordinates.length > 0
      ? createArrowEntities({
          coordinates: flatCoordinatesUnloadedTrip,
          interval: 1,
          description: 'Direction of Unloaded trip path',
          color: Color.ORANGE,
        })
      : null;
  const arrowsForLoaded =
    flatCoordinatesLoadedTrip.length > 0
      ? createArrowEntities({
          coordinates: flatCoordinatesLoadedTrip as number[],
          interval: 1,
          description: 'Direction of loaded trip path',
          color: Color.BLUE,
        })
      : null;
  useFullscreenChange(viewerRef);

  return !trip || trip.paths.Items.length <= 0 ? (
    <NoChartData message="No trip data" enableDarkTheme={enableDarkTheme} />
  ) : (
    <Viewer
      creditContainer={dummyCredit}
      timeline={false}
      navigationHelpButton={false}
      homeButton={false}
      animation={false}
      ref={viewerRef}
    >
      {loadedTripCoordinates.length > 0 &&
      typeof loadedTripCoordinates[1]?.gpslongitude === 'number' &&
      typeof loadedTripCoordinates[1]?.gpslatitude === 'number' ? (
        <CameraFlyTo
          destination={Cartesian3.fromDegrees(
            loadedTripCoordinates[1]?.gpslongitude,
            loadedTripCoordinates[1]?.gpslatitude,
            1000
          )}
          duration={0}
        />
      ) : unloadedTripCoordinates.length > 0 &&
        typeof unloadedTripCoordinates[1]?.gpslongitude === 'number' &&
        typeof unloadedTripCoordinates[1]?.gpslatitude === 'number' ? (
        <CameraFlyTo
          destination={Cartesian3.fromDegrees(
            unloadedTripCoordinates[1]?.gpslongitude,
            unloadedTripCoordinates[1]?.gpslatitude,
            1000
          )}
          duration={0}
        />
      ) : null}

      {loadedTripCoordinates.length > 0 && (
        <>
          <TripPits
            coordinates={{
              gpslongitude: trip.loading_longitude,
              gpslatitude: trip.loading_latitude,
              gpsaltitude: 0,
            }}
            name={`Loading Area: ${trip.loading_area}`}
            description={`Loading between: ${formatTimestamp(
              new Date(trip.loading_start_ts)?.getTime()
            )} and ${formatTimestamp(
              new Date(trip.loading_end_ts)?.getTime()
            )}`}
            color={Color.YELLOW}
          />
          {arrowsForLoaded}

          {loadedTripCoordinates.map((path, index) => (
            <Entity
              key={index}
              name={`Loaded Trip Path for Trip ${trip.trip}`}
              description={`Loaded Trip between ${trip.loaded_trip_start_ts} and ${trip.loaded_trip_end_ts}         
        `}
              position={Cartesian3.fromDegrees(
                path.gpslongitude,
                path.gpslatitude,
                0
              )}
            >
              {loadedTripCoordinates[
                loadedTripCoordinates.indexOf(path) + 1
              ] && (
                <PolylineGraphics
                  positions={Cartesian3.fromDegreesArrayHeights([
                    path.gpslongitude,
                    path.gpslatitude,
                    0,
                    loadedTripCoordinates[index + 1].gpslongitude,
                    loadedTripCoordinates[index + 1].gpslatitude,
                    0,
                  ])}
                  width={4}
                  material={
                    new PolylineDashMaterialProperty({
                      color: Color.BLUE,
                    })
                  }
                  clampToGround={true}
                />
              )}
              <PointGraphics color={Color.BLUE} pixelSize={10} />
            </Entity>
          ))}
        </>
      )}

      {unloadedTripCoordinates.length > 0 && (
        <>
          {unloadedTripCoordinates.map((path, index) => (
            <Entity
              key={index}
              name={`unLoaded Trip Path for Trip ${trip.trip}`}
              description={`unLoaded Trip between ${formatTimestamp(
                new Date(trip.unloaded_trip_start_ts)?.getTime()
              )} and ${formatTimestamp(
                new Date(trip.unloaded_trip_end_ts)?.getTime()
              )}    
        `}
              position={Cartesian3.fromDegrees(
                path.gpslongitude,
                path.gpslatitude,
                0
              )}
            >
              {unloadedTripCoordinates[
                unloadedTripCoordinates.indexOf(path) + 1
              ] && (
                <PolylineGraphics
                  positions={Cartesian3.fromDegreesArrayHeights([
                    path.gpslongitude,
                    path.gpslatitude,
                    0,
                    unloadedTripCoordinates[
                      unloadedTripCoordinates.indexOf(path) + 1
                    ].gpslongitude,
                    unloadedTripCoordinates[
                      unloadedTripCoordinates.indexOf(path) + 1
                    ].gpslatitude,
                    0,
                  ])}
                  width={4}
                  material={
                    new PolylineDashMaterialProperty({
                      color: Color.ORANGE,
                    })
                  }
                  clampToGround={true}
                />
              )}
              <PointGraphics color={Color.ORANGE} pixelSize={10} />
            </Entity>
          ))}
          {arrowsForUnloaded}
          <TripPits
            coordinates={{
              gpslongitude: trip.unloading_longitude,
              gpslatitude: trip.unloading_latitude,
              gpsaltitude: 0,
            }}
            name={`Unloading Area: ${trip.unloading_area}`}
            description={`Unloading between: ${trip.unloading_start_ts} and ${trip.unloading_end_ts}`}
            color={Color.MAGENTA}
          />
        </>
      )}
    </Viewer>
  );
};

const TripPits = ({
  coordinates,
  name,
  description,
  color,
}: {
  coordinates: {
    gpslongitude: number;
    gpslatitude: number;
    gpsaltitude: number;
  };
  name: string;
  description: string;
  color: Color;
}) => {
  return (
    <Entity
      name={name}
      description={description}
      position={Cartesian3.fromDegrees(
        coordinates.gpslongitude,
        coordinates.gpslatitude,
        coordinates.gpsaltitude
      )}
    >
      <PointGraphics color={color} pixelSize={30} />
    </Entity>
  );
};

export default PathViewer;
