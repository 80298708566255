import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ThemePalette } from 'mui.theme';
import AssetMap from '../components/AssetMap';
import { type Asset } from 'types/asset.types';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { SVG } from 'components/Asset/SVG';
import { includedLargeSVgs } from 'views/HealthIndex/utils';
import { assetTypeMappings } from '../helpers/asset.helpers';
import {
  getIsAssetOffline,
  getMakeModel,
  svgStyleImport,
  type CustomCSSProperties,
} from '../helpers/map.helpers';
import CircularProgressChart from 'views/Production/components/CircularProgress/CircularProgress';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import {
  calculateCurrentDailyAverage,
  hasMetTarget,
  targetPercentageDiff,
} from 'views/Production/AllFleet/utils';
import {
  calculateUtilCurrentDailyAverage,
  daysLeftInYear,
  getShiftHours,
} from 'views/Utilization/utils';
import { truncateTo2dp } from 'utils/helpers/general';

interface Props {
  filteredAssets: Asset[];
}

const FleetOverView3 = ({ filteredAssets }: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const viewerRef = useRef<any>(null);
  const {
    data: {
      allFleet: { totalFleetDetails, fleetPeriodSummary },
    },
    loadingStates,
  } = useAppSelector((state) => state.productionReducer);

  const { data: utilData, loadingStates: utilLoadingState } = useAppSelector(
    (state) => state.utilizationReducer
  );

  const {
    allFleet: {
      fleetDetails: { fleet: fleetUtilData },
      fleetPeriodSummary: utilFleetPeriodSummary,
    },
  } = utilData;

  const { unitSystem, code: customerCode } = useAppSelector(
    (state) => state.persistedReducer
  ).customer;

  const targetData = useAppSelector((state) => state.targetReducer.data.target);
  const isLoadingTarget = useAppSelector(
    (state) => state.targetReducer.loadingStates?.isLoadingTarget
  );

  const [isAssetPanelVisible, setIsAssetPanelVisible] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredArray, setFilteredArray] = useState(filteredAssets);
  const [rollingDailyAverage, setRollingDailyAverage] = useState<number>(0); // TODO
  const [sevenDayAvg, setDayAvg] = useState<number>(0); // TODO

  const noOfAssets = Number(fleetUtilData.number_of_assets);

  const target = 365 * getShiftHours(customerCode) * noOfAssets;

  const currentDailyAvg = useMemo(() => {
    return calculateUtilCurrentDailyAverage(utilFleetPeriodSummary.fleet ?? []);
  }, [utilFleetPeriodSummary.fleet]);

  useEffect(() => {
    const filteredData = filteredAssets.filter(
      (asset) =>
        asset.assetType.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.model.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.bumperNumber
          .toLowerCase()
          .includes(String(searchValue).toLowerCase())
    );

    setFilteredArray(filteredData);
  }, [searchValue, filteredAssets]);

  const onEyeClick = () => {
    setIsAssetPanelVisible(!isAssetPanelVisible);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const [selectedAsset, setselectedAsset] = useState<Asset | null>(null);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const handleEntityClick = (asset?: Asset, movement?: any, position?: any) => {
    if (!asset) {
      setselectedAsset(null);
    } else {
      const cartesian2 =
        viewerRef.current.cesiumElement.scene.cartesianToCanvasCoordinates(
          position
        );

      setClickPosition(cartesian2);
      setselectedAsset(asset);
    }
  };

  const makeModel = getMakeModel(selectedAsset as Asset);

  const currentDailyAverage = useMemo(() => {
    return calculateCurrentDailyAverage(fleetPeriodSummary ?? []);
  }, [fleetPeriodSummary]);

  return (
    <Grid
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        color: '#fff',
      }}
    >
      {/* Sidebar */}
      <Box
        style={{
          position: 'absolute',
          top: 10,
          left: 10,
          width: '18%',
          height: '100%',

          zIndex: 10,
          color: 'white',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            marginBottom: '15px',
            display: 'flex',
          }}
        >
          <TextField
            label="Search Asset"
            variant="outlined"
            InputProps={{
              sx: {
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                backgroundColor: enableDarkTheme ? '#152642' : '#ffffff',
                width: '95%',
                height: '50px',
              },
            }}
            InputLabelProps={{
              sx: {
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                '&.Mui-focused': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                },
              },
            }}
            sx={{
              marginTop: '8px',
              borderRadius: '5px',
              width: '100%',
              '& .MuiInputBase-input::placeholder': {
                color: 'gray',
              },
            }}
            value={searchValue}
            onChange={onChange}
          />
          <SVG
            name={'hideIcon'}
            style={{
              marginTop: '8px',
              height: '50px',
              width: '20%',
              cursor: 'pointer',
            }}
            onClick={onEyeClick}
          />
        </Box>
        <Box
          style={{
            backgroundColor: enableDarkTheme
              ? '#0A2341'
              : ThemePalette.light.boxBackground,
            border: 'solid 1px rgba(255,255,255, 0.1)',
            borderRadius: 5,
            display: !isAssetPanelVisible ? 'none' : 'block',
            marginBottom: '15px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#3666EC',
              padding: '10px',
              border: 'none',
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: '#ffffff',
              }}
            >
              Assets
            </Typography>
          </Box>

          {filteredArray.map((asset, index) => {
            const makeModel = getMakeModel(asset);
            const isAssetOffline = getIsAssetOffline(asset);
            const isNotification =
              asset?.notifications && asset?.notifications?.length > 0;
            const svgStyle: CustomCSSProperties = {
              '--background-color': isAssetOffline ? '#C3C3C3' : '#0000FF',
              '--mountain-color': isAssetOffline ? '#787878' : '#FFFFFF',
            };
            return (
              <Grid key={index} sx={{ cursor: 'pointer' }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '60%',
                    justifyContent: 'left',
                    padding: '10px',
                    paddingBottom: '0px',
                  }}
                >
                  <Box>
                    <SVG
                      name={
                        asset.assetType === 'haul_truck'
                          ? includedLargeSVgs.includes(makeModel)
                            ? `${makeModel}ImageLarge`
                            : `cat810eImageLarge`
                          : `cat810eImageLarge`
                      }
                      style={{
                        height: '60px',
                        width: '90px',
                        marginLeft: '-20px',
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      marginLeft: '-15px',
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: enableDarkTheme ? '#ffffff' : '#000000',
                          fontSize: '16px',
                          marginRight: '5px',
                        }}
                      >
                        {asset.bumperNumber}
                      </Typography>
                      <SVG
                        name={'hillsIcon'}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginRight: '5px',
                          ...svgStyle,
                        }}
                      />
                      <SVG
                        name={isNotification ? 'redIcon2' : 'greenIcon2'}
                        style={{
                          fill: '#EB5757',
                          height: '20px',
                          width: '20px',
                        }}
                      />
                    </Box>

                    <Grid container>
                      <Typography
                        sx={{
                          color: enableDarkTheme
                            ? 'rgba(225,225,225, 0.5)'
                            : 'rgba(0,0,0, 0.5)',
                          fontSize: '10px',
                        }}
                      >
                        {assetTypeMappings[asset.assetType]}
                      </Typography>

                      {asset?.liveTagData?.gps_latitude &&
                      asset?.liveTagData?.gps_longitude ? null : (
                        <Typography
                          sx={{
                            color: enableDarkTheme
                              ? 'rgba(225,225,225, 0.5)'
                              : 'rgba(0,0,0, 0.5)',
                            backgroundColor: 'rgba(195, 195, 195, 0.2)',
                            padding: '2px 5px',
                            borderRadius: '5px',
                            fontSize: '10px',
                          }}
                        >
                          gps data not available
                        </Typography>
                      )}
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    paddingLeft: '10px',
                    justifyContent: 'space-between',
                    width: '60%',
                    paddingBottom: '10px',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <SVG
                      name={'productionIcon'}
                      style={{
                        fill: enableDarkTheme ? '#ffffff' : '#000000',
                        height: '20px',
                        width: '20px',
                        paddingRight: '5px',
                      }}
                    />
                    <Typography
                      sx={{
                        color: enableDarkTheme ? '#ffffff' : '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        paddingRight: '5px',
                      }}
                    >
                      {truncateTo2dp(
                        asset.productivity?.prod_score
                          ? asset.productivity.prod_score
                          : 0
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <SVG
                      name={'utilizationIcon'}
                      style={{
                        fill: enableDarkTheme ? '#ffffff' : '#000000',
                        height: '20px',
                        width: '20px',
                        paddingRight: '5px',
                      }}
                    />
                    <Typography
                      sx={{
                        color: enableDarkTheme ? '#ffffff' : '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        paddingRight: '5px',
                      }}
                    >
                      {truncateTo2dp(
                        asset.utilization?.util_score
                          ? +asset.utilization.util_score
                          : 0
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <SVG
                      name={'fuelUsageIcon'}
                      style={{
                        fill: enableDarkTheme ? '#ffffff' : '#000000',
                        height: '20px',
                        width: '20px',
                        paddingRight: '5px',
                      }}
                    />
                    <Typography
                      sx={{
                        color: enableDarkTheme ? '#ffffff' : '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {truncateTo2dp(
                        asset.fuelUsage?.fuel_score
                          ? +asset.fuelUsage.fuel_score
                          : 0
                      )}
                      %
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    background: enableDarkTheme
                      ? 'rgba(225,225,225, 0.5)'
                      : ThemePalette.border,
                    fontSize: '10px',
                    width: '100%',
                  }}
                />
              </Grid>
            );
          })}
        </Box>
      </Box>

      {/* Analytics Panel */}
      <Box
        style={{
          position: 'absolute',
          top: 50,
          right: 20,
          width: '15%',
          height: '90%',
          backgroundColor: '#0d1b2a',
          zIndex: 10,
          color: '#fff',
          padding: '10px',
          borderRadius: 5,
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={2} sx={{ color: '#FFF' }}>
          <Grid item xs={12}>
            <Card
              sx={{
                backgroundColor: '#16293E',
                padding: 2,
                borderRadius: 2,
                border: 'solid 1px rgba(255,255,255, 0.3)',
                color: '#ffffff',
              }}
            >
              <Grid container>
                <SVG
                  name={'sidebarProduction'}
                  style={{
                    height: '25px',
                    width: '25px',
                    marginRight: '5px',
                    marginTop: '2px',
                    backgroundColor: 'rgba(54, 102, 236, 1)',
                    padding: '5px',
                    borderRadius: '45%',
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#ffffff',
                  }}
                >
                  Production
                </Typography>

                <ChevronRightIcon
                  sx={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    marginLeft: '20px',
                    marginTop: '5px',
                  }}
                  fontSize={'large'}
                />
              </Grid>

              {loadingStates.isLoadingFleetDetails ? (
                <MultiColorCircularLoader height="300px" />
              ) : (
                <Box
                  sx={{
                    paddingTop: '15px',
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ marginLeft: '20%' }}>
                        <CircularProgressChart
                          enableDarkTheme={enableDarkTheme}
                          value={totalFleetDetails.tons}
                          target={totalFleetDetails.ytdTarget}
                          unitSystem={unitSystem}
                        />
                      </Box>
                    </Grid>
                    <Grid container>
                      <Grid item lg={6} sm={12}>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.black,
                            }}
                          >
                            YTD Target
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                              paddingBottom: '15px',
                            }}
                          >
                            {formatNumberToShortScale(
                              convertToTwoDecimalPlaces(
                                totalFleetDetails.ytdTarget.toString()
                              )
                            )}{' '}
                            {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: '11px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.black,
                            }}
                          >
                            Rolling Daily Avg
                          </Typography>
                          {loadingStates.isLoadingFleetPeriodSummary ? (
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.white
                                  : ThemePalette.typography.black,
                                paddingTop: '10px',
                              }}
                            >
                              Loading...
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.white
                                  : ThemePalette.typography.black,
                                paddingTop: '10px',
                              }}
                            >
                              {formatNumberToShortScale(
                                convertToTwoDecimalPlaces(
                                  (rollingDailyAverage || 0).toString()
                                )
                              )}
                              <span
                                style={{
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                }}
                              >
                                {' '}
                                {unitSystem === 'imperial'
                                  ? 'iTons/Day'
                                  : 'Tons/Day'}
                              </span>
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item lg={6} sm={12}>
                        <Box>
                          <Grid container>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                }}
                              >
                                Annual Target
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                  paddingBottom: '15px',
                                }}
                              >
                                {formatNumberToShortScale(
                                  convertToTwoDecimalPlaces(
                                    totalFleetDetails.annualTarget.toString()
                                  )
                                )}{' '}
                                {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                }}
                              >
                                Current Daily Avg
                              </Typography>
                              {loadingStates.isLoadingFleetPeriodSummary ? (
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.white
                                      : ThemePalette.typography.black,
                                    paddingTop: '10px',
                                  }}
                                >
                                  Loading...
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.white
                                      : ThemePalette.typography.black,
                                    paddingTop: '10px',
                                  }}
                                >
                                  {utilLoadingState.isLoadingFleetPeriodSummary
                                    ? 'Loading...'
                                    : formatNumberToShortScale(
                                        convertToTwoDecimalPlaces(
                                          (currentDailyAverage ?? 0).toString()
                                        )
                                      )}
                                  <span
                                    style={{
                                      color: enableDarkTheme
                                        ? ThemePalette.typography.lightGrey
                                        : ThemePalette.typography.black,
                                    }}
                                  >
                                    {' '}
                                    {unitSystem === 'imperial'
                                      ? 'iTons/Day'
                                      : 'Tons/Day'}
                                  </span>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>

                          {hasMetTarget(totalFleetDetails) ? (
                            <></>
                          ) : (
                            <Box
                              sx={{
                                textAlign: 'center',
                                border: '1px solid #2F445F',
                                borderRadius: '5px',
                                marginTop: '15px',
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <WarningIcon
                                  sx={{ color: '#FFB402', marginRight: '4px' }}
                                />
                                Avg Production needs to go up by
                                <span
                                  style={{
                                    color: '#FFB402',
                                    marginLeft: '4px',
                                  }}
                                >
                                  {targetPercentageDiff(totalFleetDetails)}%
                                </span>
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              sx={{
                backgroundColor: '#16293E',
                padding: 2,
                borderRadius: 2,
                border: 'solid 1px rgba(255,255,255, 0.3)',
                color: '#ffffff',
              }}
            >
              <Grid container>
                <SVG
                  name={'sidebarUtilization'}
                  style={{
                    height: '25px',
                    width: '25px',
                    marginRight: '5px',
                    marginTop: '2px',
                    backgroundColor: 'rgba(54, 102, 236, 1)',
                    padding: '5px',
                    borderRadius: '45%',
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#ffffff',
                  }}
                >
                  Utilization
                </Typography>

                <ChevronRightIcon
                  sx={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    marginLeft: '20px',
                    marginTop: '5px',
                  }}
                  fontSize={'large'}
                />
              </Grid>

              <Box
                sx={{
                  paddingTop: '15px',
                }}
              >
                <Grid container>
                  <Grid
                    item
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ marginLeft: '20%' }}>
                      <CircularProgressChart
                        enableDarkTheme={enableDarkTheme}
                        value={Number(
                          fleetUtilData.total_engine_working_minutes
                        )}
                        target={(targetData?.targetValue || target) * 60}
                        unitSystem={unitSystem}
                      />
                    </Box>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} sm={12}>
                      <Box>
                        <>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.black,
                            }}
                          >
                            YTD Target
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                          >
                            {isLoadingTarget
                              ? 'Loading...'
                              : `${formatNumberToShortScale(
                                  targetData?.ytdTarget
                                )} Hours`}
                          </Typography>
                        </>

                        <Typography
                          sx={{
                            fontSize: '11px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          Rolling Daily Average
                        </Typography>
                        {utilLoadingState.isLoadingFleetPeriodSummary ? (
                          <Typography
                            sx={{
                              fontSize: '13px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                              paddingTop: '10px',
                            }}
                          >
                            Loading...
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                          >
                            {sevenDayAvg > 0 ? (
                              <>
                                {truncateTo2dp(sevenDayAvg)}
                                <span
                                  style={{
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.lightGrey
                                      : ThemePalette.typography.black,
                                  }}
                                >
                                  {' '}
                                  Hours/Day
                                </span>
                              </>
                            ) : (
                              <>{'No data available'}</>
                            )}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '11px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Annual Productivity Target
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        }}
                      >
                        {formatNumberToShortScale(
                          targetData?.targetValue ||
                            365 * getShiftHours(customerCode) * noOfAssets
                        )}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                            paddingBottom: '15px',
                          }}
                        >
                          {' '}
                          Hours
                        </span>
                      </Typography>

                      {/* <>
                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                }}
                              >
                                Annual Target
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                {isLoadingTarget
                                  ? 'Loading...'
                                  : `${formatNumberToShortScale(
                                      targetData?.targetValue
                                    )} Hours`}
                              </Typography>
                            </> */}
                      <Typography
                        sx={{
                          fontSize: '11px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        Current Daily Average
                      </Typography>
                      {utilLoadingState.isLoadingFleetPeriodSummary ? (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                        >
                          Loading...
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                        >
                          {truncateTo2dp(currentDailyAvg / 60)}
                          <span
                            style={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.black,
                            }}
                          >
                            {' '}
                            Hours/Day
                          </span>
                        </Typography>
                      )}
                    </Grid>

                    {!hasMetTarget ? (
                      <></>
                    ) : (
                      <Box
                        sx={{
                          textAlign: 'center',
                          border: '1px solid #2F445F',
                          borderRadius: '5px',
                          marginTop: '15px',
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleIcon
                            sx={{ color: '#029161', marginRight: '4px' }}
                          />
                          Avg Productive hours are on target
                        </Typography>
                      </Box>
                    )}
                    {/* </Box> */}
                    {/* </Grid> */}
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>

          {/* Productive Hours Card */}
          {/* <Grid item xs={12}>
            <Card
              sx={{ backgroundColor: '#16293E', padding: 2, borderRadius: 2 }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Productive Hours
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    YTD Actual
                  </Typography>
                  <CircularProgress
                    variant="determinate"
                    value={70}
                    size={100}
                    sx={{ color: '#00BFFF', mt: 1 }}
                  />
                  <Typography variant="h4" sx={{ textAlign: 'center', mt: 1 }}>
                    2,100 Hours
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="body2">
                    YTD Target: 2,000 Hours
                  </Typography>
                  <Typography variant="body2">
                    Annual Target: 3,000 Hours
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    95 Hours/Day
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid> */}
        </Grid>
      </Box>

      {/* Cesium Viewer */}
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <AssetMap
          filteredAssets={filteredArray}
          setselectedAsset={setselectedAsset}
          handleEntityClick={handleEntityClick}
          selectedAsset={selectedAsset}
          viewerRef={viewerRef}
        />

        {/* // ANOTHER COMPONENT Info box */}
        {selectedAsset && (
          <Box
            style={{
              position: 'absolute',
              bottom: '10%',
              width: '30%',
              backgroundColor: '#09182D',
              color: 'white',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
              zIndex: 10,
              top: clickPosition.y + 20,
              left: clickPosition.x + 80,
              transform: 'translate(-50%, 0)',
            }}
          >
            {/*  chatbox tail */}
            <Box
              sx={{
                position: 'absolute',
                top: '-10px',
                left: '32%',
                transform: 'translateX(-50%)',
                width: '0',
                height: '0',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '10px solid #0d1b2a',
                zIndex: 1,
              }}
            />

            <Box
              sx={{
                maxHeight: '100%',
                overflowY: 'auto',
                paddingRight: '10px',
              }}
            >
              <Grid
                container
                direction="row"
                spacing={1}
                sx={{ alignItems: 'center' }}
              >
                <Grid item>
                  <SVG
                    name={
                      selectedAsset.assetType === 'haul_truck'
                        ? includedLargeSVgs.includes(makeModel)
                          ? `${makeModel}ImageLarge`
                          : `cat810eImageLarge`
                        : `cat810eImageLarge`
                    }
                    style={{
                      height: '60px',
                      width: '90px',
                      marginLeft: '-20px',
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {selectedAsset.bumperNumber}
                  </Typography>
                  <Typography sx={{ fontSize: '10px' }}>
                    {assetTypeMappings[selectedAsset.assetType]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      padding: '4px',
                      paddingX: '8px',
                      backgroundColor: getIsAssetOffline(selectedAsset)
                        ? 'rgba(195, 195, 195, 0.5)'
                        : 'rgba(0, 0, 255, 0.3)',
                      maxHeight: '17px',
                      borderRadius: '15px',
                    }}
                  >
                    <span>
                      <SVG
                        name={'hillsIcon'}
                        style={{
                          height: '15px',
                          width: '15px',
                          marginRight: '5px',
                          marginBottom: '-5px',
                          ...svgStyleImport(selectedAsset),
                        }}
                      />
                    </span>
                    {getIsAssetOffline(selectedAsset)
                      ? 'Asset Offline'
                      : 'Asset Online'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      padding: '4px',
                      paddingX: '8px',
                      backgroundColor:
                        selectedAsset?.notifications &&
                        selectedAsset.notifications.length > 0
                          ? 'rgba(235, 87, 87, 0.5)'
                          : 'rgba(0, 145, 97, 0.2)',
                      maxHeight: '17px',
                      borderRadius: '15px',
                      marginRight: '80px',
                    }}
                  >
                    <span>
                      <SVG
                        name={
                          selectedAsset?.notifications &&
                          selectedAsset.notifications.length > 0
                            ? 'redIcon2'
                            : 'greenIcon2'
                        }
                        style={{
                          height: '15px',
                          width: '15px',
                          marginRight: '5px',
                          marginBottom: '-5px',
                        }}
                      />
                    </span>
                    {selectedAsset?.notifications &&
                    selectedAsset.notifications.length > 0
                      ? 'Warning'
                      : 'Optimal'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    size="small"
                    icon={<CloseIcon sx={{ color: 'white' }} />}
                    sx={{
                      color: 'white',
                      marginTop: '-50px',
                      marginRight: '-50px',
                    }}
                    onClick={() => {
                      setselectedAsset(null);
                    }}
                  />
                </Grid>
              </Grid>

              {/* Status Section */}
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginY: '16px', justifyContent: 'space-around' }}
              >
                <Chip
                  label="Engine On"
                  size="small"
                  icon={<DoneIcon sx={{ color: 'white' }} />}
                  sx={{ backgroundColor: '#204536', color: 'white' }}
                />
                <Chip
                  label="1st Gear"
                  size="small"
                  sx={{ backgroundColor: '#204536', color: 'white' }}
                />
                <Chip
                  label="2 Tire Warning"
                  size="small"
                  sx={{ backgroundColor: '#D64545', color: 'white' }}
                />
                <Chip
                  label="21 Liters"
                  size="small"
                  sx={{ backgroundColor: '#D64545', color: 'white' }}
                />
              </Stack>

              {/* Driver Details Section */}
              {/* <Typography
                sx={{ fontWeight: 'bold', opacity: '0.4', fontSize: '12px' }}
              >
                Driver Details
              </Typography>
              <Grid
                container
                alignItems="center"
                spacing={2}
                sx={{ marginY: '8px' }}
              >
                <Grid item>
                  <Avatar alt="Driver Image" src="/path-to-driver-image.jpg" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Harsh Jain
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      opacity: '0.4',
                      fontSize: '12px',
                    }}
                  >
                    3 h 25 mins
                  </Typography>
                </Grid>
              </Grid> */}

              {/* Metrics Section */}
              {/* <Grid container spacing={2} sx={{ marginTop: '16px' }}>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      opacity: '0.4',
                      fontSize: '12px',
                    }}
                  >
                    Payload
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    322 <span style={{ opacity: '0.4' }}>Tons</span>
                  </Typography>
                  <Typography variant="caption" color="error">
                    ↓ 10.24%{' '}
                    <span style={{ opacity: '0.4' }}>
                      <Typography variant="caption" color="white">
                        than Fleet Average{' '}
                      </Typography>{' '}
                    </span>
                  </Typography>
                  <Chip
                    label="Fleet Average: 350 Tons"
                    size="small"
                    sx={{
                      marginTop: '8px',
                      backgroundColor: '#42516E',
                      color: 'white',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      opacity: '0.4',
                      fontSize: '12px',
                    }}
                  >
                    Shift Trips
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    12
                  </Typography>
                  <Typography variant="caption" color="error">
                    ↓ 50%{' '}
                    <span style={{ opacity: '0.4' }}>
                      <Typography variant="caption" color="white">
                        than Fleet Average{' '}
                      </Typography>{' '}
                    </span>
                  </Typography>
                  <Chip
                    label="Fleet Average: 12"
                    size="small"
                    sx={{
                      marginTop: '8px',
                      backgroundColor: '#42516E',
                      color: 'white',
                    }}
                  />
                </Grid>
              </Grid> */}

              {/* Suggestions Section */}
              {/* <Typography
                sx={{
                  fontWeight: 'bold',
                  opacity: '0.4',
                  fontSize: '12px',
                  marginTop: '16px',
                }}
              >
                Key Suggestions
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: '8px' }}>
                <Grid item xs={6}>
                  <Button startIcon={<WarningIcon />}>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                      }}
                    >
                      Inflate left tire
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    startIcon={<WarningIcon />}
                    sx={{
                      fontSize: '12px',
                      color: 'white',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                      }}
                    >
                      Change engine oil
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    startIcon={<WarningIcon />}
                    sx={{
                      fontSize: '12px',
                      color: 'white',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                      }}
                    >
                      Replace air filter
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    startIcon={<WarningIcon />}
                    sx={{
                      fontSize: '12px',
                      color: 'white',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                      }}
                    >
                      Replace oil filter
                    </Typography>
                  </Button>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default FleetOverView3;
