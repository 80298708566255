import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import RuleHandler from 'handlers/rule.handler';
import { type Notification } from 'types/notification.types';
import { ThemePalette } from 'mui.theme';

export {};

/**
 *
 * The Delete rule modal.
 */

interface Props {
  open: boolean;
  rule: Notification;
  handleAbort: () => void;
  enableDarkTheme: boolean;
}

export const DeleteRuleModal = ({
  open,
  rule,
  handleAbort,
  enableDarkTheme,
}: Props) => {
  const ruleHandler = new RuleHandler();
  const title = `Delete Rule ${rule.ruleName}`;
  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          await ruleHandler.softDeleteRule(rule.ruleId);
          handleAbort();
        },
        disabled: false,
        label: 'Delete Rule',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      enableDarkTheme={enableDarkTheme}
    >
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          fontSize: '14px',
        }}
        key="blurb-1"
      >
        Please confirm that you want to delete this rule
      </Typography>
      <Typography key="blurb-2" variant="small"></Typography>
    </InteractiveModal>
  );
};
