import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { useAppSelector } from 'store/hook';
import { COLORS } from 'utils/enums';
const { svgIcons } = theme.palette;

export const NotificationCounter = () => {
  const [unReadNotificationsCount, setUnReadNotificationsCount] =
    React.useState<number>(0);
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/notifications');
  };

  const { notifications } = useAppSelector(
    (state) => state.ruleNotificationsReducer
  );

  React.useEffect(() => {
    const unReadNotifications = notifications.filter((ntfc) => !ntfc.isRead);
    setUnReadNotificationsCount(unReadNotifications.length);
  }, [notifications]);

  return (
    <span style={{ paddingRight: '10px', position: 'relative' }}>
      {unReadNotificationsCount > 0 && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '18px',
            height: '18px',
            backgroundColor: COLORS.red,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '8px',
          }}
        >
          {unReadNotificationsCount}
        </div>
      )}
      <SVG
        name="notificationIcon"
        style={{
          ...svgIcons.primary,
          width: '30px',
          height: '30px',
          cursor: 'pointer',
        }}
        onClick={handleRedirect}
      />
    </span>
  );
};
