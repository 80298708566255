/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import {
  Cartesian3,
  Color,
  Ion,
  PolylineArrowMaterialProperty,
  ScreenSpaceEventType,
} from 'cesium';
import {
  Viewer,
  Entity,
  CameraFlyTo,
  ScreenSpaceEventHandler,
  ScreenSpaceEvent,
  PolylineGraphics,
} from 'resium';

import { type Asset } from 'types/asset.types';
import {
  ASSET_TYPES,
  COLORS,
  CUSTOMERS_WITH_NO_GPS,
  NOTIFICATION_LEVEL,
} from 'utils/enums';
import NoChartData from 'views/FuelAnalytics/NoChart';
import { handleAssetStatus } from 'views/Settings/ExternalUsers/AssetView/helpers/common';
import { getEnvVariable } from 'utils/helpers/getEnvVariable';
import { useAppSelector } from 'store/hook';
import { truncateTo2dp } from 'utils/helpers/general';
import { selectAssetsWithExcessiveIdling } from 'store/asset.slice';
import { isDarkTheme } from 'utils/theme';
import useFullscreenChange from 'hooks/geoMaps';
import { SVG } from 'components/Asset/SVG';
import {
  type CustomCSSProperties,
  svgToDataUrl,
  toCartesian3,
} from 'views/FleetOverview/helpers/map.helpers';
import { Box, Typography } from '@mui/material';
import { type Device } from 'types/device.types';
import { useState } from 'react';

interface Props {
  filteredAssets: Asset[];
  searchValue?: string;
  setselectedAsset?: any;
  handleEntityClick?: any;
  selectedAsset: Asset | null;
  viewerRef: any;
}
export const dummyCredit = document.createElement('div');

const AssetMap = ({
  filteredAssets,
  setselectedAsset,
  handleEntityClick,
  selectedAsset,
  viewerRef,
}: Props) => {
  const customerId = useAppSelector((state) => state.persistedReducer).customer
    .id;

  const assetsWithExccessiveIdling = useAppSelector(
    selectAssetsWithExcessiveIdling
  );
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const handleMouseEnter = () => {
    // Change cursor to pointer when hovering over the entity
    document.body.style.cursor = 'pointer';
  };

  const handleMouseLeave = () => {
    // Reset cursor to default when mouse leaves the entity
    document.body.style.cursor = 'default';
  };

  if (CUSTOMERS_WITH_NO_GPS.find((item) => item.id === customerId)) {
    return (
      <NoChartData
        message={
          'GPS mapping is not available for this site at this time. Please Contact 1-800-519-5496 for assistance.'
        }
        enableDarkTheme={enableDarkTheme}
      />
    );
  }
  const [newPosition, setNewPosition] = useState<any>();

  const assetList: Asset[] = [];

  Ion.defaultAccessToken = getEnvVariable(
    'REACT_APP_CESIUM_ION_DEFAULT_ACCESS_TOKEN'
  );

  filteredAssets.map((asset) => {
    if (asset?.liveTagData?.gps_latitude && asset?.liveTagData?.gps_longitude) {
      return assetList.push(asset);
    } else {
      return assetList;
    }
  });

  if (assetList.length < 1) {
    return (
      <NoChartData
        message="No GPS data available for the listed assets"
        enableDarkTheme={enableDarkTheme}
      />
    );
  }

  const sumLongitude: number = assetList.reduce(
    (total: number, obj: Record<string, any>) => {
      return total + Number(obj?.liveTagData?.gps_longitude);
    },
    0
  );
  const sumLatitude: number = assetList.reduce(
    (total: number, obj: Record<string, any>) => {
      return total + Number(obj?.liveTagData?.gps_latitude);
    },
    0
  );
  const deviceCount = assetList.filter(
    (obj: Record<string, any>) => obj?.liveTagData?.gps_latitude !== undefined
  ).length;

  const averageLongitude = deviceCount > 0 ? sumLongitude / deviceCount : 0;
  const averageLatitude = deviceCount > 0 ? sumLatitude / deviceCount : 0;

  const generateArrowSegments = (asset: Asset) => {
    const path = asset.latestGeoPaths?.paths.Items ?? [];

    if (path.length < 2) return null;

    const start = asset.liveTagData;
    const end = path[0];

    if (!start || !end) return null;

    const startCartesian = toCartesian3(
      Number(start?.gps_longitude),
      Number(start?.gps_latitude),
      Number(start?.gps_altitude)
    );
    const endCartesian = toCartesian3(
      end.gpslongitude,
      end.gpslatitude,
      end.gpsaltitude
    );

    const interpolationFactor = 0.08;
    const shortEndCartesian = Cartesian3.lerp(
      startCartesian,
      endCartesian,
      interpolationFactor,
      new Cartesian3()
    );

    return { start: startCartesian, end: shortEndCartesian };
  };

  return (
    <Viewer
      style={{
        position: 'inherit',
        width: '100%',
        height: '100%',
      }}
      ref={viewerRef}
      creditContainer={dummyCredit}
      timeline={false}
      navigationHelpButton={false}
      homeButton={false}
      animation={false}
      infoBox={false}
      // selectionIndicator={false}
    >
      <CameraFlyTo
        // TODO: make this fly in only when opened and not everytime the data is updated
        destination={toCartesian3(averageLongitude, averageLatitude, 10000)}
        duration={0}
      />
      {assetList.map((device: Asset) => {
        if (
          !device.liveTagData?.gps_longitude ||
          !device.liveTagData?.gps_latitude
        ) {
          return undefined;
        }

        const tagsForMapView = device.device.tags.filter(
          (item: any) => item.showOnFleetOverviewMap
        );

        const tags: any = [];
        tagsForMapView.map((tag) => {
          const tagFromLiveTag = device?.liveTagData?.[`${tag.tagName}`];
          tags.push([
            `${tag.tagAlias || tag.tagName}`,
            `${tagFromLiveTag}`,
            `${tag.unit}`,
          ]);
          return null;
        });

        const longitude = Number(device.liveTagData.gps_longitude);
        const latitude = Number(device.liveTagData.gps_latitude);
        const assetStatus = handleAssetStatus(device.isActive);
        const upperCaseAssetStatus = `${assetStatus
          .charAt(0)
          .toUpperCase()}${assetStatus.slice(1)}`;
        const isExcessiveIdling = assetsWithExccessiveIdling.find(
          (asset) => asset.device === device.device.deviceId
        );
        const color = isExcessiveIdling
          ? '#FF8A00'
          : device.notifications && device.notifications.length <= 0
          ? '#00FFFF'
          : device?.notifications?.some(
              (el: any) =>
                el.rule.notificationLevel === NOTIFICATION_LEVEL[2].id
            )
          ? '#A03434'
          : device?.notifications?.some(
              (el: any) =>
                el.rule.notificationLevel === NOTIFICATION_LEVEL[1].id
            )
          ? '#F5C274'
          : '#77859F';

        const svgStyle: CustomCSSProperties = {
          '--circle-color': color,
          '--background-color':
            (device.device.deviceStatus as string).toLowerCase() !== 'online'
              ? '#C3C3C3'
              : '#0000FF',
          '--mountain-color':
            (device.device.deviceStatus as string).toLowerCase() !== 'online'
              ? '#787878'
              : '#FFFFFF',
        };

        const svgDataUrl = svgToDataUrl(
          <SVG
            name="alertsOnlineHaulTruck"
            width={50}
            height={50}
            style={svgStyle}
          />
        );

        const segments = generateArrowSegments(device);
        return (
          <Box
            data-tooltip-id="my-tooltip"
            key={device.device.deviceId}
            style={{ cursor: 'pointer' }}
          >
            <Entity
              name={`${
                ASSET_TYPES.find((e) => e.id === device.assetType)?.display
              } ${device.device.assetBumperNumber}`}
              position={toCartesian3(longitude, latitude)}
              billboard={{
                image: svgDataUrl,
              }}
              description={undefined}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              id={device.device.deviceId}
              onClick={(movement) => {
                const position = toCartesian3(longitude, latitude);
                setNewPosition(position);
                handleEntityClick(device, movement, position);
              }}
            >
              {segments && (
                <PolylineGraphics
                  positions={[segments.start, segments.end]}
                  material={
                    new PolylineArrowMaterialProperty(
                      Color.fromCssColorString('#64e52d')
                    )
                  }
                  clampToGround
                  width={25}
                />
              )}
            </Entity>
          </Box>
        );
      })}
      <ScreenSpaceEventHandler>
        <ScreenSpaceEvent
          action={(movement) => {
            // handleEntityClick(null, movement, newPosition); // Deselect any entity if the map is clicked
          }}
          type={ScreenSpaceEventType.LEFT_CLICK}
        />
      </ScreenSpaceEventHandler>
    </Viewer>
  );
};

export default AssetMap;
