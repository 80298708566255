import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  List,
  Button,
  Checkbox,
  Typography,
  Badge,
  Divider,
  Grid,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { groupedData, fineGrainGroupedData } from 'utils/helpers/tags';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { ThemePalette } from 'mui.theme';
import { determineBadgeCount } from 'views/VehicleStatusView/utils';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
  tags: any[];
  addTags: any;
  defaultTags: string[];
  dataType: string;
}

export const SelectTagModal = ({
  title,
  open,
  handleAbort,
  enableDarkTheme = false,
  tags,
  addTags,
  defaultTags,
  dataType,
}: Props) => {
  const [selectOptions, setSelectOption] = useState<any[]>([]);
  const [currentGroup, setCurrentGroup] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([...defaultTags]);
  const [tagOptions, setTagOptions] = useState<any[]>([]);
  const [value, setValue] = useState('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const tagsToWorkWith = [...tags];
    const tagOptionsWithName = tagsToWorkWith.map((item) => {
      return {
        id: item.tagName,
        display: item.tagAlias,
        tagName: item.tagName,
      };
    });

    const groupedTags: any[] = groupedData({
      selectorOptions: tagOptionsWithName,
    });

    const fineGrainGroupedTags: any = fineGrainGroupedData(tagOptionsWithName);

    const groupToUse =
      dataType === 'regular' ? groupedTags : fineGrainGroupedTags;

    const filteredTags = groupToUse.map((item: any) => ({
      name: item.groupName,
      tags: item.options,
    }));

    setSelectOption([...filteredTags]);
    setCurrentGroup(filteredTags[0]?.name);
  }, [tags]);

  useEffect(() => {
    if (currentGroup !== '') {
      const selectedGroup = selectOptions.find(
        (item) => item.name === currentGroup
      );

      const possibleTags = selectedGroup?.tags?.map((item: any) => {
        return { ...item };
      });
      setTagOptions(possibleTags);
    }
  }, [currentGroup]);

  useEffect(() => {
    if (value !== '') {
      const allTags = [...tags].map((item) => item.tagAlias ?? item.tagName);

      const searchedTags = allTags.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );

      const possibleOptions = searchedTags.map((item) => {
        const itemDetails = tags.find((td) =>
          [td.tagAlias, td.tagName].includes(item)
        );
        return {
          id: itemDetails.tagName,
          tagName: itemDetails.tagName,
          display: itemDetails.tagAlias,
        };
      });
      setCurrentGroup('');
      setTagOptions(possibleOptions);
    }
  }, [value]);

  const handleTagClick = (tagName: string) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tagName)) {
        return prevSelectedTags.filter((item) => item !== tagName);
      } else {
        return [...prevSelectedTags, tagName];
      }
    });
  };

  const handleTagGroupClick = (tagGroupName: string) => {
    setCurrentGroup(tagGroupName);
  };

  const selectAll = () => {
    const allTags = [...tags].map((item) => item.tagName);
    setSelectedTags(allTags);
  };

  const unSelectAll = () => {
    setSelectedTags([]);
    const allTags = [...tags].map((item) => item.tagName);
    const isSbIgnitionAvailable = allTags.find(
      (item) => item === 'sb_ignition_status'
    );
    if (isSbIgnitionAvailable) {
      setSelectedTags(['sb_ignition_status']);
    }
  };

  function isAllOptionsSelected() {
    const tagNames = tagOptions.map((item) => item.id);
    return tagNames.every((tag) => selectedTags.includes(tag));
  }

  function handleCategorySelectAll() {
    const tagNames = tagOptions.map((item) => item.id);
    const tagsToSelect = [...selectedTags, ...tagNames];
    setSelectedTags([...new Set(tagsToSelect)]);
  }

  function handleCategoryUnSelectAll() {
    const tagNames = tagOptions.map((item) => item.id);
    const tagsToSelect = selectedTags.filter(
      (item) => !tagNames.includes(item)
    );
    setSelectedTags(tagsToSelect);
  }

  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: false,
        onClick: () => {
          addTags([...new Set(selectedTags)]);
          handleAbort();
        },
        label: 'Apply',
      }}
      handleAbort={handleAbort}
      title={title}
      enableDarkTheme={enableDarkTheme}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          minWidth: '800px',
        }}
      ></List>
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <TextField
            label="Search for a tag"
            variant="outlined"
            InputProps={{
              sx: {
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                '&.Mui-focused': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                },
              },
            }}
            sx={{
              marginBottom: '15px',
              borderRadius: '4px',
              width: '70%',
              '& .MuiInputBase-input::placeholder': {
                color: 'gray',
              },
            }}
            value={value}
            onChange={handleChange} // Track the value in state
            onKeyDown={handleKeyDown} // Prevent Enter key from submitting or doing any action
          />
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: '20px',
            }}
          >
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {selectedTags.length} Tags Selected
            </Typography>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Button
                sx={{
                  color: '#3666EC',
                  textTransform: 'none',
                }}
                onClick={() => {
                  unSelectAll();
                }}
              >
                Deselect all
              </Button>
              <Button
                sx={{
                  color: '#3666EC',
                  textTransform: 'none',
                }}
                onClick={() => {
                  selectAll();
                }}
              >
                Select all
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            backgroundColor: '#4b535E',
          }}
        />
        {selectOptions.length > 0 ? (
          <Box>
            <Grid
              container
              sx={{
                minHeight: '350px',
                maxHeight: '350px',
                borderBottom: '1px solid #4b535E',
              }}
            >
              <Grid
                item
                lg={4}
                sx={{
                  borderRight: '1px solid #4b535E',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      color: 'white',
                      width: '100%',
                      height: '100%',
                      maxHeight: '350px', // Set a maximum height for scrolling
                      overflowY: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {selectOptions.map((tag, index) => (
                      <React.Fragment key={index}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            cursor: 'pointer',
                            backgroundColor:
                              currentGroup === tag.name ? '#09182D' : '',
                            '&:hover': {
                              backgroundColor: '#1D2738',
                            },
                            minHeight: '40px',
                            borderBottom: '1px solid #4b535E',
                          }}
                          onClick={() => {
                            handleTagGroupClick(tag.name);
                          }}
                        >
                          <Typography
                            sx={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                              fontSize: '12px',
                            }}
                          >
                            <Badge
                              badgeContent={determineBadgeCount(
                                tag.name,
                                selectedTags,
                                selectOptions
                              )}
                              color="primary"
                              sx={{
                                '& .MuiBadge-badge': { right: -10, top: 5 },
                              }}
                            >
                              {capitalizeFirstLetter(tag.name)}
                            </Badge>
                          </Typography>
                          <ArrowForwardIosIcon
                            sx={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                          />
                        </Box>
                        <Divider sx={{ backgroundColor: '#1D2738' }} />
                      </React.Fragment>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <Box
                  sx={{
                    padding: '10px',
                    paddingLeft: '20px',
                    height: '100%',
                    maxHeight: '330px', // Set a maximum height for scrolling
                    overflowY: 'auto',
                  }}
                >
                  <FormGroup>
                    {tagOptions.length > 0 && (
                      <>
                        <FormControlLabel
                          key="Select-all-cat"
                          control={
                            <Checkbox
                              checked={isAllOptionsSelected()}
                              onChange={() => {
                                isAllOptionsSelected()
                                  ? handleCategoryUnSelectAll()
                                  : handleCategorySelectAll();
                              }}
                              sx={{
                                alignSelf: 'center',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.cornflowerBlue
                                  : ThemePalette.typography.primary,
                                '&.Mui-checked': {
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.cornflowerBlue
                                    : ThemePalette.typography.primary,
                                },
                                '&.Mui-disabled': {
                                  color: ThemePalette.typography.secondary,
                                },
                              }}
                            />
                          }
                          label={
                            isAllOptionsSelected()
                              ? 'UnSelect All'
                              : 'Select All'
                          }
                          sx={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                        />
                        {tagOptions.map((tag: any, index: number) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={selectedTags.includes(tag.tagName)}
                                onChange={() => {
                                  handleTagClick(tag.tagName);
                                }}
                                sx={{
                                  alignSelf: 'center',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.cornflowerBlue
                                    : ThemePalette.typography.primary,
                                  '&.Mui-checked': {
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.cornflowerBlue
                                      : ThemePalette.typography.primary,
                                  },
                                  '&.Mui-disabled': {
                                    color: ThemePalette.typography.secondary,
                                  },
                                }}
                              />
                            }
                            label={tag.display}
                            sx={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                          />
                        ))}
                      </>
                    )}
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography
            sx={{
              fontSize: '12px',
              paddingTop: '10px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            No available tags for selected asset
          </Typography>
        )}
      </Box>
    </InteractiveModal>
  );
};
