import { useAppSelector } from 'store/hook';
import { CustomTabPanel } from '../components/CustomTabPanel';
import EventList from './components/EventsList';
import type { DriverBehaviorData } from 'types/driver.behavior.types';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  handleChange: any;
  data: DriverBehaviorData;
}

const Fleet = ({ value, enableDarkTheme, handleChange, data }: Props) => {
  const assetReducer = useAppSelector((state) => state.assetReducer);
  const { assets } = assetReducer;
  return (
    <>
      <CustomTabPanel value={value} index={0}>
        <div>
          <EventList
            enableDarkTheme={enableDarkTheme}
            handleChange={handleChange}
            data={data}
            assets={assets}
          />
        </div>
      </CustomTabPanel>
    </>
  );
};

export default Fleet;
