import * as htmlToImage from 'html-to-image';
import {
  pdf,
  Document,
  Page,
  Image,
  StyleSheet,
  Text,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

export const exportIndividualToPdf = async (
  chartIds: string[],
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined
): Promise<any> => {
  try {
    const images = await Promise.all(
      chartIds.map(async (id) => {
        const node = document.getElementById(id);
        if (node) {
          return await htmlToImage.toPng(node);
        }
        return null;
      })
    );

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        padding: 10,
        flexDirection: 'column',
      },
      header: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 10,
        color: enableDarkTheme ? '#ffffff' : '#000000',
      },
      chartImage: {
        marginBottom: 10,
      },
    });

    const pageOrientation = orientation ?? 'portrait';

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page} orientation={pageOrientation}>
          <Text style={styles.header}>{title}</Text>
          {images.map(
            (src, index) =>
              src && <Image key={index} src={src} style={styles.chartImage} />
          )}
        </Page>
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();
    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};
