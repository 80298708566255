import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import ErrorIcon from '@mui/icons-material/Error';
import SuggestionItems from './SuggestionItems';
import type { TripData } from 'types/production.types';
import MultiColorCircularLoader from '../BoxLoader/MultiColorCircularLoader';

interface Props {
  enableDarkTheme: boolean;
  minHeight: string;
  data: TripData[];
  isLoading: boolean;
  startDate: string;
  isSelected: string;
}

const Suggestions = ({
  enableDarkTheme,
  minHeight,
  data,
  isLoading,
  startDate,
  isSelected,
}: Props) => {
  return (
    <Grid item lg={3} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight,
          maxHeight: '300px', // Fixed height for the list
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        {/* <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          Suggestions
        </Typography> */}
        <Box
        // sx={{
        //   paddingTop: '10px',
        // }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : data.length > 0 ? (
            <SuggestionItems enableDarkTheme={enableDarkTheme} data={data[0]} />
          ) : (
            <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>
              No data available currently
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default Suggestions;
