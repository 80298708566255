import { Typography, ListItem, ListItemText, Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import {
  convertBasedOnUnitSystem,
  convertLitersToGallons,
  getLabelWrtUnitSystem,
} from 'utils/helpers/unitSystem';

interface Props {
  enableDarkTheme: boolean;
  data: {
    haulers: {
      totalFuel: number;
      idleFuel: number;
      idleFuelPercentage: number;
    };
    loaders: {
      totalFuel: number;
      idleFuel: number;
      idleFuelPercentage: number;
    };
    totalFleet: {
      totalFuel: number;
      idleFuel: number;
      idleFuelPercentage: number;
    };
  };
  assetType: string;
  unitSystem: string;
}

const SuggestionItems = ({
  enableDarkTheme,
  data,
  assetType,
  unitSystem,
}: Props) => {
  const renderFuelSavingsMessage = (name: string, percentage: number) => (
    <p>
      Possible fuel savings for {name}: {percentage.toFixed(2)}%
    </p>
  );

  const renderFactsMessage = (
    name: string,
    totalFuel: number,
    idleFuel: number
  ) => (
    <Box sx={{ marginLeft: 2 }}>
      {' '}
      {name}
      <Box sx={{ marginLeft: 2 }}>
        <p>
          Total Fuel:{' '}
          {convertBasedOnUnitSystem(
            convertLitersToGallons,
            totalFuel || 0,
            unitSystem
          ).toFixed(2)}{' '}
          {getLabelWrtUnitSystem('L', unitSystem).toLowerCase()}
        </p>
        <p>
          Idle Fuel:{' '}
          {convertBasedOnUnitSystem(
            convertLitersToGallons,
            idleFuel || 0,
            unitSystem
          ).toFixed(2)}{' '}
          {getLabelWrtUnitSystem('L', unitSystem).toLowerCase()}
        </p>
      </Box>
    </Box>
  );

  const renderSectionByAssetType = (
    renderFunction: (name: string, ...args: number[]) => JSX.Element
  ) => {
    switch (assetType) {
      case 'all':
        return (
          <>
            {renderFunction(
              'Total Fleet',
              data.totalFleet.totalFuel,
              data.totalFleet.idleFuel
            )}
            {renderFunction(
              'Haulers',
              data.haulers.totalFuel,
              data.haulers.idleFuel
            )}
            {renderFunction(
              'Loaders',
              data.loaders.totalFuel,
              data.loaders.idleFuel
            )}
          </>
        );
      case 'hauler':
        return renderFunction(
          'Haulers',
          data.haulers.totalFuel,
          data.haulers.idleFuel
        );
      case 'loader':
        return renderFunction(
          'Loaders',
          data.loaders.totalFuel,
          data.loaders.idleFuel
        );
      default:
        return <p>No valid asset type selected.</p>;
    }
  };
  const renderSavingsBasedOnAssetType = () => {
    switch (assetType) {
      case 'all':
        return (
          <>
            {renderFuelSavingsMessage(
              'Total Fleet',
              data.totalFleet.idleFuelPercentage
            )}
            {renderFuelSavingsMessage(
              'Haulers',
              data.haulers.idleFuelPercentage
            )}
            {renderFuelSavingsMessage(
              'Loaders',
              data.loaders.idleFuelPercentage
            )}
          </>
        );
      case 'hauler':
        return renderFuelSavingsMessage(
          'Haulers',
          data.haulers.idleFuelPercentage
        );
      case 'loader':
        return renderFuelSavingsMessage(
          'Loaders',
          data.loaders.idleFuelPercentage
        );
      default:
        return <p>No valid asset type selected.</p>;
    }
  };

  return (
    <>
      {data ? (
        <>
          <Typography
            sx={{ color: '#b0b7c3', fontSize: '10px', paddingTop: '2px' }}
          >
            <i>*** based on idling</i>
          </Typography>
          {/* Facts Section */}
          <ListItem
            sx={{
              borderBottom: '1px solid #2d3b55',
              paddingBottom: '16px',
              display: 'flex',
              direction: 'row',
            }}
          >
            <ListItemText
              primary={
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Facts
                </Typography>
              }
              secondary={
                <Typography
                  sx={{ color: '#b0b7c3', fontSize: '12px', paddingTop: '5px' }}
                >
                  {' '}
                  <p>Fuel consumption:</p>
                  {renderSectionByAssetType(renderFactsMessage)}
                </Typography>
              }
            />
          </ListItem>
          {/* Suggestions Section */}
          <ListItem
            sx={{
              borderBottom: '1px solid #2d3b55',
              paddingBottom: '16px',
              display: 'flex',
              direction: 'row',
            }}
          >
            <ListItemText
              primary={
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Recommendations
                </Typography>
              }
              secondary={
                <Typography
                  sx={{ color: '#b0b7c3', fontSize: '12px', paddingTop: '5px' }}
                >
                  {renderSavingsBasedOnAssetType()}
                </Typography>
              }
            />
          </ListItem>
        </>
      ) : (
        <Typography
          sx={{ color: '#b0b7c3', fontSize: '12px', paddingTop: '5px' }}
        >
          No data available.
        </Typography>
      )}
    </>
  );
};

export default SuggestionItems;
