import { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  type SelectChangeEvent,
  Button,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { FleetOverviewV2GridItem } from './FleetOverviewV2GridItem';
import {
  getGridDataPoints,
  getFleetKpiThreshold,
} from '../components/AssetCardsV2/utils';
import { ThemePalette } from 'mui.theme';
import DatePicker from 'react-datepicker';
import { useAppSelector } from 'store/hook';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import useDateRange from 'hooks/dateRange';

export const FleetOverviewV2GridBox = ({
  enableDarkTheme,
  assetOverview,
  hasNoDozer,
  isLoading,
}: {
  enableDarkTheme: boolean;
  assetOverview: any;
  hasNoDozer: boolean;
  isLoading: boolean;
}) => {
  const {
    customer: { code },
  } = useAppSelector((state) => state.authReducer);
  const isBuzzi = code === 'buz';
  const names = ['Fuel Usage', 'Utilization', 'Production'];
  const data = getGridDataPoints(assetOverview);
  const [printPage, setPrintPage] = useState<string[]>([names[0]]);

  const handleChange = (event: SelectChangeEvent<typeof names>) => {
    const {
      target: { value },
    } = event;
    setPrintPage(typeof value === 'string' ? value.split(',') : value);
  };

  const { startDate, endDate, setStartDate, setEndDate, yesterday } =
    useDateRange();

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const navigate = useNavigate();

  const handleGenerateClick = () => {
    navigate('/reports/productivity', {
      state: { startDate, endDate, pageType: printPage },
    });
  };
  return (
    <>
      <Box
        sx={{
          paddingTop: '15px',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
          }}
        >
          {/** Fleet Performance Grid Block */}
          <Grid item xs={12} lg={isBuzzi ? 2.5 : 3} md={6} sm={6}>
            <Card
              sx={{
                minWidth: 275,
                height: isBuzzi ? 200 : null,
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.boxBackground
                  : ThemePalette.light.boxBackground,
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  gutterBottom
                >
                  {/* <IconButton
                    edge="end"
                    sx={{
                      paddingRight: '15px',
                      color: '#EB5757',
                    }}
                  >
                    <ErrorIcon />
                  </IconButton> */}
                  Fleet Performance
                </Typography>
                <FleetOverviewV2GridItem
                  enableDarkTheme={enableDarkTheme}
                  value={data.fleet}
                  type="total"
                  kpiThresholds={getFleetKpiThreshold(
                    assetOverview.kpiThresholds
                  )}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </Grid>
          {/** Hauler Grid Block */}
          <Grid item xs={12} lg={isBuzzi ? 2.5 : 3} md={6} sm={6}>
            <Card
              sx={{
                minWidth: 275,
                height: isBuzzi ? 200 : null,
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.boxBackground
                  : ThemePalette.light.boxBackground,
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  gutterBottom
                >
                  {/* <IconButton
                    edge="end"
                    sx={{
                      paddingRight: '15px',
                      color: '#F9A405',
                    }}
                  >
                    <DoDisturbOnIcon />
                  </IconButton> */}
                  Hauler Performance
                </Typography>
                <FleetOverviewV2GridItem
                  enableDarkTheme={enableDarkTheme}
                  value={data.haul}
                  type={'Haul Truck'}
                  kpiThresholds={assetOverview.kpiThresholds}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </Grid>
          {/** Loader Grid Block */}
          <Grid item xs={12} lg={isBuzzi ? 2.5 : 3} md={6} sm={6}>
            <Card
              sx={{
                minWidth: 275,
                height: isBuzzi ? 200 : null,
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.boxBackground
                  : ThemePalette.light.boxBackground,
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  gutterBottom
                >
                  {/* <IconButton
                    edge="end"
                    sx={{
                      paddingRight: '15px',
                      color: '#229161',
                    }}
                  >
                    <CheckCircleIcon />
                  </IconButton> */}
                  Loader Performance
                </Typography>
                <FleetOverviewV2GridItem
                  enableDarkTheme={enableDarkTheme}
                  value={data.loader}
                  type={'Loader'}
                  kpiThresholds={assetOverview.kpiThresholds}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </Grid>
          {/** Dozer Grid Block */}
          <Grid item xs={12} lg={isBuzzi ? 2.5 : 3} md={6} sm={6}>
            <Card
              sx={{
                minWidth: 275,
                height: isBuzzi ? 200 : null,
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.boxBackground
                  : ThemePalette.light.boxBackground,
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  gutterBottom
                >
                  {/* <IconButton
                    edge="end"
                    sx={{
                      paddingRight: '15px',
                      color: '#EB5757',
                    }}
                  >
                    <ErrorIcon />
                  </IconButton> */}
                  Dozer Performance
                </Typography>
                <FleetOverviewV2GridItem
                  enableDarkTheme={enableDarkTheme}
                  value={data.dozer}
                  type={'Others'}
                  kpiThresholds={assetOverview.kpiThresholds}
                  disabled={hasNoDozer}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </Grid>

          {/** Generate report for print out */}
          {isBuzzi && (
            <Grid item xs={12} lg={2} md={6} sm={6}>
              <Card
                sx={{
                  maxWidth: '100%',
                  height: 200,
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.boxBackground
                    : ThemePalette.light.boxBackground,
                }}
              >
                <CardContent>
                  <FormControl>
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        marginBottom: '5px',
                        '&.Mui-focused': {
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        },
                        '&.Mui-active': {
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        },
                      }}
                    >
                      Generate PDF Report
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={printPage}
                      onChange={handleChange}
                      input={<OutlinedInput label="Select Report" />}
                      renderValue={(selected: any[]) => selected.join(', ')}
                      SelectDisplayProps={{
                        style: {
                          display: 'flex',
                          height: '10px',
                          minHeight: '10px',
                          alignItems: 'center',
                          width: '110px',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            minHeight: 0,
                            backgroundColor: enableDarkTheme
                              ? ThemePalette.dark.menuOptions
                              : ThemePalette.light.menuOptions,
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      }}
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                        ...(enableDarkTheme && {
                          '& .MuiSvgIcon-root': {
                            color: 'purple',
                          },
                          '&.MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: ThemePalette.border,
                            },
                            '&:hover fieldset': {
                              borderColor:
                                ThemePalette.typography.cornflowerBlue,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor:
                                ThemePalette.typography.cornflowerBlue,
                            },
                          },
                        }),

                        marginBottom: '12px',
                        borderRadius: '2px',
                      }}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={printPage.includes(name)}
                            sx={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.cornflowerBlue
                                : ThemePalette.typography.primary,
                              '&.Mui-checked': {
                                color: enableDarkTheme
                                  ? ThemePalette.typography.cornflowerBlue
                                  : ThemePalette.typography.primary,
                              },
                              '&.Mui-disabled': {
                                color: ThemePalette.typography.secondary,
                              },
                            }}
                          />
                          <ListItemText
                            primary={name}
                            sx={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.primary,
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <DatePicker
                    showIcon
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateChange}
                    maxDate={yesterday}
                    selectsRange
                    className={
                      enableDarkTheme
                        ? 'custom-datepicker'
                        : 'custom-datepicker-light'
                    }
                  />
                  <Button
                    sx={{
                      width: '157px',
                      textAlign: 'center',
                      marginTop: '5px',
                      borderRadius: '2px',
                      height: '35px',
                      backgroundColor: '#6495ED',
                      '&:hover': {
                        backgroundColor: '#1E90FF',
                      },
                    }}
                    onClick={handleGenerateClick}
                  >
                    Go
                  </Button>
                  <Typography
                    sx={{
                      marginTop: '4px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      fontSize: '8px',
                    }}
                  >
                    ** Report generated for the selected time period might be
                    different than the stats below in Assets Cards Summary as
                    they are “Last Known” stats for those assets
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
