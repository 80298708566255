import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { ThemePalette } from 'mui.theme';
import { SVG } from 'components/Asset/SVG';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { ASSET_TYPES } from 'utils/enums';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import TireProfileHandler from 'handlers/tire-pressure';
import { truncateTo2dp } from 'utils/helpers/general';
import { SWToolTip } from 'components/SWToolTip';
import { tireCodeNames } from './util';

const FleetInsight = () => {
  const tireProfileHandler = new TireProfileHandler();
  const navigate = useNavigate();
  const { assets, isLoadingAllAssets } = useAppSelector(
    (state) => state.assetReducer
  );
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const { fleetData, isLoadingFLeet } = useAppSelector(
    (state) => state.tireProfileReducer
  );
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const [fleetList, setFleetList] = useState<any[]>([...assets]);

  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (searchValue !== '') {
      const filteredList = assets.filter(
        (asset) =>
          asset.assetType.toLowerCase().includes(searchValue.toLowerCase()) ||
          asset.make.toLowerCase().includes(searchValue.toLowerCase()) ||
          asset.model.toLowerCase().includes(searchValue.toLowerCase()) ||
          asset.bumperNumber.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFleetList([...filteredList]);
    } else {
      setFleetList([...assets]);
    }
  }, [searchValue]);

  useEffect(() => {
    void tireProfileHandler.getFleetTireProfile();
  }, []);

  const formatTimestampWithHour = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('YYYY MMM DD HH:mm (Z)');
    return formattedDate;
  };

  return (
    <Box
      sx={{
        marginTop: '20px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.toolBarBackground
          : ThemePalette.light.toolBarBackground,
        padding: '10px',
        borderRadius: '4px',
      }}
    >
      {isLoadingAllAssets || isLoadingFLeet ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <SingleFadeLoader />
        </div>
      ) : (
        <>
          <SWTextField
            name="filter"
            placeholder={'Filter...'}
            TextFieldProps={{
              sx: {
                marginBottom: '20px',
                fontSize: '18px',
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                input: {
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                },
                ...(enableDarkTheme && {
                  border: `1px solid ${String(ThemePalette.border)}`,
                }),
                borderRadius: '3px',
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchValue(e.target.value);
              },
            }}
            enableDarkTheme={enableDarkTheme}
          />

          {fleetList.length > 0 ? (
            <Grid container spacing={2}>
              {fleetList.map((item, index) => {
                const itemData = fleetData[item.device.deviceId] ?? {};
                const dataKeys = Object.keys(itemData);
                const dataValues = Object.values(itemData);
                // @ts-expect-error ignoring since we have const for tirecodenames
                const tireCodes = tireCodeNames[item.assetType];
                return (
                  <Grid item key={index} lg={3} md={6} sm={6} xs={12}>
                    <Box
                      sx={{
                        borderRadius: '4px',
                        border: '1px solid #2E435D',
                        cursor: 'pointer',
                        minHeight: '260px',
                      }}
                      onClick={() => {
                        navigate(
                          `/tire-analytics/vehicle-insight?asset=${item.bumperNumber}`
                        );
                      }}
                    >
                      <Box
                        sx={{
                          borderTopRightRadius: '4px',
                          borderTopLeftRadius: '4px',
                          padding: '5px',
                          borderBottom: '1px solid #2E435D',
                          backgroundColor: enableDarkTheme
                            ? '#162A46'
                            : '#7385A3',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: ThemePalette.typography.white,
                            textDecoration: 'underline',
                          }}
                        >
                          {
                            ASSET_TYPES.find(
                              (type) => type.id === item.assetType
                            )?.display
                          }
                        </Typography>
                        <Typography
                          sx={{
                            paddingTop: '4px',
                            color: ThemePalette.typography.white,
                            fontSize: '10px',
                          }}
                        >
                          {item.bumperNumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: '10px',
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item lg={4}>
                            <SVG
                              name={mapVehiclesAssets(item.assetType)}
                              style={{
                                fill: '#E2C171',
                                paddingLeft: '20px',
                                width: '55px',
                              }}
                            />
                          </Grid>
                          <Grid item lg={8}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                OEM
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                {item.make}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '5px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                Model
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                {item.model}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          padding: '10px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                          >
                            Tire Pressure Status
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                              marginRight: '10px',
                            }}
                          >
                            {unitSystem === 'metric' ? 'PSI' : 'KPA'}
                          </Typography>
                        </Box>
                        {dataKeys.length > 0 ? (
                          <>
                            {dataKeys.map((item, index) => {
                              const tireCode = tireCodes[item];
                              return (
                                <div key={index}>
                                  <SWToolTip
                                    title={`Last Updated: ${
                                      Object.values(dataValues[index])
                                        .length === 0
                                        ? 'N/A'
                                        : formatTimestampWithHour(
                                            dataValues[index].ts
                                          )
                                    }`}
                                    placement="top-start"
                                  >
                                    <Box
                                      sx={{
                                        backgroundColor: enableDarkTheme
                                          ? '#27354c'
                                          : '#7385A3',
                                        marginTop: '10px',
                                        padding: '5px 10px 5px 10px',
                                        borderRadius: '4px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: '12px',
                                          color: enableDarkTheme
                                            ? ThemePalette.typography.white
                                            : ThemePalette.typography.black,
                                        }}
                                      >
                                        {tireCode}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: '12px',
                                          color: enableDarkTheme
                                            ? ThemePalette.typography.white
                                            : ThemePalette.typography.black,
                                        }}
                                      >
                                        {Object.values(dataValues[index])
                                          .length === 0 ? (
                                          <Typography
                                            sx={{
                                              fontSize: '12px',
                                              color: '#EB5758',
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <ErrorIcon
                                              fontSize="small"
                                              sx={{
                                                fontSize: '12px',
                                                marginRight: '4px',
                                              }}
                                            />
                                            NA
                                          </Typography>
                                        ) : (
                                          `${truncateTo2dp(
                                            dataValues[index].avg_val
                                          )}`
                                        )}
                                      </Typography>
                                    </Box>
                                  </SWToolTip>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>No Tire Pressure data</>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Typography> No asset available</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default FleetInsight;
