import { createSlice } from '@reduxjs/toolkit';
import type {
  FuelLoadedUnloadedAnalytics,
  FuelIdleEvents,
  FuelNoneIdleEvents,
  FuelConsumptionEngineHoursAnalytics,
  FuelConsumptionRangeAnalytics,
  HistoricalAnalysisCard,
  FleetDeviceDetails,
  AirFilterData,
} from 'types/fuelAnalytics';

export interface FuelAnalyticsState {
  fuelLoadedUnloadedAnalytics: FuelLoadedUnloadedAnalytics;
  fuelIdleEvents: FuelIdleEvents;
  fuelNoneIdleEvents: FuelNoneIdleEvents;
  fuelConsumptionEngineHoursAnalytics: FuelConsumptionEngineHoursAnalytics;
  fuelConsumptionRangeAnalytics: FuelConsumptionRangeAnalytics;
  historicalAnalysisCard: HistoricalAnalysisCard;
  fleetDeviceDetails: FleetDeviceDetails[];
  airFilterData: AirFilterData[];
  isLoading: boolean;
}

const initialState: FuelAnalyticsState = {
  fuelLoadedUnloadedAnalytics: {
    data: [],
    sortedBottom5LoadedFuelData: [],
    sortedBottom5UnLoadedFuelData: [],
    sortedTop5LoadedFuelData: [],
    sortedTop5UnLoadedFuelData: [],
    loadedFuelData: [],
    unloadedFuelData: [],
    loadedAverage: 0,
    unLoadedAverage: 0,
    engineAverages: [],
  },
  fuelIdleEvents: {
    data: [],
    sortedBottom5IdlingEventData: [],
    sortedTop5IdlingEventData: [],
    idlingEventAverage: 0,
  },
  fuelNoneIdleEvents: {
    data: [],
    sortedTop5NonEventIdlingData: [],
    sortedBottom5NonEventIdlingData: [],
    nonIdlingAverage: 0,
  },
  fuelConsumptionEngineHoursAnalytics: {
    data: [],
    sortedBottom5FuelConsumptionEngineHoursData: [],
    sortedTop5FuelConsumptionEngineHoursData: [],
    fuelConsumptionAverage: 0,
  },
  fuelConsumptionRangeAnalytics: [],
  historicalAnalysisCard: {
    totalFuelConsumed: 0,
    fuelConsumedIdling: 0,
    co2Emissions: 0,
    consumptioinIdex: 0,
  },
  fleetDeviceDetails: [],
  airFilterData: [],
  isLoading: false,
};

export const fuelAnalyticsSlice = createSlice({
  name: 'fuelAnalytics',
  initialState,
  reducers: {
    setFuelAnalytics(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearFuelAnalytics(state) {
      return initialState;
    },
    setFuelAnalyticsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { setFuelAnalytics, clearFuelAnalytics, setFuelAnalyticsLoading } =
  fuelAnalyticsSlice.actions;
export default fuelAnalyticsSlice.reducer;
