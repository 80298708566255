import FuelAnalyticsAPI from 'api/fuelAnalytics.api';
import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import {
  setFuelAnalytics,
  clearFuelAnalytics,
  setFuelAnalyticsLoading,
} from 'store/fuelAnalytics.slice';
import IdleAnalyticsAPI from 'api/idleAnalytics.api';
import { setIdleAnalytics } from 'store/idleAnalytics.slice';

export default class FuelAnalyticsHandler extends BaseHandler {
  private readonly api: FuelAnalyticsAPI;
  private readonly idleApi: IdleAnalyticsAPI;

  constructor() {
    super();

    this.api = new FuelAnalyticsAPI();
    this.idleApi = new IdleAnalyticsAPI();
  }

  // Gets asset usage dashboard analysis
  async getFuelUsageAnalytics(
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    isHaulTruck: boolean = true,
    startDate: string,
    endDate: string,
    selectedConsumptionRange: string,
    assetTypeCategory: string
  ) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setFuelAnalyticsLoading(true));
    try {
      let response: any = {};
      const fuelType = isHaulTruck
        ? 'getFuelEfficiency'
        : 'getFuelConsumptionEngineHours';
      const fuelReducer = isHaulTruck
        ? 'fuelLoadedUnloadedAnalytics'
        : 'fuelConsumptionEngineHoursAnalytics';

      const [
        fuelEfficiencyDataResult,
        fuelIdleEventsDataResult,
        fuelNoneIdleEventsDataResult,
        fuelMonthOnMonthDataResult,
        historicalAnalysisCardDataResult,
      ] = await Promise.allSettled([
        (this.api as any)[fuelType](makeModels, startDate, endDate),
        this.api.getFuelIdleEvents(makeModels, startDate, endDate),
        this.api.getFuelNoneIdleEvents(makeModels, startDate, endDate),
        this.api.getMonthOnMonthData(makeModels, selectedConsumptionRange),
        this.api.getHistoricalAnalysisCardData(
          assetTypeCategory,
          startDate,
          endDate
        ),
      ]);

      const fuelEfficiencyData =
        fuelEfficiencyDataResult.status === 'fulfilled'
          ? fuelEfficiencyDataResult.value
          : null;
      const fuelIdleEventsData =
        fuelIdleEventsDataResult.status === 'fulfilled'
          ? fuelIdleEventsDataResult.value
          : null;
      const fuelNoneIdleEventsData =
        fuelNoneIdleEventsDataResult.status === 'fulfilled'
          ? fuelNoneIdleEventsDataResult.value
          : null;

      const fuelMonthOnMonthData =
        fuelMonthOnMonthDataResult.status === 'fulfilled'
          ? fuelMonthOnMonthDataResult.value
          : null;

      const historicalAnalysisCardData =
        historicalAnalysisCardDataResult.status === 'fulfilled'
          ? historicalAnalysisCardDataResult.value
          : null;

      response[fuelReducer] = fuelEfficiencyData;

      response = {
        ...response,
        fuelIdleEvents: fuelIdleEventsData,
        fuelNoneIdleEvents: fuelNoneIdleEventsData,
        fuelConsumptionRangeAnalytics: fuelMonthOnMonthData.data,
        historicalAnalysisCard: historicalAnalysisCardData,
      };

      this.dispatch(setFuelAnalytics(response));
      this.dispatch(setFuelAnalyticsLoading(false));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel analytics data: ' + msg
      );
      this.dispatch(setFuelAnalyticsLoading(false));
    }
  }

  // Gets historical analysis data
  async getFuelUsageHistoricalAnalytics(
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string,
    selectedConsumptionRange: string,
    assetTypeCategory: string
  ) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setFuelAnalyticsLoading(true));
    try {
      let response: any = {};

      let fuelMonthOnMonthDataResult, fleetDeviceDetailsResult;

      if (selectedConsumptionRange === 'custom') {
        const [resp1, resp2] = await Promise.allSettled([
          this.api.getMonthOnMonthData(makeModels, 'day', startDate, endDate),
          this.api.getFleetFuelConsumptionPerDevice(),
        ]);

        fuelMonthOnMonthDataResult = resp1;
        fleetDeviceDetailsResult = resp2;
      } else {
        const [resp1, resp2] = await Promise.allSettled([
          this.api.getMonthOnMonthData(makeModels, selectedConsumptionRange),
          this.api.getFleetFuelConsumptionPerDevice(),
        ]);
        fuelMonthOnMonthDataResult = resp1;
        fleetDeviceDetailsResult = resp2;
      }

      const fuelMonthOnMonthData =
        fuelMonthOnMonthDataResult.status === 'fulfilled'
          ? fuelMonthOnMonthDataResult.value
          : null;

      const fleetDeviceDetailsData =
        fleetDeviceDetailsResult.status === 'fulfilled'
          ? fleetDeviceDetailsResult.value
          : null;

      response = {
        fuelConsumptionRangeAnalytics: fuelMonthOnMonthData.data,
        fleetDeviceDetails: fleetDeviceDetailsData,
      };

      this.dispatch(setFuelAnalytics(response));
      this.dispatch(setFuelAnalyticsLoading(false));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel analytics data: ' + msg
      );
      this.dispatch(setFuelAnalyticsLoading(false));
    }
  }

  async getAirFilters(devices: string[]) {
    const airFilterResponse = await this.api.getAirFilters(devices);
    const response = {
      airFilterData: airFilterResponse.Items,
    };

    this.dispatch(setFuelAnalytics(response));
    this.handleSuccess();
  }

  catch(error: any) {
    let msg: string = '';
    if (error.isAxiosError) {
      msg = error.response.data.message as string;
    }

    this.handleError('An error occured while getting air filter data: ' + msg);
    this.dispatch(setFuelAnalyticsLoading(false));
  }

  clearFuelUsageAnalytics() {
    this.dispatch(clearFuelAnalytics());
  }

  async getIdleAnalytics(startDate: string, endDate: string) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setIdleAnalytics([]));
    try {
      const response = await this.idleApi.getIdleAnalytics(startDate, endDate);
      this.dispatch(setIdleAnalytics(response));
      this.handleSuccess();
      this.dispatch(updateLoadingState(false));
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the idle analytics data: ' + msg
      );
    }
  }
}
