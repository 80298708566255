import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { FlexBox } from 'components/Containers/FlexBox';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

export const Chart = ({
  enableDarkTheme,
  dataType,
  data,
  colors,
  allowedProfiles,
  unitSystem,
  tireCodes,
  metricType,
}: {
  enableDarkTheme: boolean;
  data: any;
  colors: string[];
  allowedProfiles: string[];
  dataType: string;
  unitSystem: string;
  tireCodes: any;
  metricType: string;
  setMetricType: any;
}) => {
  const dataToUse =
    metricType === 'Temperature' ? data.temperatureData : data.pressureData;
  const dataLines = Object.keys(dataToUse);
  const dataValues = Object.values(dataToUse);

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    let formattedDate = date.format('YY MMM DD');
    if (dataType === 'highGrain') {
      formattedDate = date.format('HH:mm:ss');
    }
    return formattedDate;
  };

  const formatTimestampWithHour = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('YYYY MMM DD HH:mm (Z)');
    return formattedDate;
  };

  const formatValue = (value: any) => {
    if (typeof value === 'number' && value % 1 !== 0) {
      return value.toFixed(2);
    }
    return value;
  };

  return (
    <FlexBox
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        width: '100%',
        height: '625px',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={400}
          margin={{
            top: 10,
            right: 20,
            left: 10,
            bottom: 30,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={'#25364c'}
            vertical={false}
          />
          <XAxis
            dataKey="ts"
            tick={{ fontSize: 9 }}
            allowDuplicatedCategory={false}
            tickFormatter={formatTimestamp}
            scale="time" // Set x-axis scale to 'time'
            type="number" // Set x-axis type to 'number'
            domain={['auto', 'auto']} // Set x-axis domain automatically
            angle={-65}
            textAnchor="end"
          />
          <YAxis
            tick={{ fontSize: 11 }}
            label={{
              value: `${metricType} (${
                metricType === 'Pressure'
                  ? unitSystem === 'metric'
                    ? 'psi'
                    : 'kpa'
                  : getLabelWrtUnitSystem('C', unitSystem).toLowerCase()
              })`,
              angle: 270,
              position: 'insideLeft',
              fontSize: 10,
              textAnchor: 'middle',
              dy: 50,
              dx: -5,
            }}
          />
          <Tooltip
            labelFormatter={(value) => formatTimestampWithHour(value)}
            formatter={(value: any, name: any) => {
              const keyPair = tireCodes[name] ?? name;
              return [`${formatValue(value)}`, `${keyPair}`];
            }}
          />
          {allowedProfiles.map((item) => {
            const generalIndex = dataLines.findIndex((e) => item === e);
            return (
              <Line
                key={item}
                data={dataValues[generalIndex]}
                type="linear"
                dataKey="avg_val"
                stroke={colors[generalIndex]}
                fill={colors[generalIndex]}
                activeDot={{ r: 3 }}
                fillOpacity={0.3}
                strokeOpacity={1}
                dot={false}
                name={item}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </FlexBox>
  );
};
