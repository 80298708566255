import { Box, Tabs, Tab, Tooltip } from '@mui/material';
import DateRange from './components/DateRange';
import { ThemePalette } from 'mui.theme';
import { calculateDaysDifference } from 'views/FuelAnalytics/IdleDashboard/utils';
import { useCallback, useMemo } from 'react';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  enableDarkTheme: boolean;
  ignoreYtd?: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  value: number;
  setHandleChange: any;
  isLoading: boolean;
}

const Header = ({
  value,
  enableDarkTheme,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  setHandleChange,
  isLoading,
}: Props) => {
  const dayDifference = useMemo(() => {
    if (isSelected === 'Custom') {
      return calculateDaysDifference(startDate, endDate);
    } else {
      return 0;
    }
  }, [isSelected, startDate, endDate]);
  const disableTimeline = useMemo(() => {
    return (
      isSelected === '7D' ||
      isSelected === '14D' ||
      isSelected === '1Y' ||
      isSelected === 'ALL' ||
      (isSelected === 'Custom' && dayDifference > 2)
    );
  }, [isSelected, dayDifference]);

  const tabChangeCallback = useCallback(
    (...args: any) => {
      if (disableTimeline && value === 0) {
        // do nothing
      } else {
        setHandleChange(...args);
      }
    },
    [disableTimeline, setHandleChange, value]
  );

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <DateRange
        enableDarkTheme={enableDarkTheme}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        yesterday={yesterday}
        handleDateChange={handleDateChange}
        isLoading={isLoading}
        isTimeline={value === 1}
      />
      <Box
        sx={{
          marginLeft: '20px',
          marginTop: '8px',
        }}
      >
        <Tabs
          value={value}
          onChange={tabChangeCallback}
          aria-label="basic tabs"
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#5E85F0',
            },
          }}
        >
          <Tab
            disableRipple
            label="Fleet"
            {...a11yProps(0)}
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'capitalize',
            }}
            disabled={isLoading}
          />
          <Tooltip
            title={
              disableTimeline
                ? 'Timeline is only available for date range of maximum 1 day'
                : ''
            }
          >
            <Tab
              disableRipple
              label="Timeline"
              {...a11yProps(1)}
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textTransform: 'capitalize',
                fontWeight: 'bold',
              }}
              disabled={isLoading}
            />
          </Tooltip>
        </Tabs>
      </Box>
    </div>
  );
};

export default Header;
