import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import useDateRange from 'hooks/dateRange';
import HeaderMenu from './HeaderMenu';
import Loader from './Loader';
import AllFleet from './AllFleet';
import Hauler from './Hauler';
import ProductionHandler from 'handlers/production.handler';
import { exportOverviewPagesToPdf } from 'utils/exports/pdf-reports';
import { setIsPrinting } from 'store/exportData.slice';

const Production = () => {
  const location = useLocation();
  const typeReceived = location.state?.type;
  const productionHandler = new ProductionHandler();
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const { data, loadingStates } = useAppSelector(
    (state) => state.productionReducer
  );
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const { assetOverView: assetOverview } = useAppSelector(
    (state) => state.assetReducer
  );
  const customerCode = useAppSelector(
    (state) => state.persistedReducer.customer.code
  );
  const { isPrinting } = useAppSelector((state) => state.exportDataReducer);
  const dispatch = useAppDispatch();

  const enableDarkTheme = isDarkTheme(theme);
  const defaultValue = typeReceived
    ? typeReceived === 'Haul Truck'
      ? 1
      : typeReceived === 'Loader'
      ? 2
      : 0
    : 0;
  const [value, setValue] = useState(defaultValue);
  const [isSelected, setIsSelected] = useState('1D');
  const [initRun, setInitRun] = useState<boolean>(true);
  const [isCustomDateChanged, setIsCustomDateChanged] = useState(false);

  const [aggregation, setAggregation] = useState<string>('Monthly');

  const { startDate, endDate, setStartDate, setEndDate, yesterday } =
    useDateRange();
  const [exportTo, setExportTo] = useState(false);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const handleExportClick = async (val: boolean) => {
    dispatch(setIsPrinting(true));
    await exportOverviewPagesToPdf(
      ['production-overview'],
      'production-overview',
      'Production Overview',
      false,
      'portrait'
    );
    dispatch(setIsPrinting(false));
  };

  const fetchData = async (range: string, startDate: Date, endDate: Date) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    const shouldSendDate = isSelected === 'Custom';
    const isNotLoadingData = Object.entries(loadingStates)
      .filter(
        ([key]) => key !== 'isLoadingTarget' && key !== 'isLoadingSuggestions'
      )
      .every(([, value]) => !value);
    if (isNotLoadingData) {
      await productionHandler.get(
        range,
        formattedStartDate,
        formattedEndDate,
        shouldSendDate,
        false,
        value === 2 ? 'loader' : value === 1 ? 'hauler' : 'all',
        ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? aggregation.toLowerCase()
          : 'daily'
      );
      setInitRun(false);
    }
  };

  useEffect(() => {
    if (isSelected === 'Custom') {
      if (!isCustomDateChanged) {
        setIsCustomDateChanged(true);
      }
      if (isCustomDateChanged && startDate && endDate) {
        void fetchData(isSelected, startDate, endDate);
      }
    } else if (startDate && endDate) {
      setIsCustomDateChanged(false);
      void fetchData(isSelected, startDate, endDate);
    }
  }, [isSelected, startDate, endDate, aggregation]);

  return (
    <>
      <HeaderMenu
        value={value}
        handleChange={handleChange}
        handleExport={handleExportClick}
      />
      <div id="production-overview">
        <Loader value={value} />
        <Hauler
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={yesterday}
          unitSystem={unitSystem}
          customerCode={customerCode}
          loadingStates={loadingStates}
          assetOverview={assetOverview}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          isPrinting={isPrinting}
        />
        <AllFleet
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={yesterday}
          data={data.allFleet}
          loadingStates={loadingStates}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          aggregation={aggregation}
          setAggregation={setAggregation}
          isPrinting={isPrinting}
        />
      </div>
    </>
  );
};

export default Production;
