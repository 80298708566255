export const ThemePalette = {
  light: {
    pageBackground: '#DFE5F0',
    topBar: '#FFFFFF',
    boxBackground: '#FFFFFF',
    toolBarBackground: '#F3F4F6',
    cartesianGrid: '#cccccc',
    menuOptions: '#FFFFFF',
    tableBackground: '#FFFFFF',
    subTableBackground: '#e0e4e9',
    tableHeader: 'grey',
    inputBackground: '#FFFFFF',
  },
  dark: {
    pageBackground: '#0A192D',
    topBar: '#152642',
    boxBackground: '#172A46',
    toolBarBackground: '#152642',
    modalBackground: '#0A2341',
    cartesianGrid: '#26364C',
    menuOptions: '#091A2F',
    tableBackground: '#0A2341',
    subTableBackground: '#475B63',
    tableHeader: '#1C3459',
    inputBackground: '#152642',
  },
  typography: {
    buttonLink: '#488AA0',
    primary: '#2449E5',
    secondary: '#7A7C7C',
    success: '#28a745',
    lightSuccess: '#009161',
    danger: '#dc3545',
    warning: '#FFA403',
    lightDanger: '#EB5757',
    info: '#437F95',
    wheat: '#EFF4F4',
    white: '#FFFFFF',
    black: '#000000',
    cyan: '#7485A2',
    lightGrey: '#B4B4B4',
    grey: '#8A92A0',
    cornflowerBlue: '#6495ED',
  },
  border: '#D9DADA',
  pie: {
    blue: '#4A7BFA',
    green: '#2ED58B',
    purple: '#A776FF',
    orange: '#FFA403',
  },
};
