import { useLocation } from 'react-router-dom';
import { type SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  Button,
  ButtonGroup,
  Autocomplete,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { Header } from './Header';
// import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DatePicker from 'react-datepicker';
import AssetHandler from 'handlers/asset.handler';
import dayjs from 'dayjs';
import {
  assetTypeMappings,
  getAssetType,
} from '../FleetOverview/helpers/asset.helpers';
import type { Asset } from 'types/asset.types';
import { CompareAssetModal } from '../FleetOverview/components/AssetCardsV2/modals/CompareModal';
import {
  clearAssetComparisonByTags,
  selectAssociatedAssets,
} from 'store/asset.slice';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { tags } from 'assets/files/tagsForComparison';
import { ComparisonMetrics } from './ComparisonMetrics';
import { SingleFadeLoader } from 'components/SingleFadeLoader';

export const FleetComparisonView = () => {
  const assetHandler = new AssetHandler();
  const location = useLocation();
  const { assets } = location.state || [];
  const dispatch = useAppDispatch();

  const assetsComparison = useAppSelector(
    (state) => state.assetReducer.assetComparison
  );

  const unfilteredAssets = useAppSelector(selectAssociatedAssets);

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const { thresholds } = useAppSelector((state) => state.tagThresholdReducer);
  const enableDarkTheme = isDarkTheme(theme);

  const [metricsType, setMetricsType] = useState('table');

  const [selectedStartDate, setStartDate] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [selectedEndDate, setEndDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleButtonClick = (type: string) => {
    setMetricsType(type);
  };

  const getAssetComparison = async (
    startDate: string,
    endDate: string,
    // assetIds: string[],
    deviceIds: string[],
    assetType: string
  ) => {
    await assetHandler.getAssetsComparisonData(
      startDate,
      endDate,
      deviceIds,
      assetType
    );
  };

  const formatParams = () => {
    const startDate = dayjs(selectedStartDate).format('YYYY-MM-DD');
    const endDate = dayjs(selectedEndDate).format('YYYY-MM-DD');
    const defaultAssetType = getAssetType(
      assetTypeMappings[assets[0].assetType]
    );
    //     const assetIds = assets.map((asset: Asset) => asset.bumperNumber);

    const deviceIds = assets.map((asset: Asset) => asset.device.deviceId);
    return {
      startDate,
      endDate,
      defaultAssetType,
      deviceIds,
    };
  };

  useEffect(() => {
    if (assets.length > 0 && selectedStartDate && selectedEndDate) {
      const { startDate, endDate, defaultAssetType, deviceIds } =
        formatParams();
      void getAssetComparison(
        startDate,
        endDate,
        //     assetIds,
        deviceIds,
        defaultAssetType
      );
    }
  }, [assets, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    return () => {
      dispatch(clearAssetComparisonByTags());
    };
  }, [location, dispatch]);

  const selectedAssetIds = assets.map((asset: Asset) => asset.assetId);
  const convertCamelToSnakeCase = (input: string): string => {
    return input.replace(/([A-Z])/g, (match, p1, offset) => {
      if (offset > 0 && input[offset - 1] !== '_') {
        return `_${p1.toLowerCase()}`;
      }
      return p1.toLowerCase();
    });
  };
  const tagsDropdown = tags
    .filter((tag) => {
      return tag.includeInBenchmarkScreen === 'Y';
    })
    .map((tag) => ({
      ...tag,
      id: convertCamelToSnakeCase(tag.id),
    }));

  const filteredTags = tagsDropdown.filter((item) => {
    return assetsComparison.deviceTags.includes(item.id);
  });

  const handleSelect = (event: SyntheticEvent<Element, Event>, value: any) => {
    const { startDate, endDate, defaultAssetType, deviceIds } = formatParams();
    void assetHandler.getAssetsComparisonByTagsData(
      startDate,
      endDate,
      deviceIds,
      defaultAssetType,
      value.id
    );
  };

  const reversedInput = [...assetsComparison.tags].reverse();
  const tagMetrics: any = reversedInput.reduce((acc, item) => {
    return { ...acc, ...item.metrics };
  }, {});

  return (
    <>
      {showModal ? (
        <CompareAssetModal
          key="compre-assets-modal-comparison"
          title="Compare Assets"
          handleAbort={() => {
            setShowModal(false);
          }}
          open={showModal}
          enableDarkTheme={enableDarkTheme}
          selectedAsset={selectedAssetIds}
          filteredAssets={unfilteredAssets}
        />
      ) : (
        <></>
      )}
      <Box>
        <Box
          sx={{
            marginTop: '20px',
            padding: '5px 10px 20px 10px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                paddingBottom: '10px',
              }}
            >
              Date Range
            </Typography>
            <DatePicker
              showIcon
              selected={selectedStartDate}
              onChange={handleDateChange}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              maxDate={new Date(new Date().getTime())}
              selectsRange
            />
          </Box>
          <Autocomplete
            disablePortal
            options={filteredTags}
            getOptionLabel={(option) => {
              return typeof option === 'object' ? option.id : option;
            }}
            onChange={handleSelect}
            renderInput={(params) => (
              <SWTextField
                name="tagName"
                label="Tag Name"
                placeholder="Add to the Screen for comparison"
                TextFieldProps={{
                  ...params,
                  name: 'tagName',
                  InputProps: {
                    ...params.InputProps,
                    sx: {
                      width: '500px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      border: 'gray 1px solid',
                    },
                  },
                }}
                enableDarkTheme={enableDarkTheme}
              />
            )}
          />
        </Box>
        <Header
          enableDarkTheme={enableDarkTheme}
          assets={assets}
          setShowModal={setShowModal}
        />

        <Box
          sx={{
            marginTop: '20px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.pageBackground
              : ThemePalette.light.boxBackground,
            minHeight: '500px',
            paddingLeft: '10px',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingRight: '5px',
            }}
          >
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              Key Metrics
            </Typography>

            <Box display="flex" alignItems="center">
              <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button
                  sx={{
                    backgroundColor:
                      metricsType === 'table' ? '#3666EC' : '#2D325A',
                  }}
                  onClick={() => {
                    handleButtonClick('table');
                  }}
                >
                  <TableChartIcon />
                </Button>
                <Button
                  sx={{
                    backgroundColor:
                      metricsType === 'chart' ? '#3666EC' : '#2D325A',
                  }}
                  onClick={() => {
                    handleButtonClick('chart');
                  }}
                >
                  <BarChartIcon />
                </Button>
              </ButtonGroup>
              {/* <SimCardDownloadIcon
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '5px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              />
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  fontSize: '12px',
                }}
              >
                Download
              </Typography> */}
            </Box>
          </Box>

          <Divider
            sx={{
              background: '#3A4657',
            }}
          />
          <div style={{ marginBottom: '40px' }}>
            <ComparisonMetrics
              metricsType={metricsType}
              enableDarkTheme={enableDarkTheme}
              assets={assets}
              assetsComparison={{ metrics: tagMetrics }}
              itemLoading={
                assetsComparison.tags.length > 0
                  ? assetsComparison.tags[assetsComparison.tags.length - 1]
                      .loading
                  : false
              }
              showChart={false}
              thresholds={thresholds}
            />
            {assetsComparison.loading ? (
              <div style={{ margin: '5% 45%' }}>
                <SingleFadeLoader />
              </div>
            ) : (
              <ComparisonMetrics
                metricsType={metricsType}
                enableDarkTheme={enableDarkTheme}
                assets={assets}
                assetsComparison={assetsComparison}
                tagMetrics={tagMetrics}
                thresholds={thresholds}
              />
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};
