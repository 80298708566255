import * as htmlToImage from 'html-to-image';
import {
  pdf,
  Document,
  Page,
  Image,
  StyleSheet,
  View,
  Text,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import symxAILogo from '../../assets/logos/symx.ai.png';

export const exportToPdf = async (
  elementIds: string[],
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined
): Promise<any> => {
  try {
    const scale = 2; // Scale factor for higher resolution
    const imagesDataUrls = await Promise.all(
      elementIds.map(async (elementId) => {
        const node = document.getElementById(elementId);
        if (!node) {
          throw new Error(`Element with ID ${elementId} not found`);
        }
        // Increase the scale for higher resolution
        return await htmlToImage.toPng(node, {
          quality: 1,
          pixelRatio: scale, // Higher DPI for sharper images
        });
      })
    );

    const pageOrientation = orientation ?? 'portrait';
    const pageWidth = pageOrientation === 'portrait' ? 595.28 : 841.89;
    const pageHeight = pageOrientation === 'portrait' ? 841.89 : 595.28;

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        padding: 0,
      },
      imageContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start', // Align to the top left
        justifyContent: 'flex-start', // Align to the top
      },
      image: {
        objectFit: 'contain',
        width: pageWidth,
        height: 'auto', // Maintain aspect ratio
        maxHeight: pageHeight,
      },
    });

    const MyDocument = () => (
      <Document>
        {imagesDataUrls.map((dataUrl, i) => (
          <Page
            key={i}
            size="A4"
            orientation={pageOrientation}
            style={styles.page}
          >
            <View style={styles.imageContainer}>
              <Image
                src={dataUrl}
                style={{
                  width: pageWidth,
                  height: 'auto',
                  maxHeight: pageHeight,
                }}
              />
            </View>
          </Page>
        ))}
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();
    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};

export const exportOverviewPagesToPdf = async (
  elementIds: string[],
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined
): Promise<any> => {
  try {
    const scale = 2;
    const pageOrientation = orientation ?? 'portrait';
    const pageWidth = pageOrientation === 'portrait' ? 595.28 : 841.89;
    const pageHeight = pageOrientation === 'portrait' ? 841.89 : 595.28;
    const headerHeight = 80;
    const availableContentHeight = pageHeight - headerHeight;

    // Get all image data URLs
    const imagesDataUrls = await Promise.all(
      elementIds.map(async (elementId) => {
        const node = document.getElementById(elementId);
        if (!node) throw new Error(`Element with ID ${elementId} not found`);
        return await htmlToImage.toPng(node, { quality: 1, pixelRatio: scale });
      })
    );
    const splitImageIntoChunks = async (dataUrl: string): Promise<string[]> => {
      return await new Promise((resolve) => {
        const img = document.createElement('img');
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const targetWidth = pageWidth;
          const targetHeight = targetWidth / aspectRatio;

          if (targetHeight <= availableContentHeight) {
            resolve([dataUrl]);
            return;
          }

          const numPages = Math.ceil(targetHeight / availableContentHeight);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const chunks = Array.from({ length: numPages }, (_, i) => {
            canvas.width = img.width;
            canvas.height = img.height / numPages;

            ctx?.drawImage(
              img,
              0,
              (i * img.height) / numPages,
              img.width,
              img.height / numPages,
              0,
              0,
              canvas.width,
              canvas.height
            );

            return canvas.toDataURL();
          });

          resolve(chunks);
        };
        img.src = dataUrl;
      });
    };

    // Split all images into chunks before rendering
    const allImageChunks = await Promise.all(
      imagesDataUrls.map(async (dataUrl) => await splitImageIntoChunks(dataUrl))
    );

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        padding: 0,
      },
      header: {
        padding: 20,
        flexDirection: 'column',
        height: headerHeight,
      },
      imageContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexGrow: 1,
      },
      logo: {
        width: 100,
        height: 'auto',
        marginBottom: 1,
      },
      textContainer: {
        marginBottom: 1,
        textAlign: 'left',
        paddingLeft: 2,
      },
      title: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 1,
      },
      subtitle: {
        fontSize: 9,
        marginBottom: 5,
        marginTop: 7,
      },
      borderLine: {
        height: 1,
        backgroundColor: '#000',
        marginBottom: 3,
        marginTop: 2,
      },
      contentContainer: {
        flexDirection: 'column',
        flexGrow: 1,
      },
    });

    const MyDocument = () => (
      <Document>
        {allImageChunks.map((imageChunks, index) =>
          imageChunks.map((chunk, chunkIndex) => (
            <Page
              key={`${index}-${chunkIndex}`}
              size="A4"
              orientation={pageOrientation}
              style={styles.page}
            >
              {chunkIndex === 0 && (
                <View style={styles.header}>
                  <Image src={symxAILogo} style={styles.logo} />
                  <View style={styles.textContainer}>
                    <Text style={styles.title}>X.Machines</Text>
                    <Text style={styles.subtitle}>{title}</Text>
                  </View>
                  <View style={styles.borderLine} />
                </View>
              )}

              <View style={styles.contentContainer}>
                <Image
                  src={chunk}
                  style={{
                    width: pageWidth,
                    height: 'auto',
                    maxHeight: availableContentHeight,
                    objectFit: 'contain',
                  }}
                />
              </View>
            </Page>
          ))
        )}
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();
    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};
