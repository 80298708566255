import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hook';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import NotificationHandler from 'handlers/notification.handler';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import {
  NotificationDate,
  NotificationTagName,
  NotificationSeverity,
  NotificationAsset,
  Status,
  LastConnected,
  RulesLogic,
  Delete,
  TooltipComponent,
  RulesLogicToolTip,
} from './CellRenderer';
import type { RuleNotifications } from 'types/notification.types';
import { Typography } from '@mui/material';
import { formatConditions } from 'views/Settings/Rules/helpers/common';
import { type TagAlertDetails } from 'types/tag.type';
import { ThemePalette } from 'mui.theme';
import { type ColDef } from 'ag-grid-community';

interface Props {
  notifications: RuleNotifications[];
  handleAlertDetails: ({
    deviceId,
    notificationTime,
    tagDetails,
  }: TagAlertDetails) => void;
  handleNotificationConditions: (conditions: []) => void;
  setSelectedRow: any;
  selectedRow: string;
  enableDarkTheme: boolean;
}

export const NotificationsList = ({
  notifications,
  handleAlertDetails,
  handleNotificationConditions,
  setSelectedRow,
  selectedRow,
  enableDarkTheme,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const location = useLocation();
  const bumperNumberReceived = location.state?.data;
  const handleGridReady = (params: any) => {
    const gridApi = params.api;
    // Apply filter on the "Bumper ID" column if bumperNumberReceived is available
    if (bumperNumberReceived && gridApi) {
      const bumperColumn = gridApi.getColumnDef('asset.bumperNumber');
      // Check if the bumperColumn is valid before attempting to set the filter
      if (bumperColumn) {
        const colId = bumperColumn.field; // Adjust this line based on your column definition structure
        gridApi.setFilterModel({
          [colId]: {
            type: 'equals',
            filter: bumperNumberReceived.bumperNumber,
          },
        });
        gridApi.onFilterChanged(); // Trigger filter change event
      }
    }
  };

  const handleCellClick = (params: any) => {
    setSelectedRow(params?.data?.notificationId ?? params.notificationId);
  };

  const getCellStyle = (params: any) => {
    return params.data.isRead
      ? {
          backgroundColor: 'none',
          padding: '0px',
          fontSize: '14px',
          cursor: 'pointer',
          color: '#ffffff',
        }
      : {
          backgroundColor: 'none',
          padding: '4px',
          fontSize: '17px',
          cursor: 'pointer',
          color: '#ffffff',
        };
  };

  const getRowStyle = (params: any) => {
    if (params.data.notificationId === selectedRow) {
      return { background: '#ABABE2' }; // Change background color for highlighted row
    }
    return null;
  };

  const notificationHandler = new NotificationHandler();
  const [notificationList, setNotificationList] = React.useState<
    RuleNotifications[]
  >([]);

  const { rules } = useAppSelector((state) => state.ruleReducer);
  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: 'alertStartTs',
      headerName: 'Start Time',
      maxWidth: 250,
      sortable: true,
      cellRenderer: NotificationDate,
      cellRendererParams: {
        field: 'ruleId',
      },
    },
    {
      field: 'rule.ruleName',
      headerName: 'Notification',
      maxWidth: 220,
      cellRenderer: NotificationTagName,
      cellRendererParams: {
        field: 'ruleId',
        handleNameClick: async (
          notificationId: string,
          deviceId: string,
          assetVin: string,
          alertTime: string,
          isRead: boolean,
          conditions: any
        ) => {
          await notificationHandler.readNotification(notificationId, isRead);
          handleCellClick({ notificationId, field: 'rule.ruleName' });
          handleNotificationConditions(conditions);
          let tagNames = [];
          let ruleSummary = '';
          if (conditions?.length > 0) {
            tagNames = conditions.map((cond: any) => {
              return {
                tagName: cond.tagName,
                operator: cond.relationalOperator,
                value: cond.value,
              };
            });
            ruleSummary = formatConditions(conditions, unitSystem);
          }
          handleAlertDetails({
            deviceId,
            notificationTime: alertTime,
            tagDetails: tagNames,
            notificationId,
          });
        },
      },
      cellStyle: (params: any) => getCellStyle(params),
      tooltipComponent: TooltipComponent,
      tooltipField: 'rule.ruleName',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'rule.notificationLevel',
      headerName: 'Severity',
      sortable: true,
      maxWidth: 170,
      suppressSizeToFit: true,
      cellRenderer: NotificationSeverity,
    },
    {
      field: 'alertCount',
      headerName: 'Count',
      sortable: true,
      maxWidth: 100,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      maxWidth: 160,
      cellRenderer: Status,
      sortable: true,
    },
    {
      field: 'asset.bumperNumber',
      headerName: 'Bumper ID',
      maxWidth: 180,
      cellStyle: { textAlign: 'left' },
      cellRenderer: NotificationAsset,
      cellRendererParams: {
        field: 'ruleId',
      },
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'alertEndTs',
      headerName: 'End Time',
      maxWidth: 150,
      cellRenderer: LastConnected,
      tooltipComponent: TooltipComponent,
      tooltipField: 'lastConnected',
      sortable: true,
    },
    {
      field: 'ruleId',
      headerName: 'Rules Logic',
      cellRenderer: RulesLogic,
      tooltipComponent: RulesLogicToolTip,
      // tooltipField: 'conditions',
      tooltipValueGetter: (params: any) => {
        if (params?.data?.conditions && unitSystem) {
          return formatConditions(params.data.conditions, unitSystem);
        } else {
          return '';
        }
      },
    },
    {
      field: 'notificationId',
      headerName: 'Active/Suspend',
      maxWidth: 140,
      cellStyle: { textAlign: 'center' },
      cellRenderer: Delete,
      cellRendererParams: {
        field: 'ruleId',
        handleToggleClick: async (
          notificationId: string,
          isActive: boolean,
          rule: { ruleName: string },
          asset: { bumperNumber: string }
        ) => {
          await notificationHandler.updateNotificationStatus(
            notificationId,
            isActive,
            rule.ruleName,
            asset.bumperNumber
          );
        },
      },
    },
  ]);

  React.useEffect(() => {
    // On change, reset this data,
    if (notifications.length < 1) {
      setNotificationList([]);
    } else {
      const notificationsWithConditions: any = notifications.map(
        (ntfc: RuleNotifications) => {
          const isFound = rules.find((item) => item.ruleId === ntfc.ruleId);
          if (isFound) {
            return {
              ...ntfc,
              conditions: isFound.conditions,
            };
          }
          return ntfc;
        }
      );
      setNotificationList(notificationsWithConditions);
    }
  }, [notifications, rules, selectedRow]);

  return (
    <>
      <PaddedListWrapper>
        {notificationList.length < 1 ? (
          <Typography
            color={
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black
            }
          >
            No Notifications Available
          </Typography>
        ) : (
          <BaseTableList
            rowData={notificationList}
            columnDefs={columnDefs}
            cacheQuickFilter={true}
            onGridReady={handleGridReady}
            tableStyles={{ height: '450px' }}
            getRowStyle={getRowStyle}
          />
        )}
      </PaddedListWrapper>
    </>
  );
};
