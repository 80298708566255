import dayjs from 'dayjs';

export function getAssetsMakeModel(carArray: string[]) {
  return carArray.map((car) => {
    const [make, model] = car.split('!');
    return { make, model };
  });
}

export const formatTimestamp = (
  timestamp: number | string,
  customFormat?: string
): string => {
  const date = dayjs(timestamp);
  const formattedDate = date.format(customFormat ?? 'YYYY-MMM-DD HH:mm (Z)');
  return formattedDate;
};

export function getTripTime(allTs: string[]) {
  const dateObjects = allTs.map((date) => new Date(date)); // Convert date strings to Date objects

  const minDate = new Date(Math.min(...(dateObjects as any))).getTime();
  const maxDate = new Date(Math.max(...(dateObjects as any))).getTime();

  // Convert back to strings
  // const startTime = minDate.toISOString().replace('T', ' ').slice(0, -5);
  // const endTime = maxDate.toISOString().replace('T', ' ').slice(0, -5);
  const startTime = formatTimestamp(minDate);
  const endTime = formatTimestamp(maxDate);
  return { startTime, endTime };
}
