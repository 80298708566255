import { createSlice } from '@reduxjs/toolkit';

export interface EngineHourPerDayInstance {
  asset: string;
  device: string;
  engine_hour_total: number;
  hour: number;
  idle_hour_total: number;
  non_idle_hour_total: number;
}

export interface DeviceEngineHour {
  device: string;
  asset: string;
  engine_hour_total: number;
  hour: number;
}

export interface EngineHoursState {
  data: any[];
  engineHoursLoaded?: boolean;
  deviceEngineHour: DeviceEngineHour[];
  deviceEngineHourPerDay: EngineHourPerDayInstance[];
  isLoadingDeviceEngineHourPerDay: boolean;
  isLoading: {
    loadingEngineHoursWithinRange: boolean;
    loadingEngineHoursForDevice: boolean;
  };
  rangeSelected: string;
}

const initialState: EngineHoursState = {
  data: [],
  engineHoursLoaded: false,
  deviceEngineHour: [],
  deviceEngineHourPerDay: [],
  isLoadingDeviceEngineHourPerDay: false,
  isLoading: {
    loadingEngineHoursWithinRange: false,
    loadingEngineHoursForDevice: false,
  },
  rangeSelected: '1D',
};

export const engineHoursSlice = createSlice({
  name: 'engineHours',
  initialState,
  reducers: {
    getAll(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    setEngineHoursLoaded(state, { payload }) {
      state.engineHoursLoaded = payload;
    },
    setDeviceEngineHour(state, { payload }) {
      state.deviceEngineHour = payload;
    },
    setDeviceEngineHourPerDay(state, { payload }) {
      state.deviceEngineHourPerDay = payload;
    },
    clearDeviceEngineHour(state) {
      state.deviceEngineHour = [];
    },
    updatePerDayLoadingState(state, { payload }) {
      state.isLoadingDeviceEngineHourPerDay = payload;
    },
    updateSpecificLoadingState(
      state,
      { payload }: { payload: Record<string, boolean> }
    ) {
      state.isLoading = {
        ...state.isLoading,
        ...payload,
      };
    },
    updateRangeSelected(state, { payload }: { payload: string }) {
      state.rangeSelected = payload;
    },
  },
});

export const {
  getAll,
  setEngineHoursLoaded,
  setDeviceEngineHour,
  setDeviceEngineHourPerDay,
  clearDeviceEngineHour,
  updatePerDayLoadingState,
  updateSpecificLoadingState,
  updateRangeSelected,
} = engineHoursSlice.actions;
export default engineHoursSlice.reducer;
