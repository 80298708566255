import * as React from 'react';
import { List, ListItem, Box, FormControlLabel, Checkbox } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { useSelector } from 'react-redux';
import { Selector } from 'components/Form/Selectors/Selector';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { useAppDispatch } from 'store/hook';
import { type RootState } from 'store';
import { NOTIFICATION_LEVEL } from 'utils/enums';
import type {
  ICreateRulePayload,
  ICreateRuleNotificationPayload,
} from 'types/payloads/rule.payload.types';
import {
  handleSelectNotificationEmail,
  handleSelectAllNotificationEmail,
  handleSelectAllNotificationSms,
  handleSelectNotificationSms,
} from 'views/Settings/Rules/helpers/common';
import { addRuleNotification } from 'store/rule.slice';
import type { SelectOptions } from 'types/utils.types';
import { ThemePalette } from 'mui.theme';

interface Props {
  operator: string;
  keyId: number;
  deleteRow: (index: number, id: string) => void;
  newRule: ICreateRulePayload;
  rows: string[];
  enableDarkTheme: boolean;
}

export const RuleNotificationComponent = ({
  operator,
  keyId,
  deleteRow,
  newRule,
  rows,
  enableDarkTheme,
}: Props): JSX.Element => {
  const {
    palette: { bg },
  } = useTheme();

  const dispatch = useAppDispatch();

  const userRole = useSelector((state: RootState) => state.authReducer.role);

  const { externalUserList: externalUserListState } = useSelector(
    (state: RootState) => state.externalUserReducer
  );

  const { internalUserList: internalUserListState } = useSelector(
    (state: RootState) => state.internalUserReducer
  );

  const [notification, setNotification] =
    React.useState<ICreateRuleNotificationPayload>({
      ...newRule,
    });

  React.useEffect(() => {
    dispatch(addRuleNotification(notification));
  }, [notification]);

  let userEmails: SelectOptions[];
  let usersWithPhones: any[] = [];

  if (userRole === 'external-user' || userRole === 'external-admin') {
    userEmails = externalUserListState.map((eUser) => ({
      id: eUser.userId,
      display: eUser.email,
    }));

    usersWithPhones = externalUserListState.map((eUser) => ({
      id: eUser.userId,
      display: eUser.phoneNumber ?? '',
      name: `${eUser.firstName} ${eUser.firstName}`,
    }));
  } else {
    userEmails = [
      ...externalUserListState.map((eUser) => ({
        id: eUser.userId,
        display: eUser.email,
      })),
      ...internalUserListState.map((iUser) => ({
        id: iUser.userId,
        display: iUser.email,
      })),
    ];

    usersWithPhones = [
      ...externalUserListState.map((eUser) => ({
        id: eUser.userId,
        display: eUser.phoneNumber ?? '',
        name: `${eUser.firstName} ${eUser.lastName}`,
      })),
      ...internalUserListState.map((iUser) => ({
        id: iUser.userId,
        display: iUser.phoneNumber ?? '',
        name: `${iUser.firstName} ${iUser.lastName}`,
      })),
    ];
  }

  const userPhones: SelectOptions[] = usersWithPhones
    .filter((item) => item.display !== '')
    .map((item) => {
      return {
        id: item.id,
        display: `${item.name} (+${item.display})`,
      };
    });

  return (
    <Box
      key={keyId}
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : bg.grey,
        paddingTop: '15px',
        marginBottom: operator ? '25px' : '',
        marginTop: rows.length > 1 ? '25px' : '',
      }}
    >
      {/** Button to delete condition form */}
      <IconButton
        aria-label="delete"
        onClick={() => {
          if (deleteRow) {
            deleteRow(keyId - 1, 'condition.id');
          }
        }}
        sx={{
          marginTop: '15px',
          marginLeft: '95%',
          maxWidth: '10px',
          color: enableDarkTheme
            ? ThemePalette.typography.cornflowerBlue
            : 'button.primary',
        }}
      >
        <CancelIcon />
      </IconButton>
      {/** List form to get Notification Details */}
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
        }}
      >
        <ListItem
          style={{
            marginRight: '15px',
          }}
        >
          <span
            style={{
              marginRight: '5px',
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
            }}
          >
            Create a
          </span>

          {/* Rule Severity Selector */}
          <Selector
            value={notification.notificationLevel}
            onChange={(event) => {
              setNotification({
                ...notification,
                notificationLevel: event.target.value.toString(),
              });
            }}
            selectorOptions={NOTIFICATION_LEVEL}
            selectSx={{
              minWidth: '100px',
              maxWidth: '200px',
              marginRight: '0px',
            }}
            enableDarkTheme={enableDarkTheme}
          />

          <span
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
            }}
          >
            Notification to be viewed
          </span>
        </ListItem>
      </List>
      {/** Checkbox group to get Notification Distribution method */}
      <List>
        <ListItem>
          <Box key="emailNotification">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              checked={notification.alertByEmail}
              label="By Email"
              onChange={() => {
                setNotification({
                  ...notification,
                  alertByEmail: !notification.alertByEmail,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
          </Box>

          <MultiSelector
            value={notification.distributionUsers}
            renderValue={(selected) => {
              return `${selected?.length || 0} Selected`;
            }}
            handleSelectOne={(event) => {
              handleSelectNotificationEmail(
                event,
                notification,
                setNotification
              );
            }}
            handleSelectAll={() => {
              handleSelectAllNotificationEmail(
                userEmails,
                notification,
                setNotification
              );
            }}
            selectorOptions={userEmails}
            minWidth="400px"
            enableDarkTheme={enableDarkTheme}
          />
        </ListItem>
      </List>

      {/** SMS Notification Selector */}
      <List>
        <ListItem>
          <Box key="smsNotification">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              checked={notification.alertBySms}
              label="By SMS"
              onChange={() => {
                setNotification({
                  ...notification,
                  alertBySms: !notification.alertBySms,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
          </Box>

          <MultiSelector
            value={notification.smsUsers}
            renderValue={(selected) => {
              return `${selected?.length || 0} Selected`;
            }}
            handleSelectOne={(event) => {
              handleSelectNotificationSms(event, notification, setNotification);
            }}
            handleSelectAll={() => {
              handleSelectAllNotificationSms(
                userPhones,
                notification,
                setNotification
              );
            }}
            selectorOptions={userPhones}
            minWidth="400px"
            enableDarkTheme={enableDarkTheme}
          />
        </ListItem>
      </List>

      <List>
        <ListItem>
          <Box key="applicationUi">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              checked={notification.alertByUI}
              label="In Application Interface/UI"
              onChange={() => {
                setNotification({
                  ...notification,
                  alertByUI: !notification.alertByUI,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
          </Box>
        </ListItem>
        <ListItem>
          <Box key="NotificationCenter">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              checked={notification.alertByNotificationCenter}
              label="In Notification Center"
              onChange={() => {
                setNotification({
                  ...notification,
                  alertByNotificationCenter:
                    !notification.alertByNotificationCenter,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
          </Box>
        </ListItem>
      </List>
    </Box>
  );
};
