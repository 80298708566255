import { ThemePalette } from 'mui.theme';
import { Box } from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import InfoPanel from './InfoPanel';
import LineAndCircle from './LineAndCircle';
import { useAppSelector } from 'store/hook';
import { convertSecondsWithUnitValues } from 'utils/helpers/dateTime';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';
import { truncateTo2dp } from 'utils/helpers/general';
import { includedLargeSVgs } from './utils';

const MainBody = ({
  enableDarkTheme,
  tripDetails,
  unitSystem,
  makeModel,
}: {
  enableDarkTheme: boolean;
  tripDetails: any;
  unitSystem: string;
  makeModel: string;
}) => {
  const { airFilterData } = useAppSelector(
    (state) => state.fuelAnalyticsReducer
  );
  const { tireData } = useAppSelector((state) => state.tireProfileReducer);

  const totalFuelConsumed = truncateTo2dp(
    tripDetails?.data.reduce(
      (acc: number, curVal: Record<string, number>) =>
        acc +
        ((curVal.loaded_trip_fuel_consumption_l ?? 0) +
          (curVal.unloaded_trip_fuel_consumption_l ?? 0) +
          (curVal.loading_fuel_consumption_l ?? 0) +
          (curVal.unloading_fuel_consumption_l ?? 0)),
      0
    )
  );

  const totalFuelConsumedLoaded = truncateTo2dp(
    tripDetails?.data.reduce(
      (acc: number, curVal: Record<string, number>) =>
        acc + (curVal.loaded_trip_fuel_consumption_l ?? 0),
      0
    )
  );

  const totalFuelConsumedUnloaded = truncateTo2dp(
    tripDetails?.data.reduce(
      (acc: number, curVal: Record<string, number>) =>
        acc + (curVal.unloaded_trip_fuel_consumption_l ?? 0),
      0
    )
  );

  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        marginTop: '20px',
        borderRadius: '5px',
      }}
    >
      {/* Truck SVG */}
      <SVG
        name={
          includedLargeSVgs.includes(makeModel)
            ? `${makeModel}ImageLarge`
            : `cat810eImageLarge`
        }
        style={{
          width: '70%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />

      {/* lines and pulsating circles */}
      <svg
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      >
        <LineAndCircle
          x1={'35%'}
          y1={'50%'}
          x2={'10%'}
          y2={'20%'}
          r1={'10'}
          r2={'20'}
        />

        <LineAndCircle
          x1={'48%'}
          y1={'60%'}
          x2={'15%'}
          y2={'90%'}
          r1={'10'}
          r2={'20'}
        />

        <LineAndCircle
          x1={'58%'}
          y1={'38%'}
          x2={'85%'}
          y2={'30%'}
          r1={'10'}
          r2={'20'}
        />

        <LineAndCircle
          x1={'55%'}
          y1={'57%'}
          x2={'95%'}
          y2={'85%'}
          r1={'10'}
          r2={'20'}
        />
      </svg>

      {/* Engine System */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Engine System"
        items={[
          {
            name: 'Air Filter',
            desc: '',
            status:
              airFilterData[0]?.filter_health.toLowerCase() === 'healthy' ||
              airFilterData[0]?.filter_health.toLowerCase() === 'unknown'
                ? 'OK'
                : airFilterData[0]?.filter_health.toLowerCase() ===
                  'approaching eol'
                ? 'WARNING'
                : airFilterData[0]?.filter_health.toLowerCase() === 'eol'
                ? 'ALERT'
                : 'UNDEFINED',
          },
          { name: 'Fuel Filter', desc: '', status: 'OK' },
          { name: 'Accel/Braking', desc: '', status: 'ALERT' },
          { name: 'Idle Time', desc: '', status: 'OK' },
        ]}
        sx={{
          position: 'absolute',
          top: '15%',
          left: '5%',
          transform: 'translate(0, -50%)',
        }}
      />

      {/* Tire System */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Tire System"
        items={[
          { name: 'TPMS Pressure', desc: '676 kPa', status: 'OK' },
          { name: 'TPMS Temp', desc: '180°F', status: 'OK' },
          { name: 'Road Condition', desc: '', status: 'ALERT' },
          { name: 'Tire Age', desc: '', status: 'OK' },
        ]}
        sx={{
          position: 'absolute',
          bottom: '10%',
          left: '5%',
        }}
      />

      {/* Payload */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Payload"
        items={[
          { name: 'Load Cycles', desc: '4.1 Cycles/day', status: 'OK' },
          {
            name: 'Total loaded distance',
            desc: `${truncateTo2dp(
              tripDetails?.analysis?.total_distance
            )} ${getLabelWrtUnitSystem('KM', unitSystem).toLowerCase()}s`,
            status: 'OK',
          },
          {
            name: 'Total Operating hours',
            desc: `${truncateTo2dp(
              convertSecondsWithUnitValues(
                tripDetails?.analysis?.total_time_in_trips
              )
            )}`,
            status: 'OK',
          },
          {
            name: 'Total payload',
            desc: `${truncateTo2dp(
              tripDetails?.analysis?.actual_total_tonnage
            )} ${getLabelWrtUnitSystem('TON', unitSystem).toLowerCase()}`,
            status: 'OK',
          },
        ]}
        sx={{
          position: 'absolute',
          top: '20%',
          right: '5%',
          transform: 'translate(0, -50%)',
        }}
      />

      {/* Fuel System */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Fuel System"
        items={[
          {
            name: 'Fuel Consumption Overall',
            desc: `${truncateTo2dp(totalFuelConsumed)} ${getLabelWrtUnitSystem(
              'L',
              unitSystem
            ).toLowerCase()}`,
            status: 'OK',
          },
          {
            name: 'Fuel Consumption Loaded',
            desc: `${truncateTo2dp(
              totalFuelConsumedLoaded
            )} ${getLabelWrtUnitSystem('L', unitSystem).toLowerCase()}`,
            status: 'OK',
          },
          {
            name: 'Fuel Consumption Unloaded',
            desc: `${truncateTo2dp(
              totalFuelConsumedUnloaded
            )} ${getLabelWrtUnitSystem('L', unitSystem).toLowerCase()}`,
            status: 'OK',
          },
        ]}
        sx={{
          position: 'absolute',
          bottom: '10%',
          right: '5%',
        }}
      />
    </Box>
  );
};

export default MainBody;
