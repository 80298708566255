import { ThemePalette } from 'mui.theme';
import { Box, Grid } from '@mui/material';
import { AssetSelector } from './AssetSelector';
import { AssetDetail } from './AssetDetail';
import { Filter } from './Filter/Index';
import { type Asset } from 'types/asset.types';

export const Header = ({
  enableDarkTheme,
  selectedOperation,
  setSelectedOperation,
  activeButton,
  setActiveButton,
  dataType,
  setDataType,
  selectedMakeModel,
  setSelectedMakeModel,
  makeModels,
  selectedAsset,
  setSelectedAsset,
  assetBumperIds,
  latestTagData,
  hierarchyInfo,
  startDate,
  endDate,
  onChange,
  maxDate,
  startDateTime,
  endDateTime,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  exportToLabel,
  exportDropdownTo,
  handleExportDropDownChange,
  chartType,
  openEndDate,
  setOpenEndDate,
}: {
  enableDarkTheme: boolean;
  selectedOperation: string;
  setSelectedOperation: any;
  activeButton: string;
  setActiveButton: any;
  dataType: string;
  setDataType: any;
  selectedMakeModel: string;
  setSelectedMakeModel: any;
  makeModels: string[];
  selectedAsset: Asset;
  setSelectedAsset: any;
  assetBumperIds: string[];
  assets: Asset[];
  latestTagData: any;
  hierarchyInfo: any;
  startDate: any;
  endDate: any;
  onChange: any;
  maxDate: any;
  startDateTime: any;
  endDateTime: any;
  handleStartDateTimeChange: any;
  handleEndDateTimeChange: any;
  exportToLabel: any;
  exportDropdownTo: any;
  handleExportDropDownChange: any;
  chartType: string;
  openEndDate: boolean;
  setOpenEndDate: any;
}) => {
  return (
    <>
      <Box
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        <Grid container>
          <AssetSelector
            enableDarkTheme={enableDarkTheme}
            makeModels={makeModels}
            selectedMakeModel={selectedMakeModel}
            setSelectedMakeModel={setSelectedMakeModel}
            assetBumperIds={assetBumperIds}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
          />
          <Grid item sm={12} md={6} lg={8}>
            <AssetDetail
              enableDarkTheme={enableDarkTheme}
              selectedAsset={selectedAsset}
              latestTagData={latestTagData}
              hierarchyInfo={hierarchyInfo}
            />
          </Grid>
        </Grid>
      </Box>
      <Filter
        enableDarkTheme={enableDarkTheme}
        selectedOperation={selectedOperation}
        setSelectedOperation={setSelectedOperation}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        dataType={dataType}
        setDataType={setDataType}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        maxDate={maxDate}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        handleStartDateTimeChange={handleStartDateTimeChange}
        handleEndDateTimeChange={handleEndDateTimeChange}
        exportToLabel={exportToLabel}
        exportDropdownTo={exportDropdownTo}
        handleExportDropDownChange={handleExportDropDownChange}
        chartType={chartType}
        openEndDate={openEndDate}
        setOpenEndDate={setOpenEndDate}
      />
    </>
  );
};
