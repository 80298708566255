import type {
  DriverBehavior,
  DriverBehaviorData,
  DriverBehaviorTimeLineData,
} from 'types/driver.behavior.types';

export const allEvents = ['overspeed', 'incorrect_gear', 'braking'];

export const allEngineHoursEvents = ['productive', 'idling', 'engine_hours'];

export function aggregateEvents(data: DriverBehavior[]) {
  const eventMap: Record<string, number> = {};

  data.forEach((item) => {
    item.data?.forEach((event) => {
      const startDate = event?.start_ts?.split(' ')[0]; // Extract date portion
      if (!eventMap[startDate]) {
        eventMap[startDate] = 0;
      }
      eventMap[startDate] += 1; // Increment count for this date
    });
  });

  // Convert the map to an array of objects
  return Object.entries(eventMap).map(([date, count]) => ({
    start_ts: date,
    noOfEvents: count,
  }));
}

export function transformDriverBehaviorData(
  driverBehaviorData: DriverBehaviorData,
  engineHours?: boolean
): DriverBehaviorTimeLineData[] {
  const mapEventsToTimelineData = (
    behaviors: DriverBehavior[],
    name: string
  ): DriverBehaviorTimeLineData => {
    return {
      name,
      data: behaviors?.flatMap(
        (behavior) =>
          behavior.data?.map((event) => ({
            x: behavior.bumper_id,
            y: [
              new Date(event?.start_ts).getTime(),
              new Date(event?.end_ts).getTime(),
            ],
            average_rpm: event?.average_rpm ? event.average_rpm : null,
            average_fuel_rate: event?.average_fuel_rate
              ? event.average_fuel_rate
              : null,
            avg_speed: event?.avg_speed ? event.avg_speed : null,
          })) ?? []
      ),
    };
  };

  const eventsToUse = engineHours ? allEngineHoursEvents : allEvents;

  const resp: DriverBehaviorTimeLineData[] = eventsToUse?.map((item) => {
    const eventData = mapEventsToTimelineData(
      // @ts-expect-error ignore we have type defined
      driverBehaviorData?.[item],
      item
    );
    return eventData;
  });

  return resp;
}

export function filterDriverBehaviorByDeviceId(
  data: DriverBehaviorData,
  bumperId: string
): DriverBehaviorData {
  const filterByDeviceId = (behaviorList: DriverBehavior[]) =>
    behaviorList.filter((behavior) => behavior.bumper_id === bumperId);

  return {
    productive: filterByDeviceId(data.productive),
    idling: filterByDeviceId(data.idling),
    overspeed: filterByDeviceId(data.overspeed),
    braking: filterByDeviceId(data.braking),
    incorrect_gear: filterByDeviceId(data?.incorrect_gear),
    engine_hours: [],
  };
}

export function isAllTimeLineDataEmpty(arr: any) {
  return arr.every(
    (item: any) => Array.isArray(item.data) && item.data.length === 0
  );
}
