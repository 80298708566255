export function getClosestChartOption(date: string) {
  const inputDate = new Date(date);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate.getTime() - inputDate.getTime();

  // Calculate the number of days
  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  // Check the conditions and return the appropriate string
  if (daysDifference < 1) {
    return '1D';
  } else if (daysDifference < 7) {
    return '7D';
  } else if (daysDifference < 14) {
    return '14D';
  } else if (daysDifference < 365) {
    return '1Y';
  } else {
    return '1Y';
  }
}

export function determineBadgeCount(
  name: string,
  selectedTags: string[],
  options: any
) {
  let resp = 0;
  const nameOption = options.find((item: any) => item.name === name);
  if (nameOption) {
    const nameOptionTags = nameOption.tags.map((item: any) => item.tagName);
    resp = nameOptionTags.filter((item: string) =>
      selectedTags.includes(item)
    ).length;
  }
  return resp;
}
