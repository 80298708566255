import dayjs from 'dayjs';
// import { truncateTo2dp } from 'utils/helpers/general';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

export const tooltip = ({
  seriesIndex,
  dataPointIndex,
  w,
  unitSystem,
}: any): string => {
  const dataPoint = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  const seriesColor = w.globals.colors[seriesIndex];
  const title = dataPoint.x; // Include the title
  const startTime = dayjs(dataPoint.y[0]).format('YYYY-MM-DD HH:mm');
  const endTime = dayjs(dataPoint.y[1]).format('YYYY-MM-DD HH:mm');
  const minutes = dayjs(dataPoint.y[1]).diff(dayjs(dataPoint.y[0]), 'minute');

  // const averageFuelRate = dataPoint?.average_fuel_rate
  //   ? dataPoint.average_fuel_rate
  //   : null;
  // const averageRpm = dataPoint?.average_rpm ? dataPoint.average_rpm : null;

  return `
      <div style="
        background: #2E3B4E; 
        color: #FFFFFF; 
        padding: 12px; 
        border-radius: 8px; 
        font-family: Arial, sans-serif; 
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
        max-width: 200px;">
        <div style="font-weight: bold; font-size: 14px; margin-bottom: 8px; text-align: center; color: white;">
          ${title}
        </div>
        <div style="font-size: 12px; margin-bottom: 4px;">
          <span style="color: ${seriesColor}; font-weight: bold;">Start:</span> ${startTime}
        </div>
        <div style="font-size: 12px; margin-bottom: 4px;">
          <span style="color: ${seriesColor}; font-weight: bold;">End:</span> ${endTime}
        </div>
        <div style="font-size: 12px;">
          <span style="color: ${seriesColor}; font-weight: bold;">Duration:</span> ${minutes} mins
        </div>
        ${
          dataPoint?.avg_speed
            ? `
          <div style="font-size: 12px; margin-top: 8px; text-align: center; color: white;">
            <span style="color: ${seriesColor}; font-weight: bold;">Average Speed:</span> ${dataPoint.avg_speed.toFixed(
                2
              )} ${getLabelWrtUnitSystem('KMH', unitSystem)}
          </div>
          `
            : ``
        }
      </div>
    `;
};

/**
 * TODO: add this later on....
${
          averageFuelRate !== null
            ? `<div style="color: #FF0000; font-size: 14px;">Average Fuel Rate: ${truncateTo2dp(
                averageFuelRate
              )} ${getLabelWrtUnitSystem('LPH', unitSystem)} </div>`
            : ''
        }
                ${
                  averageRpm !== null
                    ? `<div style="color: #FF0000; font-size: 14px;">Average Fuel Rate: ${truncateTo2dp(
                        averageRpm
                      )}</div>`
                    : ''
                }
 */
