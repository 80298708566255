import { BaseTableList } from 'components/AgGrid/AgGrid';
import { PaddedListWrapper2 } from 'components/AgGrid/CellRenderer/ListView.styled';
import { type RoughRoadResponse } from 'types/roadConditions.types';
import { sortableFunction, selectSpacedItems } from './utils';
import { useCallback, useEffect, useState } from 'react';
import { CellDisplay, Last7Days, ToggleStatus } from './CellRenderer';
import RoughRoadHandler from 'handlers/rough-road.handler';
import { type GridApi } from 'ag-grid-community';

interface Props {
  filteredPatches: RoughRoadResponse[];
  setSelectedRow: any;
  selectedRow: any;
  setToggledPatch: any;
  setSelectedPatchSequence: any;
  setIsRowClicked: any;
}
const RoadConditionList = ({
  filteredPatches,
  setSelectedRow,
  selectedRow,
  setToggledPatch,
  setSelectedPatchSequence,
  setIsRowClicked,
}: Props) => {
  const roughRoadHandler = new RoughRoadHandler();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api);
  };

  const ensureRowVisible = useCallback(() => {
    if (!gridApi || !selectedRow) return;

    const rowIndex = filteredPatches.findIndex(
      (patch) => patch.roadConditionId === selectedRow.roadConditionId
    );

    if (rowIndex >= 0) {
      gridApi.ensureIndexVisible(rowIndex, 'top');
    }
  }, [gridApi, selectedRow, filteredPatches]);

  useEffect(() => {
    ensureRowVisible();
  }, [selectedRow, ensureRowVisible]);

  const [columnDefs] = useState([
    {
      field: 'roadConditionId',
      headerName: 'Geo Hash Area',
      sortable: true,
      comparator: sortableFunction,
      cellRenderer: CellDisplay,
      cellRendererParams: {
        handleClick: function (data: any) {
          setSelectedRow(data);
          setSelectedPatchSequence(selectSpacedItems(data.patchSequence, 200));
          setIsRowClicked(true);
        },
      },
    },
    // {
    //   field: 'fuelWasted',
    //   headerName: 'Fuel Wasted',
    //   sortable: true,
    // },
    // {
    //   field: 'timeWasted',
    //   headerName: 'Time Wasted',
    //   sortable: true,
    //   suppressSizeToFit: true,
    // },
    // {
    //   field: 'co2Wasted',
    //   headerName: 'CO2 Wasted',
    //   sortable: true,
    // },
    // {
    //   field: 'moneyWasted',
    //   headerName: 'Total $ Wasted',
    //   cellStyle: { textAlign: 'left' },
    //   filter: 'agTextColumnFilter',
    //   sortable: true,
    // },
    {
      field: '',
      headerName: 'Last 7 Days Reported',
      tooltipField: 'lastConnected',
      sortable: true,
      cellRenderer: Last7Days,
    },

    {
      field: 'isActive',
      headerName: 'Active',
      sortable: true,
      cellRenderer: ToggleStatus,
      cellRendererParams: {
        field: 'isActive',
        handleToggleClick: async (
          roadPatchId: string,
          isActive: boolean,
          roadConditionId: string
        ) => {
          const toggle = await roughRoadHandler.toggleStatus(
            roadPatchId,
            isActive,
            roadConditionId
          );

          if (toggle && toggle.patchId === roadPatchId) {
            setToggledPatch(toggle.patchId);
          }
        },
      },
    },
  ]);

  const getRowStyle = (params: any) => {
    if (!selectedRow && filteredPatches) {
      if (params.data.roadConditionId === filteredPatches[0].roadConditionId) {
        return { background: '#ABABE2' };
      }
    } else {
      if (params.data.roadConditionId === selectedRow?.roadConditionId) {
        return { background: '#ABABE2' };
      }
    }
    return null;
  };

  return (
    <PaddedListWrapper2>
      <BaseTableList
        rowData={filteredPatches ?? []}
        columnDefs={columnDefs}
        cacheQuickFilter={true}
        tableStyles={{ height: '250px', borderRadius: '5px' }}
        getRowStyle={getRowStyle}
        onGridReady={onGridReady}
      />
    </PaddedListWrapper2>
  );
};

export default RoadConditionList;
