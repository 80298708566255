import { ThemePalette } from 'mui.theme';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from 'recharts';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import ChartTooltip from './ChartTooltip';

const FuelConsumptionChart = ({
  data,
}: {
  data: Array<Record<string, string | number | undefined>>;
}) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const colors = ['#FFA403', '#497BFA', '#EB5757', '#009161'];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="#26364C"
        />
        <XAxis
          type="number"
          label={{
            value: `Fuel Consumed ${
              unitSystem === 'metric' ? '(in litres)' : '(in gals)'
            }`,
            position: 'insideBottom',
            offset: -2,
            fill: enableDarkTheme ? ThemePalette.typography.white : 'blue',
            fontWeight: 'bold',
            fontFamily: 'Arial',
          }}
        />
        <YAxis type="category" dataKey="name" />
        <Tooltip
          cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
          content={<ChartTooltip fuelChart={true} />}
        />
        <Bar dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FuelConsumptionChart;
