import FuelAnalyticsAPI from 'api/fuelAnalytics.api';
import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import {
  getAll,
  setEngineHoursLoaded,
  setDeviceEngineHour,
  clearDeviceEngineHour,
  setDeviceEngineHourPerDay,
  updatePerDayLoadingState,
  updateSpecificLoadingState,
} from 'store/engine.hours.slice';
import dayjs from 'dayjs';

export default class EngineHoursHandler extends BaseHandler {
  private readonly api: FuelAnalyticsAPI;

  constructor() {
    super();

    this.api = new FuelAnalyticsAPI();
  }

  /**
   * Gets all assets that the current user has access to
   */
  async getAll() {
    this.dispatch(setEngineHoursLoaded(false));
    this.dispatch(updateLoadingState(true));
    try {
      const days = 1;
      const startApiDate = dayjs().subtract(days, 'day').format('YYYY-MM-DD');
      const endApiDate = dayjs().format('YYYY-MM-DD');
      this.dispatch(
        updateSpecificLoadingState({
          loadingEngineHoursWithinRange: true,
        })
      );
      const { data: engineHours } = await this.api.getEngineHoursWithinRange(
        startApiDate,
        endApiDate
      );
      this.dispatch(getAll(engineHours));
      this.dispatch(setEngineHoursLoaded(true));
      this.dispatch(updateLoadingState(false));
      this.handleSuccess();
    } catch (error: any) {
      this.dispatch(getAll([]));
      this.dispatch(updateLoadingState(false));
      this.dispatch(setEngineHoursLoaded(true));

      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving Engine hours: ' + msg
      );
    } finally {
      this.dispatch(
        updateSpecificLoadingState({
          loadingEngineHoursWithinRange: false,
        })
      );
    }
  }

  async getDeviceEngineHour(
    deviceId: string,
    startApiDate: string,
    endApiDate: string
  ) {
    this.dispatch(clearDeviceEngineHour());
    this.dispatch(updateLoadingState(true));
    this.dispatch(
      updateSpecificLoadingState({
        loadingEngineHoursForDevice: true,
      })
    );
    try {
      const response = await this.api.getAssetEngineHour(
        deviceId,
        startApiDate,
        endApiDate
      );

      this.dispatch(setDeviceEngineHour(response.Items));
      // set state with response
      this.handleSuccess();
    } catch (error: any) {
      this.dispatch(updateLoadingState(false));

      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving Engine hours for device: ' + msg
      );
    } finally {
      this.dispatch(
        updateSpecificLoadingState({
          loadingEngineHoursForDevice: false,
        })
      );
    }
  }

  async getEngineHoursSummaryWithinDateRange(
    startApiDate: string,
    endApiDate: string
  ) {
    this.dispatch(
      updateSpecificLoadingState({
        loadingEngineHoursWithinRange: true,
      })
    );
    try {
      const { data: engineHours } = await this.api.getEngineHoursWithinRange(
        startApiDate,
        endApiDate
      );

      this.dispatch(getAll(engineHours));

      // set state with response
      this.handleSuccess();
    } catch (error: any) {
      this.dispatch(updateLoadingState(false));

      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving Engine hours: ' + msg
      );
    } finally {
      this.dispatch(
        updateSpecificLoadingState({
          loadingEngineHoursWithinRange: false,
        })
      );
    }
  }

  async getDeviceEngineHourPerDay(
    deviceId: string,
    startApiDate: string,
    endApiDate: string
  ) {
    this.dispatch(updatePerDayLoadingState(true));
    try {
      const response = await this.api.getAssetEngineHourPerDay(
        deviceId,
        startApiDate,
        endApiDate
      );

      this.dispatch(setDeviceEngineHourPerDay(response.Items));
      // set state with response
      this.handleSuccess();
    } catch (error: any) {
      this.dispatch(updatePerDayLoadingState(false));

      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving Per day Engine hours for device: ' +
          msg
      );
    } finally {
      this.dispatch(updatePerDayLoadingState(false));
    }
  }
}
