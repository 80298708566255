import { Button } from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import { useState } from 'react';
import { useAppDispatch } from 'store/hook';
import { showNotification } from 'store/notification.slice';
import { CreateCustomerModal } from 'views/Settings/Customer/components/modals/CreateCustomerModal';
import { CreateInternalUser } from 'views/Settings/InternalUsers/components/modals/CreateInternalUserModal';
import { UpdateInternalUserModal } from 'views/Settings/InternalUsers/components/modals/UpdateInternalUserModal';
import { UserDeactivationModal } from 'views/Settings/InternalUsers/components/modals/UserDeactivationModal';

export const PlayGround = () => {
  const dispatch = useAppDispatch();
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] =
    useState(false);
  const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] =
    useState(false);

  const showSnackbar = (
    type: 'success' | 'error',
    message: string = 'This is a test message'
  ) => {
    dispatch(
      showNotification({
        type,
        message,
      })
    );
  };

  /* STUBBED DATA BELOW */
  const internalUserStubbed = {
    userId: 'UUID-1234-5678-9101-1121',
    firstName: 'John',
    lastName: 'Doe',
    email: 'johndoe@symboticware.com',
    isActive: true,
    department: 'product',
    userCustomerAssociations: [
      {
        id: '123',
        display: 'Customer 123',
      },
      { id: '345', display: 'Customer 345' },
    ],
    loginCount: 0,
    lastLogin: '2021-09-01T00:00:00.000Z',
  };
  /* STUBBED DATA END */

  return (
    <Base
      sx={{
        padding: '40px',
      }}
    >
      <CreateInternalUser
        key={`${isAddUserModalOpen.toString()}-create-user-modal`}
        open={isAddUserModalOpen}
        handleAbort={() => {
          setIsAddUserModalOpen(false);
        }}
        title="Add a New Symboticware User"
        enableDarkTheme={false}
      />
      <UpdateInternalUserModal
        key={`${isEditUserModalOpen.toString()}-update-user-modal`}
        open={isEditUserModalOpen}
        handleAbort={() => {
          setIsEditUserModalOpen(false);
        }}
        title="Edit Symboticware User"
        userData={internalUserStubbed}
        enableDarkTheme={false}
      />

      <UserDeactivationModal
        key={`${isDeactivateUserModalOpen.toString()}-deactivate-user-modal}`}
        firstName="John"
        lastName="Doe"
        userId={'UUID-1234-5678-9101-1121'}
        open={isDeactivateUserModalOpen}
        handleAbort={() => {
          setIsDeactivateUserModalOpen(false);
        }}
      />

      <CreateCustomerModal
        key={`${isCreateCustomerModalOpen.toString()}-create-customer-modal`}
        open={isCreateCustomerModalOpen}
        handleAbort={() => {
          setIsCreateCustomerModalOpen(false);
        }}
        title="Create a New Customer Account"
        enableDarkTheme={false}
      />

      <Button
        variant="contained"
        onClick={() => {
          setIsAddUserModalOpen(true);
        }}
        sx={{
          marginRight: '10px',
          backgroundColor: 'button.primary',
        }}
      >
        Create User
      </Button>

      <Button
        variant="contained"
        onClick={() => {
          setIsEditUserModalOpen(true);
        }}
        sx={{
          marginRight: '10px',
          backgroundColor: 'button.primary',
        }}
      >
        Update User
      </Button>

      <Button
        variant="contained"
        onClick={() => {
          showSnackbar('success');
        }}
        sx={{
          marginRight: '10px',
          backgroundColor: 'green',
        }}
      >
        Success Snackbar
      </Button>

      <Button
        variant="contained"
        onClick={() => {
          showSnackbar('error');
        }}
        sx={{
          marginRight: '10px',
          backgroundColor: 'red',
        }}
      >
        Error Snackbar
      </Button>

      <Button
        variant="contained"
        onClick={() => {
          setIsDeactivateUserModalOpen(true);
        }}
        sx={{
          marginRight: '10px',
          backgroundColor: 'button.primary',
        }}
      >
        Show Deactivate User Modal
      </Button>

      <Button
        variant="contained"
        onClick={() => {
          setIsCreateCustomerModalOpen(true);
        }}
        sx={{
          marginRight: '10px',
          backgroundColor: 'button.primary',
        }}
      >
        Show Create Customer Modal
      </Button>
    </Base>
  );
};
