import { Box, Tab, Tabs } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { useState } from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import CustomEngineHoursTabPanel from './Tabs/CustomEngineHoursTabPanel';
import EngineHoursOverview from './Tabs/EngineHoursOverview';
import EngineHoursTimeline from './Tabs/EngineHoursTimeline';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    disableRipple: true,
  };
}

const EngineHoursTabView = () => {
  const { theme } = useAppSelector((state) => state.authReducer).customer;
  const enableDarkTheme = isDarkTheme(theme);
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box width={'100%'}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs"
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#5E85F0',
            },
          }}
        >
          <Tab
            label="Overview"
            {...a11yProps(0)}
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}
          />
          <Tab
            label="Timeline Chart"
            {...a11yProps(1)}
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}
          />
        </Tabs>
      </Box>
      <CustomEngineHoursTabPanel value={value} index={0}>
        <EngineHoursOverview />
      </CustomEngineHoursTabPanel>
      <CustomEngineHoursTabPanel value={value} index={1}>
        <EngineHoursTimeline />
      </CustomEngineHoursTabPanel>
    </Box>
  );
};

export default EngineHoursTabView;
