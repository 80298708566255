import { Box, FormControlLabel, Tooltip } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import StyledSwitchButton from 'components/Buttons/SwitchButton';
import {
  type DataJson,
  type RoughRoadResponse,
} from 'types/roadConditions.types';
import { isDarkTheme } from 'utils/theme';
import { useAppSelector } from 'store/hook';

interface Props {
  value: string;
  data: RoughRoadResponse;
  handleNameClick: (
    dataJson: [
      {
        date: string;
        color: string;
        metrics: [];
      }
    ]
  ) => void;
  selectedNotification: any;
  handleToggleClick: (
    roadPatchId: string,
    isActive: boolean,
    roadConditionId: string
  ) => any;
  handleClick: (data: any) => void;
}

export const CellDisplay = ({ value, data, handleClick }: Props) => {
  const { theme } = useAppSelector((state) => state.authReducer).customer;
  const enableDarkTheme = isDarkTheme(theme);

  return (
    <span
      style={{
        color: `${enableDarkTheme ? ThemePalette.typography.white : '#0047AB'}`,
        textDecoration: 'underline',
        padding: '0px',
        fontSize: '14px',
        cursor: 'pointer',
      }}
      onClick={() => {
        handleClick(data);
      }}
    >
      {value}
    </span>
  );
};

export const ToggleStatus = ({
  data: { roadPatchId, isActive, roadConditionId },
  handleToggleClick,
}: Props) => {
  return (
    <FormControlLabel
      control={<StyledSwitchButton />}
      label=""
      labelPlacement="start"
      checked={isActive}
      sx={{
        textAlign: 'center',
        marginRight: '10px',
      }}
      onChange={async () => {
        await handleToggleClick(roadPatchId, isActive, roadConditionId);
      }}
    />
  );
};

export const Last7Days = ({ data: { dataJson } }: Props) => {
  const uniqueDates: DataJson[] = [];
  const seenDates = new Set();

  dataJson.forEach((item) => {
    if (!seenDates.has(item.date)) {
      seenDates.add(item.date);
      uniqueDates.push(item);
    }
  });

  const sortedCircles = [...uniqueDates].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const recentCircles = sortedCircles.slice(0, 7);

  // If there are fewer than 7 items, fill with greyed-out circles
  const filledCircles = [
    ...Array(7 - recentCircles.length).fill({
      color: 'grey',
      date: 'No data',
    }),
    ...recentCircles,
  ];
  return (
    <Box display="flex" justifyContent="left" gap={1}>
      {filledCircles.map((item, index) => (
        <Tooltip key={index} title={item.date} arrow>
          <Box
            key={index}
            sx={{
              width: 15,
              height: 15,
              backgroundColor: item.color,
              borderRadius: '50%',
              margin: '12px 0',
              border: '#000 2px solid',
            }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};
