import { Box, Typography, ButtonGroup, Button } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const ContentSelector = ({
  enableDarkTheme,
  chartType,
  setChartType,
  setIsSelectTagModalOpen,
  dataType,
}: {
  enableDarkTheme: boolean;
  chartType: string;
  setChartType: any;
  tags: any[];
  selectedTags: string[];
  setIsSelectTagModalOpen: any;
  dataType: string;
}) => {
  const chartTypeBtnList = ['Individual', 'Grouped'];
  const handleClick = (event: any) => {
    setIsSelectTagModalOpen(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            color: enableDarkTheme ? '#8A8A8A' : ThemePalette.typography.black,
            paddingTop: '8px',
            paddingRight: '10px',
          }}
        >
          Readings for:
        </Typography>
        <Button
          size="small"
          id="basic-button"
          sx={{
            border: `1px solid ${
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.lightGrey
            }`,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            borderRadius: '5px',
            paddingLeft: '15px',
            paddingRight: '15px',
            textTransform: 'none',
          }}
          onClick={handleClick}
        >
          <AddCircleOutlineIcon
            sx={{
              paddingRight: '8px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '16px',
            }}
          />
          Select More Tags
        </Button>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: enableDarkTheme ? '#8A8A8A' : ThemePalette.typography.black,
            paddingTop: '8px',
            paddingRight: '10px',
          }}
        >
          View Graph as:
        </Typography>
        <ButtonGroup
          size="small"
          aria-label="Small button group"
          sx={{
            border: '1px solid #38465F',
          }}
        >
          {chartTypeBtnList.map((item, index) => {
            return (
              <Button
                key={index}
                sx={{
                  backgroundColor: chartType === item ? '#3666EC' : '#172946',
                  color: ThemePalette.typography.white,
                  textTransform: 'none',
                }}
                onClick={() => {
                  setChartType(item);
                }}
                disabled={dataType !== 'regular' && item === 'Grouped'}
              >
                {item}
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
    </Box>
  );
};
