class A {
  async getAll(): Promise<any> {}
}

class B {
  async get(): Promise<any> {}
}
class C {
  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean,
    assetType: string,
    aggregation: string
  ): Promise<any> {}
}
class D {
  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean,
    assetType: string,
    aggregation: string,
    shiftWiseAnalysis: boolean,
    shiftNumber: number,
    utilizationType: string
  ): Promise<any> {}
}

interface IHandlerMap {
  handlers: Partial<{
    internalUserHandler: A;
    customerHandler: A;
    externalUserHandler: A & {
      currentCustomer: () => Promise<any>;
    };
    assetHandler: A & {
      getAssetsWithExcessiveIdling: () => Promise<any>;
    };
    operatorHandler: A;
    hierarchyHandler: B;
    ruleHandler: A;
    notificationHandler: A & {
      getAllLatest: () => Promise<any>;
    };
    deviceHandler: A & {
      getDeviceLatestSensorDataAll: () => Promise<any>;
    };
    tagHandler: A;
    engineHoursHandler: A;
    weatherHandler: B;
    fuelPriceHandler: B;
    geoHandler: B;
    fleetOverviewHandler: A;
    utilizationHandler: D;
    productionHandler: C;
    tirePressureHandler: B;
    tagThresholdHandler: B;
  }>;
}

// execute a fetch for the getAll method of each handler
export async function internalFetchAll({ handlers }: IHandlerMap) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const formattedDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
  currentDate.setDate(currentDate.getDate() - 7);
  const startDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
  const Promises1 = [
    handlers.weatherHandler?.get(),
    handlers.internalUserHandler?.getAll(),
    handlers.customerHandler?.getAll(),
    handlers.externalUserHandler?.getAll(),
    handlers.assetHandler?.getAll(),
    handlers.assetHandler?.getAssetsWithExcessiveIdling(),
    handlers.operatorHandler?.getAll(),
    handlers.ruleHandler?.getAll(),
    handlers.tirePressureHandler?.get(),
    handlers.tagThresholdHandler?.get(),
    handlers.geoHandler?.get(),
  ];
  const Promises2 = [
    handlers.deviceHandler?.getAll(),
    handlers.notificationHandler?.getAllLatest(),
    handlers.deviceHandler?.getDeviceLatestSensorDataAll(),
    handlers.tagHandler?.getAll(),

    handlers.hierarchyHandler?.get(),

    handlers.externalUserHandler?.currentCustomer(),
    handlers.engineHoursHandler?.getAll(),
    handlers.fuelPriceHandler?.get(),
    handlers.fleetOverviewHandler?.getAll(),
  ];
  const Promises3 = [
    handlers.utilizationHandler?.get(
      '1D',
      formattedDate,
      formattedDate,
      false,
      true,
      'all',
      'daily',
      false,
      1,
      'shiftHours'
    ),
    // handlers.utilizationHandler?.get(
    //   '7D',
    //   startDate,
    //   formattedDate,
    //   false,
    //   true,
    //   'all'
    // ),
    handlers.productionHandler?.get(
      '1D',
      formattedDate,
      formattedDate,
      false,
      true,
      'all',
      'daily'
    ),
    // handlers.productionHandler?.get(
    //   '7D',
    //   startDate,
    //   formattedDate,
    //   false,
    //   true,
    //   'all'
    // ),
  ];

  try {
    await Promise.allSettled(Promises1);
    await Promise.allSettled(Promises2);
    await Promise.allSettled(Promises3);
  } catch (error) {
    // do something with error
    console.log(error);
  }
}

export async function externalFetchAll({ handlers }: IHandlerMap) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const formattedDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
  currentDate.setDate(currentDate.getDate() - 7);
  const startDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

  const Promises1 = [
    handlers.weatherHandler?.get(),
    handlers.externalUserHandler?.getAll(),
    handlers.assetHandler?.getAll(),
    handlers.assetHandler?.getAssetsWithExcessiveIdling(),
    handlers.operatorHandler?.getAll(),
    handlers.hierarchyHandler?.get(),
    handlers.ruleHandler?.getAll(),
    handlers.tirePressureHandler?.get(),
  ];
  const Promises2 = [
    handlers.deviceHandler?.getAll(),
    handlers.notificationHandler?.getAllLatest(),
    handlers.deviceHandler?.getDeviceLatestSensorDataAll(),
    handlers.tagHandler?.getAll(),

    handlers.externalUserHandler?.currentCustomer(),
    handlers.engineHoursHandler?.getAll(),
    handlers.fuelPriceHandler?.get(),
    handlers.fleetOverviewHandler?.getAll(),
    handlers.geoHandler?.get(),
  ];
  const Promises3 = [
    handlers.utilizationHandler?.get(
      '1D',
      formattedDate,
      formattedDate,
      false,
      true,
      'all',
      'daily',
      false,
      1,
      'shiftHours'
    ),
    // handlers.utilizationHandler?.get(
    //   '7D',
    //   startDate,
    //   formattedDate,
    //   false,
    //   true,
    //   'all'
    // ),
    handlers.productionHandler?.get(
      '1D',
      formattedDate,
      formattedDate,
      false,
      true,
      'all',
      'daily'
    ),
    // handlers.productionHandler?.get(
    //   '7D',
    //   startDate,
    //   formattedDate,
    //   false,
    //   true,
    //   'all'
    // ),
  ];

  try {
    await Promise.allSettled(Promises1);
    await Promise.allSettled(Promises2);
    await Promise.allSettled(Promises3);
  } catch (error) {
    // do something with error
    console.log(error);
  }
}
