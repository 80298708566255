import { Box, Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { extractTagChartData } from '../../utils';
import { GroupedLineChart } from './LineChart';
import { ThemePalette } from 'mui.theme';

export const GroupedChart = ({
  enableDarkTheme,
  data,
  selectedTags,
  keyToUse,
  activeButton,
  startDate,
  endDate,
  selectedAsset,
  unitSystem,
  dataType,
  tagGrainData,
  exportTo,
  handleExport,
  selectedOperation,
}: {
  enableDarkTheme: boolean;
  data: any;
  keyToUse: string;
  activeButton: string;
  selectedAsset: any;
  selectedOperation: any;
  startDate: any;
  endDate: any;
  unitSystem: string;
  tagGrainData: any;
  dataType: string;
  selectedTags: string[];
  exportTo: any;
  handleExport: any;
}) => {
  const tagsData = selectedTags
    .map((tag) => {
      const tagData = data.find((item: any) => item.tagName === tag);
      if (tagData) {
        const { filteredArray, unit } = extractTagChartData(
          tagData,
          keyToUse,
          activeButton,
          startDate,
          endDate,
          selectedAsset,
          unitSystem,
          dataType,
          tagGrainData
        );
        return {
          name: tag,
          data: filteredArray,
          unit,
        };
      }
      return null;
    })
    .filter(
      (tg): tg is { name: string; data: any; unit: string } => tg !== null
    );

  const tagsWithData = tagsData.filter((item) => item.data.length > 0);
  const tagsWithoutData = tagsData.filter((item) => item.data.length === 0);
  const allDataUnits = tagsWithData.map((item) => item.unit);
  const uniqueUnits = allDataUnits.filter(
    (value, index, self) => self.indexOf(value) === index
  );

  function getTagAlias(tagName: string) {
    const alias = selectedAsset.device.tags.find(
      (item: any) => item.tagName === tagName
    );
    if (alias) {
      return alias.tagAlias;
    }
    return tagName;
  }

  return (
    <Box>
      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
          width: '100%',
          height: '500px',
        }}
      >
        <GroupedLineChart
          enableDarkTheme={enableDarkTheme}
          dataType={dataType}
          data={tagsWithData}
          uniqueUnits={uniqueUnits}
          exportTo={exportTo}
          unitSystem={unitSystem}
          handleExport={handleExport}
          selectedAsset={selectedAsset}
          startDate={startDate}
          endDate={endDate}
          activeButton={activeButton}
          selectedOperation={selectedOperation}
        />
      </FlexBox>
      <Box
        sx={{
          textAlign: 'center',
          paddingTop: '20px',
        }}
      >
        <Typography
          style={{
            fontSize: '14px',
            color: ThemePalette.typography.warning,
            display: tagsWithoutData.length > 0 ? 'block' : 'none',
            padding: '0 10%',
            textAlign: 'center',
          }}
        >
          <strong>
            Asset was not able to transmit value during this time period for:
          </strong>
          <br />
          {tagsWithoutData.map((tag: { name: string }, index) => (
            <span key={index} style={{ margin: '5px 0', color: 'red' }}>
              {'\u00A0\u00A0*'} {getTagAlias(tag.name)}
            </span>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};
