import { truncateTo2dp } from 'utils/helpers/general';

export const findMinMaxValues = (arr: any[]) => {
  return arr.reduce(
    (acc, curr) => {
      curr.data.forEach((item: any) => {
        const value = item.value ?? 0; // Use 0 if value is undefined
        acc.min = Math.min(acc.min, value);
        acc.max = Math.max(acc.max, value);
      });
      return acc;
    },
    { min: Infinity, max: -Infinity }
  );
};

export function categorizeRange({ min, max }: any) {
  // Validate inputs
  if (typeof min !== 'number' || typeof max !== 'number' || min > max) {
    throw new Error(
      "Invalid input: 'min' should be less than 'max' and both should be numbers."
    );
  }

  let minVal = min;

  if (minVal > 0) {
    minVal = 0;
  }

  // Calculate intervals
  const interval = (max - minVal) / 3;

  // Define ranges and categories
  const categories = [
    { category: 'danger', range: [minVal, minVal + interval] },
    {
      category: 'warning',
      range: [minVal + interval + 0.01, minVal + 2 * interval],
    },
    { category: 'success', range: [minVal + 2 * interval + 0.01, max] },
  ];

  return categories;
}

export const thresholdColors = {
  success: '#008A5F',
  danger: '#EB5758',
  warning: '#FFA404',
};

export function processUtilizationAttributes(array: any[]) {
  return array.map((obj) => {
    const newObj: any = {};

    for (const [key, value] of Object.entries(obj)) {
      newObj[key] =
        key !== 'name' && typeof value === 'number'
          ? truncateTo2dp(value * 100)
          : value;
    }

    return newObj;
  });
}
