import { Grid, Typography, Box } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { ThemePalette } from 'mui.theme';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { SVG } from 'components/Asset/SVG';
import type { DeviceStats } from 'types/utilization.types';
import { truncateTo2dp } from 'utils/helpers/general';
import {
  calculateUtilization,
  convertMinutesToHours,
  convertsecondsToHours,
  getShiftHours,
} from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  customerCode: string;
  data: DeviceStats;
  utilizationType: string;
}

const AssetItemDetails = ({
  enableDarkTheme,
  unitSystem,
  customerCode,
  data,
  utilizationType,
}: Props) => {
  const noOfValidEntries = Object.values(data.date_stats).filter(
    (entry) => entry.utilization_engine_active_minute > 0
  ).length;

  const shiftHours =
    noOfValidEntries > 0
      ? getShiftHours(customerCode) *
        (noOfValidEntries - 1 > 0 ? noOfValidEntries - 1 : 1)
      : 0;

  return (
    <Box
      sx={{
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SVG
        name={mapVehiclesAssets(data.asset_type)}
        style={{
          fill: '#E2C171',
          height: '30px',
          width: '60px',
          paddingRight: '10px',
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '13px',
              }}
            >
              {data.bumper_id}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PowerSettingsNewIcon
                sx={{
                  color: '#00C853',
                  marginRight: '8px',
                  fontSize: '12px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '10px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {data.make} - {data.model}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              Utilization
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {truncateTo2dp(
                Number(data?.avg_utilization_by_shift_hours) * 100
              )}
              %
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              Productive Hours
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {truncateTo2dp(Number(data?.total_engine_working_minutes) / 60)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              Idling Hours
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {truncateTo2dp(
                convertsecondsToHours(Number(data?.total_idling_duration))
              )}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              {utilizationType === 'shiftHours'
                ? 'Shift Hours'
                : 'Engine Hours'}
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {utilizationType === 'shiftHours'
                ? truncateTo2dp(
                    Number(convertMinutesToHours(Number(data?.totalShiftHours)))
                  )
                : truncateTo2dp(
                    Number(
                      convertMinutesToHours(
                        Number(data?.total_engine_active_minutes)
                      )
                    )
                  )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AssetItemDetails;
