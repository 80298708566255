import { addHours } from 'date-fns';
import dayjs from 'dayjs';
import { Box, Typography, Button } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Selector } from 'components/Form/Selectors/Selector';
import DatePicker from 'react-datepicker';
import { AVG_VAL } from 'utils/enums';
import { FilterButtonGroup } from './ButtonGroup';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';

export const Filter = ({
  enableDarkTheme,
  selectedOperation,
  setSelectedOperation,
  activeButton,
  setActiveButton,
  dataType,
  setDataType,
  startDate,
  endDate,
  onChange,
  maxDate,
  startDateTime,
  endDateTime,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  exportToLabel,
  exportDropdownTo,
  handleExportDropDownChange,
  chartType,
  openEndDate,
  setOpenEndDate,
}: {
  enableDarkTheme: boolean;
  selectedOperation: string;
  setSelectedOperation: any;
  activeButton: string;
  setActiveButton: any;
  dataType: string;
  setDataType: any;
  startDate: any;
  endDate: any;
  onChange: any;
  maxDate: any;
  startDateTime: any;
  endDateTime: any;
  handleStartDateTimeChange: any;
  handleEndDateTimeChange: any;
  exportToLabel: any;
  exportDropdownTo: any;
  handleExportDropDownChange: any;
  chartType: string;
  openEndDate: boolean;
  setOpenEndDate: any;
}) => {
  const getEndDateTime = (start: any) => {
    return addHours(start, 4);
  };

  const maxEndTime = getEndDateTime(startDateTime);

  let showDateSelector = false;
  if (dataType !== 'regular') {
    showDateSelector = true;
  } else {
    if (activeButton === 'Custom') {
      showDateSelector = true;
    }
  }

  const handleFocus = () => {
    setOpenEndDate(true);
  };

  return (
    <Box
      sx={{
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        paddingTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography
          variant="bodyBold"
          mb={'4px'}
          sx={{
            paddingRight: '10px',
            fontWeight: '400',
            fontSize: '12px',
          }}
        >
          Raw Sensor
        </Typography>
        {dataType === 'regular' && (
          <>
            <Selector
              minWidth="1px"
              value={selectedOperation}
              onChange={(event) => {
                setSelectedOperation(String(event.target.value));
              }}
              selectorOptions={AVG_VAL}
              enableDarkTheme={enableDarkTheme}
              disabled={dataType !== 'regular'}
            />
            <Typography
              variant="bodyBold"
              mb={'4px'}
              sx={{
                paddingLeft: '10px',
                paddingRight: '10px',
                fontWeight: '400',
                fontSize: '12px',
              }}
            >
              Quick Range:
            </Typography>
            <FilterButtonGroup
              enableDarkTheme={enableDarkTheme}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              dataType={dataType}
            />
          </>
        )}{' '}
        {showDateSelector && (
          <>
            {activeButton === 'Custom' ? (
              <DatePicker
                showIcon
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                maxDate={maxDate}
                selectsRange
                className={
                  enableDarkTheme
                    ? 'custom-datepicker-prod'
                    : 'custom-datepicker-prod-light-theme'
                }
              />
            ) : (
              <>
                <label
                  htmlFor="start-datetime"
                  style={{
                    fontSize: '12px',
                  }}
                >
                  From:
                </label>{' '}
                <DatePicker
                  id="start-datetime"
                  selected={startDateTime}
                  onChange={handleStartDateTimeChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  // maxTime={
                  //   dayjs(startDateTime).isSame(dayjs(), 'day')
                  //     ? dayjs().toDate()
                  //     : dayjs().endOf('day').toDate()
                  // }
                  // minTime={dayjs().startOf('day').toDate()}
                  maxDate={dayjs().toDate()}
                  minDate={dayjs('2000-01-01').toDate()}
                  dateFormat="yyyy-MM-dd HH:mm"
                  className={
                    enableDarkTheme
                      ? 'custom-datetimepicker-prod'
                      : 'custom-datetimepicker-prod-light-theme'
                  }
                  onCalendarClose={() => {
                    setOpenEndDate(true);
                  }}
                />{' '}
                <label
                  htmlFor="end-datetime"
                  style={{
                    fontSize: '12px',
                  }}
                >
                  To:
                </label>{' '}
                <DatePicker
                  id="end-datetime"
                  selected={endDateTime}
                  onChange={handleEndDateTimeChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="yyyy-MM-dd HH:mm"
                  minDate={startDateTime}
                  maxDate={maxEndTime}
                  minTime={startDateTime}
                  maxTime={maxEndTime}
                  className={
                    enableDarkTheme
                      ? 'custom-datetimepicker-prod'
                      : 'custom-datetimepicker-prod-light-theme'
                  }
                  open={openEndDate}
                  onClickOutside={() => setOpenEndDate(false)}
                  onFocus={handleFocus}
                />
                <Typography
                  variant="bodyBold"
                  mb={'4px'}
                  sx={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    fontWeight: '400',
                    fontSize: '12px',
                  }}
                >
                  * only 4 hours time window can be selected
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
      <Box>
        <ExportSelector
          view={''}
          exportToLabel={exportToLabel}
          exportTo={exportDropdownTo}
          onExportToChange={handleExportDropDownChange}
          enableDarkTheme={enableDarkTheme}
        />

        <Button
          size="small"
          sx={{
            border: '1px solid #2B53BC',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            borderRadius: '5px',
            paddingLeft: '15px',
            paddingRight: '15px',
            textTransform: 'none',
            marginLeft: '10px',
          }}
          onClick={() => {
            setDataType(dataType === 'regular' ? 'highGrain' : 'regular');
          }}
          disabled={dataType === 'regular' && chartType === 'Grouped'}
        >
          <ShowChartIcon
            sx={{
              paddingRight: '8px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '16px',
            }}
          />
          {dataType === 'regular'
            ? 'View High Grain Data'
            : 'View Regular Data'}
        </Button>
      </Box>
    </Box>
  );
};
