import { Color } from 'cesium';
import React, { useState, useEffect } from 'react';
import { PointGraphics } from 'resium';

interface Props {
  color: any;
  pixelSize: number;
}

const PulsatingPointGraphics = ({ color, pixelSize }: Props) => {
  const [basePixelSize, setBasePixelSize] = useState(pixelSize);
  const [scaleMultiplier, setScaleMultiplier] = useState(1);
  const [colorAlpha, setColorAlpha] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setScaleMultiplier((prevMultiplier) => {
        if (prevMultiplier >= 1.5) {
          setColorAlpha(1);
          return 1;
        } else {
          setColorAlpha(prevMultiplier / 1.5);
          return prevMultiplier + 0.1;
        }
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentColor = new Color(
    color.red,
    color.green,
    color.blue,
    color.alpha * colorAlpha
  );

  return (
    <PointGraphics
      color={currentColor}
      pixelSize={basePixelSize * scaleMultiplier}
      outlineColor={color}
    />
  );
};

export default PulsatingPointGraphics;
