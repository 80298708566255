import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { CustomTabPanel } from '../components/CustomTabPanel';
import { ThemePalette } from 'mui.theme';
import Assets from './components/Assets';
import Asset from './components/Asset';
import type { DriverBehaviorData } from 'types/driver.behavior.types';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  timeLineAsset: string;
  timelineDate: string;
  data: DriverBehaviorData;
}

const TimeLine = ({
  value,
  enableDarkTheme,
  timeLineAsset,
  timelineDate,
  data,
}: Props) => {
  const assetId: string = timeLineAsset;

  const hierarchyInfo = useAppSelector((state) => state.hierarchyReducer);
  const assetReducer = useAppSelector((state) => state.assetReducer);
  const { latestTagData } = useAppSelector((state) => state.deviceReducer);
  const { assets } = assetReducer;

  const [selectedAsset, setSelectedAsset] = useState(assetId);

  useEffect(() => {
    if (assetId) {
      setSelectedAsset(assetId);
    }
  }, [assetId]);

  return (
    <>
      <CustomTabPanel value={value} index={1}>
        <Box
          sx={{
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '20px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            minHeight: '40px',
            borderRadius: '6px',
            background: enableDarkTheme
              ? ThemePalette.dark.topBar
              : ThemePalette.light.topBar,
          }}
        >
          {assetId === '' ? (
            <>
              <Assets
                enableDarkTheme={enableDarkTheme}
                data={data}
                assets={assets}
                timelineDate={timelineDate}
              />
            </>
          ) : (
            <Asset
              enableDarkTheme={enableDarkTheme}
              hierarchyInfo={hierarchyInfo}
              assets={assets}
              latestTagData={latestTagData}
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              timelineDate={timelineDate}
              data={data}
            />
          )}
        </Box>
      </CustomTabPanel>
    </>
  );
};

export default TimeLine;
