import dayjs from 'dayjs';

export const formatDate = (rawDate: string): string => {
  const date = new Date(rawDate);

  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const formatDateToString = (
  date: Date,
  format: string = 'MMM DD, YYYY'
): string => {
  return dayjs(date).format(format);
};

export const calculateTimeStampFromCurrentTime = (
  timestamp: number
): number => {
  const currentTimestamp = Date.now();
  return currentTimestamp - timestamp;
};

export const isWithinLast24Hours = (timestamp: number): boolean => {
  const difference = calculateTimeStampFromCurrentTime(timestamp);
  const millisecondsIn24Hours = 24 * 60 * 60 * 1000;

  return difference <= millisecondsIn24Hours;
};

export const todaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

//  format date to YYYY-MM-DD
export const formatDateShortener = (val: Date): string => {
  const date = new Date(val);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
  const day = date.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const convertSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(0);

  return { hours, minutes, seconds: remainingSeconds };
};

export const convertSecondsWithUnitValues = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.ceil(seconds % 60);

  let timeString = '';

  if (hours > 0) {
    timeString += `${hours} hr `;
  }
  if (minutes > 0) {
    timeString += `${minutes} min `;
  }
  timeString += `${remainingSeconds} sec`;

  return timeString.trim();
};

/**
 * Checks if a given date string is within a specified number of days from the current date or a given date.
 * @param dateString The date string to check.
 * @param noOfDays The number of days to consider for the comparison (default is 7 days).
 * @param startDate An optional parameter to specify a custom date for comparison. It defaults to today if no value supplied
 * @param endDate An optional parameter to specify a custom enddate for comparison. It defaults to today if no value supplied
 * @returns A boolean indicating whether the input date is within the specified range.
 */
export const isWithinGivenDays = (
  dateString: string,
  noOfDays = 7,
  startDate?: string,
  endDate?: string
) => {
  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0);
  if (startDate && endDate) {
    const startDateFormat = new Date(startDate);
    const endDateFormat = new Date(endDate);
    return inputDate >= startDateFormat && inputDate <= endDateFormat;
  } else {
    const today = startDate ? new Date(startDate) : new Date();

    today.setHours(0, 0, 0, 0);

    const xDaysAgo = new Date();
    xDaysAgo.setDate(today.getDate() - noOfDays);
    return inputDate >= xDaysAgo && inputDate <= today;
  }
};

/**
 * Formats a given date string into a human-readable format displaying the month and year.
 * e.g "2024-09-01 00:00:00.000" to "September 2024"
 * @param dateString - The date string to be formatted.
 * @returns A string representing the formatted date in "Month Year" format.
 */
export const formatDateToMonthYear = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const formatTimestamp = (
  timestamp: number | string,
  customFormat?: string
): string => {
  const date = dayjs(timestamp);
  const formattedDate = date.format(customFormat ?? 'YYYY-MMM-DD HH:mm (Z)');
  return formattedDate;
};

// Function to format the 'age' value into a more readable format
// Input format example: '1 04:38:40.000' (1 day, 4 hours, 38 mins, 40 secs)
// Desired output: '1 day, 4 hours, 38 mins and 40 secs'
export const formatAge = (age: string): string => {
  // Regular expression to capture days, hours, minutes, and seconds from the age string
  const ageRegex = /(\d+) (\d{2}):(\d{2}):(\d{2})\.\d+/;

  // Use the regex to match the 'age' string
  const match = age.match(ageRegex);

  // If the format matches, proceed with the transformation
  if (match) {
    // Extract the matched groups and convert them to numbers for proper formatting
    const [, days, hours, minutes, seconds] = match.map(Number);

    // Initialize an empty result string to build the formatted age
    let result = '';

    // Add days if greater than 0, taking care of singular/plural
    if (days > 0) result += `${days} day${days > 1 ? 's' : ''}, `;

    // Add hours if greater than 0, with singular/plural logic
    if (hours > 0) result += `${hours} hour${hours > 1 ? 's' : ''}, `;

    // Only add minutes if they are greater than 0
    if (minutes > 0) result += `${minutes} min${minutes > 1 ? 's' : ''} and `;

    // Add seconds with singular/plural check
    result += `${seconds} sec${seconds > 1 ? 's' : ''}`;

    // Return the fully formatted string, removing any trailing commas
    return result;
  }

  // If no match is found (unlikely based on input), return the original age string
  return age;
};
