import styled from 'styled-components';
import { COLORS } from 'utils/enums';

export const ListWrapper = styled.div`
  margin-top: 10px;
`;

export const IdSpan = styled.span`
  color: #5a9bd6; /* Soft Blue: Readable and balanced for both light and dark backgrounds */
  font-weight: 600 !important;
  cursor: pointer !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const OperationalStatusText = styled.span<{ isOperational: boolean }>`
  margin-left: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: inline-block;
  height: 35px;
  color: ${(props) => (props.isOperational ? COLORS.green : COLORS.red)};
`;

export const PaddedListWrapper = styled.div`
  padding: 15px 20px 40px 20px;
`;

export const PaddedListWrapper2 = styled.div`
  padding: 15px 0px 0px 0px;
`;
