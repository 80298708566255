import { Box, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { ThemePalette } from 'mui.theme';
import AssetScene from './AssetScene';
import { Divider } from '../Header';
import { ASSET_TYPES } from 'utils/enums';
import { fuelValueConversion } from 'views/FuelHistoricalAnalysis/utils';

// const val = ['consumption', 'co2 emission', 'cost', 'idle time'];

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload && payload.length) {
    const { name, val } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: 'rgba(217, 229, 247, 0.8) ',
          border: '1px solid #ccc',
          color: '#000',
          padding: '3px 5px',
          borderRadius: '4px',
        }}
      >
        <p>{`${name}: ${val}`}</p>
      </div>
    );
  }
  return null;
};

interface Props {
  enableDarkTheme: boolean;
  device: any;
  handleClicked: (device: string) => void;
  // deviceData: any;
  allFleetData: any;
  baseCost: {
    diesel: number;
    gas: number;
  };
  unitSystem: string;
  fuelUnit: 'ltr' | 'gal';
}

const AssetDetails = ({
  enableDarkTheme,
  device,
  handleClicked,
  // deviceData,
  allFleetData,
  baseCost,
  unitSystem,
  fuelUnit,
}: Props) => {
  // const [fuelMeasure, setFuelMeasure] = useState<
  //   'consumption' | 'co2 emission' | 'cost' | 'idle time'
  // >('consumption');

  // const handleFuelMeasure = (
  //   event: React.MouseEvent<HTMLElement>,
  //   fuelMeasure: 'consumption' | 'co2 emission' | 'cost' | 'idle time'
  // ) => {
  //   if (fuelMeasure !== null) {
  //     setFuelMeasure(fuelMeasure);
  //   }
  // };

  const deviceData = [];
  deviceData[0] = {
    name: 'Fuel Consumption',
    fleetVal: fuelValueConversion(
      allFleetData.reduce((accumulator: number, currentItem: any) => {
        return accumulator + parseFloat(currentItem.total_fuel_consumption);
      }, 0) / allFleetData.length,
      unitSystem,
      fuelUnit
    ).toFixed(2),
    val: fuelValueConversion(
      device.total_fuel_consumption,
      unitSystem,
      fuelUnit
    ).toFixed(2),
  };

  deviceData[1] = {
    name: 'Cost',
    fleetVal: (
      (allFleetData.reduce((accumulator: number, currentItem: any) => {
        return accumulator + parseFloat(currentItem.total_fuel_consumption);
      }, 0) /
        allFleetData.length) *
      baseCost[device.fuelSource.toLowerCase() as 'gas' | 'diesel']
    ).toFixed(2),
    val: (
      device.total_fuel_consumption *
      baseCost[device.fuelSource.toLowerCase() as 'gas' | 'diesel']
    ).toFixed(2),
  };

  deviceData[2] = {
    name: 'CO2 Emission',
    fleetVal: (
      allFleetData.reduce((accumulator: number, currentItem: any) => {
        return accumulator + parseFloat(currentItem.CO2);
      }, 0) / allFleetData.length
    ).toFixed(2),
    val: device.CO2,
  };

  return (
    <Box
      style={{
        padding: '30px 20px',
        border: '1px solid rgba(98, 98, 98, 0.2)',
        borderRadius: '4px',
        width: '80%',
      }}
    >
      <Box
        style={{
          color: enableDarkTheme ? ThemePalette.typography.white : '#3d3d3d',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '40px',
        }}
      >
        <Box>
          <Typography
            style={{
              fontSize: '14px',
              padding: '5px 10px 5px 0',
              fontWeight: 'bold',
            }}
          >
            Fleet Fuel Consumption
          </Typography>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '54px',
                fontWeight: 'bold',
                cursor: 'pointer',
                lineHeight: '1',
                marginRight: '8px',
                marginTop: '-4px',
                color: '#497BFA',
              }}
              onClick={() => {
                handleClicked('');
              }}
            >
              &larr;
            </span>
            {device.bumper_number}
          </Typography>
        </Box>

        <Box>
          {/* <ToggleButtonGroup
            value={fuelMeasure}
            exclusive
            onChange={handleFuelMeasure}
            aria-label="fuelMeasure"
            sx={{ height: '30px' }}
            color="info"
          >
            {val.map((item: any, index) => {
              return (
                <ToggleButton
                  key={index}
                  value={item}
                  aria-label={item}
                  sx={{
                    color: enableDarkTheme ? '#D0D5DD' : '#000',
                    borderColor: '#7c7e82',
                    '&.Mui-selected': {
                      backgroundColor: enableDarkTheme ? '#0F49E5' : '#022859',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#022859',
                      },
                    },
                  }}
                >
                  <Typography style={{ fontSize: '12px' }}>{item}</Typography>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup> */}
          <Typography style={{ textAlign: 'right', padding: '20px 0 0 0' }}>
            Consumption:{' '}
            <span style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {' '}
              {device.total_fuel_consumption}
            </span>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <AssetScene enableDarkTheme={enableDarkTheme} device={device} />
        <Box
          sx={{
            width: '58%',
          }}
        >
          <Box
            sx={{
              border: '1px solid rgba(98, 98, 98, 0.2)',
              borderRadius: '4px',
              padding: '10px 20px',
            }}
          >
            <Typography
              variant="body2"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                marginBottom: '10px',
                color: enableDarkTheme ? '#fff' : '#333333',
              }}
            >
              Truck Details
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '10px 10px 5px 60px',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ textAlign: 'center', minWidth: '20%' }}>
                <Typography
                  style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
                >
                  Bumper ID
                </Typography>
                <Typography
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px 5px 0',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                >
                  {device.bumper_number}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ textAlign: 'center', minWidth: '20%' }}>
                <Typography
                  style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
                >
                  Type
                </Typography>
                <Typography
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px 5px 0',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                >
                  {ASSET_TYPES.find((e) => e.id === device.asset_type)?.display}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ textAlign: 'center', minWidth: '20%' }}>
                <Typography
                  style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
                >
                  Hierarchy Node
                </Typography>
                <Typography
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px 5px 0',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                >
                  {device.customer_code}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '10px 10px 5px 60px',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ textAlign: 'center', minWidth: '20%' }}>
                <Typography
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px 5px 0',
                  }}
                >
                  OEM
                </Typography>
                <Typography
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px 5px 0',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                >
                  {device.make}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ textAlign: 'center', minWidth: '20%' }}>
                <Typography
                  style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
                >
                  Model
                </Typography>
                <Typography
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px 5px 0',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                >
                  {device.model}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ textAlign: 'center', minWidth: '20%' }}>
                <Typography
                  style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}
                >
                  Status
                </Typography>
                <Typography
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px 5px 0',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                >
                  {}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                border: '1px solid rgba(98, 98, 98, 0.2)',
                borderRadius: '4px',
                padding: '10px',
                width: '45%',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  padding: '10px',
                  color: enableDarkTheme ? '#fff' : '#022859',
                }}
              >
                Fleet Haul Truck Average
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={300}
                  height={250}
                  data={deviceData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip
                    cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
                    content={<CustomTooltip />}
                  />
                  {/* <Legend /> */}
                  <Bar
                    dataKey="val"
                    fill="#497BFA"
                    activeBar={<Rectangle fill="pink" stroke="blue" />}
                    radius={[8, 8, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>

            <Box
              sx={{
                border: '1px solid rgba(98, 98, 98, 0.2)',
                borderRadius: '4px',
                padding: '10px',
                width: '45%',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  padding: '10px',
                  color: enableDarkTheme ? '#fff' : '#022859',
                }}
              >
                This Vehicle
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={300}
                  height={250}
                  data={deviceData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey="fleetVal"
                    fill="#EB5757"
                    activeBar={<Rectangle fill="pink" stroke="blue" />}
                    radius={[8, 8, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetDetails;
