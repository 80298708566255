import { Box, Typography, Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import type { Asset } from 'types/asset.types';
import { SVG } from 'components/Asset/SVG';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { transformText } from '../FleetOverview/helpers/asset.helpers';

export const Header = ({
  enableDarkTheme,
  assets,
  setShowModal,
}: {
  enableDarkTheme: boolean;
  assets: Asset[];
  setShowModal: any;
}) => {
  const assetDisplay = [
    {
      bumperNumber: 'Benchmark',
      assetType: assets[0].assetType,
    },
    ...assets,
  ];

  return (
    <Box
      sx={{
        marginTop: '15px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
      }}
    >
      <Grid container>
        {assetDisplay.map((item, index) => {
          return (
            <Grid item lg={3} md={6} xs={12} key={item.bumperNumber}>
              <Box
                sx={{
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.boxBackground
                    : ThemePalette.light.toolBarBackground,
                  borderRight:
                    assetDisplay.length - 1 === index
                      ? ''
                      : '1px solid #3A4657',
                  minHeight: '90px',
                  paddingTop: '15px',
                  textAlign: 'center',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  paddingBottom: '25px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                >
                  {item.bumperNumber}
                  {index === 0 ? (
                    <span
                      style={{
                        fontSize: '13px',
                      }}
                    >
                      {' '}
                      - {transformText(item.assetType)}
                    </span>
                  ) : null}
                </Typography>
                <SVG
                  name={mapVehiclesAssets(item.assetType)}
                  style={{
                    fill: '#E2C171',
                    height: '30px',
                    width: '60px',
                    paddingTop: '15px',
                  }}
                />
              </Box>
            </Grid>
          );
        })}

        {assetDisplay.length < 4 ? (
          <Grid item lg={3} md={6} xs={12} key={'Unassigned-grid'}>
            <Box
              sx={{
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.boxBackground
                  : ThemePalette.light.toolBarBackground,
                borderLeft: '1px solid #3A4657',
                minHeight: '150px',
                textAlign: 'center',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                paddingTop: '15px',
                paddingBottom: '25px',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <ControlPointIcon />
              <Typography
                sx={{
                  paddingLeft: '5px',
                }}
              >
                Add Another Asset
              </Typography>
            </Box>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};
