import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { addHours } from 'date-fns';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { AssetDetail } from './Components/AssetDetail/Index';
import { Content } from './Components/Content/Index';
import type { Asset } from 'types/asset.types';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import { PressureLimitModal } from './Components/modal/PressureLimit';
import TireProfileHandler from 'handlers/tire-pressure';
import { getAssetMakeModel } from 'views/FleetOverview/components/AssetCardsV2/utils';
import { AssetSelector } from './Components/AssetSelector';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
import { type Device } from 'types/device.types';

const VehicleInsight = () => {
  const tireProfileHandler = new TireProfileHandler();
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const { assets, isLoadingAllAssets } = useAppSelector(
    (state) => state.assetReducer
  );

  const makeModels = getAssetMakeModel(assets);
  const { latestTagData } = useAppSelector((state) => state.deviceReducer);
  const hierarchyInfo = useAppSelector((state) => state.hierarchyReducer);
  const { tireData, limits, isLoadingVehicle } = useAppSelector(
    (state) => state.tireProfileReducer
  );
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const enableDarkTheme = isDarkTheme(theme);
  const [selected, setSelected] = useState('Last 7 Days');
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [dataType, setDataType] = useState<string>('regular');
  const defaultAsset: string | null = new URLSearchParams(location.search).get(
    'asset'
  );
  const [executeGrainCall, setExecuteGrainCall] = useState<boolean>(false);

  const [selectedMakeModel, setSelectedMakeModel] = useState(() => {
    if (makeModels.length > 0) {
      return makeModels[0];
    } else {
      return '';
    }
  });

  const [assetBumperIds, setAssetBumperIds] = useState<string[]>([]);

  const [selectedAsset, setSelectedAsset] = useState<Asset>({
    assetId: '',
    assetVin: '',
    assetType: 'haul_truck',
    make: '',
    bumperNumber: '',
    engineHoursOffset: -1,
    device: {} as const as Device,
    isActive: false,
    model: '',
    odometerOffset: -1,
    fuelSource: '',
    operators: [],
    externalId: '',
    assetNotes: [],
  });

  const [comparingAsset, setComparingAsset] = useState<string>('');

  const [metricType, setMetricType] = useState<string>('Pressure');

  const [startDateTime, setStartDateTime] = useState(
    new Date(new Date().getTime() - 5 * 60000)
  );
  const [endDateTime, setEndDateTime] = useState(new Date());

  async function addPressureLimit(arg: any) {
    void tireProfileHandler.setTireProfileLimits(arg);
  }

  function handleSelectAsset(bumperNumber: string) {
    const item = assets.find((asset) => asset.bumperNumber === bumperNumber);
    if (item) {
      setSelectedAsset(item);
    }
  }

  const getEndDateTime = (start: any) => {
    return addHours(start, 4);
  };

  const handleStartDateTimeChange = (date: any) => {
    setExecuteGrainCall(false);
    setStartDateTime(date);
    setEndDateTime(getEndDateTime(date));
  };

  const handleEndDateTimeChange = (date: any) => {
    setExecuteGrainCall(true);
    setEndDateTime(date);
  };

  useEffect(() => {
    const filteredAssets = assets
      .filter(
        (asset) =>
          composeUniqueKey([asset.make, asset.model]) ===
          selectedMakeModel.replace('-', '!')
      )
      .map((item) => {
        return item.bumperNumber;
      });
    setAssetBumperIds(filteredAssets);
    handleSelectAsset(filteredAssets[0]);
  }, [selectedMakeModel]);

  useEffect(() => {
    if (defaultAsset) {
      const defaultAssetDetails = assets.find(
        (item) => item.bumperNumber === defaultAsset
      );
      if (defaultAssetDetails) {
        const assetMakeModel = composeUniqueKey(
          [defaultAssetDetails.make, defaultAssetDetails.model],
          '-'
        );
        setSelectedAsset(defaultAssetDetails);
        setSelectedMakeModel(assetMakeModel);
      }
    } else {
      setSelectedAsset(assets[0]);
    }
  }, []);

  useEffect(() => {
    if (
      selectedAsset &&
      selectedAsset.assetId !== '' &&
      selectedAsset.device?.deviceId
    ) {
      if (dataType === 'regular') {
        void tireProfileHandler.getAssetTireData(
          selectedAsset.device.deviceId,
          selected,
          selectedAsset.assetType
        );
      } else {
        void tireProfileHandler.getAssetTireGrainData(
          selectedAsset.device.deviceId,
          selected,
          selectedAsset.assetType,
          startDateTime,
          endDateTime
        );
      }
    }
  }, [selectedAsset, selected, dataType]);

  useEffect(() => {
    if (comparingAsset !== '') {
      const assetToFetch = assets.find(
        (item) => item.bumperNumber === comparingAsset
      );
      if (assetToFetch) {
        if (dataType === 'regular') {
          void tireProfileHandler.getAssetTireData(
            assetToFetch.device.deviceId,
            selected,
            assetToFetch.assetType
          );
        } else {
          void tireProfileHandler.getAssetTireGrainData(
            assetToFetch.device.deviceId,
            selected,
            assetToFetch.assetType,
            startDateTime,
            endDateTime
          );
        }
      }
    }
  }, [comparingAsset, selected, dataType]);

  useEffect(() => {
    if (executeGrainCall) {
      void tireProfileHandler.getAssetTireGrainData(
        selectedAsset.device.deviceId,
        selected,
        selectedAsset.assetType,
        startDateTime,
        endDateTime
      );
    }

    if (comparingAsset !== '') {
      const assetToFetch = assets.find(
        (item) => item.bumperNumber === comparingAsset
      );
      if (assetToFetch) {
        void tireProfileHandler.getAssetTireGrainData(
          assetToFetch.device.deviceId,
          selected,
          assetToFetch.assetType,
          startDateTime,
          endDateTime
        );
      }
    }
  }, [endDateTime]);

  const assetsWithDevices = assets.filter((item) => item.device);

  return (
    <>
      {isSelectModalOpen && (
        <PressureLimitModal
          open={isSelectModalOpen}
          handleAbort={() => {
            setIsSelectModalOpen(false);
          }}
          title="Tire Pressure Limits"
          enableDarkTheme={enableDarkTheme}
          assets={assetsWithDevices}
          addPressureLimit={addPressureLimit}
        />
      )}
      <Box>
        {isLoadingAllAssets || isLoadingVehicle ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <SingleFadeLoader />
          </div>
        ) : (
          <>
            {!selectedAsset ? (
              <Box
                sx={{
                  marginTop: '20px',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.toolBarBackground
                    : ThemePalette.light.toolBarBackground,
                  padding: '10px',
                  borderRadius: '4px',
                }}
              >
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  No Asset or Asset details unavailable
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    marginTop: '20px',
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.toolBarBackground
                      : ThemePalette.light.toolBarBackground,
                    padding: '10px',
                    borderRadius: '4px',
                    display: 'flex',
                  }}
                >
                  <AssetSelector
                    makeModels={makeModels}
                    enableDarkTheme={enableDarkTheme}
                    selectedMakeModel={selectedMakeModel}
                    setSelectedMakeModel={setSelectedMakeModel}
                    assetBumperIds={assetBumperIds}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={handleSelectAsset}
                    comparingAsset={comparingAsset}
                    setComparingAsset={setComparingAsset}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: '20px',
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.toolBarBackground
                      : ThemePalette.light.toolBarBackground,
                    padding: '10px',
                    borderRadius: '4px',
                  }}
                >
                  <AssetDetail
                    enableDarkTheme={enableDarkTheme}
                    selectedAsset={selectedAsset}
                    latestTagData={latestTagData}
                    hierarchyInfo={hierarchyInfo}
                    setIsSelectModalOpen={setIsSelectModalOpen}
                    metricType={metricType}
                  />
                </Box>

                <Box
                  sx={{
                    marginTop: '20px',
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.toolBarBackground
                      : ThemePalette.light.toolBarBackground,
                    padding: '10px',
                    borderRadius: '4px',
                  }}
                >
                  <Content
                    enableDarkTheme={enableDarkTheme}
                    selected={selected}
                    setSelected={setSelected}
                    limits={limits}
                    tireData={tireData}
                    selectedAsset={selectedAsset}
                    dataType={dataType}
                    setDataType={setDataType}
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    handleStartDateTimeChange={handleStartDateTimeChange}
                    handleEndDateTimeChange={handleEndDateTimeChange}
                    unitSystem={unitSystem}
                    metricType={metricType}
                    setMetricType={setMetricType}
                    comparingAsset={comparingAsset}
                    assets={assets}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default VehicleInsight;
