import { Grid } from '@mui/material';
import { CustomTabPanel } from 'views/Production/CustomPanel/index';
import ProductiveHours from '../components/ProductiveHours';
import DateRangeDiv from 'views/Utilization/HeaderMenu/DateRangeDiv';
import GridAnalytics from '../components/GridAnalytics';
import { ThemePalette } from 'mui.theme';
import HoursBreakdown from '../components/HoursBreakdown';
import Suggestions from 'views/Utilization/components/Suggestions/Suggestions';
import AssetWrapper from './AssetWrapper';
import type {
  utilizationLoadingStates,
  utilizationFleetData,
} from 'types/utilization.types';
import { getShiftHours } from '../utils';

export interface AggregatedResult {
  totalFleet: FuelConsumptionWithPercentage;
  haulers: FuelConsumptionWithPercentage;
  loaders: FuelConsumptionWithPercentage;
}

export interface FuelConsumption {
  totalFuel: number;
  idleFuel: number;
}

export interface FuelConsumptionWithPercentage extends FuelConsumption {
  idleFuelPercentage: number;
}

export function calculateIdleFuelPercentage(
  idleFuel: number,
  totalFuel: number
): number {
  return totalFuel > 0 ? (idleFuel / totalFuel) * 100 : 0;
}

export function aggregateFuelConsumption(data: any[]): AggregatedResult {
  const result: AggregatedResult = {
    totalFleet: { totalFuel: 0, idleFuel: 0, idleFuelPercentage: 0 },
    haulers: { totalFuel: 0, idleFuel: 0, idleFuelPercentage: 0 },
    loaders: { totalFuel: 0, idleFuel: 0, idleFuelPercentage: 0 },
  };

  data.forEach((item) => {
    const totalFuel = parseFloat(item.total_fuel_consumption_l);
    const idleFuel = parseFloat(item.total_idle_fuel_consumption_l);

    // Aggregate for total fleet
    result.totalFleet.totalFuel += totalFuel;
    result.totalFleet.idleFuel += idleFuel;

    // Aggregate separately for haulers and loaders
    if (item.asset_type === 'haul_truck') {
      result.haulers.totalFuel += totalFuel;
      result.haulers.idleFuel += idleFuel;
    } else if (item.asset_type === 'loader') {
      result.loaders.totalFuel += totalFuel;
      result.loaders.idleFuel += idleFuel;
    }
  });

  // Calculate percentages for each group
  result.totalFleet.idleFuelPercentage = calculateIdleFuelPercentage(
    result.totalFleet.idleFuel,
    result.totalFleet.totalFuel
  );
  result.haulers.idleFuelPercentage = calculateIdleFuelPercentage(
    result.haulers.idleFuel,
    result.haulers.totalFuel
  );
  result.loaders.idleFuelPercentage = calculateIdleFuelPercentage(
    result.loaders.idleFuel,
    result.loaders.totalFuel
  );

  return result;
}

interface Props {
  value: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  unitSystem: string;
  customerCode: string;
  assetOverview: any;
  loadingStates: utilizationLoadingStates;
  data: utilizationFleetData;
  aggregation: string;
  setAggregation: any;
  shiftNumber: number;
  setShiftNumber: any;
  shiftWiseAnalysis: boolean;
  setShiftWiseAnalysis: any;
  isPrinting: boolean;
  utilizationType: string;
  setUtilizationType: any;
}

const AllFleet = ({
  value,
  enableDarkTheme,
  unitSystem,
  customerCode,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  assetOverview,
  loadingStates,
  data,
  aggregation,
  setAggregation,
  shiftWiseAnalysis,
  setShiftWiseAnalysis,
  shiftNumber,
  setShiftNumber,
  isPrinting,
  utilizationType,
  setUtilizationType,
}: Props) => {
  const summaryData = {
    utilization: data.summary.fleet_avg_utilization_engine_by_shift_hours * 100,
    utilizationDiff:
      data.summary.fleet_avg_utilization_engine_working_minute_diff,
    idling: data.summary.fleet_avg_idling_duration,
    idlingDiff: data.summary.fleet_avg_idling_duration_diff,
    shiftHours: getShiftHours(customerCode) * 60, // Shift hours in mins,
    shiftHoursDiff: 0,
    engineHours: data.summary.fleet_avg_utilization_engine_working_minute,
    engineHoursDiff:
      data.summary.fleet_avg_utilization_engine_working_minute_diff,
    engineActiveMinutes:
      data.summary.fleet_avg_utilization_engine_active_minute,
    engineActiveMinutesDiff:
      data.summary.fleet_avg_utilization_engine_active_minute_diff,
  };

  const aggregatedData = aggregateFuelConsumption(data.assets);
  const haulerAssets = data.assets.filter((item) =>
    ['haul_truck', 'light_truck', 'heavy_truck'].includes(item.asset_type)
  );
  const loaderAssets = data.assets.filter((item) =>
    ['loader'].includes(item.asset_type)
  );

  const haulerBreakDown = {
    productiveHours: data.summary.hauler_total_engine_working_minutes,
    productiveHoursDiff: data.summary.hauler_total_engine_working_minutes_diff,
    idling: data.summary.hauler_total_idling_minutes,
    idlingDiff: data.summary.hauler_total_idling_minutes_diff,
    noOfAssets: data.summary.number_of_haulers,
    dateRange: data.summary.date_range,
    engineHours: data.summary.hauler_total_engine_active_minutes,
    shiftHours: data.summary.hauler_total_shift_hours,
    engineHoursDiff: data.summary.hauler_total_engine_active_minutes_diff,
    shiftHoursDiff: data.summary.hauler_total_shift_hours_diff,
  };

  const loaderBreakDown = {
    productiveHours: data.summary.loader_total_engine_working_minutes,
    productiveHoursDiff: data.summary.loader_total_engine_working_minutes_diff,
    idling: data.summary.loader_total_idling_minutes,
    idlingDiff: data.summary.loader_total_idling_minutes_diff,
    noOfAssets: data.summary.number_of_loaders,
    dateRange: data.summary.date_range,
    engineHours: data.summary.loader_total_engine_active_minutes,
    shiftHours: data.summary.loader_total_shift_hours,
    engineHoursDiff: data.summary.loader_total_engine_active_minutes_diff,
    shiftHoursDiff: data.summary.loader_total_shift_hours_diff,
  };

  return (
    <>
      <CustomTabPanel value={value} index={0}>
        <div>
          <ProductiveHours
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            customerCode={customerCode}
            loadingStates={loadingStates}
            chartData={data.fleetPeriodSummary.fleet}
            detailsData={data.fleetDetails.fleet}
            assetType="all"
          />

          <DateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            yesterday={yesterday}
            ignoreYtd={value === 2}
            aggregation={aggregation}
            setAggregation={setAggregation}
            shiftWiseAnalysis={shiftWiseAnalysis}
            setShiftWiseAnalysis={setShiftWiseAnalysis}
            shiftNumber={shiftNumber}
            setShiftNumber={setShiftNumber}
            customerCode={customerCode}
            utilizationType={utilizationType}
            setUtilizationType={setUtilizationType}
          />

          <GridAnalytics
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            isLoading={loadingStates.isLoadingFleetSummary}
            assetOverview={assetOverview}
            summary={summaryData}
            score={data.score}
            data={data.summaryChart.fleet}
            aggregation={aggregation}
            isSelected={isSelected}
            assetType="total"
            customerCode={customerCode}
            shiftNumber={shiftNumber}
            shiftWiseAnalysis={shiftWiseAnalysis}
          />

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Grid item lg={9} sm={12} md={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <HoursBreakdown
                  enableDarkTheme={enableDarkTheme}
                  title="Hauler Hours Breakdown"
                  isLoading={loadingStates.isLoadingFleetSummary}
                  customerCode={customerCode}
                  data={haulerBreakDown}
                />
                <HoursBreakdown
                  enableDarkTheme={enableDarkTheme}
                  title="Loaders Hours Breakdown"
                  isLoading={loadingStates.isLoadingFleetSummary}
                  customerCode={customerCode}
                  data={loaderBreakDown}
                />
              </Grid>
            </Grid>
            <Suggestions
              enableDarkTheme={enableDarkTheme}
              minHeight="400px"
              data={aggregatedData}
              isLoading={loadingStates.isLoadingFleetSummary}
              startDate={startDate as string}
              isSelected={isSelected}
              assetType="all"
              unitSystem={unitSystem}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              marginTop: isPrinting ? '60%' : null,
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <AssetWrapper
              enableDarkTheme={enableDarkTheme}
              title="Haulers"
              isLoading={loadingStates.isLoadingFleetAssets}
              unitSystem={unitSystem}
              customerCode={customerCode}
              data={haulerAssets}
              isPrinting={isPrinting}
              utilizationType={utilizationType}
            />
            <AssetWrapper
              enableDarkTheme={enableDarkTheme}
              title="Loaders"
              isLoading={loadingStates.isLoadingFleetAssets}
              unitSystem={unitSystem}
              customerCode={customerCode}
              data={loaderAssets}
              isLoaderType={true}
              isPrinting={isPrinting}
              utilizationType={utilizationType}
            />
          </Grid>
        </div>
      </CustomTabPanel>
    </>
  );
};

export default AllFleet;
