import ProductionAPI from 'api/production.api';
import {
  setTotalFleetAssets,
  setTotalFleetSummary,
  setTotalFleetDetails,
  setIsLoading,
  setFleetPeriodSummary,
  setSuggestions,
} from 'store/production.slice';

import { BaseHandler } from './base.handler';

export default class ProductionHandler extends BaseHandler {
  private readonly api: ProductionAPI;

  constructor() {
    super();

    this.api = new ProductionAPI();
  }

  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean,
    assetType: string,
    aggregation: string
  ): Promise<any> {
    const cacheKey = `production_cache_${range}_${assetType}_${aggregation}`;
    const cachedData = sessionStorage.getItem(cacheKey);

    // If cached data exists and range is not Custom, parse it and return
    if (cachedData && range !== 'Custom') {
      const data = JSON.parse(cachedData);
      this.dispatch(setTotalFleetSummary(data.fleetSummary));
      this.dispatch(setTotalFleetAssets(data?.assets));
      this.dispatch(setSuggestions(data.suggestions));
      if (initialRun) {
        this.dispatch(setTotalFleetDetails(data.fleetDetails));
        this.dispatch(setFleetPeriodSummary(data.fleetPeriod));
      }
      return data.assets; // Return cached assets
    }

    let stateChanges: any = {
      isLoadingFleetAssets: true,
      isLoadingFleetSummary: true,
      isLoadingSuggestions: true,
    };

    if (initialRun) {
      stateChanges = {
        ...stateChanges,
        isLoadingFleetDetails: true,
        isLoadingFleetPeriodSummary: true,
      };
    }
    this.dispatch(setIsLoading(stateChanges));

    try {
      // Fire off all the API calls without awaiting them
      const fleetSummaryPromise = this.api.getTotalFleetSummary(
        range,
        startDate,
        endDate,
        shouldSendDate,
        aggregation
      );

      const fleetAssetsPromise = this.api.getTotalFleet(
        range,
        startDate,
        endDate,
        shouldSendDate,
        aggregation
      );

      const suggestionsPromise = this.api.getSuggestions(
        range,
        startDate,
        endDate,
        shouldSendDate,
        assetType,
        aggregation
      );

      let fleetDetailsPromise, fleetPeriodPromise;
      if (initialRun) {
        fleetDetailsPromise = this.api.getTotalFleetProduction(
          assetType as 'all' | 'hauler' | 'loader'
        );
        fleetPeriodPromise = this.api.getFleetPeriodSummary();
      }

      if (initialRun) {
        fleetDetailsPromise
          ?.then((totalFleetDetails) => {
            this.dispatch(
              setTotalFleetDetails(totalFleetDetails.totalFleetDetails)
            );
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet details');
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          });

        fleetPeriodPromise
          ?.then((fleetPeriod) => {
            this.dispatch(setFleetPeriodSummary(fleetPeriod.data));
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet period summary');
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          });
      }

      const fleetSummary = await fleetSummaryPromise;
      this.dispatch(setTotalFleetSummary(fleetSummary));
      this.dispatch(
        setIsLoading({
          isLoadingFleetSummary: false,
        })
      );

      const suggestions = await suggestionsPromise;
      this.dispatch(setSuggestions(suggestions));
      this.dispatch(
        setIsLoading({
          isLoadingSuggestions: false,
        })
      );

      const assets = await fleetAssetsPromise
        .then((fleetAssets) => {
          this.dispatch(setTotalFleetAssets(fleetAssets?.assets));
          this.dispatch(
            setIsLoading({
              isLoadingFleetAssets: false,
            })
          );
          return fleetAssets.assets;
        })
        .catch(() => {
          this.handleError('Error fetching fleet assets');
          this.dispatch(
            setIsLoading({
              isLoadingFleetAssets: false,
            })
          );
          return {};
        });

      // Store the results in session storage
      const dataToCache = {
        fleetSummary,
        suggestions,
        fleetDetails: initialRun ? await fleetDetailsPromise : null,
        fleetPeriod: initialRun ? await fleetPeriodPromise : null,
        assets,
      };

      try {
        sessionStorage.setItem(cacheKey, JSON.stringify(dataToCache));
      } catch (e) {
        console.log('Error setting cache for production');
      }

      return assets; // Return fetched assets
    } catch (_) {
      this.handleError('An error occurred while fetching production details');
      this.dispatch(
        setIsLoading({
          isLoadingFleetAssets: false,
          isLoadingFleetSummary: false,
          isLoadingFleetDetails: false,
          isLoadingFleetPeriodSummary: false,
          isLoadingSuggestions: false,
        })
      );
    }

    return {};
  }
}
