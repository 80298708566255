import { createSlice } from '@reduxjs/toolkit';
import type { DriverBehaviorData } from 'types/driver.behavior.types';

export interface driverBehaviorState {
  data: DriverBehaviorData;
  isLoading: boolean;
  error: {
    timelineError: string | null;
  };
}

const initialState: driverBehaviorState = {
  data: {
    braking: [],
    overspeed: [],
    incorrect_gear: [],
    productive: [],
    idling: [],
    engine_hours: [],
  },
  isLoading: true,
  error: {
    timelineError: null,
  },
};

export const driverBehaviorSlice = createSlice({
  name: 'driverBehavior',
  initialState,
  reducers: {
    getAll(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        isLoading: false,
      };
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setTimelineError(state, { payload }: { payload: string }) {
      state.error.timelineError = payload;
    },
    resetTimelineError(state) {
      state.error.timelineError = null;
    },
  },
});

export const { getAll, setIsLoading, setTimelineError, resetTimelineError } =
  driverBehaviorSlice.actions;
export default driverBehaviorSlice.reducer;
