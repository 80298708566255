import RoughRoadAPI from 'api/rough-road.api';

import { BaseHandler } from './base.handler';
import { updateLoadingState } from 'store/app.slice';

export default class RoughRoadHandler extends BaseHandler {
  private readonly api: RoughRoadAPI;

  constructor() {
    super();

    this.api = new RoughRoadAPI();
  }

  async getRoughRoadPatches(): Promise<any> {
    try {
      const roughRoadData = await this.api.getRoughRoadPatches();

      return roughRoadData;
    } catch (_) {
      this.handleError(
        'An error occurred while fetching rough road patches data.'
      );
    }

    return {
      data: [],
      analysis: {},
    };
  }

  async toggleStatus(
    patchId: string,
    isActive: boolean,
    roadConditionId: string
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const response = await this.api.toggleRoughRoadPatchStatus(patchId);
      if (response.patchId === patchId) {
        this.handleSuccess(
          `${roadConditionId} is now ${
            response.isActive ? 'Activated' : 'Suspended'
          }`
        );
        return response;
      } else {
        throw new Error();
      }
    } catch (error: any) {
      let msg: string = '';

      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while updating road condition: ' + msg
      );
    }
  }
}
