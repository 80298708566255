import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import PathViewer from './PathViewer';
import { FlexBox } from 'components/Containers/FlexBox';
import { useEffect, useMemo, useState } from 'react';
import { Base } from 'components/Containers/BaseContainer';
import GeoHandler from 'handlers/geo.handler';
import { type GeoTrip } from 'types/geo.types';
import { useAppSelector } from 'store/hook';
import TripsAreasChart from './TripsAreaChart';
import DatePicker from 'react-datepicker';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { type Asset } from 'types/asset.types';
import { formatDateShortener } from 'utils/helpers/dateTime';
import NoChartData from '../NoChart';
import {
  getSpecificAssetTypesFromMakeModel,
  resolveAssetTypeDisplay,
} from 'utils/helpers/assets';
import {
  useAssetFromPath,
  useNavigateOnAssetChange,
} from 'hooks/navigationPath';
import { type Device } from 'types/device.types';
import FuelConsumptionChart from './FuelConsumptionChart';
import { selectAssetByMakeModel } from 'store/asset.slice';
import CardSection from './CardSection';
import AllAssetsGeoDataTabs from './Tab';
import {
  productionLabels,
  getFuelLabelWrtUnitSystem,
} from 'utils/helpers/labels';
import { useLocation } from 'react-router-dom';
import { underGroundCustomers } from 'utils/helpers/general';
import { SingleFadeLoader } from 'components/SingleFadeLoader';

const formatCustomMessage = (
  key: string,
  analysis: Record<string, any>,
  unitSystem: string
) => {
  switch (key) {
    case 'total_possible_time_savings':
      if (analysis.total_possible_time_savings > 0) {
        const averageTimePerTripHours = (
          analysis.average_time_per_trip / 3600
        ).toFixed(2);
        const totalTimeInTripsHours = (
          analysis.total_time_in_trips / 3600
        ).toFixed(2);
        const totalPossibleTimeSavingsHours = (
          analysis.total_possible_time_savings / 3600
        ).toFixed(2);

        return (
          <div>
            Average trip time was {averageTimePerTripHours} hours.
            <br />
            Total time taken for trips was {totalTimeInTripsHours} hours.
            <br />
            Total possible time savings based on min. time per trip can be{' '}
            {totalPossibleTimeSavingsHours} hours.
            <br />
            Trips that took more than average time:{' '}
            {analysis.trips_more_than_average_time.join(', ')}
            <br />
            <br />
            {analysis.outlier_trips_based_on_time.length > 0 && (
              <>
                <b>Outlier Trip based on time: </b>
                {analysis.outlier_trips_based_on_time.join(', ')}
              </>
            )}
          </div>
        );
      } else {
        return ``;
      }

    case 'possible_increase_in_tonnage_per_trip':
      if (
        Number(
          Number(analysis.possible_increase_in_tonnage_per_trip).toFixed(2)
        ) > 0
      ) {
        return (
          <div>
            Average load per trip was {analysis.average_ton_per_trip.toFixed(2)}{' '}
            {getFuelLabelWrtUnitSystem(unitSystem, productionLabels.Load)}.
            <br />
            Maximum load per trip was {analysis.max_ton_per_trip.toFixed(
              2
            )}{' '}
            {getFuelLabelWrtUnitSystem(unitSystem, productionLabels.Load)}.
            <br />
            It can be increased by{' '}
            {analysis.possible_increase_in_tonnage_per_trip.toFixed(2)}{' '}
            {getFuelLabelWrtUnitSystem(unitSystem, productionLabels.Load)}.
          </div>
        );
      } else {
        return ``;
      }

    case 'total_possible_distance_savings':
      if (analysis.total_possible_distance_savings > 0) {
        return (
          <div>
            Average distance per trip was{' '}
            {analysis.average_distance_per_trip.toFixed(2)}{' '}
            {getFuelLabelWrtUnitSystem(unitSystem, productionLabels.Distance)}.{' '}
            <br />
            Total distance for trips was {analysis.total_distance.toFixed(
              2
            )}{' '}
            {getFuelLabelWrtUnitSystem(unitSystem, productionLabels.Distance)}.
            <br />
            Total possible distance savings based on min. distance per trip can
            be {analysis.total_possible_distance_savings.toFixed(2)}{' '}
            {getFuelLabelWrtUnitSystem(unitSystem, productionLabels.Distance)}.
            Trips that traveled more than average distance:
            {analysis.trips_more_than_average_distance.join(', ')}
          </div>
        );
      } else {
        return ``;
      }
  }
};

const renderAnalysisData = (
  unitSystem: string,
  analysis: Record<string, any>
) => {
  const timeSavingsMessage = formatCustomMessage(
    'total_possible_time_savings',
    analysis,
    unitSystem
  );
  const tonnageIncreaseMessage = formatCustomMessage(
    'possible_increase_in_tonnage_per_trip',
    analysis,
    unitSystem
  );
  const distanceSavingsMessage = formatCustomMessage(
    'total_possible_distance_savings',
    analysis,
    unitSystem
  );

  return (
    <Grid container spacing={2}>
      {timeSavingsMessage && (
        <Grid item xs={12}>
          <Paper style={{ padding: '10px', margin: '10px' }}>
            <Typography variant="body1">{timeSavingsMessage}</Typography>
          </Paper>
        </Grid>
      )}
      {tonnageIncreaseMessage && (
        <Grid item xs={12}>
          <Paper style={{ padding: '10px', margin: '10px' }}>
            <Typography variant="body1">{tonnageIncreaseMessage}</Typography>
          </Paper>
        </Grid>
      )}
      {distanceSavingsMessage && (
        <Grid item xs={12}>
          <Paper style={{ padding: '10px', margin: '10px' }}>
            <Typography variant="body1">{distanceSavingsMessage}</Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

const Production = (): JSX.Element => {
  const [allTrips, setAllTrips] = useState<GeoTrip[]>();
  const [allTripAnalysis, setAllTripAnalysis] =
    useState<Record<string, unknown>>();
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const geoHandler = new GeoHandler();
  const [selectedAsset, setSelectedAsset] = useState<Asset>({
    assetId: '',
    assetVin: '',
    assetType: 'haul_truck',
    make: '',
    bumperNumber: '',
    engineHoursOffset: -1,
    device: {} as const as Device,
    isActive: false,
    model: '',
    odometerOffset: -1,
    fuelSource: '',
    operators: [],
    externalId: '',
    assetNotes: [],
  });
  const [selectBumperId, setSelectBumperId] = useState<boolean>(false);
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const location = useLocation();
  const [startDate, setStartDate] = useState<Date>(() => {
    const stateDate = location.state?.date;
    return stateDate ? new Date(stateDate) : yesterday;
  });

  const formattedDate = formatDateShortener(startDate);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const getTrips = async () => {
      if (selectedAsset?.device?.deviceId) {
        const dataa = await geoHandler.getTrips(
          selectedAsset.device.deviceId,
          formattedDate
        );
        setAllTrips(dataa.data);
        setAllTripAnalysis(dataa.analysis);
      }
    };

    void getTrips();
    setSelectedTrip(0);
  }, [selectBumperId, startDate, selectedAsset]);

  useEffect(() => {
    setLoading(false);
  }, [allTrips]);

  const { theme, code } = useAppSelector((state) => state.authReducer).customer;
  const isUnderGroundCustomer = underGroundCustomers.includes(code);
  const enableDarkTheme = isDarkTheme(theme);

  const { assets } = useAppSelector((state) => state.assetReducer);
  const assetsByMakeModel = useAppSelector(selectAssetByMakeModel);

  const getHaulTruckMakeModel = getSpecificAssetTypesFromMakeModel(
    assetsByMakeModel,
    'haul_truck'
  );
  const assetMakeModelList = Object.keys(getHaulTruckMakeModel);

  const uniquePairs = new Set();

  assetMakeModelList.forEach((pair) => {
    const [make, model] = pair.split('!');
    uniquePairs.add(`${make}-${model}`);
  });

  const uniquePairsArray = Array.from(uniquePairs).sort((a, b) => {
    const [makeA, modelA] = (a as string).split('-');
    const [makeB, modelB] = (b as string).split('-');
    if (makeA !== makeB) {
      return makeA.localeCompare(makeB);
    }
    return modelA.localeCompare(modelB);
  });

  const [selectedMakeModel, setSelectedMakeModel] = useState<string>(
    uniquePairsArray.length > 0
      ? code === 'klg'
        ? (uniquePairsArray.find((item) => item === 'cat-795f') as string)
        : (uniquePairsArray[0] as string)
      : ''
  );

  // Filter assets based on selected make and model
  const filteredAssets = useMemo(() => {
    const sortedAssets = [...assets];
    sortedAssets.sort((a, b) => a.bumperNumber.localeCompare(b.bumperNumber));
    if (!selectedMakeModel)
      return sortedAssets.filter((asset) => asset.device.deviceId);
    const [selectedMake, selectedModel] = selectedMakeModel.split('-');
    return sortedAssets.filter(
      (asset) =>
        asset.make.toLowerCase() === selectedMake.toLowerCase() &&
        asset.model.toLowerCase() === selectedModel.toLowerCase() &&
        asset.device.deviceId
    );
  }, [selectedMakeModel, assets]);

  // Map the list of filteredAssets to a hashmap
  const assetMap = useMemo(() => {
    const map: Record<Asset['assetVin'], Asset> = {};
    filteredAssets.forEach((asset) => (map[asset.assetVin] = asset));
    return map;
  }, [filteredAssets]);

  useEffect(() => {
    const activeAssets = filteredAssets.find((asset) => asset.isActive);
    if (activeAssets) {
      setSelectedAsset(activeAssets);
    } else if (filteredAssets.length > 0) {
      setSelectedAsset(filteredAssets[0]);
    }
  }, [filteredAssets]);

  useAssetFromPath(assetMap, (asset) => {
    setSelectedAsset(asset);
  });
  useNavigateOnAssetChange(selectedAsset, 'production');

  const handleDateChange = (range: any) => {
    if (range.getTime() !== startDate.getTime()) {
      setStartDate(range);
    }
  };

  const [selectedTrip, setSelectedTrip] = useState(0);
  const [open, setOpen] = useState(false);

  const handleSelecttrip = (trip: number) => {
    setSelectedTrip(trip);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AllAssetsGeoDataTabs
        type={selectedAsset.assetType}
        date={formattedDate}
        geoHandler={geoHandler}
        startDate={startDate}
        analysis={allTripAnalysis ?? {}}
      />
      <Typography
        key="small-info-text"
        variant="body2"
        sx={{
          paddingTop: '0px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Cards shown above depict aggregated data for entire fleet for the
        selected day
      </Typography>
      <Divider
        sx={{
          width: '100%',
          borderColor: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          height: '3px',
        }}
      />
      <Box
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.menuOptions
            : ThemePalette.light.menuOptions,
          height: '120px',
          opacity: 1,
          paddingLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          marginTop: '15px',
        }}
      >
        <FormControl fullWidth style={{ width: '20%' }}>
          <InputLabel
            id="asset-select-label"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Make-Model
          </InputLabel>
          <Select
            labelId="make-model-select-label"
            id="make-model-select"
            value={selectedMakeModel}
            label="Make Model"
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedMakeModel(selectedValue);
            }}
            style={{ width: '80%' }}
            sx={{
              width: '25%',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              ...(enableDarkTheme && {
                '& .MuiSvgIcon-root': {
                  color: 'purple', // <------------------ arrow-svg-color
                },
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: ThemePalette.border, // <------------------ outline-color by default
                  },
                  '&:hover fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions, // Change background color here
                },
              },
            }}
          >
            {uniquePairsArray.map((pair: any) => {
              return (
                <MenuItem
                  key={pair}
                  value={pair}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                  }}
                >
                  {pair}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: '20%' }}>
          <InputLabel
            id="asset-select-label"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Bumper ID
          </InputLabel>
          <Select
            labelId="asset-select-label"
            id="asset-select"
            value={selectedAsset?.assetVin}
            label="Bumper ID"
            onChange={(event) => {
              setSelectedAsset(assetMap[event.target.value]);
              setSelectBumperId(!selectBumperId);
            }}
            style={{ width: '80%' }}
            sx={{
              width: '25%',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              ...(enableDarkTheme && {
                '& .MuiSvgIcon-root': {
                  color: 'purple', // <------------------ arrow-svg-color
                },
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: ThemePalette.border, // <------------------ outline-color by default
                  },
                  '&:hover fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions, // Change background color here
                },
              },
            }}
          >
            {filteredAssets
              .filter((asset) => asset?.device?.deviceId)
              .map((asset) => {
                return (
                  <MenuItem
                    key={asset.assetVin}
                    value={asset.assetVin}
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.lightGrey
                        : ThemePalette.typography.black,
                    }}
                  >
                    {asset.bumperNumber}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Typography
          sx={{
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          Select Date:
        </Typography>
        <DatePicker
          showIcon
          selected={startDate}
          onChange={handleDateChange}
          maxDate={yesterday}
        />
        {Object.keys(allTripAnalysis ?? {}).length > 0 && (
          <Typography
            sx={{
              marginLeft: '100px',
              marginRight: '10px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Show Suggestions
            </Button>
          </Typography>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.menuOptions
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              boxShadow: 'none',
            },
          }}
        >
          <DialogTitle>
            Showing suggestions for Asset {selectedAsset?.bumperNumber} on {}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {renderAnalysisData(unitSystem, allTripAnalysis ?? {})}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {loading ? (
        <div style={{ margin: '5% 45%' }}>
          <SingleFadeLoader />
        </div>
      ) : !allTrips || allTrips.length <= 0 ? (
        <>
          <NoChartData
            message="No data available for the selected time range on this asset"
            enableDarkTheme={enableDarkTheme}
          />
        </>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            marginTop: '20px',
            padding: '10px',
          }}
        >
          <div>
            <span
              style={{
                padding: '16px',
                marginBottom: '40px',
                color: 'grey',
                alignContent: 'right',
              }}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TripsAreasChart
                data={allTrips}
                handleSelectTrip={handleSelecttrip}
                loading={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <CardSection
                selectedTrip={allTrips[selectedTrip]}
                enableDarkTheme={enableDarkTheme}
                ThemePalette={ThemePalette}
                disclaimerMessage={
                  (allTrips[selectedTrip].loading_area === 'NA' ||
                    allTrips[selectedTrip].loading_field === 'NA' ||
                    allTrips[selectedTrip].unloading_area === 'NA' ||
                    allTrips[selectedTrip].unloading_field === 'NA') &&
                  '* Stats might be erroneous due to inadequate trip info'
                }
              />
            </Grid>
            <Grid
              item
              xs={isUnderGroundCustomer ? 12 : 6}
              sx={{ marginTop: '30px' }}
            >
              {isUnderGroundCustomer ? null : (
                <Paper
                  style={{
                    padding: '3px',
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.menuOptions
                      : ThemePalette.light.menuOptions,
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {(allTrips[selectedTrip].loading_area === 'NA' ||
                    allTrips[selectedTrip].loading_field === 'NA' ||
                    allTrips[selectedTrip].unloading_area === 'NA' ||
                    allTrips[selectedTrip].unloading_field === 'NA') && (
                    <div>
                      *Complete trip analysis info might be missing for this
                      particular trip in GPS view due to insufficient data
                      received
                    </div>
                  )}
                  <PathViewer trip={allTrips[selectedTrip]} />
                </Paper>
              )}

              <Paper
                style={{
                  marginTop: '10px',
                  padding: '3px',
                  textAlign: 'center',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                <div>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      paddingLeft: '5px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      marginBottom: '20px',
                    }}
                  >
                    Truck Details
                  </Typography>
                </div>
                <FlexBox
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    padding: '0px 3px',
                  }}
                >
                  <div>
                    <Typography variant="subtitle2">Bumper ID</Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {selectedAsset?.bumperNumber}
                    </Typography>

                    <Typography variant="subtitle2">OEM</Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {selectedAsset?.make}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2">Type</Typography>{' '}
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {selectedAsset?.assetType
                        ? resolveAssetTypeDisplay(selectedAsset.assetType)
                        : null}
                    </Typography>
                    <Typography variant="subtitle2">Model</Typography>{' '}
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {selectedAsset?.model}
                    </Typography>
                  </div>
                </FlexBox>
              </Paper>
            </Grid>
            <Grid
              item
              xs={isUnderGroundCustomer ? 12 : 6}
              sx={{
                marginTop: '30px',
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
              }}
            >
              <Paper
                style={{
                  marginTop: '10px',
                  padding: '3px',
                  textAlign: 'center',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions,
                }}
              >
                <div>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      paddingLeft: '5px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    Fuel Consumption for Trip {allTrips[selectedTrip]?.trip}
                  </Typography>
                </div>
                <Base
                  sx={{
                    height: '400px',
                    padding: '0px 15px',
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.menuOptions
                      : ThemePalette.light.menuOptions,
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  <FuelConsumptionChart
                    data={[
                      {
                        name: 'Loaded Trip',
                        value:
                          allTrips[selectedTrip]
                            ?.loaded_trip_fuel_consumption_l ?? 0,
                      },
                      {
                        name: 'Unloaded Trip',
                        value:
                          allTrips[selectedTrip]
                            ?.unloaded_trip_fuel_consumption_l ?? 0,
                      },
                      {
                        name: 'Loading',
                        value:
                          allTrips[selectedTrip]?.loading_fuel_consumption_l ??
                          0,
                      },
                      {
                        name: 'Unloading',
                        value:
                          allTrips[selectedTrip]
                            ?.unloading_fuel_consumption_l ?? 0,
                      },
                    ]}
                  />
                </Base>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Production;
