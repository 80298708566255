import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { type Asset } from 'types/asset.types';
import { type Device } from 'types/device.types';
import { useEffect, useState } from 'react';
import Header from './Header';
import MainBody from './MainBody';
import { getAssetMakeModel } from 'views/FleetOverview/components/AssetCardsV2/utils';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
import HealthIndexHandler from 'handlers/healthIndex.handler';

const HealthIndex = () => {
  const healthIndexHandler = new HealthIndexHandler();

  const { latestTagData } = useAppSelector((state) => state.deviceReducer);
  const hierarchyInfo = useAppSelector((state) => state.hierarchyReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const { isLoading } = useAppSelector((state) => state.appReducer);

  const { assets } = useAppSelector((state) => state.assetReducer);
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const defaultAsset: string | null = new URLSearchParams(location.search).get(
    'asset'
  );
  const makeModels = getAssetMakeModel(assets);

  const [assetBumperIds, setAssetBumperIds] = useState<string[]>([]);

  const [selectedMakeModel, setSelectedMakeModel] = useState(() => {
    if (makeModels.length > 0) {
      return makeModels[0];
    } else {
      return '';
    }
  });

  const [selectedAsset, setSelectedAsset] = useState<Asset>({
    assetId: '',
    assetVin: '',
    assetType: 'haul_truck',
    make: '',
    bumperNumber: '',
    engineHoursOffset: -1,
    device: {} as const as Device,
    isActive: false,
    model: '',
    odometerOffset: -1,
    fuelSource: '',
    operators: [],
    externalId: '',
    assetNotes: [],
  });
  const [tripDetails, setTripDetails] = useState<any>();

  const handleSelectAsset = (bumperNumber: string) => {
    const item = assets.find((asset) => asset.bumperNumber === bumperNumber);
    if (item) {
      setSelectedAsset(item);
    }
  };

  useEffect(() => {
    const filteredAssets = assets
      .filter(
        (asset) =>
          composeUniqueKey([asset.make, asset.model]) ===
          selectedMakeModel.replace('-', '!')
      )
      .map((item) => {
        return item.bumperNumber;
      });
    setAssetBumperIds(filteredAssets);
    handleSelectAsset(filteredAssets[0]);
  }, [selectedMakeModel]);

  useEffect(() => {
    if (defaultAsset) {
      const defaultAssetDetails = assets.find(
        (item) => item.bumperNumber === defaultAsset
      );
      if (defaultAssetDetails) {
        const assetMakeModel = composeUniqueKey(
          [defaultAssetDetails.make, defaultAssetDetails.model],
          '-'
        );
        setSelectedAsset(defaultAssetDetails);
        setSelectedMakeModel(assetMakeModel);
      }
    } else {
      setSelectedAsset(assets[0]);
    }
  }, []);

  const getHealthIndexData = async () => {
    const { tripInfo }: any = await healthIndexHandler.getHealthData(
      selectedAsset
    );
    if (tripInfo) {
      setTripDetails(tripInfo.value);
    } else {
      console.error('Failed to fetch trip info');
    }
  };

  useEffect(() => {
    if (
      selectedAsset &&
      selectedAsset.assetId !== '' &&
      selectedAsset.device?.deviceId
    ) {
      void getHealthIndexData();
    }
  }, [selectedAsset]);

  return (
    <>
      <Header
        enableDarkTheme={enableDarkTheme}
        latestTagData={latestTagData}
        hierarchyInfo={hierarchyInfo}
        selectedAsset={selectedAsset}
        makeModels={makeModels}
        assetBumperIds={assetBumperIds}
        setSelectedAsset={handleSelectAsset}
        selectedMakeModel={selectedMakeModel}
        setSelectedMakeModel={setSelectedMakeModel}
      />
      {!isLoading && (
        <MainBody
          enableDarkTheme={enableDarkTheme}
          tripDetails={tripDetails}
          unitSystem={unitSystem}
          makeModel={`${selectedAsset.make.toLowerCase()}${selectedAsset.model.toLowerCase()}`}
        />
      )}
    </>
  );
};

export default HealthIndex;
