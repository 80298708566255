import { createSlice } from '@reduxjs/toolkit';
import type { Notification } from 'types/notification.types';
import type { ICreateRulePayload } from 'types/payloads/rule.payload.types';

export interface RuleState {
  rules: Notification[];
  newRule: ICreateRulePayload;
  isClonedStatus: boolean;
}

const initialState: RuleState = {
  rules: [],
  newRule: {
    ruleName: '',
    assets: [],
    conditions: [],
    alertByEmail: false,
    alertBySms: false,
    alertByUI: false,
    alertByNotificationCenter: false,
    distributionUsers: [],
    notificationLevel: '1',
    smsUsers: [],
  },
  isClonedStatus: false,
};

export const ruleSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    updateNewRule(
      state,
      { payload }: { payload: typeof initialState.newRule }
    ) {
      state.newRule = payload;
    },
    resetCreateRule(state) {
      state.newRule = initialState.newRule;
      state.isClonedStatus = initialState.isClonedStatus;
    },
    setIsClonedStatus(state, { payload }) {
      state.isClonedStatus = payload;
    },

    appendRuleCondition(state, { payload }) {
      /*
       * This action adds a conditon to the newRule attribute if it does not exist
       */
      const isCloned =
        state.newRule.ruleName.endsWith('- copy') || state.isClonedStatus;

      if (!state.newRule.conditions) {
        state.newRule.conditions = [payload];
        return;
      }

      const existingConditionIndex = state.newRule.conditions.findIndex((c) =>
        isCloned
          ? payload?.conditionId
            ? c.conditionId === payload.conditionId
            : c.id === payload.id
          : c.id === payload.id
      );

      if (existingConditionIndex !== -1) {
        // Update existing condition
        state.newRule.conditions[existingConditionIndex] = {
          ...state.newRule.conditions[existingConditionIndex],
          ...payload,
        };
      } else {
        // Append new condition
        state.newRule.conditions = [...state.newRule.conditions, payload];
      }
    },

    /*
     * This action removes an item from the condition array
     */
    removeRuleCondition(state, { payload }) {
      if (state.newRule.conditions) {
        const updatedConditions = state.newRule.conditions.filter(
          (c) => c.id !== payload
        );
        state.newRule.conditions = updatedConditions;
      }
      return state;
    },
    /*
     * This action updates the notification attributes in the newRule Attribute
     */
    addRuleNotification(state, { payload }) {
      const {
        alertByEmail,
        alertBySms,
        alertByUI,
        alertByNotificationCenter,
        distributionUsers,
        notificationLevel,
        smsUsers,
      } = payload;
      const updatedRule = {
        ...state,
        newRule: {
          ...state.newRule,
          alertByEmail,
          alertBySms,
          alertByUI,
          alertByNotificationCenter,
          distributionUsers,
          notificationLevel,
          smsUsers,
        },
      };
      return updatedRule;
    },
    appendRule(state, { payload }) {
      state.rules.push(payload);
      state.newRule = initialState.newRule;
    },
    setRules(state, { payload }) {
      state.rules = payload;
    },
    updateRuleList(state, { payload }: { payload: Notification }) {
      const index = state.rules.findIndex(
        (rule) => rule.ruleId === payload.ruleId
      );

      if (index === -1) {
        // do not update the state if the operator is not found
        return;
      }
      state.rules[index] = payload;
    },
    updateRuleStatus(state, { payload }) {
      const updatedRules = state.rules.map((rule) => {
        if (rule.ruleId === payload.ruleId) {
          return {
            ...rule,
            ...payload,
          };
        }
        return rule;
      });
      state.rules = updatedRules;
    },
    removeRule(state, { payload }) {
      const updatedRules = state.rules.filter(
        (rule) => rule.ruleId !== payload.ruleId
      );
      state.rules = updatedRules;
    },
  },
});

export const {
  updateNewRule,
  resetCreateRule,
  appendRuleCondition,
  removeRuleCondition,
  addRuleNotification,
  appendRule,
  setRules,
  updateRuleList,
  updateRuleStatus,
  removeRule,
  setIsClonedStatus,
} = ruleSlice.actions;

export default ruleSlice.reducer;
