import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';

const InfoPanel = ({
  enableDarkTheme,
  header,
  items,
  sx,
}: {
  enableDarkTheme: boolean;
  header: string;
  items: Array<Record<string, unknown>>;
  sx: any;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: enableDarkTheme ? '#313F53 solid 2px' : '#cdd1d6 solid 2px',
        borderRadius: '5px',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        minWidth: '250px',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          height: '39px',
          backgroundColor: '#3666EC',
          borderTopRightRadius: '5px',
          borderTopLeftRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <Typography
          sx={{ fontSize: '12px', fontWeight: '600', paddingLeft: '10px' }}
        >
          {header}
        </Typography>
      </Box>

      {items.map((item: any, index) => {
        return (
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '5px 0',
            }}
            key={index}
          >
            <Typography
              sx={{ fontSize: '10px', fontWeight: '600', paddingLeft: '10px' }}
            >
              {item.name}{' '}
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: '300',
                  color: enableDarkTheme ? '#878A99' : '#878A99',
                  textAlign: 'left',
                  display: 'block',
                  marginTop: '-15px',
                }}
              >
                <br />
                {item.desc}
              </span>
            </Typography>

            <Box
              sx={{
                width: '35%',
                textAlign: 'center',
                borderRadius: '5px',
                height: '25px',
                backgroundColor:
                  item.status === 'OK'
                    ? '#009161'
                    : item.status === 'ALERT'
                    ? '#EB5757'
                    : item.status === 'WARNING'
                    ? '#d19929'
                    : '#e0e0e0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
              }}
            >
              <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>
                {item.status}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default InfoPanel;
